import { z } from 'zod'

/** Represents the enum public.site_status_type_ */
enum SiteStatusType {
  ACTIVE = 'ACTIVE',
  RETIRED = 'RETIRED',
}

export default SiteStatusType

/** Zod schema for site_status_type_ */
export const siteStatusType = z.enum(['ACTIVE', 'RETIRED'])
