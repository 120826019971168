import { Button, Tooltip } from '@chakra-ui/react'
import { useState } from 'react'

import { cable as cableApi } from 'api'

export const useBulkExport = () => {
  const [cables, setCables] = useState<number[]>([])
  const [mode, setMode] = useState<'SELECT' | 'OFF'>('OFF')

  const toggleIncludedCable = (id: number, checked: boolean) => {
    const newCables = new Set(cables)

    if (checked) {
      newCables.add(id)
    }
    else {
      newCables.delete(id)
    }

    setCables(Array.from(newCables))
  }

  const handleButtonState = (e: any, cablesInput?: any[]) => {
    if (mode === 'OFF') {
      setMode('SELECT')
    }
    else if (mode === 'SELECT' && cables.length > 0) {
      cableApi.admin.downloadBulkFishbowlCsv(cables)
      setMode('OFF')
      setCables([])
    }
    else if (mode === 'SELECT') {
      const ids = cablesInput
        ?.filter((c) => c.serial !== undefined)
        ?.map((c) => c.id)
      if (ids) {
        setCables(ids)
      }
    }
  }

  const handleXmlExport = (e: any, cablesInput?: any[]) => {
    if (mode === 'OFF') {
      setMode('SELECT')
    }
    else if (mode === 'SELECT' && cables.length > 0) {
      cableApi.admin.downloadMultipleCutterXml(cables)
      setMode('OFF')
      setCables([])
    }
    else if (mode === 'SELECT') {
      const ids = cablesInput
        ?.filter((c) => c.serial !== undefined)
        ?.map((c) => c.id)
      if (ids) {
        setCables(ids)
      }
    }
  }

  const BulkExportButton = ({ cablesInput }: { cablesInput?: any[] }) => {
    const tooltipText =
      mode === 'OFF'
        ? 'Select cables to export'
        : mode === 'SELECT' && cables.length > 0
          ? 'Export selected cables'
          : 'Select all cables'

    const buttonText =
      mode === 'OFF'
        ? 'Bulk Export'
        : mode === 'SELECT' && cables.length > 0
          ? 'Export Selected'
          : 'Select All'

    return (
      <Tooltip label={tooltipText}>
        <Button onClick={(e) => handleButtonState(e, cablesInput)}>
          FB: {buttonText}
        </Button>
      </Tooltip>
    )
  }

  const BulkXmlButton = ({ cablesInput }: { cablesInput?: any[] }) => {
    const tooltipText =
      mode === 'OFF'
        ? 'Select cables to export'
        : mode === 'SELECT' && cables.length > 0
          ? 'Export selected cables'
          : 'Select all cables'

    const buttonText =
      mode === 'OFF'
        ? 'Bulk Export'
        : mode === 'SELECT' && cables.length > 0
          ? 'Export Selected'
          : 'Select All'

    return (
      <Tooltip label={tooltipText}>
        <Button onClick={(e) => handleXmlExport(e, cablesInput)}>
          Cutter: {buttonText}
        </Button>
      </Tooltip>
    )
  }

  return {
    toggleIncludedCable,
    cables,
    mode,
    BulkExportButton,
    BulkXmlButton,
  }
}
