import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Portal,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { createContext, useContext, useState } from 'react'
import { FaWindowMaximize, FaWindowMinimize } from 'react-icons/fa'

import { useDraggable } from 'hooks/useDraggable'

import { TerminalEmulator } from './TerminalEmulator'

const IrcConext = createContext({
  isOpen     : false,
  onOpen     : () => {},
  onClose    : () => {},
  isMaximized: false,
  onMaximize : () => {},
  onMinimize : () => {},
  addChannel : (channel: string) => {},
  channels   : new Set<string>(),
})

export const useIrcContext = () => useContext(IrcConext)

export const ProvideIrcContext = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isMaximized,
    onOpen: onMaximize,
    onClose: onMinimize,
  } = useDisclosure({ defaultIsOpen: true })
  const [channels, setChannels] = useState(new Set<string>())

  const addChannel = (channel: string) => {
    if (channels.has(channel)) {
      onMaximize()
      if (!isOpen) onOpen()
    }
    else {
      setChannels((prev) => prev.add(channel))
      onMaximize()
      onOpen()
    }
  }

  return (
    <IrcConext.Provider
      value={{
        isOpen,
        onOpen,
        onClose,
        isMaximized,
        onMaximize,
        onMinimize,
        addChannel,
        channels,
      }}
    >
      <IrcModule>{children}</IrcModule>
    </IrcConext.Provider>
  )
}

interface IrcModuleProps {
  children?: React.ReactNode
}

export const IrcModule = ({ children }: IrcModuleProps) => {
  const { isOpen, onClose, isMaximized, onMaximize, onMinimize } =
    useIrcContext()
  const { onMouseDown, onMouseMove, onMouseUp, position, elemRef, hasMoved } =
    useDraggable()
  const borderColor = useColorModeValue('gray.300', 'gray.700')

  return (
    <>
      {children}
      <Portal>
        {!isMaximized && (
          <Box padding={2} bottom={0} right={0} position='fixed'>
            <IconButton
              onClick={onMaximize}
              aria-label='Open IRC'
              icon={<FaWindowMaximize />}
            />
          </Box>
        )}

        <Modal
          closeOnOverlayClick={false}
          onClose={onClose}
          isOpen={isOpen}
          isCentered={!hasMoved}
          blockScrollOnMount={false}
          scrollBehavior='inside'
          variant={isMaximized ? 'alwaysOpen' : 'minimized'}
          size='2xl'
        >
          <ModalContent
            maxH='100vh'
            marginTop='0'
            marginBottom='0'
            {...position}
            {...(hasMoved ? { position: 'absolute' } : {})}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
            onMouseLeave={onMouseUp}
            ref={elemRef}
            borderColor={borderColor}
            borderWidth='1px'
          >
            <ModalHeader onMouseDown={onMouseDown} cursor='move'>
              Logger IRC
            </ModalHeader>
            <Button
              onClick={onMinimize}
              aria-label='Minimize IRC'
              outline='2px solid transparent'
              outlineOffset='2px'
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexShrink={0}
              borderRadius='md'
              position='absolute'
              fontSize='12px'
              top={2}
              right={10}
              backgroundColor='transparent'
              padding={0}
              height='32px'
              width='32px'
              sx={{
                '&:hover': {
                  backgroundColor: 'whiteAlpha.100',
                },
              }}
            >
              <FaWindowMinimize />
            </Button>
            <ModalCloseButton />
            <ModalBody padding={0}>
              <TerminalTabs />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Portal>
    </>
  )
}

const TerminalTabs = () => {
  const { channels } = useIrcContext()

  return (
    <Tabs size='md'>
      <TabList>
        {[...channels].map((channel) => (
          <Tab key={channel}>{channel}</Tab>
        ))}
      </TabList>
      <TabPanels>
        {[...channels].map((channel) => (
          <TabPanel key={channel} padding={0}>
            <TerminalEmulator channel={channel} />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}
