import { LockIcon, UnlockIcon } from '@chakra-ui/icons'
import {
  Input,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  useBoolean,
  IconButton,
  HStack,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import type { FormikValues } from 'formik'

import { InfoTooltip } from 'components/InfoTooltip'

import { InputWrapperProps } from '../lib/FieldWrapper'

export const DefaultInput = ({
  left,
  right,
  placeholder,
  label,
  name,
  field,
  type,
  requiresGlobalAdmin = false,
  global = { view: false, edit: false, admin: false },
  format,
  tooltip,
  onChange,
  isNumber,
  shouldBeVisible,
  style = {},
}: InputWrapperProps) => {
  const [locked, setLocked] = useBoolean(true)
  const { setFieldValue, values } = useFormikContext()

  const visible =
    typeof shouldBeVisible === 'function'
      ? shouldBeVisible(values as FormikValues)
      : typeof shouldBeVisible === 'boolean'
        ? shouldBeVisible
        : true

  let value = field.value
  if (format) value = format(field.value)

  if (left || right) {
    return (
      <>
        {label && visible && <FormLabel htmlFor={name}>{label}</FormLabel>}
        <HStack {...(visible === false ? { visibility: 'hidden' } : {})}>
          <InputGroup>
            {left && <InputLeftAddon children={left} />}
            <Input
              type={type}
              placeholder={placeholder ?? name}
              isDisabled={locked && requiresGlobalAdmin}
              {...field}
              value={value}
              {...(typeof onChange === 'function'
                ? {
                  onChange: (e) => {
                    onChange(e, setFieldValue, values)
                    field.onChange(e)
                  },
                }
                : {})}
            />
            {right && <InputRightAddon children={right} />}
          </InputGroup>
          {tooltip && <InfoTooltip text={tooltip} />}

          {requiresGlobalAdmin && global.edit && (
            <IconButton
              icon={locked ? <LockIcon /> : <UnlockIcon />}
              onClick={setLocked.toggle}
              aria-label={locked ? 'locked' : 'unlocked'}
            />
          )}
        </HStack>
      </>
    )
  }

  return (
    <>
      {label && visible && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <HStack {...(visible === false ? { visibility: 'hidden' } : {})}>
        <Input
          type={type}
          placeholder={placeholder ?? name}
          isDisabled={locked && requiresGlobalAdmin}
          {...field}
          value={value}
          {...(typeof onChange === 'function'
            ? {
              onChange: (e) => {
                onChange(e, setFieldValue, values)
                field.onChange(e)
              },
            }
            : {})}
          {...(type === 'label'
            ? { isReadOnly: true, border: 0, tabIndex: -1 }
            : {})}
          {...(type === 'label' && isNumber === true
            ? {
              textAlign         : 'right',
              fontFamily        : 'monospace',
              paddingInlineStart: 1,
              paddingInlineEnd  : 1,
              fontSize          : '0.95rem',
            }
            : {})}
          {...style}
        />
        {tooltip && <InfoTooltip text={tooltip} />}

        {requiresGlobalAdmin && global.edit && (
          <IconButton
            icon={locked ? <LockIcon /> : <UnlockIcon />}
            onClick={setLocked.toggle}
            aria-label={locked ? 'locked' : 'unlocked'}
          />
        )}
      </HStack>
    </>
  )
}
