import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  Text,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { BaseInputProps } from 'forms/helpers/types'

type TextareaInputProps = BaseInputProps & {
  rows?: number
}

export const TextareaInput = (props: TextareaInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <FormControl
      isRequired={Boolean(props?.hookformProps?.required)}
      isInvalid={Boolean(errors[props.name])}
    >
      <FormLabel htmlFor={props.name}>{props.label ?? props.name}</FormLabel>
      {props.description && <Text>{props.description}</Text>}
      <Textarea
        rows={props.rows ?? 6}
        {...register(props.name, props.hookformProps)}
      />
      <FormErrorMessage>{errors[props.name]?.message}</FormErrorMessage>
    </FormControl>
  )
}
