import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

/** Represents the table public.event_timestamp_ */
export default interface EventTimestampTable {
  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<string | null, string | null, string | null>
}

export type EventTimestamp = Selectable<EventTimestampTable>

export type EventTimestampInitializer = Insertable<EventTimestampTable>

export type EventTimestampMutator = Updateable<EventTimestampTable>

export const eventTimestamp = z.object({
  createdAt: z.coerce.date().nullable().optional(),
  createdBy: z.string().nullable().optional(),
  updatedAt: z.coerce.date().nullable().optional(),
  updatedBy: z.string().nullable().optional(),
  deletedAt: z.coerce.date().nullable().optional(),
  deletedBy: z.string().nullable().optional(),
}) as unknown as z.Schema<EventTimestamp>

export const eventTimestampInitializer = z.object({
  createdAt: z.coerce.date().optional().nullable().optional(),
  createdBy: z.string().optional().nullable().optional(),
  updatedAt: z.coerce.date().optional().nullable().optional(),
  updatedBy: z.string().optional().nullable().optional(),
  deletedAt: z.coerce.date().optional().nullable().optional(),
  deletedBy: z.string().optional().nullable().optional(),
}) as unknown as z.Schema<EventTimestampInitializer>

export const eventTimestampMutator = z.object({
  createdAt: z.coerce.date().optional().nullable().optional(),
  createdBy: z.string().optional().nullable().optional(),
  updatedAt: z.coerce.date().optional().nullable().optional(),
  updatedBy: z.string().optional().nullable().optional(),
  deletedAt: z.coerce.date().optional().nullable().optional(),
  deletedBy: z.string().optional().nullable().optional(),
}) as unknown as z.Schema<EventTimestampMutator>
