import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'

import type { Obj } from 'types/common'

import * as apiUtils from '.'

const url = '/cable-prices'

export const getCurrent = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}`, merge({ params: { type: 'current' } }, options))

export const getAll = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, options)

export const create = (data: Obj) => apiUtils.POST(`${url}/`, data)

export const get = (id: string, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/${id}`, options)
