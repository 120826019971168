import { Button, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { DataDump } from 'components/DataDump'
import { Timer } from 'components/Timer'

import { Card } from 'containers/Card'

import { useRouter } from 'hooks/useRouter'
import { useUserContext } from 'hooks/useUserContext'

const redirectingTimer = 4 * 1000

type ErrorCardProps = {
  errors: Array<any>
}

export const ErrorCard = ({ errors = [] }: ErrorCardProps) => {
  const { global, logout } = useUserContext()

  const uniqueErrors = Array.from(new Set(errors))

  const { push } = useRouter()
  const [redirecting, setRedirecting] = useState(
    uniqueErrors.length === 1 && uniqueErrors.includes('access denied'),
  )
  const [notLoggedIn] = useState(
    JSON.stringify(uniqueErrors).includes('not logged in'),
  )

  useEffect(() => {
    const timeout = setTimeout(() => push('/'), redirectingTimer)

    !redirecting && clearTimeout(timeout)
    return () => clearTimeout(timeout)
  }, [redirecting, push])

  useEffect(() => {
    if (notLoggedIn) logout()
  }, [notLoggedIn, logout])

  return redirecting ? (
    <Card>
      <Text>You shouldn't be here!</Text>
      <Text>Redirecting...</Text>
      <Timer ms={redirectingTimer} />
      {global.view ? (
        <Button onClick={() => setRedirecting(false)}>
          Cancel Redirecting
        </Button>
      ) : (
        <></>
      )}
    </Card>
  ) : (
    <ErrorCardUI errors={uniqueErrors} />
  )
}

export const ErrorCardUI = ({ errors }: ErrorCardProps) => (
  <DataDump name='Error' data={errors} />
)
