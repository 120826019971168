import { Obj } from 'types/common/Obj'

export type Diff = {
  key: string
  old: any
  new: any
}

type NewChangeLog = {
  id: number
  changeActions: Obj
  objId: number
  objType: string
  createdAt: Date | string
  createdBy: number
}

type OldChangeLog = {
  by_user: {
    id: number
    username: string
  }
  v: number
  changes: Obj
}

export type IncomingChangelog = NewChangeLog | OldChangeLog

export type ProcessedChangeLog = {
  by_user: {
    id: number
    username: string
  }
  date: Date
  changes: Diff[]
}

export const parseChangeLog = (changelog: IncomingChangelog[]) => {
  if (!changelog) return []

  const preparedChangelog = changelog.map(parseIncomingChangeLog)

  return preparedChangelog
}

export const parseIncomingChangeLog = (
  changelog: IncomingChangelog,
): ProcessedChangeLog => {
  if ('id' in changelog) {
    // new changelog
    return {
      by_user: {
        id      : changelog.createdBy,
        // @TODO: get username from user table in the future
        username: 'n/a',
      },
      date   : new Date(changelog.createdAt),
      changes: parseDiff('', changelog.changeActions),
    }
  }

  const date =
    changelog.v ??
    changelog.changes.ts__added.created ??
    changelog.changes.ts__added.updated ??
    0

  // else old changelog
  return {
    by_user: changelog.by_user,
    date   : new Date(date),
    changes: parseDiff('', changelog.changes),
  }
}

export const parseDiff = (prop_str: string, diff: Obj): Diff[] => {
  if (Object.keys(diff).length === 0) return []

  if (typeof diff === 'string') {
    if (prop_str.endsWith('Added')) {
      return [
        {
          key: `${prop_str.replace('Added', '')} added`,
          old: undefined,
          new: diff,
        },
      ]
    }
    return [{ key: prop_str, old: diff, new: diff }]
  }

  const diffs: Array<Diff> = []

  try {
    if (Array.isArray(diff)) {
      const oldItems = []
      const newItmes = []
      for (const idx in diff) {
        if (diff[idx][0] === '+') newItmes.push(diff[idx][1])
        if (diff[idx][0] === '-') oldItems.push(diff[idx][1])
        if (diff[idx][0] === '~') {
          diffs.push(...parseDiff(`${prop_str}.${idx}`, diff[idx][1]))
          return diffs
        }
      }
      diffs.push({ key: prop_str, old: oldItems, new: newItmes })
      return diffs
    }
  }
  catch (e) {
    console.info('error parsing diff', prop_str, diff)
    console.error(e)
  }

  try {
    const keys = Object.keys(diff)
    if (keys.length === 2 && keys.includes('__old') && keys.includes('__new')) {
      diffs.push({ key: prop_str, old: diff.__old, new: diff.__new })
      return diffs
    }
    else if (
      keys.length === 2 &&
      keys.includes('_Old') &&
      keys.includes('_New')
    ) {
      diffs.push({ key: prop_str, old: diff._Old, new: diff._New })
      return diffs
    }
  }
  catch (e) {
    console.info('error parsing diff', prop_str, diff)
    console.error(e)
  }

  try {
    Object.entries(diff).forEach(([key, data]) => {
      if (key.includes('__added')) {
        diffs.push({
          key: prop_str
            ? `${prop_str}.${key.split('__')[0]}`
            : key.split('__')[0],
          old: undefined,
          new: data,
        })
        return
      }
      if (key.includes('__deleted')) {
        diffs.push({
          key: prop_str
            ? `${prop_str}.${key.split('__')[0]}`
            : key.split('__')[0],
          old: data,
          new: undefined,
        })
        return
      }

      diffs.push(...parseDiff(prop_str ? `${prop_str}.${key}` : key, data))
    })
  }
  catch (e) {
    console.info('error parsing diff', diff)
    console.error(e)
  }

  return diffs
}
