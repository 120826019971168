import memoize from 'memoizee'

export const getPrecision = memoize((units: string) => {
  switch (units) {
    case 'ft':
    case 'FT':
      return 2
    case 'in':
    case 'IN':
      return 1
    case 'cm':
    case 'CM':
      return 1
    case 'm':
    case 'M':
      return 3
    case 'mm':
    case 'MM':
      return 0
    default:
      return 0
  }
})
