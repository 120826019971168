import {
  Center,
  Checkbox,
  Table,
  Thead,
  Text,
  Tbody,
  Td,
  Th,
  Tr,
  AccordionPanel,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Accordion,
  Box,
} from '@chakra-ui/react'

import { GlobalRoleType, UserWithPermissions } from '@beaded/models'

import { perm } from 'api'

import { queryClient } from 'lib/react-query'

interface GlobalBlockProps {
  user: UserWithPermissions
  lastSection: boolean
}

type Level = 'VIEW' | 'EDIT' | 'ADMIN'
const levels: Level[] = ['ADMIN', 'EDIT', 'VIEW']

type Category = 'GLOBAL' | 'CABLE' | 'LOGGER' | 'SALES'
const categories: Category[] = ['GLOBAL', 'CABLE', 'LOGGER', 'SALES']

export const GlobalBlock = ({ user, lastSection }: GlobalBlockProps) => {
  const checkField = async (
    category: Category,
    level: Level,
    value: boolean,
  ) => {
    const role = `${category}_${level}` as GlobalRoleType

    if (value === true) await perm.addGlobalRole(user.id, role)
    else await perm.removeGlobalRole(user.id, role)

    queryClient.invalidateQueries(['user', { id: user.id }])
  }

  return (
    <>
      <Accordion allowToggle mb={6}>
        <AccordionItem>
          <AccordionButton>
            <Box flex='1' textAlign='left' fontWeight='semibold'>
              Global Permissions
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text fontWeight='semibold' mb={4}>
              This section only appears if a user belongs to the beadedstream
              organization.
            </Text>
            <Text fontWeight='semibold' mb={4}>
              This section grants permissions for the entire application. This
              should only be granted to users who need to be able to access all
              data and features in beadedcloud.
            </Text>
            <Table size='sm' mb={8}>
              <Thead>
                <Tr>
                  <Th colSpan={2}></Th>
                  <Th>
                    <Center>Admin</Center>
                  </Th>
                  <Th>
                    <Center>Edit</Center>
                  </Th>
                  <Th>
                    <Center>View</Center>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td colSpan={5} fontWeight='semibold'></Td>
                </Tr>
                {categories.map((category) => (
                  <Tr key={category}>
                    <Td colSpan={2} fontWeight='semibold'>
                      {category[0].toUpperCase() + category.slice(1)}
                    </Td>
                    {levels.map((level: Level) => (
                      <Td key={`${category}-${level}`}>
                        {category !== 'GLOBAL' && level !== 'ADMIN' ? (
                          <></>
                        ) : (
                          <Center>
                            <Checkbox
                              isChecked={user.permissions?.global?.some(
                                (o) => o.role === `${category}_${level}`,
                              )}
                              onChange={(e) =>
                                checkField(category, level, e.target.checked)
                              }
                            />
                          </Center>
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  )
}
