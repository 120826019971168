import { sprintf } from 'sprintf-js'

import StpDoc from './stp-doc.js'

export const filterReadings = function (doc, opts) {
  if (opts === undefined) return
  if (!Array.isArray(opts?.decode) || opts.decode.length === 0) return
  // if (!doc?.readings?.length) return

  if (opts.decode.length === 1 && opts.decode[0].serial === undefined) {
    const filteredReadings = []
    for (const sensor_num of opts.decode[0].order) {
      filteredReadings.push(doc?.readings?.[sensor_num - 1] ?? -901)
    }
    doc.readings = filteredReadings
  }
  else if (opts.decode.every((v) => v.serial !== undefined)) {
    // this is assumed to be v11
    for (const c of doc.cable) {
      if (!c.serial) continue
      const filteredReadings = []
      const decode = opts.decode.find((v) => v.serial === c.serial)
      for (const sensor_num of decode.order) {
        filteredReadings.push(c?.readings?.[sensor_num - 1] ?? -901)
      }
      c.readings = filteredReadings
    }
  }
}

export const applyOffsets = function (doc, opts) {
  if (!doc.readings || !opts.sensor || !opts.offsets) return
  if (doc.is_calibrated) return // v4 & v5 may have this set

  const offsets = csvAsFloats(opts.offsets)

  for (let i = 0; i < doc.readings.length; i++) {
    if (doc.readings[i] === undefined) continue
    doc.readings[i] = +(doc.readings[i] - offsets[i]).toFixed(4)
  }
}

export const csvAsFloats = function (str) {
  const result = []
  for (const s of str.split(',')) {
    if (isNaN(parseFloat(s))) throw new Error(`could not coerce to float: ${s}`)
    result.push(parseFloat(s))
  }
  return result
}

export const get_air_temp = function (doc, raw, position) {
  const included = (raw.readUInt8(0) & 0b00010000) >> 4
  if (!included) return
  if (position >= raw.length) return

  switch (doc.data.format) {
    case 3:
      break
    case 4:
    case 5:
    case 6:
      doc.set('site.air_temp', raw.readInt8(position))
      // console.log(`v${doc.data.format} air_temp: ${doc.site.air_temp} in doc ${doc.id}`)
      position++
      break
    case 7:
      doc.set('site.air_temp', raw.readInt16LE(position) / 100)
      // console.log(`v${doc.data.format} air_temp: ${doc.site.air_temp} in doc ${doc.id}`)
      position += 2
      break
    default:
      throw new Error(
        `version ${doc.data.format} not supported by 'get_air_temp'`,
      )
  }
}

export const get_logger_voltage = function (ver, byte) {
  let v

  switch (ver) {
    case 3:
      v = round(byte * 0.05 + 2)
      if (v < 2) {
        console.info('correcting v3 logger battery rollover')
        // correct readings over 14.75 that rolled over to 2 (from grab_data.php)
        v = 14.75 + v - 2
      }
      return v
    case 4:
      return round(byte / 15 + 2)
    case 5:
    case 6:
    case 7:
      return round(byte / 50 + 5)
    default:
      throw new Error(`version ${ver} not supported by get_logger_voltage`)
  }
}

export const assemble_record_date = function (doc) {
  if (!doc.ts.year) return

  doc.set(
    'ts.record',
    new Date(
      `${doc.ts.year}-${doc.ts.month}-${doc.ts.day} ${doc.ts.hour}:${doc.ts.minute}:00Z`,
    ),
  )
  if (isNaN(doc.ts?.record?.getTime())) {
    delete doc.ts.record
  }
  else {
    doc.set(
      'ts.record',
      new Date(
        sprintf(
          '%4s-%02s-%02s %02s:%02s:00Z',
          doc.ts.year,
          doc.ts.month,
          doc.ts.day,
          doc.ts.hour,
          doc.ts.minute,
        ),
      ),
    )
    for (const k of ['year', 'month', 'day', 'hour', 'minute', 'second']) {
      delete doc.ts[k]
    }
  }
}

export const taste_version = function (data, dbVer) {
  // console.log(arguments)

  const data_bytes = Buffer.from(data)

  let tasteVer = data_bytes.readUInt16LE(0)

  if (tasteVer === 32768) {
    // yep, its v2
    if (dbVer && 2 !== dbVer) {
      console.error(`taste v2 -vs- DB v${dbVer}`)
      return dbVer
    }
    return 2
  }

  tasteVer = data_bytes.readUInt8(0) & 0b00001111
  if (dbVer && dbVer !== tasteVer) {
    // console.error(`WARNING: tasted v${tasteVer} -vs- DB v${dbVer}`)
    return dbVer
  }

  return tasteVer
}

export const getVersion = function (rawRow, tacRow) {
  switch (tacRow.data_format) {
    case 'v11':
    case '11':
      taste_version(rawRow.data_packet, 11)
      return 11
    case 'v7':
    case '7':
      taste_version(rawRow.data_packet, 7)
      return 7
    case 'v6':
    case '6':
      taste_version(rawRow.data_packet, 6)
      return 6
    case 'v5':
    case '5':
      taste_version(rawRow.data_packet, 5)
      return 5
    case 'v4':
    case '4':
      taste_version(rawRow.data_packet, 4)
      return 4
    case 'v3':
    case '3':
      taste_version(rawRow.data_packet, 3)
      return 3
    case 'v2':
    case '2':
      taste_version(rawRow.data_packet, 2)
      return 2
    case 'v1':
      return 1
    default:
      // console.log(`getVersion: relying on taste_version`)
      return taste_version(rawRow.data_packet, null)
  }
}

export const getDoc = function (rawRow, tacRow) {
  const doc = new StpDoc()
  doc.set('data.format', getVersion(rawRow, tacRow))

  if (tacRow) {
    doc.set('record.id', tacRow.record_id)
    doc.set('record.table', tacRow.data_table)
    doc.set('ts.email', tacRow.email_datetime)
    doc.set('site.id', tacRow.siteid)
    doc.set('geo.lat', tacRow.lat)
    doc.set('geo.lon', tacRow.lon)
    doc.set('project.id', tacRow.projectid)
  }

  if (rawRow) {
    doc.set('record.raw.id', rawRow.id)
    doc.set('geo.lat', rawRow.latitude)
    doc.set('geo.lon', rawRow.longitude)
    // doc.set('ts.update'      , rawRow.updated)
  }

  if (rawRow && tacRow) {
    // DB has lat/log stored alternately as FLOAT, DOUBLE(8,2), and DECIMAL(11,8)
    if (
      rawRow.latitude &&
      tacRow.lat &&
      round(rawRow.latitude) !== round(tacRow.lat)
    ) {
      console.error(`latitute mismatch ${rawRow.latitude} != ${tacRow.lat}`)
    }
    if (
      rawRow.longitude &&
      tacRow.lon &&
      round(rawRow.longitude) !== round(tacRow.lon)
    ) {
      console.error(`longitude mismatch ${rawRow.longitude} != ${tacRow.lon}`)
    }
  }

  return doc
}

export const round = function (number, decimals) {
  if (decimals === undefined) decimals = 2
  return Number(Number(number).toFixed(decimals))
}

export const decadeOf = function (opts) {
  // TAC v3-6 sends the year as (Year % 10) of current year (0..9)
  // this function returns the decade start (2010, 2020, 2030...)
  let ed = opts.email_datetime
  if (!ed && opts.ts && opts.ts.email) {
    ed = opts.ts.email
  }
  if (!ed) throw new Error('decadeOf needs email_datetime or ts.email set')

  if (!(ed instanceof Date)) ed = new Date(opts.email_datetime)
  return parseInt(`${ed.getFullYear().toString().slice(0, 3)}0`, 10)
}

export const getPartialSensorId = function (bytes, start_index) {
  const onewire_parts = []
  onewire_parts.push(bytes.readUInt8(start_index))
  onewire_parts.push(bytes.readUInt8(start_index + 1))
  onewire_parts.push(bytes.readUInt8(start_index + 2))
  onewire_parts.push(bytes.readUInt8(start_index + 3))

  const onewire_id = onewire_parts.reduce((acc, val) => {
    const hex = val.toString(16).padStart(2, '0')
    return acc + hex
  }, '')

  return onewire_id
}
