import {
  Button,
  Code,
  IconButton,
  Center,
  Tooltip,
  Text,
  Input,
} from '@chakra-ui/react'
import type { FocusableElement } from '@chakra-ui/utils'
import { useRef } from 'react'
import { FaTrashAlt } from 'react-icons/fa'

import { ErrorWrapper } from 'components/ErrorWrapper'

import { ModalContainer } from 'containers/ModalContainer'

import { useInput } from 'hooks/useInput'

import { formatLoggerSerial } from 'lib/formatLoggerSerial'
import { getLastModemUse } from 'lib/lastUse'

interface IDeleteModalProps {
  data: any
  iconButtonProps?: any
  deleteFn: React.MouseEventHandler
}

const findName = (data: any) => {
  if (typeof data.name === 'string') return data.name
  if (data.organizationName) return data.organizationName
  if (data.projectName) return data.projectName
  if (data.nameFirst || data.nameLast) {
    return `${data.nameFirst} ${data.nameLast}`
  }
  if (data?.serial && data.serial?.id !== undefined)
    return formatLoggerSerial(data.serial)
  if (data?.modem?.imei) return data.modem.imei
  if (data.priceChart)
    return data.name ?? data?.project.name ?? data?.org?.organizationName
  if (data.imei && data.use) {
    const lastUse = getLastModemUse(data.use)
    if (lastUse?.logger?.serial)
      return formatLoggerSerial(lastUse.logger.serial)
  }

  console.info(`couldn't get name for `, data)
}

export const DeleteModal = ({
  data,
  iconButtonProps,
  deleteFn,
}: IDeleteModalProps) => {
  const cancelRef = useRef<FocusableElement>()
  const [verify, setVerify] = useInput('')
  const name = findName(data)

  return (
    <ErrorWrapper>
      <ModalContainer
        TriggerButton={({ onClick }) => (
          <>
            <Tooltip label={'Delete'}>
              <Center>
                <IconButton
                  icon={<FaTrashAlt />}
                  onClick={onClick}
                  {...iconButtonProps}
                />
              </Center>
            </Tooltip>
          </>
        )}
        header={'Delete Item'}
        footer={({ onClose }) => (
          <>
            <Button
              ref={cancelRef as React.LegacyRef<HTMLButtonElement>}
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant='ghost'
              color='red'
              onClick={deleteFn}
              tabIndex={-1}
              disabled={verify !== name}
            >
              Delete
            </Button>
          </>
        )}
      >
        <Text pb='1rem'>
          Are you sure you want to delete <Code>{name}</Code>?
          <br />
          Enter the name to confirm.
        </Text>
        <Input role='verify' value={verify} onChange={setVerify} />
      </ModalContainer>
    </ErrorWrapper>
  )
}
