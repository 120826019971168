import { AnyZodObject, z } from 'zod'

import { UserMutator as UM, userMutator as uM } from '../schemas/public/User.js'

export type ExtendedUserMutator = UM & {
  password?: string
  passwordConfirm?: string
} & {
  passwordHash?: string
  passwordSalt?: string
}

const passwordField = z.object({
  password: z
    .string()
    .superRefine((val, ctx) => {
      const containsNumber = (ch: string) => /[0-9]/.test(ch)
      const containsUppercase = (ch: string) => /[A-Z]/.test(ch)
      const containsLowercase = (ch: string) => /[a-z]/.test(ch)
      const containsSpecialChar = (ch: string) =>
        /[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~ ]/.test(ch)

      const error =
        'password must bet at least 10 characters and contain at least one of each: lowercase, uppercase, special character, number'

      if (
        val !== '' &&
        val !== undefined &&
        !(
          containsNumber(val) &&
          containsUppercase(val) &&
          containsLowercase(val) &&
          containsSpecialChar(val) &&
          val.length >= 10
        )
      ) {
        ctx.addIssue({
          code   : 'custom',
          message: error,
        })
      }
    })
    .optional(),
  passwordConfirm: z.string().optional(),
})

export const extendedUserMutator = passwordField
  .merge(uM as unknown as AnyZodObject)
  .merge(
    z.object({
      passwordHash: z.string().optional(),
      passwordSalt: z.string().optional(),
    }) as unknown as AnyZodObject,
  )
  .superRefine(({ passwordConfirm, password }, ctx) => {
    if (passwordConfirm !== undefined && passwordConfirm !== password)
      ctx.addIssue({
        code   : 'custom',
        message: 'The passwords did not match',
        path   : ['passwordConfirm'],
      })
  }) as unknown as z.Schema<ExtendedUserMutator>
