import {
  IconButton,
  Center,
  Tooltip,
  Text,
  Code,
  Grid,
  GridItem,
  AccordionButton,
  AccordionItem,
  Accordion,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react'
import { MdOutlineChangeCircle } from 'react-icons/md'

import { ErrorBoundary } from 'components/ErrorBoundary'
import { ErrorWrapper } from 'components/ErrorWrapper'

import { ModalContainer } from 'containers/ModalContainer'

import { combineDecodeList } from 'lib/parseDecodeOrder'

import { Diff, parseChangeLog } from './parseChangeLog'

interface IChangeLogModalProps {
  data: any
  title?: string
  iconButtonProps?: any
  tooltip?: string
}

const filteredKeys = [
  'v',
  'events',
  'ts.updated',
  'ts.created',
  'ts.deleted',
  'notes',
  'by_user',
  'by_user.username',
  'by_user.id',
]

export const ChangeLogModal = ({
  data,
  title = 'Change Log',
  iconButtonProps = {},
  tooltip = '',
}: IChangeLogModalProps) => {
  const changelog = parseChangeLog(data).reverse()

  return (
    <ErrorBoundary>
      <ModalContainer
        TriggerButton={({ onClick }) => (
          <>
            <Tooltip label={tooltip}>
              <Center>
                <IconButton
                  icon={<MdOutlineChangeCircle />}
                  onClick={onClick}
                  {...iconButtonProps}
                />
              </Center>
            </Tooltip>
          </>
        )}
        header={title}
        modalProps={{ size: '3xl' }}
      >
        {changelog.length > 0 ? (
          <Accordion allowMultiple allowToggle defaultIndex={0}>
            <ErrorBoundary>
              {changelog
                .filter((cl) =>
                  cl.changes.reduce((acc, obj) => {
                    return acc + (filteredKeys.includes(obj.key) ? 0 : 1)
                  }, 0),
                )
                .map((diff) => {
                  return (
                    <AccordionItem
                      key={diff.date.toISOString() ?? JSON.stringify(diff)}
                    >
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left'>
                            <Text fontSize='xl' fontWeight='bold'>
                              {diff.date.getTime() === 0
                                ? 'n/a'
                                : new Date(diff.date).toLocaleString('en-US')}
                            </Text>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <ChangeLogTile
                          date={diff.date}
                          diff={diff.changes}
                          user={diff.by_user?.username}
                        />
                      </AccordionPanel>
                    </AccordionItem>
                  )
                })}
            </ErrorBoundary>
          </Accordion>
        ) : (
          <Text>No changelog found</Text>
        )}
      </ModalContainer>
    </ErrorBoundary>
  )
}

const ChangeLogTile = ({
  date,
  diff,
  user,
}: {
  date: any
  user: string
  diff: Diff[]
}) => {
  return (
    <ErrorBoundary>
      <Grid key={date} templateColumns='50% 50%' gap={3}>
        <GridItem colSpan={2}>
          <Text fontWeight='semibold' as='span'>
            User:{' '}
          </Text>
          <Text as='span'>{user}</Text>
        </GridItem>
        <GridItem>
          <Text fontSize='xl' fontWeight='bold'>
            Old
          </Text>
        </GridItem>
        <GridItem>
          <Text fontSize='xl' fontWeight='bold'>
            New
          </Text>
        </GridItem>
        {diff
          .filter((d) => !filteredKeys.includes(d.key))
          .map((d) => (
            <ChangeLogEntry diff={d} key={`${date}-${d.key}`} />
          ))}
      </Grid>
    </ErrorBoundary>
  )
}

const ChangeLogEntry = ({ diff }: { diff: Diff }): JSX.Element => {
  if (diff.key === 'sensor.decode') {
    return (
      <>
        <GridItem colSpan={2}>
          <Text>{diff.key}</Text>
        </GridItem>
        <GridItem>
          <Code
            style={{
              whiteSpace: 'pre-wrap',
              width     : '100%',
            }}
            position='relative'
            padding='5'
          >
            {JSON.stringify(combineDecodeList(diff.old), null, 2)}
          </Code>
        </GridItem>
        <GridItem>
          <Code
            style={{
              whiteSpace: 'pre-wrap',
              width     : '100%',
            }}
            position='relative'
            padding='5'
          >
            {JSON.stringify(combineDecodeList(diff.new), null, 2)}
          </Code>
        </GridItem>
      </>
    )
  }

  if (diff.key === 'notes') {
    const extractedNewNotes = diff.new.replace(diff.old, '')

    if (extractedNewNotes !== diff.new) {
      return (
        <>
          <GridItem colSpan={2}>
            <Text>{diff.key}</Text>
          </GridItem>
          <GridItem colSpan={2}>
            <Text>Added Notes:</Text>
          </GridItem>
          <GridItem colSpan={2}>
            <Code
              style={{
                whiteSpace: 'pre-wrap',
                width     : '100%',
              }}
              position='relative'
              padding='5'
            >
              {diff.new.replace(diff.old, '')}
            </Code>
          </GridItem>
        </>
      )
    }

    const oldNotes = diff.old?.split('\n\n') ?? []
    const newNotes = diff.new?.split('\n\n') ?? []

    const newNotesList = newNotes
      .filter((n: string) => !oldNotes.includes(n))
      .join('\n\n')

    if (newNotesList.trim() !== '') {
      return (
        <>
          <GridItem colSpan={2}>
            <Text>{diff.key}</Text>
          </GridItem>
          <GridItem colSpan={2}>
            <Text>Edited Notes:</Text>
          </GridItem>
          <GridItem colSpan={2}>
            <Code
              style={{
                whiteSpace: 'pre-wrap',
                width     : '100%',
              }}
              position='relative'
              padding='5'
            >
              {newNotesList}
            </Code>
          </GridItem>
        </>
      )
    }

    return (
      <>
        <GridItem colSpan={2}>
          <Text>{diff.key}</Text>
        </GridItem>
        <GridItem>
          <Code
            style={{
              whiteSpace: 'pre-wrap',
              width     : '100%',
            }}
            position='relative'
            padding='5'
          >
            {diff.old}
          </Code>
        </GridItem>
        <GridItem>
          <Code
            style={{
              whiteSpace: 'pre-wrap',
              width     : '100%',
            }}
            position='relative'
            padding='5'
          >
            {diff.new}
          </Code>
        </GridItem>
      </>
    )
  }

  return (
    <>
      <GridItem colSpan={2}>
        <Text>{diff.key}</Text>
      </GridItem>
      <GridItem>
        <Code
          style={{
            whiteSpace: 'pre-wrap',
            width     : '100%',
          }}
          position='relative'
          padding='5'
        >
          {JSON.stringify(diff.old, null, 2)}
        </Code>
      </GridItem>
      <GridItem>
        <Code
          style={{
            whiteSpace: 'pre-wrap',
            width     : '100%',
          }}
          position='relative'
          padding='5'
        >
          {JSON.stringify(diff.new, null, 2)}
        </Code>
      </GridItem>
    </>
  )
}
