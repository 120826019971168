import { LockIcon, UnlockIcon } from '@chakra-ui/icons'
import {
  Input,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  useBoolean,
  IconButton,
  HStack,
} from '@chakra-ui/react'
// import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format'

import { InfoTooltip } from 'components/InfoTooltip'

import { InputWrapperProps } from '../lib/FieldWrapper'

export const MaskedInput = ({
  left,
  right,
  placeholder,
  label,
  name,
  field,
  type,
  requiresGlobalAdmin = false,
  global = { view: false, edit: false, admin: false },
  format,
  tooltip,
  mask,
  style = {},
}: InputWrapperProps) => {
  const [locked, setLocked] = useBoolean(true)

  let value = field.value
  if (format) value = format(field.value)

  return left || right ? (
    <>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <HStack>
        <InputGroup>
          {left && <InputLeftAddon children={left} />}
          <Input
            as={NumberFormat}
            placeholder={placeholder ?? name}
            isDisabled={locked && requiresGlobalAdmin}
            format={mask}
            {...field}
            value={value}
            allowEmptyFormatting={true}
            mask='_'
            {...style}
          />
          {right && <InputRightAddon children={right} />}
        </InputGroup>
        {tooltip && <InfoTooltip text={tooltip} />}

        {requiresGlobalAdmin && global.edit && (
          <IconButton
            icon={locked ? <LockIcon /> : <UnlockIcon />}
            onClick={setLocked.toggle}
            aria-label={locked ? 'locked' : 'unlocked'}
          />
        )}
      </HStack>
    </>
  ) : (
    <>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <HStack>
        <Input
          as={NumberFormat}
          placeholder={placeholder ?? name}
          isDisabled={locked && requiresGlobalAdmin}
          format={mask}
          {...field}
          value={value}
          allowEmptyFormatting={true}
          mask='_'
          {...style}
        />
        {tooltip && <InfoTooltip text={tooltip} />}

        {requiresGlobalAdmin && global.edit && (
          <IconButton
            icon={locked ? <LockIcon /> : <UnlockIcon />}
            onClick={setLocked.toggle}
            aria-label={locked ? 'locked' : 'unlocked'}
          />
        )}
      </HStack>
    </>
  )
}
