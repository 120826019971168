import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useHistory } from 'react-router-dom'

import { useUserContext } from 'hooks/useUserContext'

ReactGA.initialize(import.meta.env.VITE_GA_TRACKING_ID as string, {})

declare global {
  interface Window {
    gtag?: (
      key: string,
      trackingId: string,
      config: { page_path: string; user_id?: string | number },
    ) => void
  }
}

export const useTracking = (
  trackingId: string | undefined = import.meta.env.VITE_GA_MEASUREMENT_ID,
) => {
  const { listen } = useHistory()
  const { currentUser } = useUserContext()

  useEffect(() => {
    const unlisten = listen((location: any) => {
      ReactGA.pageview(`${location.pathname}${location.search}`)

      if (!window.gtag) return
      if (!trackingId) {
        console.info(
          'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.',
        )
        return
      }

      window.gtag('config', trackingId, {
        page_path: location.pathname,
        ...(currentUser?.id?.[0] ? { user_id: currentUser?.id?.[0] } : {}),
      })
    })

    return unlisten
  }, [trackingId, listen, currentUser?.id])
}
