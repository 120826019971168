import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import { Button, VStack } from '@chakra-ui/react'

import { ExternalLink } from 'components/Links'

import { ModalContainer } from 'containers/ModalContainer'

export const ContactModal = () => {
  // const auth = useUserContext();
  // const { url } = useGoogleForm({ email: auth?.currentUser?.email?.[0] ?? '' });

  return (
    <ModalContainer
      TriggerButton={({ onClick }) => (
        <Button onClick={onClick} size='sm'>
          Contact Us
        </Button>
      )}
      header='Contact Us!'
    >
      <VStack align='left'>
        <ExternalLink
          name={import.meta.env.VITE_OFFICE_PHONE as string}
          href={`tel:+${import.meta.env.VITE_OFFICE_PHONE}`}
          left={<PhoneIcon />}
          right={false}
        />
        <ExternalLink
          name={import.meta.env.VITE_OFFICE_EMAIL as string}
          href={`mailto:${import.meta.env.VITE_OFFICE_EMAIL}`}
          left={<EmailIcon />}
          right={false}
        />
        <ExternalLink
          name='Feedback'
          href={import.meta.env.VITE_GOOGLE_FORM_FEEDBACK_URL as string}
        />
      </VStack>
    </ModalContainer>
  )
}
