import { LockIcon, UnlockIcon } from '@chakra-ui/icons'
import {
  Textarea as ChakraTextarea,
  FormLabel,
  useBoolean,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import { InfoTooltip } from 'components/InfoTooltip'

import { InputWrapperProps } from '../lib/FieldWrapper'

export const Textarea = ({
  name,
  label,
  placeholder,
  field,
  requiresGlobalAdmin = false,
  global = { view: false, edit: false, admin: false },
  tooltip,
  onChange,
  styles = {},
}: InputWrapperProps) => {
  const [locked, setLocked] = useBoolean(true)
  const { setFieldValue, values } = useFormikContext()

  return (
    <>
      <FormLabel htmlFor={name}>{label ?? name}</FormLabel>
      <HStack>
        <ChakraTextarea
          placeholder={placeholder ?? name}
          isDisabled={locked && requiresGlobalAdmin}
          rows='6'
          {...field}
          {...(typeof onChange === 'function'
            ? {
              onChange: (e) => {
                onChange(e, setFieldValue, values)
                field.onChange(e)
              },
            }
            : {})}
          {...styles}
        />

        {requiresGlobalAdmin && global.edit && (
          <IconButton
            icon={locked ? <LockIcon /> : <UnlockIcon />}
            onClick={setLocked.toggle}
            aria-label={locked ? 'locked' : 'unlocked'}
          />
        )}
        {tooltip && <InfoTooltip text={tooltip} />}
      </HStack>
    </>
  )
}
