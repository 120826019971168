export const validateDate = (value: any) => {
  let errorMessage

  if (value && value !== '____-__-__' && value !== '____-__-__ __:__') {
    try {
      const date = new Date(value)
      if (date.toString() === 'Invalid Date') errorMessage = 'invalid date'
    }
    catch (e) {
      console.error(e)
      errorMessage = 'invalid date'
    }
  }
  return errorMessage
}
