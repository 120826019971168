import { SettingsIcon, CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  Checkbox,
} from '@chakra-ui/react'

import { ErrorWrapper } from 'components/ErrorWrapper'

// import { MultiSelect } from 'components/MultiSelect';
import { ModalContainer } from 'containers/ModalContainer'

import {
  useUserContext,
  temperatureOptions, // timezoneOptions,
  coordinateOptions,
  superUserOptions,
  depthOptions,
} from 'hooks/useUserContext'

export const SettingsModalWrapper = () => {
  return <SettingsModal />
}

export const SettingsModal = () => {
  const {
    global,
    prefs: {
      temp,
      setTemp,
      // timezone,
      // setTimezone,
      coordinates,
      setCoordinates,
      depth,
      setDepth,
      compactCableBuilder,
      setCompactCableBuilder,
      save,
    },
    superUser,
    setSuperUser,
    mutation,
  } = useUserContext()

  return (
    <ErrorWrapper>
      <ModalContainer
        TriggerButton={({ onClick }) => (
          <Center p='2'>
            <IconButton
              aria-label='open user settings'
              onClick={onClick}
              icon={<SettingsIcon />}
            />
          </Center>
        )}
        header='User Settings'
        footer={({ onClose }) => (
          <>
            <HStack>
              <Spacer />
              {mutation?.isSuccess && <CheckCircleIcon color='green.400' />}
              {mutation?.isError && <WarningIcon color='red.400' />}
              <Button
                type='submit'
                disabled={mutation?.isLoading}
                onClick={save}
              >
                Save
              </Button>
              <Button onClick={onClose}>Close</Button>
            </HStack>

            {mutation?.isError && (
              <Text color='red.400'>{mutation?.error.toString()}</Text>
            )}
          </>
        )}
      >
        <Grid gap={6}>
          <GridItem>
            <FormControl>
              <FormLabel>Set Temperature</FormLabel>
              <RadioGroup onChange={setTemp} value={temp}>
                <HStack>
                  {temperatureOptions.map(({ value, name }) => (
                    <Radio value={value} key={value}>
                      {name ?? value}
                    </Radio>
                  ))}
                </HStack>
              </RadioGroup>
            </FormControl>
          </GridItem>

          {/* <GridItem>
            <FormControl>
              <FormLabel>Select your preferred timezone</FormLabel>
              <MultiSelect
                name='timezone'
                options={timezoneOptions}
                placeholder='Select a timezone...'
                value={timezone}
                onChange={setTimezone}
              />
            </FormControl>
          </GridItem> */}

          <GridItem>
            <FormControl>
              <FormLabel>Set Depth Format</FormLabel>
              <RadioGroup onChange={setDepth} value={depth}>
                <HStack>
                  {depthOptions.map(({ value, name }) => (
                    <Radio value={value} key={value}>
                      {name ?? value}
                    </Radio>
                  ))}
                </HStack>
              </RadioGroup>
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <FormLabel>Set Coordinate format</FormLabel>
              <RadioGroup onChange={setCoordinates} value={coordinates}>
                <HStack>
                  {coordinateOptions.map(({ value, name }) => (
                    <Radio value={value} key={value}>
                      {name ?? value}
                    </Radio>
                  ))}
                </HStack>
              </RadioGroup>
              <FormHelperText>
                Example:{' '}
                {coordinates === 'DMS'
                  ? '61° 8\' 59" N, 149° 52\' 41" W'
                  : '61.1499, -149.8783'}
              </FormHelperText>
            </FormControl>
          </GridItem>
        </Grid>

        {global.view && (
          <GridItem pt='1rem'>
            <FormControl>
              <FormLabel>Super User Default</FormLabel>
              <RadioGroup onChange={(e) => setSuperUser(e)} value={superUser}>
                <HStack>
                  {superUserOptions.map(({ value, name }) => (
                    <Radio value={value} key={value}>
                      {name ?? value}
                    </Radio>
                  ))}
                </HStack>
              </RadioGroup>
            </FormControl>
          </GridItem>
        )}

        {false && global.view && (
          <GridItem pt='1rem'>
            <FormControl>
              <Checkbox
                value={compactCableBuilder}
                onChange={(e) => setCompactCableBuilder(e.target.checked)}
              >
                Compact Cable Builder
              </Checkbox>
            </FormControl>
          </GridItem>
        )}

        {/* <Button onClick={onClose}>Close</Button> */}
      </ModalContainer>
    </ErrorWrapper>
  )
}
