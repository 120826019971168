import { z } from 'zod'

/** Represents the enum public.cable_section_type_ */
enum CableSectionType {
  LEAD = 'LEAD',
  BODY = 'BODY',
}

export default CableSectionType

/** Zod schema for cable_section_type_ */
export const cableSectionType = z.enum(['LEAD', 'BODY'])
