import { z } from 'zod'

/** Represents the enum public.modem_status_type_ */
enum ModemStatusType {
  UNKNOWN = 'UNKNOWN',
  INVENTORY = 'INVENTORY',
  INSTALLED = 'INSTALLED',
}

export default ModemStatusType

/** Zod schema for modem_status_type_ */
export const modemStatusType = z.enum(['UNKNOWN', 'INVENTORY', 'INSTALLED'])
