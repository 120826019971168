import { FormControl, FormErrorMessage } from '@chakra-ui/react'
import { getIn, Field, useFormikContext } from 'formik'
import type { FieldAttributes } from 'formik'

import type { GlobalPerm, SuperUser } from '@beaded/models'

import { ErrorBoundary } from 'components/ErrorBoundary'

import { Checkbox } from '../fields/Checkbox.component'
import { DatePicker } from '../fields/DatePicker'
import { DefaultInput } from '../fields/DefaultInput.component'
import { ImageInput } from '../fields/ImageInput.component'
import { MaskedInput } from '../fields/MaskedInput.component'
import { MultiSelect } from '../fields/MultiSelect.component'
import { NumberInput } from '../fields/NumberInput.component'
import { RadioGroup } from '../fields/RadioGroup.component'
import { Select } from '../fields/Select.component'
import { TagInput } from '../fields/TagInput.component'
import { Textarea } from '../fields/TextArea.component'

export interface FieldWrapperProps extends FieldAttributes<any> {
  name: string
  type?: string
  validate?: Function
  options?:
    | Array<{ value: string | number; name: string }>
    | (() => Promise<any>)
    | string
  isReadOnly?: boolean
  isRequired?: boolean
  global?: GlobalPerm
  requiresGlobalAdmin?: boolean
  superUser?: SuperUser
}

export const FieldWrapper = ({
  name,
  validate = () => null,
  isReadOnly = false,
  isRequired = false,
  global = { view: false, edit: false, admin: false },
  ...rest
}: FieldWrapperProps) => {
  const { errors, touched } = useFormikContext()

  return (
    <Field name={name} validate={validate} key={name}>
      {({ field }: { field: FieldAttributes<any> }) => (
        <ErrorBoundary>
          <FormControl
            isInvalid={
              errors && getIn(errors, name) && touched && getIn(touched, name)
            }
            isReadOnly={Boolean(isReadOnly)}
            isRequired={Boolean(isRequired)}
          >
            <InputWrapper
              name={name}
              field={field}
              isReadOnly={Boolean(isReadOnly)}
              isRequired={Boolean(isRequired)}
              {...rest}
            />
            <FormErrorMessage>{errors && getIn(errors, name)}</FormErrorMessage>
          </FormControl>
        </ErrorBoundary>
      )}
    </Field>
  )
}

export interface InputWrapperProps extends FieldWrapperProps {
  field: FieldAttributes<any>
}
export const InputWrapper = ({ type, ...props }: InputWrapperProps) => {
  if (props?.field?.value === null) props.field.value = undefined

  switch (type) {
    case 'textarea':
      return <Textarea {...props} />

    case 'select':
      return <Select {...props} />

    case 'multiselect':
      return <MultiSelect {...props} />

    case 'creatableselect':
      return <MultiSelect {...props} />

    case 'checkbox':
      return <Checkbox {...props} />

    case 'radio':
      return <RadioGroup {...props} />

    case 'number':
      return <NumberInput {...props} />

    case 'date':
      return <DatePicker onChange={props?.onChange} {...props} />

    case 'tag':
      return <TagInput {...props} />

    case 'mask':
      return <MaskedInput {...props} />

    case 'image':
      return <ImageInput {...props} />

    default:
      return <DefaultInput type={type} {...props} />
  }
}
