import axios from 'axios'
import type { AxiosRequestConfig, AxiosError } from 'axios'

import { API_BASE_URI, ApiVersion } from 'constants/api'

import type { Obj } from 'types/common'

const config: AxiosRequestConfig = {
  headers: {
    Accept        : 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  },
  withCredentials: true,
}

export const GET = (
  url: string,
  options: AxiosRequestConfig = {},
  version: ApiVersion = 'v1',
) =>
  axios
    .get(`${API_BASE_URI(version)}${url}`, { ...config, ...options })
    .then((res) => res.data)
    .catch(handleError)

export const POST = (
  url: string,
  data: Obj,
  options: AxiosRequestConfig = {},
  version: ApiVersion = 'v1',
) =>
  axios
    .post(`${API_BASE_URI(version)}${url}`, data, { ...config, ...options })
    .then((res) => res?.data || res)
    .catch(handleError)

export const PUT = (
  url: string,
  data: Obj,
  options: AxiosRequestConfig = {},
  version: ApiVersion = 'v1',
) =>
  axios
    .put(`${API_BASE_URI(version)}${url}`, data, { ...config, ...options })
    .then((res) => res?.data || res)
    .catch(handleError)

export const PATCH = (
  url: string,
  data: Obj,
  options: AxiosRequestConfig = {},
  version: ApiVersion = 'v1',
) =>
  axios
    .patch(`${API_BASE_URI(version)}${url}`, data, { ...config, ...options })
    .then((res) => res?.data || res)
    .catch(handleError)

export const DELETE = (
  url: string,
  data?: Obj,
  options: AxiosRequestConfig = {},
  version: ApiVersion = 'v1',
) =>
  axios
    .delete(`${API_BASE_URI(version)}${url}`, { ...config, ...options, data })
    .then((res) => res?.data || res)
    .catch(handleError)

const handleError = (err: AxiosError) => {
  if (axios.isAxiosError(err)) {
    err.response?.data && console.error(err.response.data)
    if (err.response?.data?.errors) throw err.response.data.errors
  }
  else {
    console.error(err)
    throw err
  }
}

export const globalFlags = { params: { global: true } }
export const extrasFlags = { params: { extras: true } }

export * as search from './search'
export * as cable from './cable'
export * as org from './org'
export * as project from './project'
export * as modem from './modem'
export * as reading from './reading'
export * as sensor from './sensor'
export * as site from './site'
export * as user from './user'
export * as logger from './logger'
export * as perm from './perm'
export * as cloudloop from './cloudloop'
export * as loggerFirmware from './loggerFirmware'
export * as cablePrices from './cablePrices'
