import {
  Button,
  Divider,
  HStack,
  Input,
  Spacer,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'

import { ErrorWrapper } from 'components/ErrorWrapper'

import { ModalContainer } from 'containers/ModalContainer'

import { useInput } from 'hooks/useInput'

export const IdConverterModal = ({
  ButtonElem,
}: {
  ButtonElem?: typeof Button
}) => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const [oldId, setOldId] = useInput('')
  const [newId, setNewId] = useInput('')

  return (
    <ErrorWrapper>
      <ModalContainer
        TriggerButton={({ onClick }) => (
          <>
            {ButtonElem ? (
              <ButtonElem onClick={onClick}>Convert IDs</ButtonElem>
            ) : (
              <Button onClick={onClick}>Convert IDs</Button>
            )}
          </>
        )}
        header='Convert IDs'
      >
        <Stack spacing={3}>
          <VStack spacing={3}>
            <Text>Convert old format ID to new format</Text>
            <HStack spacing={3}>
              <Input
                value={oldId}
                onChange={setOldId}
                onBlur={setOldId}
                placeholder='id from old site'
              />
              <Spacer />
              <Input readOnly value={unshuffle_bits(parseInt(oldId))} />
            </HStack>
          </VStack>
          <Divider />
          <VStack spacing={3}>
            <Text>Convert new format ID to old format</Text>
            <HStack spacing={3}>
              <Input
                value={newId}
                onChange={setNewId}
                onBlur={setNewId}
                placeholder='id from new site'
              />
              <Spacer />
              <Input readOnly value={shuffle_bits(parseInt(newId))} />
            </HStack>
          </VStack>
        </Stack>
      </ModalContainer>
    </ErrorWrapper>
  )
}

function shuffle_bits(x: number) {
  let t = (x ^ (x >> 2)) & 0x0c0c0c0c
  x = x ^ t ^ (t << 2)

  t = (x ^ (x >> 1)) & 0x22222222
  x = x ^ t ^ (t << 1)

  return x
}

function unshuffle_bits(x: number) {
  let t = (x ^ (x >> 1)) & 0x22222222
  x = x ^ t ^ (t << 1)

  t = (x ^ (x >> 2)) & 0x0c0c0c0c
  x = x ^ t ^ (t << 2)

  return x
}
