import { z } from 'zod'

/** Represents the enum public.cable_type_ */
enum CableType {
  GROUND = 'GROUND',
  WATER = 'WATER',
  AIR = 'AIR',
  SNOWPROBE = 'SNOWPROBE',
}

export default CableType

/** Zod schema for cable_type_ */
export const cableType = z.enum(['GROUND', 'WATER', 'AIR', 'SNOWPROBE'])
