import Beaded from './beaded.js'
import { round, filterReadings, applyOffsets } from './utils.js'

export const decode1 = async function (opts) {
  const doc = new Beaded()
  doc.set('data.format', opts.data.format)
  doc.set('data.packet', opts.data.packet)

  const raw = Buffer.from(opts.data.packet)

  doc.set('ts.record', new Date(raw.readUInt32LE(0) * 1000 + 946684800 * 1000))

  if (raw.length < 7) return doc
  doc.set('logger.temp', round(raw.readInt16LE(4) * 0.0078, 4))
  doc.set('logger.battery', raw.readInt16LE(6) / 100)

  if (raw.length < 9) return doc
  doc.set('modem.battery', raw.readInt16LE(8) / 100)

  // console.log(`start: ${raw.readUInt8(10)} end: ${raw.readUInt8(11)}`)

  let position = 12

  while (position < raw.length - 1) {
    doc.push('readings', round(raw.readInt16LE(position) / 1024, 4))
    position += 2
  }

  filterReadings(doc, opts)
  applyOffsets(doc, opts)

  return doc
}
