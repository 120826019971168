import { Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import type { FormikValues } from 'formik'
import merge from 'lodash.merge'

import { reading } from 'api'

import { validateDate } from 'lib/validateDate'

import type { FormInfo, UpdateFormProps } from 'types/FormInfo'
import type { Obj } from 'types/common'

export const downloadSiteForm = ({
  initialValues,
}: UpdateFormProps): FormInfo<FormikValues> => ({
  title: 'Download Site Chart Data',

  fields: [
    {
      name   : 'download.dl',
      type   : 'select',
      label  : 'File Organization',
      options: [
        { name: 'timeseries (user-friendly)', value: 'timeseries' },
        { name: 'flat file (for pivot tables)', value: 'flat' },
      ],
      isRequired: true,
    },

    {
      name   : 'download.ts',
      type   : 'select',
      label  : 'Date Format (all times are output in UTC)',
      options: [
        { name: 'YYYY-MM-DDTHH:MM:SS.000Z', value: 'iso' },
        { name: 'YYYY-MM-DD HH:MM', value: 'split' },
      ],
      isRequired: true,
    },

    {
      name           : 'download.includeArchivedData',
      type           : 'checkbox',
      label          : 'Download Archived Data',
      shouldBeVisible: (values: any) => {
        if (values?.includes_archived_data === true) return true
        return false
      },
      tooltip: (
        <Text>
          Someone has archived data that is included in this site.
          <br />
          Check this box to include archived data in your downloaded file.
        </Text>
      ),
    },

    {
      name           : 'download.includeErrors',
      type           : 'checkbox',
      label          : 'Include Errors',
      shouldBeVisible: (values: any) => Boolean(values?.global?.view),
    },

    {
      type        : 'group',
      title       : 'Units',
      titleColSpan: 2,
      gridProps   : {
        templateColumns: '1fr 1fr',
        gap            : 3,
      },
      fields: [
        {
          name   : 'download.temp',
          type   : 'radio',
          label  : 'Temperature',
          options: [
            { name: 'F', value: 'F' },
            { name: 'C', value: 'C' },
          ],
        },

        {
          name   : 'download.length',
          type   : 'radio',
          label  : 'Length',
          options: [
            { name: 'm', value: 'm' },
            { name: 'cm', value: 'cm' },
            { name: 'ft', value: 'ft' },
            { name: 'in', value: 'in' },
          ],
        },
      ],
    },

    {
      type        : 'group',
      title       : 'Time Range',
      titleColSpan: 2,
      gridProps   : {
        templateColumns: '1fr 1fr',
        gap            : 3,
      },
      fields: [
        {
          name       : 'download.begin',
          label      : 'Begin',
          type       : 'mask',
          placeholder: 'YYYY-MM-DD HH:MM',
          mask       : '####-##-## ##:##',
          validate   : validateDate,
        },

        {
          name       : 'download.end',
          label      : 'End',
          type       : 'mask',
          placeholder: 'YYYY-MM-DD HH:MM',
          mask       : '####-##-## ##:##',
          validate   : validateDate,
        },
      ],
    },
  ],

  initialValues: merge(
    {
      download: {
        dl                 : 'timeseries',
        ts                 : 'split',
        temp               : 'C',
        length             : 'm',
        includeArchivedData: false,
        includeErrors      : false,
      },
    },
    initialValues,
  ),

  submitText: 'Download CSV',

  submitFn: async (values: any) => {
    const options: Obj = {
      dl                     : values.download.dl,
      ts                     : values.download.ts,
      temp                   : values.download.temp,
      length                 : values.download.length,
      'include-archived-data': values.download.includeArchivedData,
      'include-errors'       : values.download.includeErrors,
    }
    if (values.download.begin)
      options['epoch.start'] = new Date(values.download.begin).getTime() / 1000
    if (values.download.end)
      options['epoch.end'] = new Date(values.download.end).getTime() / 1000

    await reading.downloadCsv(values.id, options)

    if (values.download.begin)
      format(new Date(values.download.begin), 'yyyy-MM-dd HH:mm')
    if (values.download.end)
      format(new Date(values.download.end), 'yyyy-MM-dd HH:mm')

    return {}
  },

  validateForm: (values: FormikValues) => ({ value: values }),

  mutationOptions: {
    onSuccess: (data: any, variables: any) => {
      // console.log(data, variables)
    },
  },
})
