import { HStack, Spacer, Text, SkeletonText } from '@chakra-ui/react'

import { ErrorCard } from 'components/ErrorCard'
import { FormModal } from 'components/FormModal'
import {
  SitesTable,
  SitesTableProjection,
} from 'components/tables/SitesTable.component'

import { Card } from 'containers/Card'

import { useTableDownload } from 'hooks/useTableDownload'

import { getTableTitle } from 'lib/getTableTitle'
import { createSiteForm } from 'lib/mutations/create/createSite'

import type { PageCard } from 'types/PageCard'

import { useSitesList } from './useSitesList'

export type SitesCardProps = PageCard<SitesTableProjection>

export const SitesCard = ({
  type,
  input,
  globalFilter,
  global,
  initialValues = {},
  projection,
  filename = 'sites.csv',
  remapData,
  filterData,
  userCanSeeCreateForm = false,
}: SitesCardProps) => {
  const { isLoading, errors, sites } = useSitesList(
    type === 'query' ? input : null,
    {
      enabled: type === 'query',
    },
    remapData,
    filterData,
  )
  const { download, DownloadButton } = useTableDownload(filename)

  const name = getTableTitle('Site', type === 'query' ? sites : input)

  return (
    <>
      <Card>
        <HStack pb='1rem'>
          <Text layerStyle='heading'>{name}</Text>
          <Spacer />
          {global.view && <DownloadButton />}
          {global.edit && (
            <FormModal
              formInfo={createSiteForm({ initialValues })}
              buttonText='Create Site'
              buttonProps={{ w: '8.5rem' }}
            />
          )}
        </HStack>

        {isLoading ? (
          <SkeletonText noOfLines={4} spacing={4} />
        ) : errors ? (
          <ErrorCard errors={errors} />
        ) : (
          <SitesTable
            data={type === 'query' ? sites : input}
            globalFilter={globalFilter}
            download={download}
            projection={projection}
            sortBy={[{ id: 'order', desc: false }]}
          />
        )}
      </Card>
    </>
  )
}
