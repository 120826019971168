import {
  HStack,
  Spacer,
  LinkOverlay,
  Text,
  Button,
  LinkBox,
  SkeletonText,
} from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import { ErrorCard } from 'components/ErrorCard'
import { FormModal } from 'components/FormModal'
import {
  CablesTable,
  CableTableProjection,
} from 'components/tables/CablesTable.component'

import { Card } from 'containers/Card'

import { useTableDownload } from 'hooks/useTableDownload'

import { getTableTitle } from 'lib/getTableTitle'
import { addCableUseForm } from 'lib/mutations/update/addCableUse'

import type { PageCard } from 'types/PageCard'

import { useBulkExport } from './useBulkExport'
import { useCablesList } from './useCablesList'

export type CablesCardProps = PageCard<CableTableProjection> & {
  showAddCable?: boolean
  showBulkExport?: boolean
}

export const CablesCard = ({
  type,
  input,
  globalFilter,
  projection,
  global,
  initialValues = {},
  showAddCable = false,
  showBulkExport = false,
  filename = 'cables.csv',
  remapData,
  filterData,
  title = 'Cable',
}: CablesCardProps) => {
  const {
    BulkExportButton,
    BulkXmlButton,
    toggleIncludedCable,
    mode: bulkExportMode,
  } = useBulkExport()
  const { isLoading, errors, cables } = useCablesList(
    type === 'query' ? input : null,
    {
      enabled: type === 'query',
    },
    remapData,
    filterData,
    {
      toggleIncludedCable,
      bulkExportMode,
    },
  )
  const { download, DownloadButton } = useTableDownload(filename)

  const name = getTableTitle(title, type === 'query' ? cables : input)

  let newCableUrl = '/cables/new'
  if (initialValues?.org?.id && initialValues?.project?.id) {
    newCableUrl += `?org=${initialValues.org.id}&project=${initialValues.project.id}`
  }

  return (
    <>
      <Card>
        <HStack pb='1rem'>
          <Text layerStyle='heading'>{name}</Text>
          <Spacer />
          {global.view && <DownloadButton />}
          {global.edit && showAddCable && (
            <FormModal
              formInfo={addCableUseForm({ initialValues })}
              buttonText='Add Cable'
              buttonProps={{ w: '8.5rem' }}
            />
          )}
          {global.edit && showBulkExport && (
            <>
              <BulkXmlButton cablesInput={cables} />
              <BulkExportButton cablesInput={cables} />
            </>
          )}
          {global.edit && (
            <LinkBox>
              <LinkOverlay to={newCableUrl} as={RouterLink}>
                <Button>Create New Cable</Button>
              </LinkOverlay>
            </LinkBox>
          )}
        </HStack>

        {isLoading ? (
          <SkeletonText noOfLines={4} spacing={4} />
        ) : errors ? (
          <ErrorCard errors={errors} />
        ) : (
          <CablesTable
            data={type === 'query' ? cables : input}
            globalFilter={globalFilter}
            download={download}
            projection={projection}
            {...(global.view ? { sortBy: [{ id: 'serial', desc: true }] } : {})}
          />
        )}
      </Card>
    </>
  )
}
