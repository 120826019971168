import {
  HStack,
  Spacer,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Select,
  Button,
  Grid,
  GridItem,
} from '@chakra-ui/react'
import memoize from 'memoizee'
import { useState } from 'react'

import { OrganizationId, UserWithPermissions } from '@beaded/models'

import { perm } from 'api'

import { ErrorWrapper } from 'components/ErrorWrapper'

import { useOrgSelectOptions } from 'hooks/useSelectOptions'

import { queryClient } from 'lib/react-query'

import type { Option } from 'types/FormInfo'

import { GlobalBlock } from './GlobalBlock.component'
import { OrgBlock } from './OrgBlock.component'

interface PermsBlockProps {
  user: UserWithPermissions
  orgFilter?: OrganizationId
}

export const PermsBlock = ({ user, orgFilter }: PermsBlockProps) => {
  const asyncOpts = useOrgSelectOptions()
  const [addOrg, setAddOrg] = useState<number>(0)

  const reduceOptions = memoize(
    (): Array<{
      name: string
      value: any
    }> | null =>
      Array.isArray(asyncOpts?.options)
        ? asyncOpts?.options?.map(({ value, name }: Option) => ({
          name,
          value,
        }))
        : null,
  )

  const addOrgToUser = async () => {
    if (addOrg) {
      await perm.addUserToOrg(user.id, addOrg as OrganizationId)
      queryClient.invalidateQueries(['user', { id: user.id }])
      setAddOrg(0)
    }
  }

  const orgIds = Array.from(
    new Set([
      ...(user?.permissions?.organizations?.map((o) => o.id) ?? []),
      ...(user.permissions?.projects?.map((p) => p.organizationId) ?? []),
    ]),
  ).filter((o) => o !== undefined)

  return (
    <ErrorWrapper>
      <HStack pb='1rem'>
        <Text layerStyle='heading' pb={4} pt={4}>
          Permissions
        </Text>
        <Spacer />
      </HStack>

      {user.permissions?.organizations?.some((o) => o.id === 7) ? (
        <>
          <GlobalBlock user={user} lastSection={false} />
        </>
      ) : (
        <></>
      )}

      <Grid templateColumns='1fr 1fr' pb='2rem'>
        <GridItem>
          <Text>Add an organization for user</Text>
        </GridItem>

        <GridItem />

        <GridItem>
          <Select
            size='sm'
            value={addOrg}
            onChange={(e) => setAddOrg(Number(e.target.value))}
            maxW='40rem'
          >
            <option value={0}>Select an organization</option>
            {reduceOptions()?.map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </Select>
        </GridItem>

        <GridItem>
          <Button size='sm' onClick={addOrgToUser}>
            Add Org
          </Button>
        </GridItem>
      </Grid>

      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Org</Th>
            <Th>Project</Th>
            <Th>Admin</Th>
            <Th>Edit</Th>
            <Th>View</Th>
          </Tr>
        </Thead>
        <Tbody>
          {orgIds.map((orgId) => (
            <OrgBlock
              key={orgId}
              user={user}
              organizationId={orgId as OrganizationId}
              org={{
                org: user.permissions?.organizations?.find(
                  (o) => o.id === orgId,
                ),
                perm: user.permissions?.organizations?.find(
                  (o) => o.id === orgId,
                ),
              }}
              lastSection={false}
            />
          ))}
        </Tbody>
      </Table>
    </ErrorWrapper>
  )
}
