import { ZodError } from 'zod'

import {
  OrganizationId,
  projectWithOrgInitializer,
  ProjectWithOrgInitializer,
} from '@beaded/models'

import { project } from 'api'

import { logValidationErrors } from 'lib/logValidationErrors'
import { queryClient } from 'lib/react-query'

import type { FormInfo, CreateFormProps } from 'types/FormInfo'
import type { ValidationError } from 'types/ValidationError'

import { projectFields } from '../fields/project'

export const createProjectForm = ({
  initialValues,
}: CreateFormProps<ProjectWithOrgInitializer> = {}): FormInfo<ProjectWithOrgInitializer> => ({
  fields: projectFields,
  title : 'Create Project',

  initialValues: Object.assign(
    {
      visible         : true,
      public          : false,
      projectName     : '',
      organizationId  : 7,
      showRetiredSites: false,
      tempUnits       : 'C',
      lengthUnits     : 'M',
      googlePhotoAlbum: '',
    },
    initialValues,
  ),

  submitText: 'Create Project',
  submitFn  : (values) => project.create(values as ProjectWithOrgInitializer),

  validateForm: (
    values: ProjectWithOrgInitializer,
  ): {
    error?: ValidationError | ZodError
    value: ProjectWithOrgInitializer
  } => {
    values.organizationId = Number(values.organizationId) as OrganizationId

    const validationResult = projectWithOrgInitializer.safeParse(values)

    if (validationResult.success === false) {
      logValidationErrors(validationResult.error)
      return { error: validationResult.error, value: values }
    }

    return {
      value: validationResult.data,
    }
  },

  mutationOptions: {
    // onMutate: (variables) => console.log('onMutate', variables),
    onError: (error, variables, context): void => {
      // console.log('onError', error, variables, context);
      // return error;
    },
    onSuccess: (data: any, variables: any) => {
      if (data?.error) throw data.error
      if (data?.updated === false)
        throw new Error('encountered error creating entry')

      // console.log('onSuccess', data, variables, context);
      queryClient.invalidateQueries('projects')
    },
  },
})
