import { AxiosRequestConfig } from 'axios'
import type { QueryKey, QueryFunction, UseQueryOptions } from 'react-query'

import { project } from 'api'

import { useQuery } from 'hooks/useQuery'
import { useUserContext } from 'hooks/useUserContext'

import { filterVisible } from 'lib/filterVisible'

import type { Obj } from 'types/common'

export const useProjectsList = (
  filter?: AxiosRequestConfig<'params'>,
  queryOpts: UseQueryOptions = {},
) => {
  const { superUser, global } = useUserContext()

  const options: [QueryKey, QueryFunction, UseQueryOptions] =
    superUser === 'on'
      ? [
        ['projects', filter, { superUser }],
        ({ queryKey }) => project.admin.getAll({ params: queryKey[1] }),
        queryOpts,
      ]
      : [
        ['projects', filter],
        ({ queryKey }) => project.getAll({ params: queryKey[1] }),
        queryOpts,
      ]

  const { data, ...rest } = useQuery(...options)

  return {
    projects: filterVisible(data?.proj, global),
    ...rest,
  }
}
