import { z } from 'zod'

/** Represents the enum public.modem_platform_type_ */
enum ModemPlatformType {
  IRIDIUM = 'IRIDIUM',
}

export default ModemPlatformType

/** Zod schema for modem_platform_type_ */
export const modemPlatformType = z.enum(['IRIDIUM'])
