import { Box, Button, Center, Divider } from '@chakra-ui/react'

import { OrganizationId, UserId } from '@beaded/models'

import { ErrorCard } from 'components/ErrorCard'
import { ErrorWrapper } from 'components/ErrorWrapper'
import { Loading } from 'components/Loading'

import { ModalContainer } from 'containers/ModalContainer'

import { UpdateUser } from 'forms/UpdateUser'

import { useUserContext } from 'hooks/useUserContext'

import { PermsBlock } from './PermsBlock.component'
import { UserBetaFlags } from './UserBetaFlags.component'
import { useUserData } from './useUserData'

interface IUserManagementModalProps {
  userId: UserId
  title?: string
  orgFilter?: OrganizationId
}

export const UserManagementModal = ({
  userId,
  title,
  orgFilter,
}: IUserManagementModalProps) => {
  return (
    <ErrorWrapper>
      <ModalContainer
        TriggerButton={({ onClick }) => (
          <Center>
            <Button size='sm' onClick={onClick}>
              Manage
            </Button>
          </Center>
        )}
        modalProps={{ size: '3xl' }}
        header={title ? title : 'Manage User'}
        footer=' '
      >
        <ModalBody userId={userId} orgFilter={orgFilter} />
      </ModalContainer>
    </ErrorWrapper>
  )
}

const ModalBody = ({ userId, orgFilter }: IUserManagementModalProps) => {
  const { global } = useUserContext()
  const { isLoading, errors, user } = useUserData(userId)

  return (
    <>
      {isLoading || errors ? (
        <>
          {errors ? <ErrorCard errors={errors} /> : null}
          {isLoading ? <Loading /> : null}
        </>
      ) : (
        <>
          <UpdateUser user={user} global={global} />

          <Divider pt={4} pb={4} />

          {global.edit ? (
            <Box>
              <UserBetaFlags user={user} />
            </Box>
          ) : null}

          <Divider pt={4} pb={4} />

          <Box>
            {global.edit && <PermsBlock orgFilter={orgFilter} user={user} />}
          </Box>
        </>
      )}
    </>
  )
}
