import {
  Button,
  Input,
  Text,
  Select,
  Grid,
  GridItem,
  Divider,
} from '@chakra-ui/react'
import { useState } from 'react'

import { CodeBlock } from 'components/CodeBlock'
import { ErrorWrapper } from 'components/ErrorWrapper'

import { ModalContainer } from 'containers/ModalContainer'

import { decode, format } from './'

export const SbdDecoderModal = ({
  ButtonElem,
}: {
  ButtonElem?: typeof Button
}) => {
  const [output, setOutput] = useState<string>('')
  const [file, setFile] = useState<File | null>(null)
  const [version, setVersion] = useState<number>(0)

  const decodeFile = async (e: any) => {
    if (!file) return

    const data = Buffer.from(await file.arrayBuffer())

    let protocolVersion
    let formattedOutput = ''

    if (version !== 0) protocolVersion = version
    else protocolVersion = taste_version(data)

    if (!decode[protocolVersion]) {
      setOutput(`Unsupported protocol version: ${protocolVersion}`)
      return
    }

    const decoded = await decode[protocolVersion]({
      data: { format: protocolVersion, packet: data },
      ts  : { email: new Date().toISOString() },
    })

    formattedOutput = await format[protocolVersion](decoded, protocolVersion)

    setOutput(formattedOutput)
  }

  return (
    <ErrorWrapper>
      <ModalContainer
        TriggerButton={({ onClick }) => (
          <>
            {ButtonElem ? (
              <ButtonElem onClick={onClick}>Decode SBD</ButtonElem>
            ) : (
              <Button onClick={onClick}>Decode SBD</Button>
            )}
          </>
        )}
        header='Decode SBD File'
        modalProps={{
          size: 'xl',
        }}
      >
        <Grid templateColumns='auto 1fr' alignItems='center' gap={3}>
          <GridItem colSpan={2}>
            <Text>
              Select an SBD file to decode.
              <br />
              This should work for formats 1-7, 10, and 11.
              <br />
              For older SBD versions, you may need to select the version to
              ensure accurate results.
            </Text>
          </GridItem>

          <GridItem colSpan={1}>
            <Text>Protocol Version:</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Select
              value={version}
              onChange={(e) => setVersion(Number(e.target.value))}
            >
              <option value={0}>Auto</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
            </Select>
          </GridItem>

          <GridItem colSpan={1}>
            <Text>SBD File:</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <Input
              type='file'
              onChange={(e) => setFile(e.target.files?.[0] ?? null)}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <Button onClick={decodeFile}>Decode</Button>
          </GridItem>
        </Grid>

        <Divider pb={2} />

        <CodeBlock code={output} />
      </ModalContainer>
    </ErrorWrapper>
  )
}

const taste_version = (data: Buffer) => {
  const data_bytes = Buffer.from(data)

  let tasteVer = data_bytes.readUInt16LE(0)

  if (tasteVer === 32768) {
    return 2
  }

  tasteVer = data_bytes.readUInt8(0) & 0b00001111

  return tasteVer
}
