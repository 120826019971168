import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react'
import { useState, useEffect } from 'react'

export const Timer = ({ ms = 0 }) => {
  const [timeLeft, setTimeLeft] = useState(ms)

  useEffect(() => {
    const timer =
      timeLeft > 0 && setInterval(() => setTimeLeft(timeLeft - 100), 100)
    return () => clearInterval(timer)
  }, [timeLeft])

  return (
    <CircularProgress value={(-100 / ms) * timeLeft + 100}>
      <CircularProgressLabel>{`${Math.round(
        timeLeft / 1000,
      )}s`}</CircularProgressLabel>
    </CircularProgress>
  )
}
