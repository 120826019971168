import type { UseQueryOptions } from 'react-query'

import { User, UserId } from '@beaded/models'

import { user, perm } from 'api'

import { useQueries } from 'hooks/useQueries'
import { useUserContext } from 'hooks/useUserContext'

type OrgRow = {
  id: number
  view?: boolean
  edit?: boolean
  admin?: boolean
  extras: {
    org: Extras
  }
}
type Extras = { id: number; name: string }
type OrgNames = { [key: number]: Extras }

export const useUserData = (userId?: UserId) => {
  const { superUser } = useUserContext()

  const userPermOptions: UseQueryOptions[] =
    superUser === 'on'
      ? [
        {
          queryKey: ['user', { id: userId }, { superUser }],
          queryFn : () => user.admin.get(userId as UserId),
          enabled : !!userId,
        },
        {
          queryKey: ['perms', { 'user.id': userId }, { superUser }],
          queryFn : () => perm.get(userId as UserId),
          enabled : !!userId,
        },
      ]
      : [
        {
          queryKey: ['user', { id: userId }, { superUser }],
          queryFn : () => user.get(userId as UserId),
          enabled : !!userId,
        },
        {
          queryKey: ['perms', { 'user.id': userId }, { superUser }],
          queryFn : () => perm.get(userId as UserId),
        },
      ]

  const { data, ...rest } = useQueries(userPermOptions)

  const orgNames: OrgNames = (data?.perm?.org ?? []).reduce(
    (acc: OrgNames, org: OrgRow) => {
      if (org?.extras?.org) {
        acc[org.id] = org.extras.org
      }
      return acc
    },
    {} as OrgNames,
  )

  for (const p of data?.perm?.project ?? []) {
    if (p?.extras?.org !== undefined) {
      if (orgNames[p.extras.org.id] === undefined) {
        orgNames[p.extras.org.id] = p.extras.org
      }
    }
  }

  return {
    orgNames,
    user : data?.user as User,
    perms: data?.perm,
    ...rest,
  }
}
