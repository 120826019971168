import merge from 'lodash.merge'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import type { QueryKey, QueryFunction, QueryOptions } from 'react-query'

import { project } from 'api'

import { alphabeticalByName } from 'forms/helpers/alphabeticalByField'
import { useAsyncSelectOptions } from 'forms/helpers/useAsyncSelectOptions'
import { SelectInput, SelectInputProps } from 'forms/inputs/SelectInput'

import { useUserContext } from 'hooks/useUserContext'

interface useProjectSelectOptionsProps {
  filterFn?: Function
}

export const useProjectSelectOptions = (
  props: useProjectSelectOptionsProps,
) => {
  const { superUser } = useUserContext()

  const queryArgs: [QueryKey, QueryFunction, QueryOptions?] =
    superUser === 'on'
      ? [
        ['project', { superUser, projection: 'menu' }],
        () => project.admin.getMenuProjection(),
      ]
      : [['project', { projection: 'menu' }], () => project.getMenuProjection()]

  const { options, ...rest } = useAsyncSelectOptions({
    queryArgs,
    dataKey: 'proj',
    mapFn  : (project: any) => ({
      name          : project.projectName,
      value         : project.id,
      organizationId: project.organizationId,
      visible       : project.visible,
    }),
    filterFn: props.filterFn,
    sortFn  : alphabeticalByName,
  })

  return {
    options,
    ...rest,
  }
}

export const SelectProjectWithOrgFilter = ({
  watchField = 'organizationId',
  name,
  label,
  placeholder,
  hookformProps = {},
}: Omit<SelectInputProps, 'options'> & { watchField?: string }) => {
  const { watch } = useFormContext()
  const { options } = useProjectSelectOptions({
    filterFn: (p: any) => p.visible,
  })
  const organizationId = watch(watchField)
  const [opts, setOpts] = useState<any[]>([])

  useEffect(() => {
    if (opts.length === 0 && organizationId !== undefined) {
      // TODO: Explicit cast of integer looks bad, determine better way to handle orgId String from watch function.
      setOpts(
        options?.filter(
          (opt: any) =>
            opt.organizationId === Number(organizationId) && opt.visible,
        ),
      )
    }
  }, [options, opts.length, organizationId])

  return (
    <SelectInput
      name={name}
      label={label}
      options={opts}
      placeholder={placeholder ?? 'Select a project'}
      hookformProps={merge(hookformProps, {
        valueAsNumber: true,
        disabled     : !organizationId,
      })}
    />
  )
}
