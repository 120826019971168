import Joi from 'joi'

import * as common from './common.js'

export const siteEvent = Joi.object({
  action: Joi.string().valid('reprocess'),

  site: Joi.object({
    id: Joi.number()
      .integer()
      .when('action', { is: 'reprocess', then: Joi.required() }),
  }).when('action', { is: 'reprocess', then: Joi.required() }),
  imei       : Joi.string().when('action', { is: 'reprocess', then: Joi.required() }),
  decodeOrder: Joi.array()
    .items(Joi.number().integer())
    .when('action', { is: 'reprocess', then: Joi.required() }),
  begin: Joi.date().when('action', { is: 'reprocess', then: Joi.required() }),
  end  : Joi.date().when('action', { is: 'reprocess', then: Joi.required() }),

  notes: Joi.string().empty(''),

  by_user: common.BY_USER,

  // this is added by the server if not passed with the body
  date: Joi.date().iso().greater('2009-01-01T00:00:00Z'),
})
