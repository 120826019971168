/**
 * useOS
 * adapted from https://stackoverflow.com/a/38241481
 * @returns {('macos'|'ios'|'windows'|'android'|'linux')}
 */
export function useOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'macos'
  }
  else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'ios'
  }
  else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'windows'
  }
  else if (/Android/.test(userAgent)) {
    os = 'android'
  }
  else if (!os && /Linux/.test(platform)) {
    os = 'linux'
  }

  return os
}
