import { Box, Center, Flex, useDisclosure } from '@chakra-ui/react'

import beadedstream from 'assets/beadedstream_logo.png'

import { NavDrawer } from 'containers/NavDrawer'

import { useUserContext } from 'hooks/useUserContext'

import { drawerContents } from 'routes'

import { NavBar } from './NavBar.component'

const BaseLayoutWrapper = ({ children }: { children: React.ReactNode }) => {
  const auth = useUserContext()

  return (
    <BaseLayout auth={auth}>
      {children}
      <Center>
        <CenteredLogo />
      </Center>
    </BaseLayout>
  )
}
export default BaseLayoutWrapper

interface BaseLayoutProps {
  children: React.ReactNode
  auth: ReturnType<typeof useUserContext>
}

export const BaseLayout = ({ children, auth }: BaseLayoutProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (!auth.currentUser) return <Box>{children}</Box>

  return (
    <Flex
      direction='column'
      sx={{
        '@media print': {
          width: '100vw',
        },
      }}
    >
      <NavDrawer
        isOpen={isOpen}
        onClose={onClose}
        drawerContents={drawerContents}
        auth={auth}
      />
      <NavBar onOpen={onOpen} auth={auth} />
      <Box ml={{ xl: '320px' }}>{children}</Box>
    </Flex>
  )
}

export const CenteredLogo = () => (
  <Box
    position='fixed'
    bottom='0'
    marginLeft='auto'
    marginRight='auto'
    width='150px'
    p='2'
    zIndex='-1'
    sx={{
      '@media print': {
        display: 'none',
      },
    }}
  >
    <Box
      as='img'
      src={beadedstream}
      alt='logo'
      bgColor='white'
      borderRadius='full'
      paddingLeft={2}
      paddingRight={2}
    />
  </Box>
)
