import { format } from 'date-fns'

const SMALL_SPACING = 4

export function ParseSnowDepth(decodedObject: any): string {
  if (!('snow_depth' in decodedObject)) return ''

  const output = `Snow Depth:\n ${' '.repeat(SMALL_SPACING)} ${
    decodedObject.snow_depth
  }\n`

  return output
}

export function ParseReadings(decodedObject: any): string {
  if (!('readings' in decodedObject)) return ''

  let output = ''

  output += 'Readings: \n'
  for (let reading = 0; reading < decodedObject.readings.length; reading++) {
    output +=
      ' '.repeat(SMALL_SPACING) +
      `${reading + 1}: ${decodedObject.readings[reading]}\n`
  }

  return output
}

export function ParseCables(decodedObject: any): string {
  if (!('cable' in decodedObject)) return ''

  let cableCount = 1
  let output = ''

  decodedObject.cable.forEach(function (cableName: any) {
    output += `Cable ${cableCount}:\n`
    output += `  Readings:\n`
    for (let reading = 0; reading < cableName.readings.length; reading++) {
      output +=
        ' '.repeat(SMALL_SPACING) +
        `${reading + 1}: ${cableName.readings[reading]}\n`
    }
    cableCount += 1
    output += '\n'
  })

  return output
}

export function ParseLogger(decodedObject: any): string {
  let output = ''

  output += `Logger:\n`
  output +=
    ' '.repeat(SMALL_SPACING) + `Temperature: ${decodedObject.logger.temp}\n`
  output +=
    ' '.repeat(SMALL_SPACING) + `Battery: ${decodedObject.logger.battery}\n\n`

  return output
}

export function ParseTransmissionTimestamp(decodedObject: any): string {
  let output = ''

  const year = new Date().getFullYear()
  const month = String(decodedObject.ts.month).padStart(2, '0')
  const day = String(decodedObject.ts.day).padStart(2, '0')
  const hour = String(decodedObject.ts.hour).padStart(2, '0')
  const minute = String(decodedObject.ts.minute).padStart(2, '0')
  const transmissionDate = `${year}/${month}/${day} ${hour}:${minute}`
  output += `Record Time: ${format(
    new Date(transmissionDate),
    'yyyy-MM-dd HH:MM',
  )}\n\n`

  return output
}
