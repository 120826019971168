import { Button } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { QueryErrorResetBoundary } from 'react-query'

import { Card } from 'containers/Card'

export const ErrorWrapper = ({
  children,
  errorText = 'There was an error!',
}: {
  children: React.ReactNode
  errorText?: string
}) => (
  <QueryErrorResetBoundary>
    {({ reset }): React.ReactNode => (
      <>
        <Sentry.ErrorBoundary
          showDialog
          onReset={reset}
          fallback={({ error, componentStack, resetError }) => (
            <Card>
              <div>{errorText}</div>
              <div>
                {error instanceof Error ? error.toString() : `Error: ${error}`}
              </div>
              <div>{componentStack}</div>
              <Button onClick={() => resetError()}>Reset stack</Button>
            </Card>
          )}
        >
          {children}
        </Sentry.ErrorBoundary>
      </>
    )}
  </QueryErrorResetBoundary>
)
