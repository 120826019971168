import { DownloadIcon } from '@chakra-ui/icons'
import { Checkbox, Grid, IconButton, Tooltip } from '@chakra-ui/react'
import { GrEdit } from 'react-icons/gr'
import { IoMdRefresh } from 'react-icons/io'

import {
  HasPermissionContext,
  OrganizationId,
  perms,
  ProjectId,
} from '@beaded/models'

import { ChangeLogModal } from 'components/ChangeLogModal'
import { DeleteModal } from 'components/DeleteModal'
import { FormModal } from 'components/FormModal'
import { InfoModal } from 'components/InfoModal'
import { ReprocessSiteModal } from 'components/ReprocessSiteModal'

import { useUserContext } from 'hooks/useUserContext'

import type { InitFormModal } from 'types/FormInfo'

interface IActionsBlockProps {
  data: any
  formInfo: InitFormModal
  deleteFn: Function
  canDownload?: boolean
  downloadFn?: Function
  downloadModal?: InitFormModal
  infoModalHiddenColumns?: string[]
  hideUpdateButton?: boolean
  hideDeleteButton?: boolean
  reprocessing?: boolean
  changelog?: boolean
  buttonSize?: 'md' | 'sm' | 'xs'
  buttonVariant?: string
  buttonProps?: any
  table?: string
  view?: 'org' | 'project'
  customerDelete?: any
  CustomFormModal: React.ElementType
}

export const ActionsBlock = ({
  data,
  formInfo,
  deleteFn,
  canDownload,
  downloadFn,
  downloadModal,
  infoModalHiddenColumns,
  hideUpdateButton,
  hideDeleteButton,
  reprocessing,
  changelog,
  buttonSize = 'sm',
  buttonProps = {},
  table,
  buttonVariant = 'solid',
  view,
  customerDelete,
  CustomFormModal,
}: IActionsBlockProps) => {
  const { global, hasPermission } = useUserContext()

  const buttons = []

  if (canDownload && downloadFn) {
    buttons.push(
      <Tooltip key={`${data?.row?.original?.id}-download`} label='Download CSV'>
        <IconButton
          icon={<DownloadIcon />}
          onClick={() => downloadFn(data?.row?.original?.id)}
          aria-label='download'
          size={buttonSize}
          {...buttonProps}
        />
      </Tooltip>,
    )
  }

  if (canDownload && downloadModal) {
    buttons.push(
      <FormModal
        key={`${data?.row?.original?.id}-download-csv`}
        formInfo={downloadModal({
          initialValues: { ...data?.row?.original, global },
        })}
        iconButton={<DownloadIcon />}
        iconButtonProps={{
          ...buttonProps,
          'aria-label': 'download',
          size        : buttonSize,
          variant     : buttonVariant,
        }}
        buttonText='Download CSV'
      />,
    )
  }

  const hasPermissionContext: HasPermissionContext = {}

  if (data?.row?.original?.mongoExtras) {
    // its a site
    hasPermissionContext.organizationId = data?.row?.original?.mongoExtras?.org
      ?.id as OrganizationId

    hasPermissionContext.projectId = data?.row?.original?.mongoExtras?.project
      ?.id as ProjectId
  }

  if (data?.row?.original?.org) {
    // its a project
    hasPermissionContext.organizationId = data?.row?.original?.org
      ?.id as OrganizationId
    hasPermissionContext.projectId = data?.row?.original?.id as ProjectId
  }

  if (
    !hideUpdateButton &&
    formInfo !== undefined &&
    (global.edit || hasPermission(perms.EDIT_SITE, hasPermissionContext))
  ) {
    /* TODO change edit button to allow people with correct perms, pending per field global edit requirements */
    buttons.push(
      <FormModal
        key={`${data?.row?.original?.id}-edit`}
        formInfo={formInfo({ initialValues: data?.row?.original })}
        iconButton={<GrEdit color='orange' className='gr-icon' />}
        iconButtonProps={{
          ...buttonProps,
          'aria-label': 'edit',
          size        : buttonSize,
          variant     : 'link',
        }}
        global={global}
        buttonText='Edit'
      />,
    )
  }

  if (CustomFormModal) {
    buttons.push(
      <CustomFormModal
        key={`${data?.row?.original?.id}-custom`}
        iconButton={<GrEdit className='gr-icon' />}
        iconButtonProps={{
          ...buttonProps,
          'aria-label': 'edit',
          size        : buttonSize,
          variant     : 'link',
        }}
        data={data?.row?.original}
      />,
    )
  }

  if (
    global.edit &&
    reprocessing &&
    data?.row?.original?.readingsVersion !== 2
  ) {
    buttons.push(
      <ReprocessSiteModal
        key={`${data?.row?.original?.id}-reprocess`}
        site={data?.row?.original}
        iconButtonProps={{
          ...buttonProps,
          'aria-label': 'reprocess',
          size        : buttonSize,
        }}
        iconButton={<IoMdRefresh />}
        buttonText='Reprocess Site Data'
      />,
    )
  }

  if (
    global.view &&
    changelog &&
    data?.row?.original?.extras?.changelog !== undefined
  ) {
    buttons.push(
      <ChangeLogModal
        key={`${data?.row?.original?.id}-changelog`}
        data={data?.row?.original?.extras?.changelog}
        tooltip='ChangeLog'
        iconButtonProps={{
          ...buttonProps,
          'aria-label': 'changelog',
          size        : buttonSize,
        }}
      />,
    )
  }

  if (global.view) {
    const name =
      data?.row?.values?.siteName ??
      data?.row?.values?.projectName ??
      data?.row?.values?.organizationName ??
      data?.row?.values?.name
    // const name = data?.row?.values?.projectName
    //   ? data?.row?.values?.projectName
    //   : data?.row?.values?.organizationName
    //     ? data?.row?.values?.organizationName
    //     : data?.row?.values?.name

    buttons.push(
      <InfoModal
        key={`${data?.row?.original?.id}-info`}
        data={
          infoModalHiddenColumns
            ? filterKeysFromObj(data?.row?.original, infoModalHiddenColumns)
            : data?.row?.original
        }
        title={name}
        iconButtonProps={{
          ...buttonProps,
          'aria-label': 'more info',
          size        : buttonSize,
        }}
        tooltip='Raw Data'
      />,
    )
  }

  if (!hideDeleteButton && global.edit) {
    buttons.push(
      <DeleteModal
        key={`${data?.row?.original?.id}-delete`}
        data={data?.row?.original}
        iconButtonProps={{
          ...buttonProps,
          'aria-label': 'delete',
          size        : buttonSize,
        }}
        deleteFn={() => deleteFn(data?.row?.original?.id)}
      />,
    )
  }

  if (customerDelete) {
    buttons.push(
      <DeleteModal
        key={`${data?.row?.original?.id}-custom-delete`}
        data={data?.row?.original}
        iconButtonProps={{
          ...buttonProps,
          'aria-label': 'delete',
          size        : buttonSize,
        }}
        deleteFn={() => deleteFn(data?.row?.original)}
      />,
    )
  }

  if (
    data?.row?.original?._addCableForExport &&
    data?.row?.original?._bulkExportMode === 'SELECT'
  ) {
    buttons.push(
      <Checkbox
        onChange={(e) => {
          data?.row?.original?._addCableForExport(
            data?.row?.original?.id,
            e.target.checked,
          )
        }}
        inputProps={{
          className: 'bulk-export-checkbox',
        }}
      />,
    )
  }

  if (buttons.length === 0) {
    return null
  }

  return (
    <Grid gap={3} templateColumns={`repeat(${buttons.length}, 1fr)`}>
      {buttons}
    </Grid>
  )
}

export const actions = (props: any) => ({
  Header             : 'Actions',
  id                 : 'actions-block',
  isCentered         : true,
  Cell               : (data: any) => <ActionsBlock data={data} {...props} />,
  disableGlobalFilter: true,
  isSticky           : true,
  canSort            : false,
})

const filterKeysFromObj = (raw: Object, keys: string[]) => {
  const filtered = Object.keys(raw)
    .filter((key) => !keys.includes(key))
    .reduce((obj: Object, key: string) => {
      obj[key] = raw[key]
      return obj
    }, {})
  return filtered
}

type Object = {
  [key: string]: any
}
