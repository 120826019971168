import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'

import { UserApiKeyId, UserId } from '@beaded/models'

import type { Obj, ID } from 'types/common'

import * as apiUtils from '.'

const url = '/user'

export const getSelf = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/me`, options)

export const get = (id: UserId, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/${id}`, options)

export const getAll = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, options)

interface IUserUpdateDoc {
  password?: string
  org?: { id: number }
  prefs?: any
  name?: { first: string; last: string }
  email?: string
  apiKey?: boolean
  notifyUser?: boolean
}
export const updateUser = (userId: UserId, updateDoc: IUserUpdateDoc) =>
  apiUtils.PUT(`${url}/${userId}`, { ...updateDoc })

export const updatePassword = (
  userId: UserId,
  password: IUserUpdateDoc['password'],
  notifyUser?: boolean,
) => updateUser(userId, { password, notifyUser })

export const updatePrefs = (userId: UserId, prefs: IUserUpdateDoc['prefs']) =>
  updateUser(userId, { prefs })

export const updateName = (userId: UserId, name: IUserUpdateDoc['name']) =>
  updateUser(userId, { name })

export const addOrg = (userId: UserId, org: IUserUpdateDoc['org']) =>
  updateUser(userId, { org })

export const removeOrg = (userId: UserId, org: IUserUpdateDoc['org']) =>
  apiUtils.DELETE(`${url}/`, { userId, org })

export const addApiKey = (userId: UserId) =>
  updateUser(userId, { apiKey: true })

export const removeApiKey = (userId: UserId, apiKey: UserApiKeyId) =>
  apiUtils.DELETE(`${url}/`, { userId, apiKey })

export const remove = (id: ID) => apiUtils.DELETE(`${url}/${id}`)

// save user settings
export const create = (data: Obj) => apiUtils.POST(`${url}/`, data)

export const login = (user: Obj) => apiUtils.POST(`${url}/session`, user)

export const logout = () => apiUtils.DELETE(`${url}/session`)

export const getMenuProjection = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({ params: { projection: 'menu' } }, options))

export const requestPasswordReset = (username: string) =>
  apiUtils.POST(`${url}/reset/`, { email: username })

export const authenticateOTP = (otp: string) =>
  apiUtils.GET(`${url}/once/${otp}`)

export const resetPassword = (otp: string, password: string) => {
  apiUtils.PUT(`${url}/reset/`, { otp, password })
}

export const addToOrg = (data: {
  name: { first: string; last: string }
  email: string
  org: { id: ID }
}) => apiUtils.POST(`${url}/add-to-org`, data)

export const removeFromOrg = (data: { email: string; org: { id: ID } }) =>
  apiUtils.POST(`${url}/remove-from-org`, data)

export const addToProject = (data: {
  name: { first: string; last: string }
  email: string
  project: { id: ID }
}) => apiUtils.POST(`${url}/add-to-project`, data)

export const removeFromProject = (data: {
  email: string
  project: { id: ID }
}) => apiUtils.POST(`${url}/remove-from-project`, data)

export const patch = (id: UserId, data: Obj) =>
  apiUtils.PATCH(`${url}/${id}`, data)

export const admin = {
  get: (id: UserId, options: AxiosRequestConfig = {}) =>
    get(id, merge({}, apiUtils.globalFlags, options)),
  getAll: (options: AxiosRequestConfig = {}) =>
    getAll(merge({}, apiUtils.globalFlags, options)),
  getMenuProjection: (options: AxiosRequestConfig = {}) =>
    getMenuProjection(merge({}, apiUtils.globalFlags, options)),
  switchToUser: (email: string, options: AxiosRequestConfig = {}) =>
    apiUtils.POST(`${url}/switch`, { email }, options),
}
