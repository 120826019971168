import { z } from 'zod'

/** Represents the enum public.cable_mold_type_ */
enum CableMoldType {
  STRAIGHT = 'STRAIGHT',
  NONE = 'NONE',
  NINETY_DEGREE = 'NINETY_DEGREE',
  END = 'END',
}

export default CableMoldType

/** Zod schema for cable_mold_type_ */
export const cableMoldType = z.enum([
  'STRAIGHT',
  'NONE',
  'NINETY_DEGREE',
  'END',
])
