import type { UseQueryOptions } from 'react-query'

import { UserId } from '@beaded/models'

import { user } from 'api'

import { useQueries } from 'hooks/useQueries'
import { useUserContext } from 'hooks/useUserContext'

export const useUserData = (userId: UserId) => {
  const { superUser } = useUserContext()

  const userPermOptions: UseQueryOptions[] =
    superUser === 'on'
      ? [
        {
          queryKey: ['user', { id: userId }, { superUser }],
          queryFn : () => user.admin.get(userId),
        },
      ]
      : [
        {
          queryKey: ['user', { id: userId }],
          queryFn : () => user.get(userId),
        },
      ]

  const { data, ...rest } = useQueries(userPermOptions)

  return {
    user: data?.user,
    ...rest,
  }
}
