import {
  Center,
  Checkbox,
  HStack,
  IconButton,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react'
import { FaTrashAlt } from 'react-icons/fa'

import { perm } from 'api'

import { ErrorCard } from 'components/ErrorCard'
import { InternalLink } from 'components/Links'
import { Loading } from 'components/Loading'

import { queryClient } from 'lib/react-query'

import { useProjectsList } from './useProjectsList'

interface OrgBlockProps {
  org: any
  perms: any
  userId: number | string
  lastSection: boolean
}

export const OrgBlock = ({
  org,
  perms,
  userId,
  lastSection,
}: OrgBlockProps) => {
  const { isLoading, errors, permsMapping } = useProjectsList(
    org.org?.id,
    perms,
  )

  const checkField = async (
    group: 'org' | 'project',
    level: 'view' | 'edit' | 'admin',
    id: number,
    value: boolean,
  ) => {
    let data = {}

    if (level === 'view') {
      if (value) {
        data = { view: true }
      }
      else {
        data = { view: false, edit: false, admin: false }
      }
    }
    else if (level === 'edit') {
      if (value) {
        data = { edit: true, view: true }
      }
      else {
        data = { edit: false, admin: false }
      }
    }
    else if (level === 'admin') {
      if (value) {
        data = { admin: true, edit: true, view: true }
      }
      else {
        data = { admin: false }
      }
    }

    if (group === 'org') {
      if (org.perm) await perm.update(userId, { [group]: { id, ...data } })
      else await perm.create(userId, { [group]: { id, ...data } })
    }
    if (group === 'project') {
      if (perms?.find((p: any) => p?.id === id))
        await perm.update(userId, { [group]: { id, ...data } })
      else await perm.create(userId, { [group]: { id, ...data } })
    }

    queryClient.invalidateQueries(['perms', { 'user.id': userId }])
  }

  const removeOrg = async (id: number) => {
    await perm.remove(userId, { org: { id } })
    queryClient.invalidateQueries(['perms', { 'user.id': userId }])
  }

  if (isLoading) return <Loading />

  if (errors) return <ErrorCard errors={errors} />

  return (
    <>
      <Tr>
        <Th colSpan={2}>
          <HStack>
            <InternalLink
              name={org.org.organizationName}
              href={`/orgs/${org.org?.id}`}
            />
            <IconButton
              icon={<FaTrashAlt />}
              size='xs'
              aria-label='remove org from user'
              onClick={() => removeOrg(org.org?.id)}
            />
          </HStack>
        </Th>
        <Td>
          <Center>
            <Checkbox
              isChecked={org?.perm?.admin}
              onChange={(e) =>
                checkField('org', 'admin', org.org.id, e.target.checked)
              }
            />
          </Center>
        </Td>
        <Td>
          <Center>
            <Checkbox
              isChecked={org?.perm?.edit}
              onChange={(e) =>
                checkField('org', 'edit', org.org.id, e.target.checked)
              }
            />
          </Center>
        </Td>
        <Td>
          <Center>
            <Checkbox
              isChecked={org?.perm?.view}
              onChange={(e) =>
                checkField('org', 'view', org.org.id, e.target.checked)
              }
            />
          </Center>
        </Td>
      </Tr>
      {permsMapping.map(({ project, perm }: any) => (
        <Tr key={project.id}>
          <Td />
          <Td>
            <InternalLink
              name={project.projectName}
              href={`/projects/${project.id}`}
            />
          </Td>
          <Td>
            <Center>
              {perm?.admin ? (
                <Checkbox
                  isChecked={perm?.admin}
                  onChange={(e) =>
                    checkField('project', 'admin', project.id, e.target.checked)
                  }
                />
              ) : org?.perm?.admin ? (
                <Checkbox
                  colorScheme='gray'
                  isIndeterminate={org?.perm?.admin}
                  onChange={(e) =>
                    checkField('project', 'admin', project.id, e.target.checked)
                  }
                />
              ) : (
                <Checkbox
                  onChange={(e) =>
                    checkField('project', 'admin', project.id, e.target.checked)
                  }
                />
              )}
            </Center>
          </Td>
          <Td>
            <Center>
              {perm?.edit ? (
                <Checkbox
                  isChecked={perm?.edit}
                  onChange={(e) =>
                    checkField('project', 'edit', project.id, e.target.checked)
                  }
                />
              ) : org?.perm?.edit ? (
                <Checkbox
                  colorScheme='gray'
                  isIndeterminate={org?.perm?.edit}
                  onChange={(e) =>
                    checkField('project', 'edit', project.id, e.target.checked)
                  }
                />
              ) : (
                <Checkbox
                  onChange={(e) =>
                    checkField('project', 'edit', project.id, e.target.checked)
                  }
                />
              )}
            </Center>
          </Td>
          <Td>
            <Center>
              {perm?.view ? (
                <Checkbox
                  isChecked={perm?.view}
                  onChange={(e) =>
                    checkField('project', 'view', project.id, e.target.checked)
                  }
                />
              ) : org?.perm?.view ? (
                <Checkbox
                  colorScheme='gray'
                  isIndeterminate={org?.perm?.view}
                  onChange={(e) =>
                    checkField('project', 'view', project.id, e.target.checked)
                  }
                />
              ) : (
                <Checkbox
                  onChange={(e) =>
                    checkField('project', 'view', project.id, e.target.checked)
                  }
                />
              )}
            </Center>
          </Td>
        </Tr>
      ))}

      {!lastSection && <Tr h='2rem' />}
    </>
  )
}
