import { Center, Box, useBreakpointValue } from '@chakra-ui/react'

type CardProps = {
  children: JSX.Element[] | JSX.Element
  containerStyle?: any
  style?: any
  cardProps?: any
}

export const Card = ({
  children,
  containerStyle,
  style,
  cardProps,
}: CardProps) => {
  const cardContainerStyle = useBreakpointValue({
    base: 'mobileCardContainer',
    md  : 'cardContainer',
  })
  const cardStyle = useBreakpointValue({
    base: 'mobileCard',
    md  : 'card',
  })

  return (
    <Center layerStyle={cardContainerStyle} style={containerStyle}>
      <Box layerStyle={cardStyle} style={style} {...cardProps}>
        {children}
      </Box>
    </Center>
  )
}
