import { z } from 'zod'

/** Represents the enum public.modem_provider_type_ */
enum ModemProviderType {
  CLOUDLOOP = 'CLOUDLOOP',
}

export default ModemProviderType

/** Zod schema for modem_provider_type_ */
export const modemProviderType = z.enum(['CLOUDLOOP'])
