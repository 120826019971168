import { Code, Text } from '@chakra-ui/react'

import { Card } from 'containers/Card'

interface DataDumpProps {
  name?: string
  data?: any
}

export const DataDump = ({ name, data }: DataDumpProps) => (
  <Card>
    <Text layerStyle='heading'>{name ?? 'Data'}</Text>
    <Code style={{ whiteSpace: 'pre-wrap' }}>
      {JSON.stringify(data, null, 2)}
    </Code>
  </Card>
)
