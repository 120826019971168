import { Grid } from '@chakra-ui/react'

// import { BrowserClient, Feedback, getClient } from '@sentry/react'
import { ContactModal } from 'components/ContactModal'
// import { FAQModal } from 'components/FAQModal';
import { ExternalLink } from 'components/Links'
import { YoutubeModal } from 'components/YoutubeModal'

import { API_HOSTNAME } from 'constants/api'

export const LinkTree = () => {
  // const client = getClient<BrowserClient>()
  // const feedback = client?.getIntegration(Feedback)

  return (
    <Grid templateColumns='1fr 1fr' gap={3}>
      <ExternalLink name='API Docs' href={`${API_HOSTNAME()}/docs.html`} />
      <YoutubeModal />
      <ExternalLink
        name='Feedback'
        href={import.meta.env.VITE_GOOGLE_FORM_FEEDBACK_URL as string}
      />
      <ContactModal />
    </Grid>
  )
}
