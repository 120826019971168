import { FormikValues } from 'formik'
import { ZodError } from 'zod'

import type { ValidationError } from 'types/ValidationError'

export interface IValidate {
  validateForm: (values: FormikValues) => {
    error?: ValidationError | ZodError
    value: FormikValues
  }
  values: FormikValues
}

export const parseError = (error: ValidationError | ZodError): FormikValues => {
  if ('details' in error) {
    const field = error.details[0].context.label
    const msg = error.details[0].message

    return {
      [field]: msg,
    }
  }

  if ('issues' in error) {
    return {
      [error.issues[0].code]: error.issues[0].message,
    }
  }

  return {}
}

export const validate = ({
  validateForm,
  values,
}: IValidate): { values: FormikValues; errors?: FormikValues } => {
  try {
    const { error, value } = validateForm(values)

    if (error) {
      return { errors: parseError(error), values: value }
    }
    return { values: value }
  }
  catch (error) {
    console.error({ error })
    return { values }
  }
}
