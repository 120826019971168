import { Flex, Text, useBreakpointValue } from '@chakra-ui/react'
import { format as formatDate } from 'date-fns'
import memoize from 'memoizee'
import { AiFillEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { Cell } from 'react-table'

import { actions } from 'components/ActionsBlock'
import { InternalLink } from 'components/Links'
import { NotesModal } from 'components/NotesModal'
import { SparkLine } from 'components/SparkLine'
import { booleanSort, DataTable } from 'components/Table'

import { downloadSiteForm } from 'lib/downloadSite'
import { formatLoggerSerial } from 'lib/formatLoggerSerial'
import { deleteSite } from 'lib/mutations/delete/deleteSite'
import { updateCustomerSiteForm } from 'lib/mutations/update/editCustomerSite'
import { updateSiteForm } from 'lib/mutations/update/updateSite'
import { combineDecodeList } from 'lib/parseDecodeOrder'

import { Site } from 'types/Site'
import type { KeysOf } from 'types/common'

type SiteCell = Cell & {
  row: {
    original: Site
  }
}

interface SitesTableProps {
  data: any
  globalFilter?: any
  projection: SitesTableProjection
  download?: any
  sortBy?: Array<any>
}

export const SitesTable = ({
  data,
  globalFilter,
  projection = 'default',
  download = () => {},
  sortBy = [],
}: SitesTableProps) => {
  const zoom = useBreakpointValue({
    base: 0.85,
    md  : 1,
  })

  const hideAirtemp = data.every(
    memoize(
      (site: Site) =>
        site?.cable?.airtemp === undefined || site?.cable?.airtemp === 0,
    ),
  )
  const hideSonic = data.every(
    memoize(
      (site: Site) =>
        site?.sensor?.height === undefined || site?.sensor?.height === 0,
    ),
  )

  if (hideAirtemp) {
    hiddenColumns.push('airtempCable')
    hiddenColumns.push('airtempDecode')
  }

  if (hideSonic) hiddenColumns.push('sonicHeight')

  return (
    <>
      {data && data?.length > 0 ? (
        <DataTable
          columns={projections[projection]}
          data={data}
          initialGlobalFilter={globalFilter}
          hiddenColumns={hiddenColumns}
          download={download}
          downloadColumns={downloadColumns}
          projection={projection}
          sortBy={sortBy}
          sx={{
            zoom,
            mozTransform: `scale(${zoom})`,
          }}
        />
      ) : (
        <Text>no data found</Text>
      )}
    </>
  )
}

const hiddenColumns: string[] = ['order']
const downloadColumns: string[] = [
  'id',
  'name',
  'status',
  'visible',
  'includes_archived_data',
  'display.order',
  'display.plot_snow',
  'display.plot_airtemp',
  'cable.airtemp',
  'geo.lat',
  'geo.lon',
  'extras.project.id',
  'extras.project.projectName',
  'extras.project.organizationId',
  'extras.project.organizationName',
  'extras.lastLogged',
  'extras.lastVoltage',
  'extras.msgSize',
  'extras.logger.serial',
  'use',
  'ts',
]

const columns = {
  id: {
    Header  : 'id',
    accessor: 'id',
    Cell    : ({ value, row }: SiteCell) => (
      <Text
        {...(row.values.status === 'retired'
          ? { color: 'gray.500', fontStyle: 'italic' }
          : {})}
      >
        {value}
      </Text>
    ),
  },

  name: {
    Header  : 'Name',
    id      : 'name',
    accessor: 'name',
    Cell    : ({ value, row }: SiteCell) => (
      <InternalLink
        name={value ?? row.original.id}
        href={`/sites/${row.original.id}`}
        {...(row.values.status === 'retired'
          ? { color: 'gray.500', fontStyle: 'italic' }
          : {})}
      />
    ),
  },

  publicName: {
    Header  : 'Name',
    accessor: 'name',
    Cell    : ({ value, row }: SiteCell) => (
      <InternalLink
        name={value ?? row.original.id}
        href={`/public/sites/${row.original.id}`}
      />
    ),
  },

  status: {
    Header  : 'Status',
    accessor: 'status',
    Cell    : ({ value, row }: SiteCell) => (
      <Text
        {...(row.values.status === 'retired'
          ? { color: 'gray.500', fontStyle: 'italic' }
          : {})}
      >
        {value}
      </Text>
    ),
  },

  visible: {
    id      : 'visible',
    accessor: 'visible',
    Cell    : ({ value }: SiteCell) => {
      if (value === true) return <AiFillEye size={16} />
      if (!value) return <AiOutlineEyeInvisible size={16} />
    },
    sortType: booleanSort,
  },

  project: {
    Header  : 'Project',
    accessor: 'extras.project.projectName',
    Cell    : ({
      row: {
        original: { extras },
        values: { status },
      },
    }: SiteCell) => {
      if (extras?.project !== undefined)
        return (
          <InternalLink
            name={extras?.project?.projectName ?? extras?.project?.id ?? ''}
            href={`/projects/${extras?.project?.id}`}
            {...(status === 'retired'
              ? { color: 'gray.500', fontStyle: 'italic' }
              : {})}
          />
        )

      return null
    },
  },

  batterySparkline: {
    Header  : 'Battery Trend',
    accessor: 'extras.batterySparkline',
    Cell    : ({ value }: SiteCell) => {
      if (value && value.length > 0) return <SparkLine data={value} />
      return null
    },
  },

  publicProject: {
    Header  : 'Project',
    accessor: 'extras.project.projectName',
    Cell    : ({
      row: {
        original: { extras },
      },
    }: SiteCell) => {
      if (extras?.project !== undefined)
        return (
          <InternalLink
            name={extras.project.projectName}
            href={`/public/projects/${extras.project.id}`}
          />
        )

      return null
    },
  },

  org: {
    Header  : 'Organization',
    accessor: 'extras.org.organizationName',
    Cell    : ({
      row: {
        original: { extras },
        values: { status },
      },
    }: SiteCell) => {
      if (extras?.project)
        return (
          <InternalLink
            name={extras.project.organizationName}
            href={`/orgs/${extras.project.organizationId}`}
            {...(status === 'retired'
              ? { color: 'gray.500', fontStyle: 'italic' }
              : {})}
          />
        )

      return null
    },
  },

  permLevel: {
    Header  : '',
    id      : 'perms',
    accessor: 'perm',
    Cell    : ({ value }: SiteCell) => {
      if (value.update === true) return 'edit'
      if (value.read === true) return 'view'
      // if (value.update === true) return <GrEdit size={16} />;
      return null
    },
  },

  publicOrg: {
    Header  : 'Organization',
    accessor: 'extras.project.organizationName',
    Cell    : ({
      row: {
        original: { extras },
      },
    }: SiteCell) => {
      if (extras?.project)
        return (
          <InternalLink
            name={extras.project.organizationName}
            href={`/orgs/${extras.project.organizationId}`}
          />
        )

      return null
    },
  },

  displayOrder: {
    Header  : 'order',
    accessor: memoize((originalRow: any, rowIndex: any) => {
      if (originalRow?.display?.order === 0) return undefined
      return Number(originalRow?.display?.order)
    }),
    // isNumeric: true,
  },

  notes: {
    Header   : 'Notes',
    accessor : 'notes',
    isNumeric: true,
    Cell     : ({ value, row }: SiteCell) => {
      if (!value) return null

      return <NotesModal note={value} name={row.values?.name} />
    },
  },

  lastLogged: {
    Header  : 'Last Transmission',
    accessor: 'extras.lastLogged',
    Cell    : ({ value, row }: SiteCell) => (
      <Text
        {...(row.values.status === 'retired'
          ? { color: 'gray.500', fontStyle: 'italic' }
          : {})}
      >
        {value !== undefined && value !== 0 && value !== null
          ? formatDate(new Date(value), 'yyyy-MM-dd')
          : null}
      </Text>
    ),
  },

  lastVoltage: {
    Header  : 'Last Voltage',
    accessor: 'extras.lastVoltage',
    Cell    : ({ value }: SiteCell) => (
      <Text
        as='span'
        color={value < 5.9 ? 'red' : value < 6 ? 'orange' : 'green'}
      >
        {value ? `${value} V` : null}
      </Text>
    ),
  },

  loggerNoLink: {
    Header  : 'Logger',
    accessor: memoize((originalRow: any, rowIndex: any) => {
      return formatLoggerSerial(originalRow?.extras?.logger?.serial)
    }),
  },

  logger: {
    Header  : 'Logger',
    accessor: memoize((originalRow: any, rowIndex: any) => {
      return formatLoggerSerial(originalRow?.extras?.logger?.serial)
    }),
    Cell: ({ value, row }: SiteCell) => (
      <InternalLink
        name={value}
        href={`/loggers/${row?.original?.extras?.logger?.id}`}
        {...(row?.values?.status === 'retired'
          ? { color: 'gray.500', fontStyle: 'italic' }
          : {})}
      />
    ),
  },

  imei: {
    Header  : 'IMEI',
    id      : 'use-imei',
    accessor: 'use',
    Cell    : ({ value, row }: SiteCell) => {
      const currentLoggers = value?.logger?.filter(
        (logger: any) => !logger.end && Boolean(logger.imei),
      )

      if (currentLoggers?.length > 0)
        return (
          <Flex direction='column' gap={3}>
            {currentLoggers.map((logger: any) => (
              <Text
                key={`site-${row.original.id}-logger-${logger.imei}`}
                {...(row.values.status === 'retired'
                  ? { color: 'gray.500', fontStyle: 'italic' }
                  : {})}
              >
                {logger.imei}
              </Text>
            ))}
          </Flex>
        )
      else if (value?.logger?.length > 0) {
        const logger = value?.logger?.at(-1)
        return (
          <Flex direction='column' gap={3}>
            <Text
              key={`site-${row.original.id}-logger-${logger.imei}`}
              {...(row.values.status === 'retired'
                ? { color: 'gray.500', fontStyle: 'italic' }
                : {})}
            >
              {logger.imei}
            </Text>
          </Flex>
        )
      }

      return null
    },
  },

  cable: {
    Header  : 'Cable',
    id      : 'use-cable-serials',
    accessor: 'use',
    Cell    : ({ value, row }: SiteCell) => {
      const cables = parseSerialAndDecodeFromUse(value?.cable)

      if (cables?.length > 0)
        return (
          <Flex
            direction='column'
            gap={3}
            key={`site-${row.original.id}-cables`}
          >
            {cables.map((cable: any) => {
              if (cable.serial && cable.serial !== 1 && cable.serial !== 2)
                return (
                  <InternalLink
                    key={`site-${row.original.id}-cable-${cable.serial}`}
                    name={cable.serial}
                    href={`/cables/sn${cable.serial}`}
                    {...(row?.values?.status === 'retired'
                      ? { color: 'gray.500', fontStyle: 'italic' }
                      : {})}
                  />
                )

              if (cable.serial === 1)
                return (
                  <span key={`site-${row.original.id}-cable-airtemp`}>
                    Air Temp
                  </span>
                )

              if (cable.serial === 2)
                return (
                  <span key={`site-${row.original.id}-cable-n/a`}>n/a</span>
                )

              return null
            })}
          </Flex>
        )

      return null
    },
  },

  cableNoLink: {
    Header  : 'Cable',
    id      : 'use-cable-serials',
    accessor: 'use',
    Cell    : ({ value, row }: SiteCell) => {
      const cables = parseSerialAndDecodeFromUse(value?.cable)

      if (cables?.length > 0)
        return (
          <Flex direction='column' gap={3}>
            {cables.map((cable: any) => (
              <>
                {cable.serial && cable.serial !== 1 && cable.serial !== 2 ? (
                  <Text
                    key={`site-${row.original.id}-cable-${cable.serial}`}
                    {...(row?.values?.status === 'retired'
                      ? { color: 'gray.500', fontStyle: 'italic' }
                      : {})}
                  >
                    {cable.serial}
                  </Text>
                ) : cable.serial === 1 ? (
                  <span>Air Temp</span>
                ) : cable.serial === 2 ? (
                  <span>n/a</span>
                ) : null}
              </>
            ))}
          </Flex>
        )

      return null
    },
  },

  decodeOrder: {
    Header  : 'Decode',
    id      : 'use-decode',
    accessor: 'use',
    // accessor: 'sensor.decode',
    Cell    : ({ value, row }: SiteCell) => {
      const currentLoggers = (value?.logger as Site['use']['logger'])?.filter(
        (logger) => !logger.end && Array.isArray(logger.decode),
      )

      if (currentLoggers?.length > 0 && row.original?.readingsVersion !== 2) {
        return (
          <Flex direction='column' gap={3}>
            {currentLoggers.map((logger) => (
              <Text
                key={`site-${row.original.id}-logger-${logger.imei}`}
                overflowWrap='anywhere'
                {...(row?.values?.status === 'retired'
                  ? { color: 'gray.500', fontStyle: 'italic' }
                  : {})}
              >
                {combineDecodeList(logger.decode)}
              </Text>
            ))}
          </Flex>
        )
      }

      const cables = parseSerialAndDecodeFromUse(value?.cable)

      if (cables?.length > 0)
        return (
          <Flex direction='column' gap={3}>
            {cables.map((cable) =>
              Array.isArray(cable.decode) ? (
                <Text
                  key={`site-${row.original.id}-cable-${cable.serial}`}
                  overflowWrap='normal'
                  {...(row?.values?.status === 'retired'
                    ? { color: 'gray.500', fontStyle: 'italic' }
                    : {})}
                >
                  {combineDecodeList(cable.decode)}
                </Text>
              ) : null,
            )}
          </Flex>
        )

      return null
    },
  },

  airtempCable: {
    Header  : 'Airtemp Cable',
    accessor: 'cable.airtemp',
    id      : 'airtempCable',
    Cell    : ({ value, row }: SiteCell) =>
      value ? (
        <InternalLink
          name={value}
          href={`/cables/sn${value}`}
          {...(row?.values?.status === 'retired'
            ? { color: 'gray.500', fontStyle: 'italic' }
            : {})}
        />
      ) : (
        <></>
      ),
  },

  airtempDecode: {
    Header  : 'Airtemp Decode',
    accessor: 'sensor.at',
    id      : 'airtempDecode',
    Cell    : ({ value, row }: SiteCell) => (value ? <Text>{value}</Text> : <></>),
  },

  sonicHeight: {
    Header  : 'Sonic Sensor Height',
    accessor: 'sensor.height',
    id      : 'sonicHeight',
    Cell    : ({ value, row }: SiteCell) =>
      value ? <Text>{value} cm</Text> : <></>,
  },

  lastTransmission: {
    Header    : 'Last Transmission',
    isCentered: true,
    columns   : [
      {
        Header  : 'Time',
        accessor: 'extras.lastLogged',
        Cell    : ({ value, row }: SiteCell) => (
          <Text
            {...(row.values.status === 'retired'
              ? { color: 'gray.500', fontStyle: 'italic' }
              : {})}
          >
            {value !== undefined && value !== 0 && value !== null
              ? formatDate(new Date(value), 'yyyy-MM-dd')
              : null}
          </Text>
        ),
      },
      {
        Header  : 'Voltage',
        accessor: 'extras.lastVoltage',
        Cell    : ({ value }: SiteCell) => (
          <Text
            as='span'
            color={value < 5.9 ? 'red' : value < 6 ? 'orange' : 'green'}
          >
            {value ? `${value} V` : null}
          </Text>
        ),
      },
    ],
  },

  adminLastTransmission: {
    Header    : 'Last Transmission',
    isCentered: true,
    columns   : [
      {
        Header  : 'Time',
        accessor: 'extras.lastLogged',
        Cell    : ({ value, row }: SiteCell) => (
          <Text
            {...(row.values.status === 'retired'
              ? { color: 'gray.500', fontStyle: 'italic' }
              : {})}
          >
            {value !== undefined && value !== 0 && value !== null
              ? formatDate(new Date(value), 'yyyy-MM-dd')
              : null}
          </Text>
        ),
      },
      {
        Header  : 'Voltage',
        accessor: 'extras.lastVoltage',
        Cell    : ({ value }: SiteCell) => (
          <Text
            as='span'
            color={value < 5.9 ? 'red' : value < 6 ? 'orange' : 'green'}
          >
            {value ? `${value} V` : null}
          </Text>
        ),
      },
      {
        Header  : 'Bytes',
        accessor: 'extras.msgSize',
        Cell    : ({ value }: SiteCell) => (
          <Text as='span' color={value <= 7 ? 'red' : 'green'}>
            {value ? `${value} bytes` : null}
          </Text>
        ),
      },
    ],
  },

  actions: actions({
    formInfo     : updateSiteForm,
    deleteFn     : deleteSite,
    changelog    : true,
    canDownload  : true,
    reprocessing : true,
    downloadModal: downloadSiteForm,
    buttonSize   : 'xs',
  }),

  customerProjectActions: actions({
    formInfo     : updateCustomerSiteForm,
    canDownload  : true,
    downloadModal: downloadSiteForm,
    buttonProps  : {},
    buttonSize   : 'md',
    buttonVariant: 'link',
    table        : 'site',
    view         : 'project',
  }),

  customerOrgActions: actions({
    formInfo     : updateCustomerSiteForm,
    canDownload  : true,
    downloadModal: downloadSiteForm,
    buttonSize   : 'md',
    buttonVariant: 'link',
    buttonProps  : {},
    table        : 'site',
    view         : 'org',
  }),
}

const projections = {
  default: [
    columns.displayOrder,
    // columns.id,
    columns.visible,
    columns.name,
    columns.status,
    columns.lastTransmission,
    columns.batterySparkline,
    columns.loggerNoLink,
    columns.project,
    columns.org,
    columns.actions,
  ],

  projectPage: [
    columns.displayOrder,
    // columns.id,
    // columns.permLevel,
    // columns.customerProjectActions,
    columns.name,
    columns.loggerNoLink,
    columns.cableNoLink,
    columns.airtempCable,
    columns.status,
    columns.lastTransmission,
    columns.batterySparkline,
    columns.customerProjectActions,
    // columns.actions,
  ],

  adminProjectPage: [
    columns.displayOrder,
    columns.visible,
    columns.id,
    columns.name,
    columns.status,
    columns.adminLastTransmission,
    columns.batterySparkline,
    columns.logger,
    columns.imei,
    columns.cable,
    columns.decodeOrder,
    columns.airtempCable,
    columns.airtempDecode,
    columns.sonicHeight,
    columns.notes,
    columns.actions,
  ],

  orgPage: [
    columns.displayOrder,
    // columns.id,
    // columns.customerOrgActions,
    // columns.permLevel,
    columns.name,
    columns.loggerNoLink,
    columns.cableNoLink,
    columns.project,
    columns.status,
    columns.lastTransmission,
    columns.batterySparkline,
    columns.customerOrgActions,
    // columns.actions,
  ],

  adminOrgPage: [
    columns.displayOrder,
    columns.visible,
    columns.id,
    columns.name,
    columns.status,
    columns.adminLastTransmission,
    columns.batterySparkline,
    columns.logger,
    columns.cable,
    columns.decodeOrder,
    columns.airtempCable,
    columns.airtempDecode,
    columns.sonicHeight,
    columns.notes,
    columns.project,
    columns.org,
    columns.actions,
  ],

  adminView: [
    columns.displayOrder,
    columns.visible,
    columns.id,
    columns.name,
    columns.status,
    columns.adminLastTransmission,
    columns.batterySparkline,
    columns.logger,
    columns.cable,
    columns.decodeOrder,
    columns.airtempCable,
    columns.airtempDecode,
    columns.sonicHeight,
    columns.notes,
    columns.project,
    columns.org,
    columns.actions,
  ],

  publicSitesPage: [
    columns.displayOrder,
    // columns.id,
    columns.publicName,
    columns.status,
    columns.publicProject,
    columns.lastLogged,
    columns.publicOrg,
  ],

  publicProjectPage: [
    columns.displayOrder,
    // columns.id,
    columns.publicName,
    columns.status,
    columns.lastLogged,
    columns.publicOrg,
  ],
}

export type SitesTableProjection = KeysOf<typeof projections>

const parseSerialAndDecodeFromUse = (cableUse: Site['use']['cable']) => {
  const currentCables = cableUse?.filter(
    (cable: any) => !cable.end && Boolean(cable.serial),
  )

  if (currentCables?.length > 0)
    return currentCables?.map((cable: any) => ({
      serial: cable.serial,
      decode: cable.decode,
    }))

  if (cableUse.length >= 2) {
    if (cableUse?.at(-2)?.end === cableUse?.at(-1)?.end) {
      // show both cables
      const cables = cableUse.slice(-2)
      return cables?.map((cable: any) => ({
        serial: cable.serial,
        decode: cable.decode,
      }))
    }
  }

  if (cableUse.length >= 1) {
    const cable = cableUse?.at(-1)
    return [{ serial: cable?.serial, decode: cable?.decode }]
  }

  return []
}
