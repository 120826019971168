import { setUser, getCurrentScope } from '@sentry/react'
import * as Sentry from '@sentry/react'
import fastJsonPatch, { Operation } from 'fast-json-patch'
import isEqual from 'lodash.isequal'
import merge from 'lodash.merge'
import {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
  Suspense,
} from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { useMutation } from 'react-query'
import timezones from 'timezones-list'

import {
  GlobalRoleType,
  hasPermission as rootHasPermission,
  HasPermissionContext,
  OrganizationId,
  perms,
  ProjectId,
  UserId,
  UserWithPermissions,
} from '@beaded/models'
import type {
  Temperature,
  Timezone,
  Coordinates,
  Depth,
  SuperUser,
  BetaFeatures,
  Obj,
} from '@beaded/models'

import { user as userApi } from 'api'

import { Loading } from 'components/Loading'

import { useRouter } from 'hooks/useRouter'

import { queryClient } from 'lib/react-query'
import { resetCookies } from 'lib/resetCookies'

const USER_REFRESH_INTERVAL = 1000 * 60 * 5 // 5 minutes

export const betaOptions = [{ name: 'forecaster', label: 'Forecaster' }]

interface IUserContext {
  currentUser: UserWithPermissions | null
  permissions: UserWithPermissions['permissions'] | null
  prefs: {
    temp: Temperature
    setTemp?: Dispatch<SetStateAction<Temperature>>
    timezone: Timezone
    setTimezone?: Dispatch<SetStateAction<Timezone>>
    coordinates: Coordinates
    setCoordinates?: Dispatch<SetStateAction<Coordinates>>
    depth: Depth
    setDepth?: Dispatch<SetStateAction<Depth>>
    compactCableBuilder: boolean
    setCompactCableBuilder?: Dispatch<SetStateAction<boolean>>
    beta: BetaFeatures
    setBeta?: Dispatch<SetStateAction<BetaFeatures>>
    save?: Function
  }
  global: GlobalPerms
  toggleSuperUser: Function
  setSuperUser?: Dispatch<SetStateAction<SuperUser>>
  superUser: SuperUser
  login: Function
  logout: Function
  switchToUser: Function
  hasPermission: (
    action: keyof typeof perms,
    context: HasPermissionContext,
  ) => boolean
  routing: {
    home: string
    canViewNavNode: Obj
  }
  userCanSeeBetaFeature: Function
}

export type Prefs = {
  temp: Temperature
  timezone: Timezone
  coordinates: Coordinates
  depth: Depth
  compactCableBuilder: boolean
  beta: BetaFeatures
}

export type GlobalPerms = {
  view: boolean
  edit: boolean
  admin: boolean
  cableAdmin: boolean
  loggerAdmin: boolean
}

export const initialState: IUserContext = {
  currentUser: null,
  prefs      : {
    temp               : 'C' as Temperature,
    timezone           : { value: 'UTC' } as Timezone,
    coordinates        : 'DD' as Coordinates,
    depth              : 'ft' as Depth,
    compactCableBuilder: false,
    beta               : betaOptions.reduce(
      (acc, curr) => {
        acc[curr.name] = false
        return acc
      },
      { all: false } as BetaFeatures,
    ),
  } as IUserContext['prefs'],
  global: {
    view       : false,
    edit       : false,
    admin      : false,
    cableAdmin : false,
    loggerAdmin: false,
  },
  permissions    : {},
  toggleSuperUser: () => {},
  superUser      : 'off',
  login          : () => {},
  logout         : () => {},
  switchToUser   : () => {},
  routing        : {
    home          : '/',
    canViewNavNode: {
      '/cables': false,
    },
  },
  userCanSeeBetaFeature: () => {},
  hasPermission        : () => false,
}

const userContext = createContext<IUserContext>(initialState)

declare global {
  interface Window {
    authState: any
    currentUser: any
    permissions: any
  }
}

export function ProvideUserContext({
  children,
}: {
  children: JSX.Element[] | JSX.Element
}) {
  const auth: IUserContext = useProvideUserContext()
  return (
    <Suspense fallback={<Loading />}>
      <userContext.Provider value={auth}>{children}</userContext.Provider>
    </Suspense>
  )
}

export const useUserContext = () => {
  return useContext(userContext)
}

function useProvideUserContext() {
  const { push } = useRouter()
  const [currentUser, setCurrentUser] = useState<UserWithPermissions | null>(
    null,
  )
  const [permissions, setPermissions] = useState<
    UserWithPermissions['permissions'] | null
  >(null)
  const [superUser, setSuperUser] = useState<SuperUser>('off')

  const [userHome, setUserHome] = useState<string>('/')
  const [canViewNavNode, setCanViewNavNode] = useState<Obj>({})

  const [temp, setTemp] = useState<Temperature>('C')
  const [timezone, setTimezone] = useState<Timezone>({ value: 'UTC' })
  const [coordinates, setCoordinates] = useState<Coordinates>('DMS') // or decimal
  const [depth, setDepth] = useState<Depth>('ft')
  const [compactCableBuilder, setCompactCableBuilder] = useState<boolean>(false)
  const [beta, setBeta] = useState<BetaFeatures>({
    all       : false,
    forecaster: false,
  })
  const mutation = useMutation((changes: Operation[]) =>
    userApi.patch((currentUser?.id as UserId) ?? 'me', changes),
  )

  const loginQuery = useMutation(userApi.login, {
    onSuccess: (res) => {
      queryClient.refetchQueries('self')
      const user = res?.user as UserWithPermissions

      Sentry.setContext('user', {
        id   : user?.id,
        email: user?.email,
      })

      setCurrentUser(user)
      setUserPrefs(user?.prefs)

      setUser({ email: user?.email })
    },
  })

  const userDataQuery = useCallback(async () => {
    try {
      const userData = await userApi.getSelf()

      if (userData?.user) {
        if (isEqual(userData?.user, currentUser)) {
          setTimeout(userDataQuery, USER_REFRESH_INTERVAL)
          return undefined
        }

        const user = userData?.user
        setCurrentUser(user)
        user?.prefs && setUserPrefs(user.prefs)
        user?.permissions && setPermissions(user.permissions)
        return undefined
      }

      setCurrentUser(null)
      setUserPrefs(null)
      setPermissions(null)
    }
    catch (e) {
      console.error(e)
      setCurrentUser(null)
      setUserPrefs(null)
      setPermissions(null)
    }
  }, [currentUser])

  useEffect(() => {
    userDataQuery()
  }, [userDataQuery])

  const setUserPrefs = (prefs: UserWithPermissions['prefs'] | null) => {
    setTemp(prefs?.units?.temperature ?? initialState.prefs.temp)
    setTimezone(prefs?.timezone ?? initialState.prefs.timezone)
    setCoordinates(prefs?.units?.coordinates ?? initialState.prefs.coordinates)
    setDepth(prefs?.units?.depth ?? initialState.prefs.depth)
    setSuperUser(prefs?.superUser ?? 'off')
    setCompactCableBuilder(prefs?.compactCableBuilder ?? false)
    setBeta(prefs?.beta ?? initialState.prefs.beta)
  }

  const hasPermission = (
    action: keyof typeof perms,
    context: HasPermissionContext,
  ): boolean => {
    if (currentUser) return rootHasPermission(currentUser, action, context)
    return false
  }

  const saveUserPrefs = () => {
    const changes = fastJsonPatch.compare(
      { prefs: currentUser?.prefs ?? {} },
      {
        prefs: merge({}, currentUser?.prefs ?? {}, {
          units: {
            temperature: temp,
            coordinates,
            depth,
          },
          superUser,
          timezone,
        }),
      },
    )
    mutation.mutate(changes)
  }

  const userCanSeeBetaFeature = (featureName: string) => {
    if (superUser === 'on') return true

    if (beta?.all === true) return true

    if (beta?.[featureName] === true) return true

    return false
  }

  const login = async (username: string, password: string) =>
    loginQuery.mutateAsync({ username, password })

  const logout = async () =>
    userApi.logout().then(() => {
      resetCookies()
      queryClient.clear()
      getCurrentScope().setUser(null)
      setCurrentUser(null)
      setUserPrefs(null)
    })

  const switchToUser = async (username: string) => {
    const res = await userApi.admin.switchToUser(username)

    if (res?.error) {
      throw res.error
    }

    resetCookies()
    queryClient.clear()
    getCurrentScope().setUser(null)

    const user = res?.user

    setCurrentUser(user)
    setUserPrefs(user?.prefs)

    setUser({ email: user?.email?.[0] ?? user?.username })

    setTimeout(() => {
      push('/')
    }, 500)
    return user
  }

  const userCanCreateSite = (level: 'org' | 'project', id: number): boolean => {
    if (
      currentUser &&
      hasPermission(perms.CREATE_SITE, {
        ...(level === 'org'
          ? { organizationId: id as OrganizationId }
          : { projectId: id as ProjectId }),
      })
    )
      return true

    return false
  }

  const userCanEditUsers = (level: 'org' | 'project', id: number): boolean => {
    if (level === 'org') {
      if (
        currentUser &&
        hasPermission(perms.CAN_ASSIGN_ORGANIZATION_VIEW, {
          organizationId: id as OrganizationId,
        })
      )
        return true
    }
    else if (level === 'project') {
      if (
        currentUser &&
        hasPermission(perms.CAN_ASSIGN_ORGANIZATION_VIEW, {
          projectId: id as ProjectId,
        })
      )
        return true
    }

    return false
  }

  useEffect(() => {
    const setUserNav = () => {
      if (superUser === 'on') {
        setUserHome('/search')
        setCanViewNavNode({ '/cables': true, '/drafts': true })
        return
      }

      if (permissions?.organizations) {
        const filteredPerms = permissions.organizations.filter(
          (p) => p.role !== null,
        )

        if (filteredPerms.length > 1) {
          setUserHome('/orgs')
          setCanViewNavNode({ '/cables': true })
          return
        }
        else if (filteredPerms.length === 1) {
          setUserHome(`/orgs/${filteredPerms[0].id}`)
          setCanViewNavNode({ '/cables': true })
          return
        }
      }

      if (permissions?.projects) {
        const filteredPerms = permissions.projects.filter(
          (p) => p.role !== null,
        )

        if (filteredPerms.length > 1) {
          setUserHome('/projects')
          setCanViewNavNode({ '/cables': false })
          return
        }
        else if (filteredPerms.length === 1) {
          setUserHome(`/projects/${filteredPerms[0].id}`)
          setCanViewNavNode({ '/cables': false })
          return
        }
      }
    }

    setUserNav()
  }, [permissions, superUser])

  useEffect(() => {
    const controller = new AbortController()
    ;(async () => {
      if (currentUser?.permissions) {
        try {
          setPermissions(currentUser?.permissions)
        }
        catch (e) {
          console.error(e)
          setPermissions(null)
        }
      }
    })()
    return () => controller?.abort()
  }, [currentUser])

  const authState = {
    currentUser,
    prefs: {
      temp,
      setTemp,
      timezone,
      setTimezone,
      coordinates,
      setCoordinates,
      depth,
      setDepth,
      compactCableBuilder,
      setCompactCableBuilder,
      beta,
      setBeta,
      save: saveUserPrefs,
    },
    permissions,
    global: {
      view:
        permissions?.global?.some(
          (g) =>
            g.role === GlobalRoleType.GLOBAL_VIEW ||
            g.role === GlobalRoleType.GLOBAL_EDIT ||
            g.role === GlobalRoleType.GLOBAL_ADMIN,
        ) ?? false,
      edit:
        permissions?.global?.some(
          (g) =>
            g.role === GlobalRoleType.GLOBAL_EDIT ||
            g.role === GlobalRoleType.GLOBAL_ADMIN,
        ) ?? false,
      admin:
        permissions?.global?.some(
          (g) => g.role === GlobalRoleType.GLOBAL_ADMIN,
        ) ?? false,
      cableAdmin:
        permissions?.global?.some(
          (g) =>
            g.role === GlobalRoleType.CABLE_ADMIN ||
            g.role === GlobalRoleType.GLOBAL_ADMIN,
        ) ?? false,
      loggerAdmin:
        permissions?.global?.some(
          (g) =>
            g.role === GlobalRoleType.LOGGER_ADMIN ||
            g.role === GlobalRoleType.GLOBAL_ADMIN,
        ) ?? false,
    },
    toggleSuperUser: () => setSuperUser(superUser === 'off' ? 'on' : 'off'),
    setSuperUser,
    superUser,
    login,
    logout,
    mutation,
    routing        : {
      home: userHome,
      canViewNavNode,
    },
    userCanSeeBetaFeature,
    switchToUser,
    userCanCreateSite,
    userCanEditUsers,
    hasPermission,
  }

  window.authState = authState
  window.currentUser = currentUser
  window.permissions = permissions

  return authState
}

export const temperatureOptions = [
  { name: 'C', value: 'C' },
  { name: 'F', value: 'F' },
]

export const superUserOptions = [
  { name: 'On', value: 'on' },
  { name: 'Off', value: 'off' },
]

export const timezoneOptions = timezones.map((tz) => ({
  value: tz.tzCode,
  label: tz.name,
}))

export const depthOptions = [
  { name: 'in', value: 'in' },
  { name: 'cm', value: 'cm' },
  { name: 'ft', value: 'ft' },
  { name: 'm', value: 'm' },
]

export const coordinateOptions = [
  { name: 'Decimal Degrees', value: 'DD', desc: '(61.1499, -149.8783)' },
  { name: 'DMS', value: 'DMS', desc: '(61° 8\' 59" N, 149° 52\' 41" W)' },
]
