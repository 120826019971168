import { IconButton, Center, Tooltip, Text } from '@chakra-ui/react'
import { MdStickyNote2 } from 'react-icons/md'

import { ErrorWrapper } from 'components/ErrorWrapper'

import { ModalContainer } from 'containers/ModalContainer'

interface INotesModalProps {
  note: string
  name: string
  iconButtonProps?: any
}

export const NotesModal = ({
  note,
  name,
  iconButtonProps,
}: INotesModalProps) => {
  return (
    <ErrorWrapper>
      <ModalContainer
        TriggerButton={({ onClick }) => (
          <>
            <Tooltip label={'Notes'}>
              <Center>
                <IconButton
                  icon={<MdStickyNote2 />}
                  onClick={onClick}
                  size='sm'
                  {...iconButtonProps}
                />
              </Center>
            </Tooltip>
          </>
        )}
        header={name}
      >
        <Text style={{ whiteSpace: 'pre-wrap' }}>{note}</Text>
      </ModalContainer>
    </ErrorWrapper>
  )
}
