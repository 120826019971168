import { useEffect } from 'react'

export const useDocumentTitle = (
  title: string | number | Array<string | number>,
) => {
  const baseTitle = '' // 'beadedcloud | ';

  useEffect(() => {
    if (Array.isArray(title)) {
      if (title.every((t) => typeof t === 'string' || typeof t === 'number'))
        document.title = baseTitle + title.join(' | ')
    }
    else if (title !== undefined) {
      document.title = baseTitle + `${title}`
    }
    else document.title = 'beadedcloud'
  }, [title])

  useEffect(() => {
    return () => {
      document.title = 'beadedcloud'
    }
  }, [])
}
