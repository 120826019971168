import { Link } from '@chakra-ui/react'
import type { LinkProps } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

interface IInternalLinkProps extends LinkProps {
  name: string | number
  href: string
  state?: any
}

export const InternalLink = ({ name, href, ...props }: IInternalLinkProps) => (
  <Link as={RouterLink} to={href} {...props}>
    {name}
  </Link>
)
