import { ChakraProvider } from '@chakra-ui/provider'
import { ColorModeScript } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'
import { Buffer } from 'buffer'
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import 'regenerator-runtime/runtime'

import * as api from 'api'

import { Router } from 'components/Router'

import { theme, Fonts } from 'constants/theme'

import { ProvideGoogleMapsApi } from 'hooks/useGoogleMapsApi'
import { ProvideUserContext } from 'hooks/useUserContext'

import * as jsValidate from 'lib/jsValidate'
import { queryClient } from 'lib/react-query'

import reportWebVitals from './reportWebVitals'

declare global {
  interface Window {
    jsValidate: typeof jsValidate
    api: typeof api
    version: string
    app_version: string
    Buffer: typeof Buffer
    Chargify: any
  }
}

console.log(import.meta.env.VITE_SENTRY_ENV)

window.jsValidate = jsValidate
window.api = api
window.version = `v${import.meta.env.VITE_VERSION}`
window.app_version = `v${import.meta.env.VITE_APP_VERSION}`
window.Buffer = Buffer

if (process.env.NODE_ENV !== 'development')
  Sentry.init({
    dsn         : import.meta.env.VITE_SENTRY_DSN,
    environment : import.meta.env.VITE_SENTRY_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay({
        maskAllText  : false,
        blockAllMedia: false,
      }),

      // Sentry.feedbackIntegration({
      //   // https://docs.sentry.io/platforms/javascript/user-feedback/configuration/
      //   // Additional SDK configuration goes in here, for example:
      //   showBranding     : false,
      //   buttonLabel      : 'Feedback',
      //   submitButtonLabel: 'Send Feedback',
      //   formTitle        : 'Help us improve your experience on this page.',
      //   autoInject       : false,
      // }),
    ],
    tracePropagationTargets: [
      'localhost',
      /^http:\/\/local-api\.beadedcloud\.com:3030/,
      /^https:\/\/api\.prod\.beadedcloud\.com/,
      /^https:\/\/api\.dev\.beadedcloud\.com/,
      /^https:\/\/api\.beadedcloud\.com/,
    ],

    release: `v${import.meta.env.VITE_VERSION}`,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <Fonts />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ProvideUserContext>
            <ProvideGoogleMapsApi>
              <Router />
            </ProvideGoogleMapsApi>
          </ProvideUserContext>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

reportWebVitals()
