export const sortSitesByAlphaWithDisplayOrder = (a: any, b: any) => {
  if (
    !isNaN(a?.display?.order) &&
    a.display.order !== 0 &&
    !isNaN(b?.display?.order) &&
    b.display.order !== 0
  ) {
    return a.display.order - b.display.order
  }

  if (!isNaN(a?.display?.order) && a?.display?.order !== 0) return -1

  if (!isNaN(b?.display?.order) && b?.display?.order !== 0) return 1

  if (a.name < b.name) return -1

  if (a.name > b.name) return 1

  return 0
}
