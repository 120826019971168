import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import generator from 'generate-password-ts'
import { useFormContext } from 'react-hook-form'
import { FaKey } from 'react-icons/fa'

import { BaseInputProps } from 'forms/helpers/types'

import { useToggle } from 'hooks/useToggle'

export type PasswordInputProps = BaseInputProps & {
  autocomplete?: string
  generate?: boolean
  setValue?: (value: any) => void
}

export const PasswordInput = (props: PasswordInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()
  const [showPassword, toggleShowPassword] = useToggle(false)

  const generatePassword = () => {
    const newPass = generator.generate({
      length                  : 12,
      numbers                 : true,
      symbols                 : '@#$%^&*!?',
      uppercase               : true,
      lowercase               : true,
      strict                  : true,
      excludeSimilarCharacters: true,
    })
    if (props.setValue) {
      props.setValue(newPass)
    }
  }

  return (
    <FormControl
      isRequired={Boolean(props?.hookformProps?.required)}
      isInvalid={Boolean(errors[props.name])}
    >
      {props.label ? (
        <FormLabel htmlFor={props.name}>{props.label}</FormLabel>
      ) : null}
      <InputGroup size='md'>
        <Input
          pr='4.5rem'
          autoComplete={props.autocomplete ?? 'off'}
          type={showPassword ? 'text' : 'password'}
          placeholder={props.placeholder ?? props.name}
          {...register(props.name, props.hookformProps)}
        />
        <InputRightElement width={props.generate ? '5.5rem' : '4.5rem'}>
          <Button
            h='1.75rem'
            size='sm'
            onClick={toggleShowPassword}
            tabIndex={-1}
            mr={props.generate ? '4px' : '0'}
          >
            {showPassword ? 'Hide' : 'Show'}
          </Button>
          {props.generate ? (
            <IconButton
              h='1.75rem'
              aria-label='Generate Password'
              icon={<Icon as={FaKey} />}
              onClick={generatePassword}
              tabIndex={-1}
              size='sm'
              mr='4px'
            />
          ) : null}
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{errors[props.name]?.message}</FormErrorMessage>
    </FormControl>
  )
}
