import { z } from 'zod'

/** Represents the enum public.cable_status_type_ */
enum CableStatusType {
  DRAFT = 'DRAFT',
  PRE_BUILD = 'PRE_BUILD',
  BUILT = 'BUILT',
  ACTIVE = 'ACTIVE',
  DISASSEMBLED = 'DISASSEMBLED',
  DEAD = 'DEAD',
  UNKNOWN = 'UNKNOWN',
}

export default CableStatusType

/** Zod schema for cable_status_type_ */
export const cableStatusType = z.enum([
  'DRAFT',
  'PRE_BUILD',
  'BUILT',
  'ACTIVE',
  'DISASSEMBLED',
  'DEAD',
  'UNKNOWN',
])
