import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { projectId, type ProjectId } from './Project.js'

/** Identifier type for public.project_alias_ */
export type ProjectAliasId = number & { __brand: 'ProjectAliasId' }

/** Represents the table public.project_alias_ */
export default interface ProjectAliasTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<ProjectAliasId, ProjectAliasId | undefined, ProjectAliasId>

  /** Database type: pg_catalog.text */
  projectAliasName: ColumnType<string, string, string>

  /** Database type: pg_catalog.int4 */
  projectId: ColumnType<ProjectId, ProjectId, ProjectId>
}

export type ProjectAlias = Selectable<ProjectAliasTable>

export type ProjectAliasInitializer = Insertable<ProjectAliasTable>

export type ProjectAliasMutator = Updateable<ProjectAliasTable>

export const projectAliasId = z.number() as unknown as z.Schema<ProjectAliasId>

export const projectAlias = z.object({
  id              : projectAliasId,
  projectAliasName: z.string(),
  projectId       : projectId,
}) as unknown as z.Schema<ProjectAlias>

export const projectAliasInitializer = z.object({
  id              : projectAliasId.optional(),
  projectAliasName: z.string(),
  projectId       : projectId,
}) as unknown as z.Schema<ProjectAliasInitializer>

export const projectAliasMutator = z.object({
  id              : projectAliasId.optional(),
  projectAliasName: z.string().optional(),
  projectId       : projectId.optional(),
}) as unknown as z.Schema<ProjectAliasMutator>
