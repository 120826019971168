import { Button, Center, Divider, Text } from '@chakra-ui/react'

import type { GlobalPerm, UserWithPermissions } from '@beaded/models'

import { ModalContainer } from 'containers/ModalContainer'

import { EditUserName, EditUserPassword, UserApiKeys } from 'forms/UpdateUser'

import { useUserContext } from 'hooks/useUserContext'

export const EditAccountModalWrapper = (props: any) => {
  const auth = useUserContext()

  return <EditAccountModal {...props} {...auth} />
}

interface IEditAccountModalProps {
  currentUser: UserWithPermissions
  ButtonElem?: any
  global?: GlobalPerm
}

export const EditAccountModal = ({
  currentUser,
  ButtonElem,
}: IEditAccountModalProps) => {
  return (
    <ModalContainer
      TriggerButton={({ onClick }) =>
        ButtonElem ? (
          <ButtonElem onClick={onClick}>Edit Account</ButtonElem>
        ) : (
          <Center p='2'>
            <Button onClick={onClick}>Edit Account</Button>
          </Center>
        )
      }
      header='User Account'
      modalProps={{ size: 'lg' }}
    >
      <Text fontSize='l' fontWeight='bold' pb={3} pt={3}>
        {currentUser?.email}
      </Text>
      <EditUserName user={currentUser} />
      <Divider mt={3} />
      <EditUserPassword user={currentUser} />
      <UserApiKeys user={currentUser} />
    </ModalContainer>
  )
}
