import { z } from 'zod'

/** Represents the enum public.global_role_type_ */
enum GlobalRoleType {
  GLOBAL_ADMIN = 'GLOBAL_ADMIN',
  GLOBAL_EDIT = 'GLOBAL_EDIT',
  GLOBAL_VIEW = 'GLOBAL_VIEW',
  LOGGER_ADMIN = 'LOGGER_ADMIN',
  CABLE_ADMIN = 'CABLE_ADMIN',
  SALES_ADMIN = 'SALES_ADMIN',
}

export default GlobalRoleType

/** Zod schema for global_role_type_ */
export const globalRoleType = z.enum([
  'GLOBAL_ADMIN',
  'GLOBAL_EDIT',
  'GLOBAL_VIEW',
  'LOGGER_ADMIN',
  'CABLE_ADMIN',
  'SALES_ADMIN',
])
