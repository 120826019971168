import { GlobalPerm } from '@beaded/models'

export const filterVisible = (items: any[], global: GlobalPerm) => {
  if (!items || items.length === 0) return []

  if (global.view) return items

  return items.filter((item: any) =>
    item.visible !== undefined ? item.visible : false,
  )
}
