import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Button,
  Grid,
  GridItem,
  HStack,
  Spacer,
  VStack,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import {
  extendedUserInitializer,
  ExtendedUserInitializer,
} from '@beaded/models'

import { user as userAPI } from 'api'

import { ModalContainer } from 'containers/ModalContainer'

import { DefaultInput } from 'forms/inputs/DefaultInput'

import { logValidationErrors } from 'lib/logValidationErrors'
import { queryClient } from 'lib/react-query'

import { PasswordInput } from './inputs/PasswordInput'

export const CreateUserModal = () => {
  const methods = useForm<ExtendedUserInitializer>({
    resolver: zodResolver(extendedUserInitializer),
  })

  const mutation = useMutation(
    async (data: ExtendedUserInitializer) => {
      const validateResult = extendedUserInitializer.safeParse(data)

      if (validateResult.success === false) {
        logValidationErrors(validateResult.error)
        return
      }

      return userAPI.create(validateResult.data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
      },
    },
  )

  const handler = (data: ExtendedUserInitializer) => mutation.mutateAsync(data)

  return (
    <ModalContainer
      TriggerButton={({ onClick }: any) => (
        <Button onClick={onClick}>Create User</Button>
      )}
      modalProps={{ size: '2xl' }}
      header='Create User'
      footer={() => (
        <VStack justifyContent='right' align='right' width='100%'>
          <HStack>
            <Spacer />
            {mutation.isSuccess && <CheckCircleIcon color='green.400' />}
            {mutation.isError && <WarningIcon color='red.400' />}
            <Button
              onClick={methods.handleSubmit(handler)}
              isLoading={mutation.isLoading}
            >
              Submit
            </Button>
          </HStack>
        </VStack>
      )}
    >
      <FormProvider {...methods}>
        <form>
          <Grid templateColumns='1fr 1fr' gap={3}>
            <GridItem>
              <DefaultInput
                name='nameFirst'
                label='First Name'
                placeholder='First Name'
                hookformProps={{
                  required: true,
                }}
              />
            </GridItem>

            <GridItem>
              <DefaultInput
                name='nameLast'
                label='Last Name'
                placeholder='Last Name'
                hookformProps={{
                  required: true,
                }}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <DefaultInput
                name='email'
                label='Email'
                placeholder='Email'
                hookformProps={{
                  required: true,
                }}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <PasswordInput
                setValue={(v) => methods.setValue('password', v)}
                name='password'
                label='Password'
                placeholder='Password'
                hookformProps={{
                  required: true,
                }}
                generate={true}
              />
            </GridItem>
          </Grid>
        </form>
      </FormProvider>
    </ModalContainer>
  )
}
