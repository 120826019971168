import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { modemHistoryId, type ModemHistoryId } from './ModemHistory.js'
import { siteId, type SiteId } from './Site.js'

/** Identifier type for public.site_modem_history_ */
export type SiteModemHistoryId = number & { __brand: 'SiteModemHistoryId' }

/** Represents the table public.site_modem_history_ */
export default interface SiteModemHistoryTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<
    SiteModemHistoryId,
    SiteModemHistoryId | undefined,
    SiteModemHistoryId
  >

  /** Database type: pg_catalog.int4 */
  siteId: ColumnType<SiteId, SiteId, SiteId>

  /** Database type: pg_catalog.int4 */
  modemHistoryId: ColumnType<ModemHistoryId, ModemHistoryId, ModemHistoryId>

  /** Database type: pg_catalog.timestamptz */
  startedAt: ColumnType<Date, Date | string, Date | string>

  /** Database type: pg_catalog.timestamptz */
  endedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.int4[] */
  decode: ColumnType<number[] | null, number[] | null, number[] | null>
}

export type SiteModemHistory = Selectable<SiteModemHistoryTable>

export type SiteModemHistoryInitializer = Insertable<SiteModemHistoryTable>

export type SiteModemHistoryMutator = Updateable<SiteModemHistoryTable>

export const siteModemHistoryId =
  z.number() as unknown as z.Schema<SiteModemHistoryId>

export const siteModemHistory = z.object({
  id            : siteModemHistoryId,
  siteId        : siteId,
  modemHistoryId: modemHistoryId,
  startedAt     : z.coerce.date(),
  endedAt       : z.coerce.date().nullable().optional(),
  decode        : z.number().array().nullable().optional(),
}) as unknown as z.Schema<SiteModemHistory>

export const siteModemHistoryInitializer = z.object({
  id            : siteModemHistoryId.optional(),
  siteId        : siteId,
  modemHistoryId: modemHistoryId,
  startedAt     : z.coerce.date(),
  endedAt       : z.coerce.date().optional().nullable().optional(),
  decode        : z.number().array().optional().nullable().optional(),
}) as unknown as z.Schema<SiteModemHistoryInitializer>

export const siteModemHistoryMutator = z.object({
  id            : siteModemHistoryId.optional(),
  siteId        : siteId.optional(),
  modemHistoryId: modemHistoryId.optional(),
  startedAt     : z.coerce.date().optional(),
  endedAt       : z.coerce.date().optional().nullable().optional(),
  decode        : z.number().array().optional().nullable().optional(),
}) as unknown as z.Schema<SiteModemHistoryMutator>
