import * as cable from '@beaded/js-validate/lib/validate/cable'
import * as cablePrices from '@beaded/js-validate/lib/validate/cablePrices'
import * as logger from '@beaded/js-validate/lib/validate/logger'
import * as modem from '@beaded/js-validate/lib/validate/modem'
import * as project from '@beaded/js-validate/lib/validate/project'
import * as sensor from '@beaded/js-validate/lib/validate/sensor'
import * as site from '@beaded/js-validate/lib/validate/site'
import * as siteEvent from '@beaded/js-validate/lib/validate/siteEvent'

export const cableSchema = cable.cable
export const cableEventSchema = cable.cableEvent
export const cableUseSchema = cable.cableUse
export const cablePricesSchema = cablePrices.cablePrices
export const loggerSchema = logger.logger
export const loggerEventSchema = logger.loggerEvent
export const loggerUseSchema = logger.loggerUse
export const projectSchema = project.project
export const siteSchema = site.site
export const loggerUseEntrySchema = site.loggerUseEntry
export const cableUseEntrySchema = site.cableUseEntry
export const siteEventSchema = siteEvent.siteEvent
export const sensorSchema = sensor.sensor
export const modemSchema = modem.modem
export const modemUseSchema = modem.modemUse
