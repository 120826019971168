import type { FormikValues } from 'formik'

import { cable } from 'api'

import { cableSchema } from 'lib/jsValidate'
import { logValidationErrors } from 'lib/logValidationErrors'
import { queryClient } from 'lib/react-query'

import type { FormInfo, UpdateFormProps } from 'types/FormInfo'
import type { ValidationError } from 'types/ValidationError'

import { cableFields } from '../fields/cable'

export const updateCableForm = ({
  initialValues,
}: UpdateFormProps): FormInfo<FormikValues> => ({
  fields    : cableFields,
  title     : 'Edit Cable',
  initialValues,
  submitText: 'Save',
  submitFn  : (values: any): Promise<any> => {
    const { value } = cableSchema.validate(values, {
      abortEarly  : false,
      stripUnknown: true,
    })

    value.lead = value.lead.map((elem: any) => ({
      ...elem,
      conduit: elem.conduit === null ? 'none' : elem.conduit,
    }))
    value.body = value.body.map((elem: any) => ({
      ...elem,
      conduit: elem.conduit === null ? 'none' : elem.conduit,
    }))
    delete value.use
    delete value.events

    return cable.update(values.id, value)
  },

  validateForm: (
    values: FormikValues,
  ): { error?: ValidationError; value: FormikValues } => {
    const { error } = cableSchema.validate(values, {
      abortEarly  : false,
      stripUnknown: true,
    })

    logValidationErrors(error)

    return { error, value: values }
  },

  mutationOptions: {
    // onMutate: (variables) => console.log('onMutate', variables),
    onError: (error, variables, context): void => {
      console.error('onError', error, variables, context)
    },
    onSuccess: (data: any, variables: any) => {
      if (data?.error) throw data.error
      if (data?.updated === false)
        throw new Error('encountered error updating entry')

      // console.log('onSuccess', data, variables);
      queryClient.invalidateQueries('cables')
      queryClient.invalidateQueries('cable')
    },
  },

  closeCondition: (res) => res?.updated === true,
})
