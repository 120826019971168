import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'

import { Site } from 'types/Site'
import type { Obj, ID } from 'types/common'

import * as apiUtils from '.'

const url = '/site'

export const get = (id: ID, options: AxiosRequestConfig = {}): Promise<Site> =>
  apiUtils.GET(`${url}/${id}`, merge({}, apiUtils.extrasFlags, options))

export const getAll = (options: AxiosRequestConfig = {}): Promise<Site[]> =>
  apiUtils.GET(`${url}/`, merge({}, apiUtils.extrasFlags, options))

export const create = (data: Obj) => apiUtils.POST(`${url}/`, data)

export const bulkCreate = (projectId: ID, file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  return apiUtils.POST(`${url}/${projectId}/bulk`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const bulkEdit = (file: File) => apiUtils.PUT(`${url}/bulk`, file)

export const patch = (id: ID, data: Obj) => apiUtils.PATCH(`${url}/${id}`, data)

export const update = (id: ID, data: Obj) => apiUtils.PUT(`${url}/${id}`, data)

export const remove = (id: ID) => apiUtils.DELETE(`${url}/${id}`)

export const getMenuProjection = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({ params: { projection: 'menu' } }, options))

export const publicPage = {
  get: (id: ID, options: AxiosRequestConfig = {}) =>
    apiUtils.GET(
      `/public${url}/${id}`,
      merge({}, apiUtils.extrasFlags, options),
    ),
  getAll: (options: AxiosRequestConfig = {}) =>
    apiUtils.GET(`/public${url}/`, merge({}, apiUtils.extrasFlags, options)),
}

export const admin = {
  get: (id: ID, options: AxiosRequestConfig = {}) =>
    get(id, merge({}, apiUtils.globalFlags, options)),
  getAll: (options: AxiosRequestConfig = {}) =>
    getAll(merge({}, apiUtils.globalFlags, options)),
  getMenuProjection: (options: AxiosRequestConfig = {}) =>
    getMenuProjection(merge({}, apiUtils.globalFlags, options)),
}
