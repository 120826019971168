import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import {
  globalRoleType,
  type default as GlobalRoleType,
} from './GlobalRoleType.js'
import { userId, type UserId } from './User.js'

/** Represents the table public.global_user_ */
export default interface GlobalUserTable {
  /** Database type: pg_catalog.uuid */
  userId: ColumnType<UserId, UserId, UserId>

  /** Database type: public.global_role_type_ */
  role: ColumnType<
    GlobalRoleType | null,
    GlobalRoleType | null,
    GlobalRoleType | null
  >
}

export type GlobalUser = Selectable<GlobalUserTable>

export type GlobalUserInitializer = Insertable<GlobalUserTable>

export type GlobalUserMutator = Updateable<GlobalUserTable>

export const globalUser = z.object({
  userId: userId,
  role  : globalRoleType.nullable().optional(),
}) as unknown as z.Schema<GlobalUser>

export const globalUserInitializer = z.object({
  userId: userId,
  role  : globalRoleType.optional().nullable().optional(),
}) as unknown as z.Schema<GlobalUserInitializer>

export const globalUserMutator = z.object({
  userId: userId.optional(),
  role  : globalRoleType.optional().nullable().optional(),
}) as unknown as z.Schema<GlobalUserMutator>
