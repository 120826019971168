import { ParseLogger, ParseReadings } from '../FormatHelpers/formatHelper'

export const format1 = (decoded: any, version: number): string => {
  let output = `Version: ${version}\n\n`

  output += ParseLogger(decoded)
  output += ParseReadings(decoded)

  return output
}
