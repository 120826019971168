import { z } from 'zod'

/** Represents the enum public.fee_type_ */
enum FeeType {
  OVERAGE = 'OVERAGE',
  LATE = 'LATE',
  LOGGER_SUBSCRIPTION = 'LOGGER_SUBSCRIPTION',
}

export default FeeType

/** Zod schema for fee_type_ */
export const feeType = z.enum(['OVERAGE', 'LATE', 'LOGGER_SUBSCRIPTION'])
