import { Input } from '@chakra-ui/react'
import { UseFiltersColumnProps } from 'react-table'

export const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}: {
  column: UseFiltersColumnProps<any>
}) => {
  const count = preFilteredRows.length
  return (
    <Input
      value={filterValue ?? ''}
      onChange={(e) => {
        setFilter(e.target.value ?? undefined)
      }}
      placeholder={`Search ${count} records...`}
    />
  )
}
