import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { cableId, type CableId } from './Cable.js'
import { siteId, type SiteId } from './Site.js'

/** Identifier type for public.site_cable_history_ */
export type SiteCableHistoryId = number & { __brand: 'SiteCableHistoryId' }

/** Represents the table public.site_cable_history_ */
export default interface SiteCableHistoryTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<
    SiteCableHistoryId,
    SiteCableHistoryId | undefined,
    SiteCableHistoryId
  >

  /** Database type: pg_catalog.int4 */
  siteId: ColumnType<SiteId, SiteId, SiteId>

  /** Database type: pg_catalog.int4 */
  cableId: ColumnType<CableId, CableId, CableId>

  /** Database type: pg_catalog.timestamptz */
  startedAt: ColumnType<Date, Date | string, Date | string>

  /** Database type: pg_catalog.timestamptz */
  endedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.int4[] */
  decode: ColumnType<number[] | null, number[] | null, number[] | null>
}

export type SiteCableHistory = Selectable<SiteCableHistoryTable>

export type SiteCableHistoryInitializer = Insertable<SiteCableHistoryTable>

export type SiteCableHistoryMutator = Updateable<SiteCableHistoryTable>

export const siteCableHistoryId =
  z.number() as unknown as z.Schema<SiteCableHistoryId>

export const siteCableHistory = z.object({
  id       : siteCableHistoryId,
  siteId   : siteId,
  cableId  : cableId,
  startedAt: z.coerce.date(),
  endedAt  : z.coerce.date().nullable().optional(),
  decode   : z.number().array().nullable().optional(),
}) as unknown as z.Schema<SiteCableHistory>

export const siteCableHistoryInitializer = z.object({
  id       : siteCableHistoryId.optional(),
  siteId   : siteId,
  cableId  : cableId,
  startedAt: z.coerce.date(),
  endedAt  : z.coerce.date().optional().nullable().optional(),
  decode   : z.number().array().optional().nullable().optional(),
}) as unknown as z.Schema<SiteCableHistoryInitializer>

export const siteCableHistoryMutator = z.object({
  id       : siteCableHistoryId.optional(),
  siteId   : siteId.optional(),
  cableId  : cableId.optional(),
  startedAt: z.coerce.date().optional(),
  endedAt  : z.coerce.date().optional().nullable().optional(),
  decode   : z.number().array().optional().nullable().optional(),
}) as unknown as z.Schema<SiteCableHistoryMutator>
