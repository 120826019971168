import { Button } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { v4 as uuidv4 } from 'uuid'

export const GenerateUUID = () => {
  const { setFieldValue } = useFormikContext()
  return (
    <Button
      aria-label='generate key'
      size='sm'
      onClick={() => setFieldValue('web.uuid', uuidv4())}
    >
      Generate
    </Button>
  )
}
