import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Button,
  Grid,
  GridItem,
  Text,
  HStack,
  Spacer,
  VStack,
  Divider,
  Stack,
  Code,
  IconButton,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { format } from 'date-fns-tz'
import fastJsonPatch from 'fast-json-patch'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaTrashAlt } from 'react-icons/fa'
import { useMutation } from 'react-query'

import {
  extendedUserMutator,
  ExtendedUserMutator,
  GlobalPerm,
  UserApiKeyId,
  userMutator as userMutatorValidator,
  UserWithPermissions,
} from '@beaded/models'

import { user as userAPI } from 'api'

import { ErrorWrapper } from 'components/ErrorWrapper'
import { ExternalLink } from 'components/Links'

import { DefaultInput } from 'forms/inputs/DefaultInput'

import { logValidationErrors } from 'lib/logValidationErrors'
import { queryClient } from 'lib/react-query'

import { PasswordInput } from './inputs/PasswordInput'

interface UpdateUserFormProps {
  user: UserWithPermissions
  global?: GlobalPerm
}

export const UpdateUser = ({ user }: UpdateUserFormProps) => {
  const methods = useForm<ExtendedUserMutator>({
    defaultValues: user,
    resolver     : zodResolver(extendedUserMutator),
  })

  // console.log(errors)
  const mutation = useMutation(
    async (data: ExtendedUserMutator) => {
      const validateResult = userMutatorValidator.safeParse(data)

      if (validateResult.success === false) {
        logValidationErrors(validateResult.error)
        return
      }

      if (data.password !== '' && data.password !== undefined) {
        await userAPI.updatePassword(user.id, data.password)
      }

      const changeset = fastJsonPatch
        .compare(user, validateResult.data)
        .filter((c) => c.path !== '/apiKeys' && c.path !== '/permissions')

      if (changeset.length > 0) {
        const res = await userAPI.patch(user.id, changeset)
        return res
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
      },
    },
  )

  const handler = (data: ExtendedUserMutator) => {
    // console.log(data)
    return mutation.mutateAsync(data)
  }

  return (
    <FormProvider {...methods}>
      <form>
        <Grid templateColumns='1fr 1fr' gap={3}>
          <GridItem colSpan={2}>
            <Text>Name</Text>
          </GridItem>
          <GridItem>
            <DefaultInput
              name='nameFirst'
              label='First Name'
              placeholder='First Name'
            />
          </GridItem>

          <GridItem>
            <DefaultInput
              name='nameLast'
              label='Last Name'
              placeholder='Last Name'
            />
          </GridItem>

          <GridItem colSpan={2}>
            <DefaultInput name='email' label='Email' placeholder='Email' />
          </GridItem>

          <GridItem colSpan={2}>
            <PasswordInput
              name='password'
              label='Change Password'
              placeholder='New Password'
              generate={true}
              setValue={(value) => methods.setValue('password', value)}
            />
          </GridItem>

          <GridItem colSpan={2}>
            <HStack>
              <Spacer />
              <Button
                onClick={methods.handleSubmit(handler)}
                isLoading={mutation.isLoading}
              >
                Submit
              </Button>
            </HStack>
          </GridItem>

          <GridItem colSpan={2}>
            <Divider />
          </GridItem>

          <UserDateBlock
            objkey='archivedAt'
            user={user}
            words={{
              add      : 'Archive',
              remove   : 'Unarchive',
              pastTense: 'Archived',
            }}
          />

          <UserDateBlock
            objkey='unsubscribedAt'
            user={user}
            words={{
              add      : 'Subscribe',
              remove   : 'Unsubscribe',
              pastTense: 'Unsubscribed',
            }}
          />
        </Grid>
      </form>
    </FormProvider>
  )
}

interface IUserDateBlockProps {
  objkey: 'archivedAt' | 'unsubscribedAt'
  user: UserWithPermissions
  words: {
    add: string
    remove: string
    pastTense: string
  }
}

const UserDateBlock = ({ objkey, user, words }: IUserDateBlockProps) => {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState<any>(null)

  const addTimestamp = async () => {
    setLoading(true)

    try {
      const initialState = {
        archivedAt    : user.archivedAt,
        unsubscribedAt: user.unsubscribedAt,
      }

      const newState = {
        archivedAt    : user.archivedAt,
        unsubscribedAt: user.unsubscribedAt,
      }

      newState[objkey] = new Date()

      const changes = fastJsonPatch.compare(initialState, newState)
      const res = await userAPI.patch(user?.id, changes)
      if (res.updated !== true) throw res
      // console.log(res)
      queryClient.invalidateQueries(['user', { id: user?.id }])
      setResult(res)
      setLoading(false)
    }
    catch (err) {
      console.error(err)
    }
    finally {
      setLoading(false)
    }
  }

  const removeTimestamp = async () => {
    setLoading(true)

    const initialState = {
      archivedAt    : user.archivedAt,
      unsubscribedAt: user.unsubscribedAt,
    }
    const newState = {
      archivedAt    : user.archivedAt,
      unsubscribedAt: user.unsubscribedAt,
    }

    if (!initialState?.[objkey]) throw new Error(`User is not ${objkey}`)

    delete newState[objkey]

    const changes = fastJsonPatch.compare(initialState, newState)
    const res = await userAPI.patch(user?.id, changes)
    if (res.updated !== true) throw res
    queryClient.invalidateQueries(['user', { id: user?.id?.[0] }])
    setResult(res)
    setLoading(false)
  }

  return (
    <GridItem>
      <VStack>
        {user?.[objkey] !== undefined ? (
          <>
            {words.pastTense} on{' '}
            {format(new Date(user[objkey] as any), 'yyyy-MM-dd HH:mm zzz')}
            <Button onClick={removeTimestamp} isLoading={loading}>
              {words.add}
            </Button>
          </>
        ) : (
          <HStack>
            {result?.updated === true ? (
              <CheckCircleIcon color='green.400' />
            ) : null}
            {result?.error !== undefined ? (
              <WarningIcon color='red.400' />
            ) : null}
            <Button onClick={addTimestamp} isLoading={loading}>
              {words.remove}
            </Button>
          </HStack>
        )}
        {result?.error ? JSON.stringify(result.error) : null}
      </VStack>
    </GridItem>
  )
}

export const EditUserName = ({ user }: UpdateUserFormProps) => {
  const methods = useForm<ExtendedUserMutator>({
    defaultValues: user,
    resolver     : zodResolver(extendedUserMutator),
  })

  const mutation = useMutation(
    async (data: ExtendedUserMutator) => {
      const validateResult = extendedUserMutator.safeParse(data)

      if (validateResult.success === false) {
        logValidationErrors(validateResult.error)
        return
      }

      const changeset = fastJsonPatch
        .compare(user, validateResult.data)
        .filter((c) => c.path !== '/apiKeys' && c.path !== '/permissions')

      const res = await userAPI.patch(user.id, changeset)
      return res
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
      },
    },
  )

  const handler = (data: ExtendedUserMutator) => mutation.mutateAsync(data)

  return (
    <FormProvider {...methods}>
      <form>
        <Grid gap={3} templateColumns='1fr 1fr'>
          <GridItem colSpan={2}>
            <Text>Name</Text>
          </GridItem>
          <GridItem>
            <DefaultInput
              name='nameFirst'
              label='First Name'
              placeholder='First Name'
            />
          </GridItem>

          <GridItem>
            <DefaultInput
              name='nameLast'
              label='Last Name'
              placeholder='Last Name'
            />
          </GridItem>

          <GridItem colSpan={2}>
            <HStack>
              <Spacer />
              <Button
                onClick={methods.handleSubmit(handler)}
                isLoading={mutation.isLoading}
              >
                Submit
              </Button>
              {mutation.isSuccess && <CheckCircleIcon color='green.400' />}
              {mutation.isError && <WarningIcon color='red.400' />}
            </HStack>
          </GridItem>
        </Grid>
      </form>
    </FormProvider>
  )
}

export const EditUserPassword = ({ user }: UpdateUserFormProps) => {
  const methods = useForm<ExtendedUserMutator>({
    defaultValues: {
      ...user,
      password       : '',
      passwordConfirm: '',
    },
    resolver: zodResolver(extendedUserMutator),
  })

  const mutation = useMutation(
    async (data: ExtendedUserMutator) => {
      const validateResult = extendedUserMutator.safeParse(data)

      if (validateResult.success === false) {
        logValidationErrors(validateResult.error)
        return
      }

      return userAPI.updatePassword(user.id, data.password)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
      },
    },
  )

  const handler = (data: ExtendedUserMutator) => mutation.mutateAsync(data)

  return (
    <FormProvider {...methods}>
      <form>
        <Grid gap={3} templateColumns='1fr 1fr'>
          <GridItem colSpan={2}>
            <Text layerStyle='heading' pb={3} pt={3}>
              Change Password
            </Text>
          </GridItem>
          <GridItem colSpan={2}>
            <PasswordInput
              name='password'
              placeholder='New Password'
              autocomplete='new-password'
            />
          </GridItem>

          <GridItem colSpan={2}>
            <PasswordInput
              name='passwordConfirm'
              placeholder='Confirm Password'
              autocomplete='new-password'
            />
          </GridItem>

          <GridItem colSpan={2}>
            <HStack>
              <Spacer />
              <Button
                onClick={methods.handleSubmit(handler)}
                isLoading={mutation.isLoading}
              >
                Submit
              </Button>
              {mutation.isSuccess && <CheckCircleIcon color='green.400' />}
              {mutation.isError && <WarningIcon color='red.400' />}
            </HStack>
          </GridItem>
        </Grid>
      </form>
    </FormProvider>
  )
}

export const UserApiKeys = ({ user }: UpdateUserFormProps) => {
  const onAddApiKey = async () => {
    const res = await userAPI.addApiKey(user.id)
    queryClient.invalidateQueries('user')
    return res
  }

  const onDeleteApiKey = async (key: UserApiKeyId) => {
    const res = await userAPI.removeApiKey(user.id, key)
    queryClient.invalidateQueries('user')
    return res
  }

  return (
    <>
      <Text layerStyle='heading' pb={3} pt={3}>
        API Keys
      </Text>
      <Text pb={3}>
        API keys are used to authenticate with the API. You can generate a new
        key at any time. After generating an API key, check your email for
        instructions. <br />
        <ExternalLink
          href='https://api.beadedcloud.com/docs.html'
          name='API Documentation'
        />
        <br />
        <ExternalLink
          href='https://api.beadedcloud.com/docs-internal.html'
          name='Internal API Documentation'
        />
      </Text>
      <Stack spacing={3}>
        <Grid templateColumns='1fr 1fr auto'>
          <ErrorWrapper>
            {user.apiKeys?.map(({ key, type, startedAt, endedAt }) => [
              <GridItem colSpan={2} key={`${key}-user`}>
                <HStack>
                  <Text fontWeight='semibold'>Key: </Text>
                  <Code>{key}</Code>
                  {typeof navigator?.clipboard?.writeText === 'function' && (
                    <Button
                      size='xs'
                      onClick={() => navigator?.clipboard?.writeText?.(key)}
                    >
                      Copy
                    </Button>
                  )}
                </HStack>
              </GridItem>,
              <GridItem rowSpan={3} key={`${key}-remove}`}>
                <IconButton
                  icon={<FaTrashAlt />}
                  onClick={() => onDeleteApiKey(key)}
                  aria-label='remove api key'
                />
              </GridItem>,
              <GridItem colSpan={2} key={`${key}-type`}>
                <HStack>
                  <Text fontWeight='semibold'>Type: </Text>
                  <Text>{type}</Text>
                </HStack>
              </GridItem>,
              <GridItem key={`${key}-start`}>
                <HStack>
                  <Text fontWeight='semibold'>Created: </Text>
                  <Text>{format(new Date(startedAt), 'yyyy-MM-dd HH:mm')}</Text>
                </HStack>
              </GridItem>,
              <GridItem key={`${key}-expire`}>
                <HStack>
                  <Text fontWeight='semibold'>Expires: </Text>
                  <Text>{format(new Date(endedAt), 'yyyy-MM-dd HH:mm')}</Text>
                </HStack>
              </GridItem>,
              <GridItem colSpan={2} key={`${key}-divider`} mb={2} mt={2}>
                <Divider />
              </GridItem>,
            ])}
          </ErrorWrapper>
        </Grid>

        <Button onClick={onAddApiKey}>Add API Key</Button>
      </Stack>
    </>
  )
}
