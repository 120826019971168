import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import {
  siteCableHistoryId,
  type SiteCableHistoryId,
} from './SiteCableHistory.js'

/** Identifier type for public.site_cable_history_label_ */
export type SiteCableHistoryLabelId = number & {
  __brand: 'SiteCableHistoryLabelId'
}

/** Represents the table public.site_cable_history_label_ */
export default interface SiteCableHistoryLabelTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<
    SiteCableHistoryLabelId,
    SiteCableHistoryLabelId | undefined,
    SiteCableHistoryLabelId
  >

  /** Database type: pg_catalog.numeric */
  number: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.text */
  note: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  visible: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.int4 */
  siteCableHistoryId: ColumnType<
    SiteCableHistoryId,
    SiteCableHistoryId,
    SiteCableHistoryId
  >
}

export type SiteCableHistoryLabel = Selectable<SiteCableHistoryLabelTable>

export type SiteCableHistoryLabelInitializer =
  Insertable<SiteCableHistoryLabelTable>

export type SiteCableHistoryLabelMutator =
  Updateable<SiteCableHistoryLabelTable>

export const siteCableHistoryLabelId =
  z.number() as unknown as z.Schema<SiteCableHistoryLabelId>

export const siteCableHistoryLabel = z.object({
  id                : siteCableHistoryLabelId,
  number            : z.number().nullable().optional(),
  note              : z.string().nullable().optional(),
  visible           : z.boolean().nullable().optional(),
  siteCableHistoryId: siteCableHistoryId,
}) as unknown as z.Schema<SiteCableHistoryLabel>

export const siteCableHistoryLabelInitializer = z.object({
  id                : siteCableHistoryLabelId.optional(),
  number            : z.number().optional().nullable().optional(),
  note              : z.string().optional().nullable().optional(),
  visible           : z.boolean().optional().nullable().optional(),
  siteCableHistoryId: siteCableHistoryId,
}) as unknown as z.Schema<SiteCableHistoryLabelInitializer>

export const siteCableHistoryLabelMutator = z.object({
  id                : siteCableHistoryLabelId.optional(),
  number            : z.number().optional().nullable().optional(),
  note              : z.string().optional().nullable().optional(),
  visible           : z.boolean().optional().nullable().optional(),
  siteCableHistoryId: siteCableHistoryId.optional(),
}) as unknown as z.Schema<SiteCableHistoryLabelMutator>
