import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

/** Identifier type for public.logger_ */
export type LoggerId = number & { __brand: 'LoggerId' }

/** Represents the table public.logger_ */
export default interface LoggerTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<LoggerId, LoggerId | undefined, LoggerId>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  firmwareVersion: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  serialNumber: ColumnType<string, string, string>

  /** Database type: pg_catalog.int4 */
  intervalLog: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.int4 */
  intervalTransmit: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.text */
  config: ColumnType<string | null, string | null, string | null>
}

export type Logger = Selectable<LoggerTable>

export type LoggerInitializer = Insertable<LoggerTable>

export type LoggerMutator = Updateable<LoggerTable>

export const loggerId = z.number() as unknown as z.Schema<LoggerId>

export const logger = z.object({
  id              : loggerId,
  createdAt       : z.coerce.date().nullable().optional(),
  createdBy       : z.string().nullable().optional(),
  updatedAt       : z.coerce.date().nullable().optional(),
  updatedBy       : z.string().nullable().optional(),
  deletedAt       : z.coerce.date().nullable().optional(),
  deletedBy       : z.string().nullable().optional(),
  firmwareVersion : z.string().nullable().optional(),
  serialNumber    : z.string(),
  intervalLog     : z.number().nullable().optional(),
  intervalTransmit: z.number().nullable().optional(),
  config          : z.string().nullable().optional(),
}) as unknown as z.Schema<Logger>

export const loggerInitializer = z.object({
  id              : loggerId.optional(),
  createdAt       : z.coerce.date().optional().nullable().optional(),
  createdBy       : z.string().optional().nullable().optional(),
  updatedAt       : z.coerce.date().optional().nullable().optional(),
  updatedBy       : z.string().optional().nullable().optional(),
  deletedAt       : z.coerce.date().optional().nullable().optional(),
  deletedBy       : z.string().optional().nullable().optional(),
  firmwareVersion : z.string().optional().nullable().optional(),
  serialNumber    : z.string(),
  intervalLog     : z.number().optional().nullable().optional(),
  intervalTransmit: z.number().optional().nullable().optional(),
  config          : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<LoggerInitializer>

export const loggerMutator = z.object({
  id              : loggerId.optional(),
  createdAt       : z.coerce.date().optional().nullable().optional(),
  createdBy       : z.string().optional().nullable().optional(),
  updatedAt       : z.coerce.date().optional().nullable().optional(),
  updatedBy       : z.string().optional().nullable().optional(),
  deletedAt       : z.coerce.date().optional().nullable().optional(),
  deletedBy       : z.string().optional().nullable().optional(),
  firmwareVersion : z.string().optional().nullable().optional(),
  serialNumber    : z.string().optional(),
  intervalLog     : z.number().optional().nullable().optional(),
  intervalTransmit: z.number().optional().nullable().optional(),
  config          : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<LoggerMutator>
