import { LockIcon, UnlockIcon } from '@chakra-ui/icons'
import {
  Checkbox as ChakraCheckbox,
  useBoolean,
  IconButton,
  HStack,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import type { FormikValues } from 'formik'

import { InfoTooltip } from 'components/InfoTooltip'

import { InputWrapperProps } from '../lib/FieldWrapper'

export const Checkbox = ({
  field,
  name,
  label,
  placeholder,
  requiresGlobalUser = false,
  tooltip,
  shouldBeVisible,
  global = { view: false, edit: false, admin: false },
  onChange,
}: InputWrapperProps) => {
  const [locked, setLocked] = useBoolean(true)
  const { setFieldValue, values } = useFormikContext()

  const visible =
    typeof shouldBeVisible === 'function'
      ? shouldBeVisible(values as FormikValues)
      : typeof shouldBeVisible === 'boolean'
        ? shouldBeVisible
        : true

  return (
    <HStack {...(visible === false ? { display: 'none' } : {})}>
      <ChakraCheckbox
        {...field}
        isChecked={field?.value}
        isDisabled={locked && requiresGlobalUser}
        {...(typeof onChange === 'function'
          ? {
            onChange: (e) => {
              onChange(e, setFieldValue, values)
              field.onChange(e)
            },
          }
          : {})}
      >
        {label ?? placeholder ?? name ?? field?.name}
      </ChakraCheckbox>
      {tooltip && <InfoTooltip text={tooltip} />}

      {requiresGlobalUser && global.edit ? (
        <IconButton
          icon={locked ? <LockIcon /> : <UnlockIcon />}
          onClick={setLocked.toggle}
          aria-label={locked ? 'locked' : 'unlocked'}
        />
      ) : null}
    </HStack>
  )
}
