import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { apiKeyType, type default as ApiKeyType } from './ApiKeyType.js'
import { userId, type UserId } from './User.js'

/** Identifier type for public.user_api_key_ */
export type UserApiKeyId = string & { __brand: 'UserApiKeyId' }

/** Represents the table public.user_api_key_ */
export default interface UserApiKeyTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<UserApiKeyId, UserApiKeyId | undefined, UserApiKeyId>

  /** Database type: pg_catalog.uuid */
  userId: ColumnType<UserId, UserId, UserId>

  /** Database type: public.api_key_type_ */
  apiKeyType: ColumnType<
    ApiKeyType | null,
    ApiKeyType | null,
    ApiKeyType | null
  >

  /** Database type: pg_catalog.timestamptz */
  startedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.timestamptz */
  endedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.timestamptz */
  lastUse: ColumnType<Date | null, Date | string | null, Date | string | null>
}

export type UserApiKey = Selectable<UserApiKeyTable>

export type UserApiKeyInitializer = Insertable<UserApiKeyTable>

export type UserApiKeyMutator = Updateable<UserApiKeyTable>

export const userApiKeyId = z.string() as unknown as z.Schema<UserApiKeyId>

export const userApiKey = z.object({
  id        : userApiKeyId,
  userId    : userId,
  apiKeyType: apiKeyType.nullable().optional(),
  startedAt : z.coerce.date().nullable().optional(),
  endedAt   : z.coerce.date().nullable().optional(),
  lastUse   : z.coerce.date().nullable().optional(),
}) as unknown as z.Schema<UserApiKey>

export const userApiKeyInitializer = z.object({
  id        : userApiKeyId.optional(),
  userId    : userId,
  apiKeyType: apiKeyType.optional().nullable().optional(),
  startedAt : z.coerce.date().optional().nullable().optional(),
  endedAt   : z.coerce.date().optional().nullable().optional(),
  lastUse   : z.coerce.date().optional().nullable().optional(),
}) as unknown as z.Schema<UserApiKeyInitializer>

export const userApiKeyMutator = z.object({
  id        : userApiKeyId.optional(),
  userId    : userId.optional(),
  apiKeyType: apiKeyType.optional().nullable().optional(),
  startedAt : z.coerce.date().optional().nullable().optional(),
  endedAt   : z.coerce.date().optional().nullable().optional(),
  lastUse   : z.coerce.date().optional().nullable().optional(),
}) as unknown as z.Schema<UserApiKeyMutator>
