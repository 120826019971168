import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { cableId, type CableId } from './Cable.js'

/** Identifier type for public.cable_eeprom_ */
export type CableEepromId = number & { __brand: 'CableEepromId' }

/** Represents the table public.cable_eeprom_ */
export default interface CableEepromTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<CableEepromId, CableEepromId | undefined, CableEepromId>

  /** Database type: pg_catalog.int4 */
  cableId: ColumnType<CableId, CableId, CableId>

  /** Database type: pg_catalog.text */
  eepromId: ColumnType<string, string, string>

  /** Database type: pg_catalog.text */
  pairedSensorId: ColumnType<string, string, string>
}

export type CableEeprom = Selectable<CableEepromTable>

export type CableEepromInitializer = Insertable<CableEepromTable>

export type CableEepromMutator = Updateable<CableEepromTable>

export const cableEepromId = z.number() as unknown as z.Schema<CableEepromId>

export const cableEeprom = z.object({
  id            : cableEepromId,
  cableId       : cableId,
  eepromId      : z.string(),
  pairedSensorId: z.string(),
}) as unknown as z.Schema<CableEeprom>

export const cableEepromInitializer = z.object({
  id            : cableEepromId.optional(),
  cableId       : cableId,
  eepromId      : z.string(),
  pairedSensorId: z.string(),
}) as unknown as z.Schema<CableEepromInitializer>

export const cableEepromMutator = z.object({
  id            : cableEepromId.optional(),
  cableId       : cableId.optional(),
  eepromId      : z.string().optional(),
  pairedSensorId: z.string().optional(),
}) as unknown as z.Schema<CableEepromMutator>
