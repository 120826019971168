import { LockIcon, UnlockIcon } from '@chakra-ui/icons'
import {
  FormLabel,
  useBoolean,
  HStack,
  IconButton,
  Input,
  Tag,
  TagLabel, // TagLeftIcon,
  // TagRightIcon,
  TagCloseButton,
} from '@chakra-ui/react'

import { InfoTooltip } from 'components/InfoTooltip'

import { InputWrapperProps } from '../lib/FieldWrapper'

export const TagInput = ({
  name,
  label,
  placeholder,
  field,
  requiresGlobalAdmin = false,
  global = { view: false, edit: false, admin: false },
  reduceToTags,
  tooltip,
}: InputWrapperProps) => {
  const [locked, setLocked] = useBoolean(true)

  const tags = reduceToTags(field.value)

  console.info(tags)

  return (
    <>
      <FormLabel htmlFor={name}>{label ?? name}</FormLabel>
      <HStack spacing={2}>
        <Tag>
          <TagLabel>yolo</TagLabel>
          <TagCloseButton />
        </Tag>
      </HStack>
      <HStack>
        <Input
          placeholder={placeholder ?? name}
          isDisabled={locked && requiresGlobalAdmin}
          {...field}
        />
        {tooltip && <InfoTooltip text={tooltip} />}

        {requiresGlobalAdmin && global.edit && (
          <IconButton
            icon={locked ? <LockIcon /> : <UnlockIcon />}
            onClick={setLocked.toggle}
            aria-label={locked ? 'locked' : 'unlocked'}
          />
        )}
      </HStack>
    </>
  )
}
