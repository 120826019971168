import { z } from 'zod'

/** Represents the enum public.temp_unit_type_ */
enum TempUnitType {
  C = 'C',
  F = 'F',
}

export default TempUnitType

/** Zod schema for temp_unit_type_ */
export const tempUnitType = z.enum(['C', 'F'])
