// a base class that provides some OO syntactic sugar

class Beaded {
  constructor(id) {
    if (id) {
      if (typeof id === 'number') {
        this.id = id
      }
      else if (typeof id === 'object') {
        this.mergeDeep(this, id)
        this.epochVersion()
        this.setTsCreated()
      }
    }
  }

  get(prop_str) {
    if (!prop_str) return this

    const parts = prop_str.split('.')
    if (['$set', '$addToSet'].includes(parts[0])) {
      return this[parts[0]][parts.slice(1).join('.')]
    }

    return parts.reduce((prev, curr) => {
      return prev ? prev[curr] : undefined
    }, this)
  }

  set(prop_str, val) {
    if (this.isEmpty(val)) return
    if (prop_str === 'modem.battery' && val === 0) return

    const path_parts = prop_str.split('.')
    let loc = this
    let lastpart

    for (let i = 0; i < path_parts.length; i++) {
      let part = path_parts[i]

      if (part === '$set' || part === '$addToSet') {
        if (!loc[part]) loc[part] = {}
        // { '$addToSet': { org: { id: 6 } } <-- default
        // { '$addToSet': { 'org.id': 6 } }  <-- required for Mongo
        loc = loc[part] // descend
        lastpart = `${path_parts.slice(+i + 1).join('.')}`
      }

      if (lastpart) {
        part = lastpart
      }
      else {
        // while another part remains
        if (i < path_parts.length - 1) {
          if (loc[part] === undefined) loc[part] = {} // initialize
          loc = loc[part] // descend
          continue
        }
      }

      // last path part, time to assign the value

      if (
        /^(\$set.)?ts\.(?!(second|minute|hour|day|month|year))/.test(prop_str)
      ) {
        // console.log(`set ${prop_str} ${val}`)
        if (this.validDate(val, prop_str))
          loc[part] = this.validDate(val, prop_str)
        return
      }

      if (/^(\$set.)?(date|begin|end)/.test(prop_str)) {
        // console.log(`set ${prop_str} ${val}`)
        if (this.validDate(val)) loc[part] = this.validDate(val)
        return
      }

      if (/email/.test(prop_str)) {
        if (/</.test(val) && />/.test(val)) {
          const [, match] = val.toLowerCase().match(/<([^>]+)>/)
          if (match && /@/.test(match) && match !== val) {
            // console.log(`reducing email: ${val} to ${match}`)
            val = match
          }
        }
        val = val.toLowerCase()
      }

      if (Array.isArray(val)) {
        loc[part] = val
      }
      else if (
        /^\d*$/.test(val) &&
        val.length < 16 &&
        !['decode', 'imei', 'labels', 'notes', 'name'].includes(part)
      ) {
        loc[part] = parseInt(val, 10)
        // console.log(`casted ${prop_str} value as integer ${loc[part]}`)
      }
      else {
        loc[part] = val
      }
    }
  }

  validDate(val, prop_str) {
    if (!val) return

    if (!(val instanceof Date)) {
      if (/[^\d]/.test(val)) {
        if (!this.validIso8601(val, prop_str)) return

        // make UTC TZ explicit for ISO 8601 format
        val = new Date(val.endsWith('Z') ? val : `${val}Z`)
      }
      else {
        val = new Date(val) // epoch seconds
      }
    }

    // there's a plethora of invalid dates already in MySQL. Filter them by
    // requiring an epoch count of seconds after 2010
    if (!this.validEpoch(val.getTime(), prop_str)) return

    if (!(val instanceof Date) || isNaN(val)) return

    return val
  }

  push(prop_str, val, unique) {
    if (this.isEmpty(val)) return

    const path_parts = prop_str.split('.')
    let loc = this
    for (let i = 0; i < path_parts.length; i++) {
      const part = path_parts[i]

      if (i < path_parts.length - 1) {
        if (loc[part] === undefined) loc[part] = {} // initialize
        loc = loc[part] // descend
        continue
      }

      // last part, assign the value
      if (loc[part] === undefined) loc[part] = []

      if (unique && loc[part].includes(val)) return

      if (Array.isArray(val)) {
        loc[part].push(...val)
      }
      else {
        loc[part].push(val)
      }
    }
  }

  // https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
  mergeDeep(target, ...sources) {
    if (!sources.length) return target
    const source = sources.shift()

    if (isObject(target) && isObject(source)) {
      for (const key in source) {
        if (isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} })
          this.mergeDeep(target[key], source[key])
        }
        else {
          Object.assign(target, { [key]: source[key] })
        }
      }
    }

    return this.mergeDeep(target, ...sources)
  }

  isEmpty(val) {
    if (Array.isArray(val)) {
      if (val.length === 0) return true
      if (val.length === 1 && val[0] === '') return true
    }

    switch (val) {
      case null:
      case undefined:
      case 'null':
      case '':
        return true
      default:
        break
    }
    return false
  }

  epochVersion() {
    if (this.v) {
      // console.error(`v already defined: ${this.v}`)
      return
    }
    this.set('v', new Date().getTime()) // epoch time in milliseconds
  }

  setTsCreated() {
    const currentDate = this.get('ts.created')

    if (typeof currentDate === 'string') return new Date(currentDate)
    if (currentDate instanceof Date) return

    if (typeof currentDate === 'number') {
      console.info(`Beaded.setTsCreated sees numeric date: ${currentDate}`)
      return new Date(currentDate)
    }

    this.set('ts.created', new Date())
  }

  validIso8601(val, prop_str) {
    if (
      !/(19|20)(\d{2})-(\d{2})-(\d{2})(T| )(\d{2}):(\d{2}):(\d{2})(\.\d{3})?Z?/.test(
        val,
      )
    )
      return false

    // format is correct, convert to epoch and test if range is valid
    return this.validEpoch(new Date(val).getTime(), prop_str)
  }

  validEpoch(val, prop_str) {
    if ('number' !== typeof val) val = parseInt(val, 10)
    if (isNaN(val)) return false

    if (val < 1230768000000) return false // prior to 2009

    // more than a day into the future
    if (val > new Date().getTime() + 86400000) {
      if (/exp/.test(prop_str)) {
        console.info(`allowing future ${prop_str}: ${new Date(val)}`)
        return true
      }

      console.info(`rejecting future ${prop_str} date: ${new Date(val)}`)
      return false
    }

    return true
  }
}

export default Beaded

function isObject(item) {
  return (
    item &&
    typeof item === 'object' &&
    !Array.isArray(item) &&
    !(item instanceof Date)
  )
}
