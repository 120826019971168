import type { QueryKey, QueryFunction } from 'react-query'

import { project } from 'api'

import { useQuery } from 'hooks/useQuery'
import { useUserContext } from 'hooks/useUserContext'

export const useProjectsList = (orgId: number, perms: any) => {
  const { superUser } = useUserContext()

  const options: [QueryKey, QueryFunction] =
    superUser === 'on'
      ? [
        ['projects', { 'org.id': orgId, projection: 'menu' }, { superUser }],
        ({ queryKey }) => project.admin.getAll({ params: queryKey[1] }),
      ]
      : ['projects', ({ queryKey }) => project.getAll({ params: queryKey[1] })]

  const { data, ...rest } = useQuery(...options)

  const permsMap = Object.values(data?.proj ?? []).reduce(
    (acc: Array<any>, val: any) => {
      acc.push({
        project: val,
        perm   : perms?.find((p: any) => p?.id === val.id),
      })
      return acc
    },
    [],
  )

  return {
    permsMapping: permsMap,
    ...rest,
  }
}
