import {
  Center,
  Checkbox,
  HStack,
  IconButton,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react'
import { FaTrashAlt } from 'react-icons/fa'

import {
  OrganizationId,
  OrganizationRoleType,
  ProjectId,
  ProjectRoleType,
  UserWithPermissions,
} from '@beaded/models'

import { perm } from 'api'

import { ErrorCard } from 'components/ErrorCard'
import { InternalLink } from 'components/Links'
import { Loading } from 'components/Loading'

import { useOrgName } from 'hooks/useOrgName'

import { queryClient } from 'lib/react-query'

import { useProjectsList } from './useProjectsList'

interface OrgBlockProps {
  organizationId: OrganizationId
  org: any
  user: UserWithPermissions
  lastSection: boolean
}

type Group = 'organization' | 'project'
export const OrgBlock = ({
  organizationId,
  user,
  lastSection,
  org,
}: OrgBlockProps) => {
  const { isLoading, errors, permsMapping } = useProjectsList(
    organizationId,
    user,
  )
  const organizationName = useOrgName(organizationId)

  const checkField = async (
    group: Group,
    level: 'VIEW' | 'EDIT' | 'ADMIN',
    id: OrganizationId | ProjectId,
    value: boolean,
  ) => {
    const role = `${group}_${level}`.toUpperCase()

    if (value) await perm.addRole(group, user.id, role as any, id)
    else await perm.removeRole(group, user.id, role as any, id)

    queryClient.invalidateQueries(['user', { id: user.id }])
  }

  const removeOrg = async (id: number) => {
    await perm.removeUserFromOrg(user.id, id as OrganizationId)
    queryClient.invalidateQueries(['user', { id: user.id }])
  }

  if (isLoading) return <Loading />

  if (errors) return <ErrorCard errors={errors} />

  const hasOrgView = org.perm?.role === OrganizationRoleType.ORGANIZATION_VIEW
  const hasOrgEdit = org.perm?.role === OrganizationRoleType.ORGANIZATION_EDIT
  const hasOrgAdmin = org.perm?.role === OrganizationRoleType.ORGANIZATION_ADMIN

  return (
    <>
      <Tr>
        <Th colSpan={2}>
          <HStack>
            <InternalLink
              name={organizationName}
              href={`/orgs/${organizationId}`}
            />
            <IconButton
              icon={<FaTrashAlt />}
              size='xs'
              aria-label='remove org from user'
              onClick={() => removeOrg(organizationId)}
            />
          </HStack>
        </Th>
        <Td>
          <Center>
            <Checkbox
              isChecked={hasOrgAdmin}
              onChange={(e) =>
                checkField(
                  'organization',
                  'ADMIN',
                  organizationId,
                  e.target.checked,
                )
              }
            />
          </Center>
        </Td>
        <Td>
          <Center>
            <Checkbox
              isChecked={hasOrgEdit}
              isIndeterminate={hasOrgAdmin}
              {...(hasOrgAdmin ? { colorScheme: 'gray' } : {})}
              onChange={(e) =>
                checkField(
                  'organization',
                  'EDIT',
                  organizationId,
                  e.target.checked,
                )
              }
            />
          </Center>
        </Td>
        <Td>
          <Center>
            <Checkbox
              isChecked={hasOrgView}
              isIndeterminate={hasOrgAdmin || hasOrgEdit}
              {...(hasOrgAdmin || hasOrgEdit ? { colorScheme: 'gray' } : {})}
              onChange={(e) =>
                checkField(
                  'organization',
                  'VIEW',
                  organizationId,
                  e.target.checked,
                )
              }
            />
          </Center>
        </Td>
      </Tr>
      {permsMapping.map(({ project, perm }: any) => {
        const hasProjectView = perm?.role === ProjectRoleType.PROJECT_VIEW
        const hasProjectEdit = perm?.role === ProjectRoleType.PROJECT_EDIT
        const hasProjectAdmin = perm?.role === ProjectRoleType.PROJECT_ADMIN

        const adminIsIndeterminate = hasOrgAdmin
        const editIsIndeterminate =
          !hasProjectEdit && (hasOrgAdmin || hasOrgEdit || hasProjectAdmin)
        const viewIsIndeterminate =
          !hasProjectView &&
          (hasOrgAdmin ||
            hasOrgEdit ||
            hasOrgView ||
            hasProjectEdit ||
            hasProjectAdmin)

        return (
          <Tr key={project.id}>
            <Td />
            <Td>
              <InternalLink
                name={project.projectName}
                href={`/projects/${project.id}`}
              />
            </Td>
            <Td>
              <Center>
                <Checkbox
                  isIndeterminate={adminIsIndeterminate}
                  {...(adminIsIndeterminate ? { colorScheme: 'gray' } : {})}
                  isChecked={hasProjectAdmin}
                  onChange={(e) =>
                    checkField('project', 'ADMIN', project.id, e.target.checked)
                  }
                />
              </Center>
            </Td>
            <Td>
              <Center>
                <Checkbox
                  isChecked={hasProjectEdit}
                  isIndeterminate={editIsIndeterminate}
                  {...(editIsIndeterminate ? { colorScheme: 'gray' } : {})}
                  onChange={(e) =>
                    checkField('project', 'EDIT', project.id, e.target.checked)
                  }
                />
              </Center>
            </Td>
            <Td>
              <Center>
                <Checkbox
                  isChecked={hasProjectView}
                  onChange={(e) =>
                    checkField('project', 'VIEW', project.id, e.target.checked)
                  }
                  isIndeterminate={viewIsIndeterminate}
                  {...(viewIsIndeterminate ? { colorScheme: 'gray' } : {})}
                />
              </Center>
            </Td>
          </Tr>
        )
      })}

      {!lastSection && <Tr h='2rem' />}
    </>
  )
}
