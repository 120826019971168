import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import {
  modemPlatformType,
  type default as ModemPlatformType,
} from './ModemPlatformType.js'
import {
  modemProviderType,
  type default as ModemProviderType,
} from './ModemProviderType.js'
import {
  modemStatusType,
  type default as ModemStatusType,
} from './ModemStatusType.js'

/** Identifier type for public.modem_ */
export type ModemId = number & { __brand: 'ModemId' }

/** Represents the table public.modem_ */
export default interface ModemTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<ModemId, ModemId | undefined, ModemId>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  imei: ColumnType<string, string, string>

  /** Database type: public.modem_platform_type_ */
  platform: ColumnType<ModemPlatformType, ModemPlatformType, ModemPlatformType>

  /** Database type: public.modem_provider_type_ */
  provider: ColumnType<ModemProviderType, ModemProviderType, ModemProviderType>

  /** Database type: public.modem_status_type_ */
  status: ColumnType<ModemStatusType, ModemStatusType, ModemStatusType>
}

export type Modem = Selectable<ModemTable>

export type ModemInitializer = Insertable<ModemTable>

export type ModemMutator = Updateable<ModemTable>

export const modemId = z.number() as unknown as z.Schema<ModemId>

export const modem = z.object({
  id       : modemId,
  createdAt: z.coerce.date().nullable().optional(),
  createdBy: z.string().nullable().optional(),
  updatedAt: z.coerce.date().nullable().optional(),
  updatedBy: z.string().nullable().optional(),
  deletedAt: z.coerce.date().nullable().optional(),
  deletedBy: z.string().nullable().optional(),
  imei     : z.string(),
  platform : modemPlatformType,
  provider : modemProviderType,
  status   : modemStatusType,
}) as unknown as z.Schema<Modem>

export const modemInitializer = z.object({
  id       : modemId.optional(),
  createdAt: z.coerce.date().optional().nullable().optional(),
  createdBy: z.string().optional().nullable().optional(),
  updatedAt: z.coerce.date().optional().nullable().optional(),
  updatedBy: z.string().optional().nullable().optional(),
  deletedAt: z.coerce.date().optional().nullable().optional(),
  deletedBy: z.string().optional().nullable().optional(),
  imei     : z.string(),
  platform : modemPlatformType,
  provider : modemProviderType,
  status   : modemStatusType,
}) as unknown as z.Schema<ModemInitializer>

export const modemMutator = z.object({
  id       : modemId.optional(),
  createdAt: z.coerce.date().optional().nullable().optional(),
  createdBy: z.string().optional().nullable().optional(),
  updatedAt: z.coerce.date().optional().nullable().optional(),
  updatedBy: z.string().optional().nullable().optional(),
  deletedAt: z.coerce.date().optional().nullable().optional(),
  deletedBy: z.string().optional().nullable().optional(),
  imei     : z.string().optional(),
  platform : modemPlatformType.optional(),
  provider : modemProviderType.optional(),
  status   : modemStatusType.optional(),
}) as unknown as z.Schema<ModemMutator>
