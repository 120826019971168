export const resetCookies = () => {
  const cookies = document.cookie.split('; ')

  for (const c in cookies) {
    const domain = window.location.hostname.split('.')

    while (domain.length > 0) {
      const cookieBase =
        encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
        '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
        domain.join('.') +
        ' ;path='

      const pathname = window.location.pathname.split('/')
      document.cookie = cookieBase + '/'

      while (pathname.length > 0) {
        document.cookie = cookieBase + pathname.join('/')
        pathname.pop()
      }

      domain.shift()
    }
  }
}
