import { InfoIcon } from '@chakra-ui/icons'
import { IconButton, Code, Center, Tooltip } from '@chakra-ui/react'

import { ModalContainer } from 'containers/ModalContainer'

interface InfoModalProps {
  data: any
  title?: string
  iconButtonProps?: any
  center?: boolean
  tooltip?: string
}

export const InfoModal = ({
  data,
  title = 'Info',
  iconButtonProps = {},
  center = false,
  tooltip = '',
}: InfoModalProps) => {
  return (
    <ModalContainer
      TriggerButton={({ onClick }) => (
        <>
          <Tooltip label={tooltip}>
            {center ? (
              <Center>
                <IconButton
                  icon={<InfoIcon />}
                  onClick={onClick}
                  {...iconButtonProps}
                />
              </Center>
            ) : (
              <IconButton
                icon={<InfoIcon />}
                onClick={onClick}
                {...iconButtonProps}
              />
            )}
          </Tooltip>
        </>
      )}
      modalProps={{ size: 'xl' }}
      header={title}
    >
      <Code variant='inModal'>{JSON.stringify(data, null, 2)}</Code>
    </ModalContainer>
  )
}
