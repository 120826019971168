import { QuestionIcon } from '@chakra-ui/icons'
import { Text, Tooltip } from '@chakra-ui/react'

interface IInfoTooltip {
  text: string | JSX.Element
}

export const InfoTooltip = ({ text }: IInfoTooltip) => (
  <Tooltip label={typeof text === 'string' ? <Text>{text}</Text> : <>{text}</>}>
    <QuestionIcon position='relative' layerStyle='hideOnPrint' />
  </Tooltip>
)
