import { z } from 'zod'

/** Represents the enum public.cable_color_type_ */
enum CableColorType {
  STANDARD = 'STANDARD',
  ARMORED = 'ARMORED',
  WHITE = 'WHITE',
  RED = 'RED',
  NO_CABLE = 'NO_CABLE',
}

export default CableColorType

/** Zod schema for cable_color_type_ */
export const cableColorType = z.enum([
  'STANDARD',
  'ARMORED',
  'WHITE',
  'RED',
  'NO_CABLE',
])
