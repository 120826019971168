import memoize from 'memoizee'

export const sortCableEvents = memoize((a: any, b: any) => {
  if (a?.date === undefined && b?.date === undefined) return 0
  if (a?.date === undefined && b?.date !== undefined) return -1
  if (a?.date !== undefined && b?.date === undefined) return 1

  const dateA = new Date(a?.date)
  const dateB = new Date(b?.date)

  if (dateA > dateB) return 1
  if (dateA < dateB) return -1
  return 0
})

export const getLastCalibration = (eventArray: Array<any> = []) => {
  if (eventArray === undefined || eventArray === null) {
    // console.info('undefined event array for cable');
    return
  }

  try {
    const sorted = eventArray?.sort(sortCableEvents)
    return sorted[sorted?.length - 1]
  }
  catch (error) {
    console.error(error)
    console.info(eventArray)
  }
}
