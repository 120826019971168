import type { FormikValues } from 'formik'
import merge from 'lodash.merge'

import { logger as loggerApi } from 'api'

import {
  useProjectSelectOptions,
  filterProjectsByOrgId,
  useOrgSelectOptions,
  useLoggerIdSelectOptions,
} from 'hooks/useSelectOptions'

import { loggerUseSchema } from 'lib/jsValidate'
import { logValidationErrors } from 'lib/logValidationErrors'
import { queryClient } from 'lib/react-query'
import { validateDate } from 'lib/validateDate'

import type { FormInfo, CreateFormProps } from 'types/FormInfo'
import type { ValidationError } from 'types/ValidationError'

export const addLoggerUseForm = ({
  initialValues,
}: CreateFormProps = {}): FormInfo<FormikValues> => ({
  title    : 'Add Logger Use',
  gridProps: {
    templateColumns: '1fr 1fr 1fr ',
    gap            : 3,
  },
  fields: [
    {
      name            : 'id',
      type            : 'select',
      label           : 'Logger',
      placeholder     : 'Select a logger',
      gridItemProps   : { colSpan: 3 },
      useSelectOptions: useLoggerIdSelectOptions,
      isMulti         : true,
      isRequired      : true,
    },
    {
      name            : 'org.id',
      type            : 'select',
      label           : 'Org',
      placeholder     : 'Select an organization',
      gridItemProps   : { colSpan: 3 },
      useSelectOptions: useOrgSelectOptions,
      isRequired      : true,
    },
    {
      name            : 'project.id',
      type            : 'select',
      label           : 'Project',
      placeholder     : 'Select a project',
      gridItemProps   : { colSpan: 3 },
      useSelectOptions: useProjectSelectOptions,
      filter          : filterProjectsByOrgId,
      filterFlags     : (values: FormikValues, name: string) => ({
        index: parseInt(name.split('.')[1]),
      }),
      isRequired: true,
    },
    {
      name         : 'condition',
      type         : 'select',
      label        : 'Condition',
      gridItemProps: { colSpan: 1 },
      placeholder  : 'Select condition...',
      options      : [
        { value: 'unbuilt', name: 'unbuilt' },
        { value: 'testing', name: 'testing' },
        { value: 'ready_for_sale', name: 'ready for sale' },
        { value: 'sold', name: 'sold' },
        { value: 'lease', name: 'lease' },
        { value: 'damaged', name: 'damaged' },
        { value: 'in_progress', name: 'in progress' },
        { value: 'none', name: 'none' },
      ],
      isRequired: true,
    },
    {
      name         : 'begin',
      type         : 'mask',
      label        : 'Begin',
      placeholder  : 'YYYY-MM-DD',
      gridItemProps: { colSpan: 1 },
      mask         : '####-##-## ##:##',
      validate     : validateDate,
      isRequired   : true,
    },
    {
      name         : 'end',
      type         : 'mask',
      mask         : '####-##-## ##:##',
      label        : 'End',
      placeholder  : 'YYYY-MM-DD',
      gridItemProps: { colSpan: 1 },
      validate     : validateDate,
    },
  ],
  initialValues: merge(
    {
      id     : 0,
      org    : { id: 0 },
      project: { id: 0 },
      begin  : '',
    },
    initialValues,
  ),

  submitText: 'Add Logger Use',

  submitFn: (values: any) => {
    const id = values?.id
    const { value } = loggerUseSchema.validate(values, {
      abortEarly  : false,
      stripUnknown: true,
    })

    if (value.begin !== undefined)
      value.begin = new Date(value.begin).toISOString()

    if (value.end !== undefined) value.end = new Date(value.end).toISOString()

    if (value?.project.id === undefined) delete value.project

    return loggerApi.createUse(id, value)
  },

  validateForm: (
    values: FormikValues,
  ): { error?: ValidationError; value: FormikValues } => {
    const { error } = loggerUseSchema.validate(values, {
      abortEarly  : false,
      stripUnknown: true,
    })
    logValidationErrors(error)

    return { error, value: values }
  },

  mutationOptions: {
    // onMutate: (variables) => console.log('onMutate', variables),
    onError: (error, variables, context): void => {
      // console.log('onError', error, variables, context);
      // return error.message;
    },
    onSuccess: (data: any, variables: any) => {
      if (data?.error) throw data.error
      if (data?.created === false)
        throw new Error('encountered error updating entry')

      // console.log('onSuccess', data, variables, context);
      queryClient.invalidateQueries('loggers')
      queryClient.invalidateQueries('logger')
    },
  },
})
