import { formatInTimeZone } from 'date-fns-tz'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useState, useRef, forwardRef } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import { ErrorBoundary } from 'components/ErrorBoundary'

const defaultOptions = {
  chart: {
    backgroundColor: null,
    borderWidth    : 0,
    type           : 'area',
    margin         : [2, 0, 2, 0],
    width          : 120,
    height         : 20,
    style          : {
      overflow: 'visible',
    },

    // small optimalization, saves 1-2 ms each sparkline
    skipClone: true,
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    labels: {
      enabled: false,
    },
    title: {
      text: null,
    },
    type   : 'datetime',
    ordinal: false,
  },
  yAxis: {
    gridLineColor: 'transparent',
    labels       : {
      enabled: false,
    },
    title: {
      text: null,
    },
    min         : 5.5,
    max         : 7.5,
    tickInterval: 0.1,
  },
  legend: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  tooltip: {
    backgroundColor: 'white',
    borderWidth    : 1,
    hideDelay      : 0,
    shared         : true,
    padding        : 8,
    borderColor    : 'silver',
    borderRadius   : 3,
    outside        : true,
    formatter      : function (this: Highcharts.TooltipFormatterContextObject) {
      return renderToStaticMarkup(
        <>
          <span style={{ fontSize: '10px' }}>
            {formatInTimeZone(new Date(this.x), 'UTC', 'yyyy-MM-dd HH:mm zzz')}
          </span>
          <br />
          <span>{this.y.toFixed(2)} V</span>
        </>,
      )
    },
  },
  plotOptions: {
    series: {
      animation   : false,
      lineWidth   : 1,
      gapSize     : 25 * 3600 * 1000,
      gapUnit     : 'value',
      connectNulls: false,
      shadow      : false,
      states      : {
        hover: {
          lineWidth: 1,
        },
      },
      marker: {
        radius: 1,
        states: {
          hover: {
            radius: 2,
          },
        },
      },
      fillOpacity: 0.25,
    },
  },
}

interface SparkLineProps {
  data: Array<{
    timestamp: number
    battery: number
  }>
}

export const SparkLine = ({ data }: SparkLineProps) => {
  const chartComponent = useRef<HighchartsReact.RefObject>(null)

  const [series] = useState({
    type: 'area',
    data: data?.map(({ timestamp, battery }) => [timestamp, battery]),
  })

  return (
    <ErrorBoundary>
      <Chart
        options={{
          ...defaultOptions,
          series,
        }}
        ref={chartComponent}
      />
    </ErrorBoundary>
  )
}

const Chart = forwardRef<HighchartsReact.RefObject, HighchartsReact.Props>(
  ({ options }, ref) => (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        // constructorType='stockChart'
        ref={ref}
      />
    </div>
  ),
)
