import Joi from 'joi'

import * as common from './common.js'

export const modemUse = Joi.object({
  id              : Joi.string().guid(),
  begin           : Joi.date().iso().greater('2000-01-01T00:00:00Z'),
  end             : Joi.date().iso().greater('2000-01-01T00:00:00Z'),
  notes           : Joi.string().empty(''),
  inventory_status: Joi.string()
    .valid('unknown', 'inventory', 'installed')
    .default('unknown')
    .required(),
  by_user: common.BY_USER,
  logger : Joi.object({
    id    : Joi.number(),
    serial: Joi.object({
      id   : Joi.number(),
      model: Joi.string().empty(''),
    }),
  }),
})

export const modem = Joi.object({
  imei     : Joi.string().required(),
  platform : Joi.string().valid('iridium').required(),
  provider : Joi.string().valid('cloudloop').required(),
  cloudloop: Joi.object({
    subscriber: Joi.object().required(),
    dateCached: Joi.date().iso().greater('2000-01-01T00:00:00Z').required(),
  }),

  by_user: common.BY_USER,

  use: Joi.array().items(modemUse).required(),

  ts: common.TS,

  v: Joi.number().default(0),
})
