import { LockIcon, UnlockIcon } from '@chakra-ui/icons'
import {
  FormLabel,
  useBoolean,
  IconButton,
  HStack,
  Input,
  Image,
  Text,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import { InfoTooltip } from 'components/InfoTooltip'

import { InputWrapperProps } from '../lib/FieldWrapper'

const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  if (e?.target?.files?.[0]) {
    const file = e.target.files[0]
    const base64 = await convertBase64(file)
    return base64
  }

  return ''
}

export const ImageInput = ({
  name,
  label,
  field,
  placeholder,
  accept,
  requiresGlobalAdmin = false,
  direction = 'row',
  spacing = 4,
  global = { view: false, edit: false, admin: false },
  tooltip,
  description,
}: InputWrapperProps) => {
  const [locked, setLocked] = useBoolean(true)
  const { setFieldValue } = useFormikContext()

  return (
    <>
      {label && (
        <FormLabel htmlFor={name} {...(description ? { mb: 0 } : {})}>
          {label}
        </FormLabel>
      )}
      {description ? (
        <Text mb={2} color='gray.500' fontStyle='italic'>
          {description}
        </Text>
      ) : null}
      {field.value ? <Image src={field.value} /> : null}
      <HStack>
        <Input
          type='file'
          placeholder={placeholder ?? name}
          isDisabled={locked && requiresGlobalAdmin}
          accept={accept}
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            const base64 = await onChange(e)
            setFieldValue(name, base64)
          }}
        />
        {tooltip && <InfoTooltip text={tooltip} />}

        {requiresGlobalAdmin && global.edit && (
          <IconButton
            icon={locked ? <LockIcon /> : <UnlockIcon />}
            onClick={setLocked.toggle}
            aria-label={locked ? 'locked' : 'unlocked'}
          />
        )}
      </HStack>
    </>
  )
}
