import { z } from 'zod'

/** Represents the enum public.label_sort_type_ */
enum LabelSortType {
  DEPTH = 'DEPTH',
  SERIAL = 'SERIAL',
}

export default LabelSortType

/** Zod schema for label_sort_type_ */
export const labelSortType = z.enum(['DEPTH', 'SERIAL'])
