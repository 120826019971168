import { Center, IconButton } from '@chakra-ui/react'
import { RiLockPasswordLine } from 'react-icons/ri'

import { ErrorWrapper } from 'components/ErrorWrapper'

import { ModalContainer } from 'containers/ModalContainer'

import { PermsBlock } from 'pages/User'

interface IUserPermsModalProps {
  userId: number
  user?: any
  title?: string
  iconButtonProps?: any
  center?: boolean
}

export const UserPermsModal = ({
  userId,
  user,
  iconButtonProps = {},
  center = false,
}: IUserPermsModalProps) => {
  return (
    <ErrorWrapper>
      <ModalContainer
        TriggerButton={({ onClick }) => (
          <>
            {center ? (
              <Center>
                <IconButton
                  icon={<RiLockPasswordLine />}
                  onClick={onClick}
                  {...iconButtonProps}
                />
              </Center>
            ) : (
              <IconButton
                icon={<RiLockPasswordLine />}
                onClick={onClick}
                {...iconButtonProps}
              />
            )}
          </>
        )}
        modalProps={{ size: '3xl' }}
        header='Permissions'
        footer=' '
      >
        <PermsBlock userId={userId} user={user} />
      </ModalContainer>
    </ErrorWrapper>
  )
}
