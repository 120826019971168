import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { projectId, type ProjectId } from './Project.js'
import { siteId, type SiteId } from './Site.js'

/** Represents the table public.project_site_ */
export default interface ProjectSiteTable {
  /** Database type: pg_catalog.int4 */
  projectId: ColumnType<ProjectId, ProjectId, ProjectId>

  /** Database type: pg_catalog.int4 */
  siteId: ColumnType<SiteId, SiteId, SiteId>
}

export type ProjectSite = Selectable<ProjectSiteTable>

export type ProjectSiteInitializer = Insertable<ProjectSiteTable>

export type ProjectSiteMutator = Updateable<ProjectSiteTable>

export const projectSite = z.object({
  projectId: projectId,
  siteId   : siteId,
}) as unknown as z.Schema<ProjectSite>

export const projectSiteInitializer = z.object({
  projectId: projectId,
  siteId   : siteId,
}) as unknown as z.Schema<ProjectSiteInitializer>

export const projectSiteMutator = z.object({
  projectId: projectId.optional(),
  siteId   : siteId.optional(),
}) as unknown as z.Schema<ProjectSiteMutator>
