import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'

import type { Obj, ID } from 'types/common'

import * as apiUtils from '.'

const url = '/sensor'

export const get = (id: ID, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/${id}`, options)

export const getAll = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, options)

export const create = (data: Obj) => apiUtils.POST(`${url}/`, data)

export const update = (id: ID, data: Obj) => apiUtils.PUT(`${url}/${id}`, data)

export const remove = (id: ID) => apiUtils.DELETE(`${url}/${id}`)

export const getCalibrationInfoForCable = (
  serial: ID,
  options: AxiosRequestConfig = {},
) => apiUtils.GET(`${url}/calibration/cable/${serial}`, options)

export const getCalibrationInfoForSite = (
  id: ID,
  options: AxiosRequestConfig = {},
) => apiUtils.GET(`${url}/calibration/site/${id}`, options)

export const getByCable = (cableSerial: ID, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(
    `${url}/`,
    merge({ params: { 'cable.serial': cableSerial } }, options),
  )

export const admin = {
  get: (id: ID, options: AxiosRequestConfig = {}) =>
    get(id, merge({}, apiUtils.globalFlags, options)),
  getAll: (options: AxiosRequestConfig = {}) =>
    getAll(merge({}, apiUtils.globalFlags, options)),
  getByCable: (cableSerial: ID, options: AxiosRequestConfig = {}) =>
    getByCable(cableSerial, merge({}, apiUtils.globalFlags, options)),
  getById: (id: ID, options: AxiosRequestConfig = {}) =>
    apiUtils.GET(
      `${url}/`,
      merge({}, apiUtils.globalFlags, { params: { id } }, options),
    ),
}
