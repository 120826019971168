import Joi from 'joi'

import * as common from './common.js'

export const loggerUse = Joi.object({
  begin    : Joi.date().iso().greater('2009-01-01T00:00:00Z'),
  end      : Joi.date().iso().greater('2009-01-01T00:00:00Z'),
  by_user  : common.BY_USER,
  condition: Joi.string()
    .valid(
      'unbuilt',
      'testing',
      'ready_for_sale',
      'sold',
      'lease',
      'damaged',
      'in_progress',
      'none',
    )
    .empty(''),
  org    : Joi.object({ id: Joi.number().integer() }).required(),
  project: Joi.object({ id: Joi.number().integer() }),
})

export const loggerEvent = Joi.object({
  action : Joi.string().empty(''),
  by_user: common.BY_USER,
  date   : Joi.date().iso().greater('2009-01-01T00:00:00Z'),
  app    : Joi.string().empty(''),
  message: Joi.any().empty(''),
  modem  : Joi.object({
    imei  : Joi.string().empty(''),
    status: Joi.string().valid(
      'active',
      'deactivated',
      'suspended',
      'none',
      'not yet activated',
    ),
  }),
  mounting_field: Joi.string().empty(''),
  plan          : Joi.string().empty(''),
  ports         : Joi.string().empty(''),
  decoded       : Joi.any().empty(''),
  type          : Joi.string().empty(''),
  serial        : Joi.number().integer().empty(0),
  onewire_id    : Joi.string().empty(''),
})

export const logger = Joi.object({
  id: Joi.number().integer(),

  app: Joi.string().empty(''),

  by_user: common.BY_USER,

  configuration: Joi.string().empty(''),

  events: Joi.array().items(loggerEvent),

  interval: {
    logging : Joi.number().integer(), // minutes
    transmit: Joi.number().integer(), // minutes
    report  : Joi.number().integer(), // minutes
    retry   : Joi.number().integer(), // minutes
  },

  modem: {
    status: Joi.string()
      .valid('active', 'deactivated', 'suspended', 'none', 'not yet activated')
      .empty(''),
    imei: Joi.string().empty(''),
  },

  modemUse: Joi.string().guid(),

  mounting_field: Joi.string().empty(''),

  photo_link: Joi.string().empty(''),

  plan: Joi.string().empty(''),

  ports: Joi.string().empty(''),

  serial: {
    id       : Joi.number(),
    model    : Joi.string().empty(''),
    orig     : Joi.alternatives().try(Joi.number(), Joi.string()),
    solar_lid: Joi.string().empty(''),
  },

  ts: common.TS,

  use: Joi.array().items(loggerUse),

  notes: Joi.string().empty(''),

  chipset: Joi.string().empty(''),

  v: Joi.number().integer(),
})
