import Joi from 'joi'

import { bodyComponent, moldType, moldColor, cableColor } from './cable.js'
import * as common from './common.js'

export const sensor = Joi.object({
  id: Joi.string().min(16).max(16).hex().uppercase().required(),

  cable: Joi.array().items(
    Joi.object({
      serial   : Joi.number().integer().required(),
      component: bodyComponent.optional(),
      mold     : Joi.object({
        type : moldType.optional(),
        color: moldColor.optional(),
      }),
      type: cableColor.optional(),

      date          : Joi.date().iso().greater('2000-01-01T00:00:00Z').max('now'),
      calibration_id: Joi.number().integer().required(),
      offset        : Joi.number().precision(5).required(),
      position      : Joi.number().integer().required(),
      spacing       : Joi.number().integer().required(),
    }),
  ),

  ts: common.TS,

  v: Joi.number().integer(),
})
