import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { loggerId, type LoggerId } from './Logger.js'
import {
  loggerConditionType,
  type default as LoggerConditionType,
} from './LoggerConditionType.js'
import { organizationId, type OrganizationId } from './Organization.js'
import { projectId, type ProjectId } from './Project.js'

/** Identifier type for public.logger_history_ */
export type LoggerHistoryId = number & { __brand: 'LoggerHistoryId' }

/** Represents the table public.logger_history_ */
export default interface LoggerHistoryTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<LoggerHistoryId, LoggerHistoryId | undefined, LoggerHistoryId>

  /** Database type: pg_catalog.int4 */
  loggerId: ColumnType<LoggerId, LoggerId, LoggerId>

  /** Database type: pg_catalog.timestamptz */
  startedAt: ColumnType<Date, Date | string, Date | string>

  /** Database type: pg_catalog.timestamptz */
  endedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: public.logger_condition_type_ */
  condition: ColumnType<
    LoggerConditionType | null,
    LoggerConditionType | null,
    LoggerConditionType | null
  >

  /** Database type: pg_catalog.int4 */
  organizationId: ColumnType<OrganizationId, OrganizationId, OrganizationId>

  /** Database type: pg_catalog.int4 */
  projectId: ColumnType<ProjectId, ProjectId, ProjectId>
}

export type LoggerHistory = Selectable<LoggerHistoryTable>

export type LoggerHistoryInitializer = Insertable<LoggerHistoryTable>

export type LoggerHistoryMutator = Updateable<LoggerHistoryTable>

export const loggerHistoryId =
  z.number() as unknown as z.Schema<LoggerHistoryId>

export const loggerHistory = z.object({
  id            : loggerHistoryId,
  loggerId      : loggerId,
  startedAt     : z.coerce.date(),
  endedAt       : z.coerce.date().nullable().optional(),
  condition     : loggerConditionType.nullable().optional(),
  organizationId: organizationId,
  projectId     : projectId,
}) as unknown as z.Schema<LoggerHistory>

export const loggerHistoryInitializer = z.object({
  id            : loggerHistoryId.optional(),
  loggerId      : loggerId,
  startedAt     : z.coerce.date(),
  endedAt       : z.coerce.date().optional().nullable().optional(),
  condition     : loggerConditionType.optional().nullable().optional(),
  organizationId: organizationId,
  projectId     : projectId,
}) as unknown as z.Schema<LoggerHistoryInitializer>

export const loggerHistoryMutator = z.object({
  id            : loggerHistoryId.optional(),
  loggerId      : loggerId.optional(),
  startedAt     : z.coerce.date().optional(),
  endedAt       : z.coerce.date().optional().nullable().optional(),
  condition     : loggerConditionType.optional().nullable().optional(),
  organizationId: organizationId.optional(),
  projectId     : projectId.optional(),
}) as unknown as z.Schema<LoggerHistoryMutator>
