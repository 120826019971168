import { Button } from '@chakra-ui/react'
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useUserContext } from 'hooks/useUserContext'

import './terminal.css'
import { GenericCommand, TerminalProps } from './types'
import { useTerminal } from './useTerminal'

interface TerminalEmulatorProps {
  channel: string
}

export const TerminalEmulator = ({ channel }: TerminalEmulatorProps) => {
  const {
    history,
    pushToHistory,
    connect,
    join,
    privmsg,
    setTerminalRef,
    resetTerminal,
  } = useTerminal()

  const { currentUser } = useUserContext()

  useEffect(() => {
    resetTerminal()

    pushToHistory(
      <>
        <div>
          <strong>Welcome!</strong> to the terminal.
        </div>
      </>,
    )
  }, [resetTerminal, pushToHistory])

  useEffect(() => {
    resetTerminal()
    connect(currentUser?.nameFirst ?? 'Anonymous')
    setTimeout(() => {
      join(channel)
    }, 2000)
  }, [connect, currentUser?.nameFirst, join, channel, resetTerminal])

  return (
    <>
      <Button
        position='absolute'
        top='110px'
        right='20px'
        onClick={() => {
          resetTerminal()
          connect(currentUser?.nameFirst ?? 'Anonymous')
          setTimeout(() => {
            join(channel)
          }, 2000)
        }}
        size='sm'
      >
        Reset
      </Button>

      <Terminal
        history={history}
        ref={setTerminalRef}
        // promptLabel='&gt;'
        sendMessage={(msg: string) => privmsg(channel, msg)}
      />
    </>
  )
}

export const Terminal = forwardRef(
  (props: TerminalProps, ref: ForwardedRef<HTMLDivElement>) => {
    const {
      history = [],
      promptLabel = '>',

      sendMessage,
    } = props

    const inputRef = useRef<HTMLInputElement>()
    const [input, setInputValue] = useState<string>('')

    /**
     * Focus on the input whenever we render the terminal or click in the terminal
     */
    useEffect(() => {
      inputRef.current?.focus()
    })

    const focusInput = useCallback(() => {
      inputRef.current?.focus()
    }, [])

    /**
     * When user types something, we update the input value
     */
    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
      },
      [],
    )

    const handleInputKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          sendMessage(input)
          setInputValue('')
        }
      },
      [input, sendMessage],
    )

    return (
      <div className='terminal' ref={ref} onClick={focusInput}>
        {history.map((line, index) => (
          <div
            className='terminal__line'
            key={`terminal-line-${index}-${line}`}
          >
            {line instanceof Object && 'command' in line
              ? ParsedLine({ line })
              : line}
          </div>
        ))}
        <div className='terminal__prompt'>
          <div className='terminal__prompt__label'>{promptLabel}</div>
          <div className='terminal__prompt__input'>
            <input
              type='text'
              value={input}
              onKeyDown={handleInputKeyDown}
              onChange={handleInputChange}
              // @ts-ignore
              ref={inputRef}
            />
          </div>
        </div>
      </div>
    )
  },
)

const ParsedLine = ({ line }: { line: GenericCommand }) => {
  switch (line.command) {
    case '372':
      return (
        <>
          <span className='terminal__line__message'>:{line.params[1]}</span>
        </>
      )
    case 'PRIVMSG':
      return (
        <>
          {/* <span className='terminal__line__user'>
            {line.prefix.split('!')[0]}
          </span>{' '} */}
          <span className='terminal__line__message'>{line.params[1]}</span>
        </>
      )
    case 'JOIN':
      return (
        <>
          <span className='terminal__line__user'>
            {line.prefix.split('!')[0]}
          </span>
          <span className='terminal__line__message'> joined the channel </span>
          <span className='terminal__line__message'>{line.params[0]}</span>
        </>
      )
    // case 'QUIT':
    //   return <>
    //   </>;
    default:
      return <>{JSON.stringify(line)}</>
  }
}
