import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'

import type { Obj } from 'types/common'

import * as apiUtils from '.'

const url = '/logger-firmware'

export const get = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}`, merge({}, apiUtils.globalFlags, options))

export const publish = (data: Obj, options: AxiosRequestConfig = {}) =>
  apiUtils.POST(`${url}`, data, merge({}, apiUtils.globalFlags, options))
