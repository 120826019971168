import memoize from 'memoizee'

import { useOrgSelectOptions } from 'hooks/useSelectOptions'

import { Obj } from 'types/common'

export const useOrgName = (id?: number) => {
  const orgOptions = useOrgSelectOptions()

  const find = memoize((id: number) => {
    return orgOptions.options.find(
      (opt: any) => opt?.value !== undefined && opt.value === id,
    )
  })

  if (Array.isArray(orgOptions?.options) && id !== undefined) {
    const opt = find(id)
    if (opt?.name) return opt.name
    if (opt?.value) return `${opt.value}`
  }

  return `${id}`
}

export const useOrgNames = (id?: Array<number>) => {
  const orgOptions = useOrgSelectOptions()

  const find = memoize((id: Array<number>) => {
    return orgOptions.options.filter(
      (opt: any) => opt?.value !== undefined && id.includes(opt.value),
    )
  })

  if (Array.isArray(orgOptions?.options) && id !== undefined) {
    const opt = find(id)
    if (opt?.length)
      return opt.reduce(
        (result: Obj, curr: { value: number; name: string }) => {
          result[curr.value] = curr.name
          return result
        },
        {},
      )
  }

  return `${id}`
}
