import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'
import throttle from 'lodash.throttle'
import queryString from 'query-string'

import { API_BASE_URI } from 'constants/api'

import type { Obj, ID } from 'types/common'

import * as apiUtils from '.'

const url = '/cable'

export const get = (id: ID, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/${id}`, options)

export const getAll = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, options)

export const getBySerialNumber = (
  serial: ID,
  options: AxiosRequestConfig = {},
) => apiUtils.GET(`${url}/serial/${serial}`, options)

export const create = (data: Obj) => apiUtils.POST(`${url}/`, data)

export const createEvent = (id: ID, data: Obj) =>
  apiUtils.PUT(`${url}/${id}/event`, data)

export const createUse = (id: ID, data: Obj) =>
  apiUtils.PUT(`${url}/${id}/use`, data)

export const getPrice = throttle(
  (bom: Obj) => apiUtils.POST(`${url}/price`, bom),
  500,
)

export const update = (id: ID, data: Obj) => apiUtils.PUT(`${url}/${id}`, data)

export const patch = (id: ID, data: Obj) => apiUtils.PATCH(`${url}/${id}`, data)

export const remove = (id: ID) => apiUtils.DELETE(`${url}/${id}`)

export const getMenuProjection = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({ params: { projection: 'menu' } }, options))

export const admin = {
  get: (id: ID, options: AxiosRequestConfig = {}) =>
    get(id, merge({}, apiUtils.globalFlags, options)),
  getAll: (options: AxiosRequestConfig = {}) =>
    getAll(merge({}, apiUtils.globalFlags, options)),
  getBySerialNumber: (serial: ID, options: AxiosRequestConfig = {}) =>
    getBySerialNumber(serial, merge({}, apiUtils.globalFlags, options)),
  getMenuProjection: (options: AxiosRequestConfig = {}) =>
    getMenuProjection(merge({}, apiUtils.globalFlags, options)),
  syncToFishbowl: (id: ID, options: AxiosRequestConfig = {}) =>
    apiUtils.PUT(
      `${url}/${id}/fishbowl-sync`,
      {},
      merge({}, apiUtils.globalFlags, options),
    ),
  syncToErpNext: (id: ID, options: AxiosRequestConfig = {}) =>
    apiUtils.PUT(
      `${url}/${id}/erpnext-sync`,
      {},
      merge({}, apiUtils.globalFlags, options),
    ),
  lockCable: (id: ID, options: AxiosRequestConfig = {}) =>
    apiUtils.POST(
      `${url}/${id}/lock`,
      {},
      merge({}, apiUtils.globalFlags, options),
    ),
  downloadFishbowlCsv: (id: ID, options: AxiosRequestConfig = {}) => {
    const params = merge({}, apiUtils.globalFlags, options)
    window.open(
      `${API_BASE_URI()}${url}/${id}/fishbowl-sync?${queryString.stringify(
        params.params,
      )}`,
      '_blank',
    )
  },
  downloadBulkFishbowlCsv: (
    ids: Array<ID>,
    options: AxiosRequestConfig = {},
  ) => {
    // url encode array of ids
    window.open(
      `${API_BASE_URI()}${url}/bulk/fishbowl-sync?fileOption=part&ids=${encodeURIComponent(
        JSON.stringify(ids),
      )}`,
      '_blank',
    )
    window.open(
      `${API_BASE_URI()}${url}/bulk/fishbowl-sync?fileOption=bom&ids=${encodeURIComponent(
        JSON.stringify(ids),
      )}`,
      '_blank',
    )
  },
  downloadMultipleCutterXml: (id: ID[], options: AxiosRequestConfig = {}) => {
    const params = merge({}, apiUtils.globalFlags, options)
    for (const cableId of id) {
      window.open(
        `${API_BASE_URI()}${url}/${cableId}/cutter?${queryString.stringify(
          params.params,
        )}`,
        '_blank',
      )
    }
  },
  downloadCutterXml: (id: ID, options: AxiosRequestConfig = {}) => {
    const params = merge({}, apiUtils.globalFlags, options)
    window.open(
      `${API_BASE_URI()}${url}/${id}/cutter?${queryString.stringify(
        params.params,
      )}`,
      '_blank',
    )
  },
}
