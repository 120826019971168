import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'

import type { Obj, ID } from 'types/common'

import * as apiUtils from '.'

const url = '/logger'

export const get = (id: ID, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/${id}`, merge({}, apiUtils.extrasFlags, options))

export const getAll = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({}, apiUtils.extrasFlags, options))

export const create = (data: Obj) => apiUtils.POST(`${url}/`, data)

export const createEvent = (id: ID, data: Obj) =>
  apiUtils.PUT(`${url}/${id}/event`, data)

export const createUse = (id: ID, data: Obj) =>
  apiUtils.PUT(`${url}/${id}/use`, data)

export const update = (id: ID, data: Obj) => apiUtils.PUT(`${url}/${id}`, data)

export const patch = (id: ID, data: Obj) => apiUtils.PATCH(`${url}/${id}`, data)

export const remove = (id: ID) => apiUtils.DELETE(`${url}/${id}`)

export const getMenuProjection = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({ params: { projection: 'menu' } }, options))

export const validateSerial = (serial: string) =>
  apiUtils.GET(`${url}/validate`, { params: { serial } })

export const admin = {
  get: (id: ID, options: AxiosRequestConfig = {}) =>
    get(id, merge({}, apiUtils.globalFlags, options)),
  getAll: (options: AxiosRequestConfig = {}) =>
    getAll(merge({}, apiUtils.globalFlags, options)),
  getMenuProjection: (options: AxiosRequestConfig = {}) =>
    getMenuProjection(merge({}, apiUtils.globalFlags, options)),
  sendSbdMessage: (id: ID, message: string, options: AxiosRequestConfig = {}) =>
    apiUtils.PUT(
      `${url}/${id}/sbd`,
      { id, message },
      merge({}, apiUtils.globalFlags, options),
    ),
}
