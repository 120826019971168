import { decode1 } from '@beaded/decode-sbd/lib/decode-sbd/decode1'
import { decode2 } from '@beaded/decode-sbd/lib/decode-sbd/decode2'
import { decode3to7 } from '@beaded/decode-sbd/lib/decode-sbd/decode3to7'
import { decode11 } from '@beaded/decode-sbd/lib/decode-sbd/decode11'

import { Obj } from 'types/common/Obj'

import { format1 } from './Formats/format1'
import { format2 } from './Formats/format2'
import { format3to7 } from './Formats/format3to7'
import { format10 } from './Formats/format10'
import { format11 } from './Formats/format11'

export * from './SbdDecoderModal.component'
// = (decoded: any): string =>

type v10Decoder = {
  data: {
    format: number
    packet: Buffer
  }
}

const decode10 = (input: v10Decoder): string => {
  return input.data.packet.toString()
}

export const decode: Obj = {
  0 : decode1,
  1 : decode1,
  2 : decode2,
  3 : decode3to7,
  4 : decode3to7,
  5 : decode3to7,
  6 : decode3to7,
  7 : decode3to7,
  10: decode10,
  11: decode11,
}

export const format: Obj = {
  0 : format1,
  1 : format1,
  2 : format2,
  3 : format3to7,
  4 : format3to7,
  5 : format3to7,
  6 : format3to7,
  7 : format3to7,
  10: format10,
  11: format11,
}
