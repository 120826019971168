import { Obj } from 'types/common'

export const format11 = (decoded: any, version: number): string => {
  delete decoded.data.packets

  let output = `Version: ${version}\n\n`
  output += Object.entries(decoded).reduce((acc, [key, value]) => {
    if (key === 'data') return acc

    // Logger
    if (key === 'logger') {
      acc += 'Logger:\n'
      for (const l of value as Obj[]) {
        acc += Object.entries(l).reduce((acc, [key, value]) => {
          if (key === 'ts')
            return (
              acc +
              `    ${'Timestamp'}: ${
                new Date(value).toISOString().split('T')[0]
              }\n`
            )
          if (key === 'temperature') {
            acc += '    Temperature:\n'
            acc += Object.entries(value).reduce((acc, [key, value]) => {
              if (key === 'ts')
                return (
                  acc +
                  `        ${key.charAt(0).toUpperCase() + key.slice(1)}: ${
                    new Date(value as unknown as string | Date)
                      .toISOString()
                      .split('T')[0]
                  }\n`
                )
              return acc + `        ${key}: ${value}\n`
            }, '')
            return acc
          }
          if (key === 'tamper') {
            acc += '    Tamper:\n'
            acc += Object.entries(value).reduce((acc, [key, value]) => {
              let res = `        ${key}: ${value}`
              switch (key) {
                case 'mag':
                  res += '  (magnet swiped)\n'
                  break
                case 'charge':
                  res += '  (charge activity)\n'
                  break
                case 'case':
                  res += '  (case opened)\n'
                  break
                case 'accel':
                  res += '  (logger was tilted)\n'
                  break
                case 'gps':
                  res += '  (logger moved more than 100m)\n'
                  break
                case 'wireless':
                  res += '  (wireless devices changed)\n'
                  break
                case 'survival':
                  res += '  (survival state)\n'
                  break
                default:
                  break
              }
              return acc + res
            }, '')
            return acc
          }
          return (
            acc +
            `    ${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}\n`
          )
        }, '')
      }
      return acc
    }

    // Cables -> Readings
    if (Array.isArray(value)) {
      acc += `${key.charAt(0).toUpperCase() + key.slice(1)}:\n`
      for (const l of value as Obj[]) {
        acc += Object.entries(l).reduce((acc, [key, value]) => {
          if (key === 'ts')
            return (
              acc + `    ${'Timestamp'}: ${new Date(value).toISOString()}\n`
            )
          if (key === 'readings') {
            acc += '    Readings:\n'
            let readingCount = 1
            for (const reading of value as number[]) {
              acc += `        ${readingCount}: ${reading}\n`
              readingCount++
            }
            return acc
          }
          return (
            acc +
            `    ${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}\n`
          )
        }, '')
      }
    }

    return acc
  }, '')

  return output
}
