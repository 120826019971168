import type { QueryKey, QueryFunction } from 'react-query'
import { useQuery as useQueryRQ } from 'react-query'

export function useQuery(
  queryKey: QueryKey,
  queryFn: QueryFunction,
  options = {},
) {
  const res: any = useQueryRQ(queryKey, queryFn, options)

  const errors = []
  res?.error && errors.push(res?.error?.toString())
  res?.error && errors.push(res?.error?.response?.data)

  res?.data?.error && errors.push(res.data.error)

  return {
    isIdle   : res.isIdle,
    isLoading: res.isLoading,
    isSuccess: res.isSuccess && Boolean(res?.data),
    errors   : res.isError || errors.length > 0 ? errors : null,
    data     : res?.data,
  }
}
