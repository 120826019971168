import type { FormikValues } from 'formik'
import { ZodError } from 'zod'

import type { ValidationError } from 'types/ValidationError'
import type { Obj } from 'types/common'

export const logValidationErrors = (
  error: ValidationError | ZodError,
): FormikValues => {
  if (!error) return {}

  const errors: Obj = {}

  if ('details' in error) {
    for (const e of error.details) {
      errors[e.path[0] as string] = e.message
    }
  }

  if ('issues' in error) {
    for (const e of error.issues) {
      errors[e.code as string] = e.message
    }
  }

  console.table(errors)

  return errors
}
