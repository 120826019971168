import Joi from 'joi'

import * as common from './common.js'

export const project = Joi.object({
  id       : Joi.number().integer(),
  projectid: Joi.string().empty(''),
  alert    : {
    users: Joi.array().items(Joi.string()),
  },
  billing: Joi.string().empty(''),
  data   : {
    site : Joi.string().empty(''),
    table: Joi.string().empty(''),
  },
  display: {
    asana: {
      link: Joi.string().empty(''),
    },
    retired_sites: Joi.boolean(),
    TZ           : Joi.string().empty(''),
  },
  logo: {
    graph  : Joi.string().empty(''),
    sidebar: Joi.string().empty(''),
  },
  name : Joi.string(),
  notes: Joi.string().empty(''),
  org  : {
    id: Joi.number().integer(),
  },
  public : Joi.boolean(),
  user   : { id: Joi.number().integer() },
  units  : common.UNITS,
  ts     : common.TS,
  visible: Joi.boolean(),
  by_user: common.BY_USER,
  v      : Joi.number().integer(),

  googlePhotoAlbum: Joi.string().empty(''),

  blueprint: Joi.object({
    image : Joi.string().empty(''), // base64 encoded image
    points: Joi.array().items({
      point: Joi.object({
        x: Joi.number(),
        y: Joi.number(),
      }),
      decode: Joi.number().integer(),
      sensor: Joi.number().integer(),
      serial: Joi.number().integer(),
      siteId: Joi.number().integer(),
    }),
    temperature_threshold: Joi.number().default(0),
  }),

  forecasting: Joi.object({
    enabled: Joi.boolean(),
  }),
})
