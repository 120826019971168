import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { loggerId, type LoggerId } from './Logger.js'

/** Identifier type for public.logger_event_ */
export type LoggerEventId = number & { __brand: 'LoggerEventId' }

/** Represents the table public.logger_event_ */
export default interface LoggerEventTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<LoggerEventId, LoggerEventId | undefined, LoggerEventId>

  /** Database type: pg_catalog.int4 */
  loggerId: ColumnType<LoggerId, LoggerId, LoggerId>

  /** Database type: pg_catalog.text */
  eventType: ColumnType<string, string, string>

  /** Database type: pg_catalog.timestamptz */
  eventDate: ColumnType<Date, Date | string, Date | string>

  /** Database type: pg_catalog.text */
  raw: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  text: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.int4 */
  cableSerial: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.text */
  onewireId: ColumnType<string | null, string | null, string | null>
}

export type LoggerEvent = Selectable<LoggerEventTable>

export type LoggerEventInitializer = Insertable<LoggerEventTable>

export type LoggerEventMutator = Updateable<LoggerEventTable>

export const loggerEventId = z.number() as unknown as z.Schema<LoggerEventId>

export const loggerEvent = z.object({
  id         : loggerEventId,
  loggerId   : loggerId,
  eventType  : z.string(),
  eventDate  : z.coerce.date(),
  raw        : z.string().nullable().optional(),
  text       : z.string().nullable().optional(),
  cableSerial: z.number().nullable().optional(),
  onewireId  : z.string().nullable().optional(),
}) as unknown as z.Schema<LoggerEvent>

export const loggerEventInitializer = z.object({
  id         : loggerEventId.optional(),
  loggerId   : loggerId,
  eventType  : z.string(),
  eventDate  : z.coerce.date(),
  raw        : z.string().optional().nullable().optional(),
  text       : z.string().optional().nullable().optional(),
  cableSerial: z.number().optional().nullable().optional(),
  onewireId  : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<LoggerEventInitializer>

export const loggerEventMutator = z.object({
  id         : loggerEventId.optional(),
  loggerId   : loggerId.optional(),
  eventType  : z.string().optional(),
  eventDate  : z.coerce.date().optional(),
  raw        : z.string().optional().nullable().optional(),
  text       : z.string().optional().nullable().optional(),
  cableSerial: z.number().optional().nullable().optional(),
  onewireId  : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<LoggerEventMutator>
