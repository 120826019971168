export const parseDecodeOrder = (input: Array<number> | string) => {
  if (Array.isArray(input)) return input.map((elem: any) => Number(elem))
  if (!input) return undefined

  const decodeList = []

  const inputSections = input.trim().split(',')

  for (const s of inputSections) {
    const ranges = getRanges(s)
    const single = getSingle(s)

    if (ranges) {
      const lo = parseInt(ranges[1])
      const hi = parseInt(ranges[2])
      for (let i = lo; i <= hi; i++) {
        decodeList.push(i)
      }
    }
    else if (single !== undefined && single !== null) {
      if (Array.isArray(single)) decodeList.push(parseInt(single[1]))
      else decodeList.push(parseInt(single as any))
    }
  }

  return decodeList
}

export const combineDecodeList = (
  input?: Array<number> | string,
  addSpace = false,
) => {
  if (input === undefined) return ''
  if (!Array.isArray(input)) return input

  // adapted from https://stackoverflow.com/a/2270987

  const ranges = []
  let start = null
  let end = null

  for (var i = 0; i < input.length; i++) {
    start = input[i]
    end = start

    // not using this display syntax for descending ranges
    // because it may be confusing to users
    // if (input[i + 1] - input[i] === 1) {
    //   while (input[i + 1] - input[i] === 1) {
    //     end = input[i + 1];
    //     i++;
    //   }
    // } else if (input[i + 1] - input[i] === -1) {
    //   while (input[i + 1] - input[i] === -1) {
    //     end = input[i + 1];
    //     i++;
    //   }
    // }

    while (input[i + 1] - input[i] === 1) {
      end = input[i + 1]
      i++
    }

    ranges.push(start === end ? `${start}` : `${start}-${end}`)
  }

  const join = addSpace ? ',​' : ','
  return ranges.join(join)
}

function getRanges(decode: number | string) {
  if (typeof decode === 'number') return

  try {
    return decode.match(/(\d+)-(\d+)/)
  }
  catch (e: any) {
    console.error(`\tgetRanges: ${decode} produced ${e?.message}`)
    return
  }
}

function getSingle(decode: number | string) {
  if (typeof decode === 'number') return decode

  try {
    return decode.match(/(\d+)/)
  }
  catch (e: any) {
    console.error(`\tgetSingle: ${decode} produced ${e?.message}`)
    return
  }
}
