// adapted from https://gist.github.com/igoro00/99e9d244677ccafbf39667c24b5b35ed
import { FormLabel, useColorMode } from '@chakra-ui/react'
import { format as formatDate } from 'date-fns'
import { useFormikContext } from 'formik'
import { HTMLAttributes, useState } from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { InputWrapperProps } from 'components/FormModal/lib/FieldWrapper'

import './date-picker.css'

interface IDatePickerProps extends ReactDatePickerProps {
  isClearable?: boolean
  field: { value: Date | string | undefined }
  showPopperArrow?: boolean
}

export const DatePicker = ({
  field: { value },
  field,
  onChange,
  isClearable = false,
  showPopperArrow = false,
  isReadOnly = false,
  format = 'yyyy-MM-dd HH:mm:ss',
  ...props
}: IDatePickerProps & HTMLAttributes<HTMLElement> & InputWrapperProps) => {
  const [date] = useState(value ? new Date(value) : undefined)
  const { setFieldValue } = useFormikContext()

  const isLight = useColorMode().colorMode === 'light'

  return (
    // if you don't want to use chakra's colors or you just wwant to use the original ones,
    // set className to "light-theme-original" ↓↓↓↓
    <div className={isLight ? 'light-theme' : 'dark-theme'}>
      <FormLabel htmlFor={field.name}>{props.label ?? props.name}</FormLabel>
      <ReactDatePicker
        disabled={isReadOnly}
        readOnly={isReadOnly}
        selected={date}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        className='react-datapicker__input-text' //input is white by default and there is no already defined class for it so I created a new one
        {...props}
        name={field.name}
        value={field?.value ? formatDate(new Date(field?.value), format) : ''}
        onChange={(val: null | Date) => {
          setFieldValue(field.name, val)
        }}
        onKeyDown={(event: React.KeyboardEvent) => {
          if (event.key === 'Backspace' || event.key === 'Delete')
            setFieldValue(field.name, undefined)
        }}
      />
    </div>
  )
}
