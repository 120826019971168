import { sprintf } from 'sprintf-js'

import Beaded from './beaded.js'
import { getPartialSensorId, filterReadings } from './utils.js'

const parseTemp = (bytes, idx) => {
  const whole = bytes.readInt8(idx + 1)
  const decim = bytes.readInt8(idx) / 100.0
  return whole + decim
}

export const decode11 = async function (opts) {
  const doc = new Beaded()
  doc.set('data.format', opts.data.format)
  doc.set('data.packet', opts.data.packet)

  const raw = Buffer.from(opts.data.packet)
  // console.dir(raw, { depth: null })

  let index = 0

  // const byte = raw.readUInt8(0) // byte 1, preamble
  // doc.set('data.format', byte >> 4 & 0b00001111)

  index++

  while (index < raw.length) {
    const rec_type = raw.readUInt8(index)

    switch (rec_type) {
      case 0: {
        // request for data from beadedcloud
        index += await decode11request(doc, raw.slice(index), opts)
        break
      }
      case 1: // logger
        // console.log(`\nrecord type: ${rec_type}, index: ${index}`)
        index += await decode11logger(doc, raw.slice(index))
        break
      case 2: // dtc
      case 6:
        // console.log(`\nrecord type: ${rec_type}, index: ${index}`)
        index += await decode11record(doc, raw.slice(index), {
          ...opts,
          recordFormat: rec_type,
        })
        break
      case 3: // sonic
        index += await decode11sonic(doc, raw.slice(index))
        break
      case 4: // barometric pressure
        index += await decode11barometer(doc, raw.slice(index))
        break
      case 5: // alternate DTC format with onewire ids instead of serial
      case 7:
        index += await decode11recordAlt(doc, raw.slice(index), {
          ...opts,
          recordFormat: rec_type,
        })
        break
      case 255:
        index += 1
        break
      default:
        throw new Error(`unknown record type: ${rec_type}`)
    }
  }

  return doc
}

const decode11record = async function (doc, bytes, opts) {
  // console.log(`decode11record: ${bytes.length} bytes remaining`)
  const len = bytes.readUInt16LE(5)
  const serial = bytes.readUInt16LE(7)

  if (bytes.length < 7 + len) {
    console.error(`Corrupt record, too few bytes`)
    return
  }
  // console.log(`length: ${len}`)

  const headerLength = 9 // type (1) + ts (4) + len (2) + serial (2)
  const readings = []
  let idx = headerLength

  while (idx < len + headerLength) {
    if (opts.recordFormat === 2) readings.push(parseTemp(bytes, idx))
    else if (opts.recordFormat === 6)
      readings.push(bytes.readInt16LE(idx) / 100.0)

    idx += 2
  }

  doc.push('cable', {
    serial  : serial,
    ts      : decode11ts(bytes.readUInt32LE(1)),
    readings: readings,
  })

  filterReadings(doc, opts)

  // console.log(`last idx: ${idx}`)
  return +(len + 9)
}

const decode11sonic = async function (doc, bytes) {
  doc.push('sonic', {
    ts   : decode11ts(bytes.readUInt32LE(1)),
    range: bytes.readUInt16LE(5),
  })

  return 7
}

const decode11barometer = async function (doc, bytes) {
  doc.push('barometer', {
    ts      : decode11ts(bytes.readUInt32LE(1)),
    pressure: bytes.readUInt24LE(5),
  })

  return 8
}

const decode11recordAlt = async function (doc, bytes, opts) {
  const len = bytes.readUInt16LE(5)

  const onewire_id = getPartialSensorId(bytes, 7)

  if (bytes.length < 9 + len) {
    console.error(`Corrupt record, too few bytes`)
    return
  }

  const headerLength = 11 // type (1) + ts (4) + len (2) + onewire_id (4)
  const readings = []
  let idx = headerLength

  while (idx < len + headerLength) {
    if (opts.recordFormat === 5) readings.push(parseTemp(bytes, idx))
    else if (opts.recordFormat === 7)
      readings.push(bytes.readInt16LE(idx) / 100.0)

    idx += 2
  }

  // if (serial)
  //   doc.push('cable', {
  //     onewire_id,
  //     serial,
  //     ts      : this.decode11ts(bytes.readUInt32LE(1)),
  //     readings: readings,
  //   })
  // else
  doc.push('cable', {
    onewire_id,
    ts      : decode11ts(bytes.readUInt32LE(1)),
    readings: readings,
  })

  return +(len + 11)
}

const decode11request = async function (doc, bytes, opts) {
  // console.log(`decode11request: ${bytes.length} bytes remaining`)

  // uint8_t request_type + connected bit flag (on leftmost bit)
  let pos = 1
  const date = decode11ts(bytes.readUInt32LE(pos))
  pos += 4
  const request_type = bytes.readUInt8(pos)
  pos += 1

  // request type options (up to 256):
  // 000: DTC connected      includes 2byte serial
  // 001: DTC disconnected   includes 2byte serial
  // 002: DTC w / out serial(1 - wire id) connected     includes 4byte onewire id
  // 003: DTC w / out serial(1 - wire id) disconnected  includes 4byte onewire id
  // 004: Sonic Ranger connected
  // 005: Sonic Ranger disconnected
  // 006: Barometer connected
  // 007: Barometer disconnected
  // 008: external I2C device connected     includes 2byte serial
  // 009: external I2C device disconnected  includes 2byte serial
  // 010 - 256: not currently used

  switch (request_type) {
    case 0: {
      const size = 2
      if (opts.reprocess === true) return pos + size

      const serial = bytes.readUInt16LE(pos)
      doc.push('requests', { type: 'DTC_CONNECTED', date, serial })
      // console.log('recieved notiication of DTC connection')
      return pos + size
    }

    case 1: {
      const size = 2
      if (opts.reprocess === true) return pos + size

      const serial = bytes.readUInt16LE(pos)
      doc.push('requests', { type: 'DTC_DISCONNECTED', date, serial })
      // console.log('recieved notiication of DTC disconnection')
      return pos + size
    }

    case 2: {
      const size = 4
      if (opts.reprocess === true) return pos + size

      const onewire_id = await getPartialSensorId(bytes, pos)
      doc.push('requests', { type: 'DTC_CONNECTED', date, onewire_id })
      // console.log('recieved notiication of DTC connection')
      return pos + size
    }

    case 3: {
      const size = 4
      if (opts.reprocess === true) return pos + size

      const onewire_id = await getPartialSensorId(bytes, pos)
      doc.push('requests', { type: 'DTC_DISCONNECTED', date, onewire_id })
      // console.log('recieved notiication of DTC disconnection')
      return pos + size
    }

    case 4: {
      const size = 0
      if (opts.reprocess === true) return pos + size
      doc.push('requests', { type: 'SONIC_CONNECTED', date })
      // console.log('recieved notification of sonic connection')
      return pos + size
    }

    case 5: {
      const size = 0
      if (opts.reprocess === true) return pos + size
      doc.push('requests', { type: 'SONIC_DISCONNECTED', date })
      // console.log('recieved notification of sonic disconnection')
      return pos + size
    }

    case 6: {
      const size = 0
      if (opts.reprocess === true) return pos + size
      doc.push('requests', { type: 'BAROMETER_CONNECTED', date })
      // console.log('recieved notification of barometer connection')
      return pos + size
    }

    case 7: {
      const size = 0
      if (opts.reprocess === true) return pos + size
      doc.push('requests', { type: 'BAROMETER_DISCONNECTED', date })
      // console.log('recieved notification of barometer disconnection')
      return pos + size
    }

    case 8: {
      const size = 2
      if (opts.reprocess === true) return pos + size

      const serial = bytes.readUInt16LE(pos)
      doc.push('requests', { type: 'I2C_CONNECTED', date, serial })
      // console.log('recieved notiication of I2C connection')
      return pos + size
    }

    case 9: {
      const size = 2
      if (opts.reprocess === true) return pos + size

      const serial = bytes.readUInt16LE(pos)
      doc.push('requests', { type: 'I2C_DISCONNECTED', date, serial })
      // console.log('recieved notiication of I2C disconnection')
      return pos + size
    }

    default: {
      break
    }
  }

  return 0
}

const decode11logger = async function (doc, bytes) {
  if (bytes.length < 7) {
    console.error(`corrupt record`)
    return
  }

  doc.push('logger', {
    ts         : decode11ds(bytes.readUInt16LE(1)),
    humidity   : bytes.readUInt8(3) * 0.5,
    temperature: {
      max: Math.round((bytes.readUInt8(4) * 0.683725 + -46.85) * 100) / 100,
      min: Math.round((bytes.readUInt8(5) * 0.683725 + -46.85) * 100) / 100,
    },
    battery: bytes.readUInt8(6) * 0.1,
    tamper : {
      charge  : Boolean(bytes.readUInt8(7) & 0b00000001),
      mag     : Boolean(bytes.readUInt8(7) & 0b00000010),
      case    : Boolean(bytes.readUInt8(7) & 0b00000100),
      accel   : Boolean(bytes.readUInt8(7) & 0b00001000),
      gps     : Boolean(bytes.readUInt8(7) & 0b00010000),
      wireless: Boolean(bytes.readUInt8(7) & 0b00100000),
      survival: Boolean(bytes.readUInt8(7) & 0b01000000),
    },
  })

  return 8
}

const decode11ts = function (bytes) {
  const year = (bytes & 0b11111110000000000000000000000000) >> 25 // 2-digit year     range 0 .. 99 -- 7 bits
  const month = (bytes & 0b00000001111000000000000000000000) >> 21 // month            range 1 .. 12 -- 4 bits
  const day = (bytes & 0b00000000000111110000000000000000) >> 16 // day of month     range 1 .. 31 -- 5 bits
  const hour = (bytes & 0b00000000000000001111100000000000) >> 11 // hour             range 0 .. 23 -- 5 bits
  const min = (bytes & 0b00000000000000000000011111100000) >> 5 // minutes          range 0 .. 59 -- 6 bits
  const sec = (bytes & 0b00000000000000000000000000011111) >> 0 // (seconds / 2)    range 0 .. 29 -- 5 bits

  return new Date(
    sprintf(
      '%4s-%02s-%02sT%02s:%02s:%02sZ',
      year + 2000,
      month,
      day,
      hour,
      min,
      sec * 2,
    ),
  )
}

const decode11ds = function (bytes) {
  const year = (bytes & 0b1111111000000000) >> 9 // 2-digit year     range 0 .. 99 -- 7 bits
  const month = (bytes & 0b0000000111100000) >> 5 // month            range 1 .. 12 -- 4 bits
  const day = (bytes & 0b0000000000011111) >> 0 // day of month     range 1 .. 31 -- 5 bits

  return new Date(sprintf('%4s-%02s-%02s', year + 2000, month, day))
}
