export const validDate = (val: any): Date | undefined => {
  if (val instanceof Date && !isNaN(val.getTime())) {
    // console.log(`already a valid Date()`)
    return val
  }

  if (!val) return

  // there's a plethora of invalid dates already in MySQL. Filter them out by
  // requiring an epoch count of seconds or ISO 8601 format string in 20 or 21st century
  if (!iso8601(val) && !epochSeconds(val)) return

  let d

  if (/[^\d]/.test(val)) {
    // make UTC TZ explicit for ISO 8601 format
    d = new Date(val.endsWith('Z') ? val : `${val}Z`)
  }
  else {
    // epoch seconds
    d = new Date(val)
  }

  if (!(d instanceof Date) || isNaN(d.getTime())) return

  return d
}

function iso8601(val: any) {
  return /(19|20)(\d{2})-(\d{2})-(\d{2})(T| )(\d{2}):(\d{2}):(\d{2})(\.\d{3})?Z?/.test(
    val,
  )
}

function epochSeconds(val: any) {
  if (/[^\d]/.test(val)) return false // has non-numeric chars
  if (typeof val !== 'number') return false // is not a JS number
  if (val < 946757532000) return false // prior to Y2K
  return true
}
