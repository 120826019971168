import { HamburgerIcon, ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Image,
  IconButton,
  Center,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react'
import { FaMoon, FaSun } from 'react-icons/fa'

import darkTextLogo from 'assets/beadedcloud-dark-font.svg'
import lightTextLogo from 'assets/beadedcloud-white-font.svg'

import { EditAccountModalWrapper } from 'components/EditAccountModal'
import { IdConverterModal } from 'components/IdConverterModal'
import { SbdDecoderModal } from 'components/SbdDecoderModal'
import { SettingsModalWrapper } from 'components/SettingsModal'

import { useUserContext } from 'hooks/useUserContext'

import { SearchInput } from 'pages/Search'

export interface NavBarProps {
  onOpen: () => void
  auth: ReturnType<typeof useUserContext>
}

export const NavBar = ({
  onOpen,
  auth: { logout, currentUser, global, superUser, toggleSuperUser },
}: NavBarProps) => {
  const { colorMode, toggleColorMode } = useColorMode()
  const SwitchIcon = useColorModeValue(FaMoon, FaSun)
  const superUserButtonColor = useColorModeValue('red.600', 'red.400')
  const isMobile = useBreakpointValue({ base: true, md: false })

  const NavButton = useBreakpointValue({
    base: (
      <IconButton
        aria-label='Open nav drawer'
        onClick={onOpen}
        icon={<HamburgerIcon />}
      />
    ),
    xl   : null,
    '2xl': null,
  })

  return (
    <Box
      ml={{ xl: '320px' }}
      w={{ base: '100%', xl: 'calc(100% - 320px)' }}
      top={0}
      position='sticky'
      layerStyle={superUser === 'on' ? 'adminMenuBar' : 'menuBar'}
      zIndex='1300'
    >
      <Flex h='4rem'>
        {NavButton && <Center p='2'>{NavButton}</Center>}
        {NavButton && !isMobile && (
          <Center w='11.5rem' p='2'>
            <Image
              h='3.5rem'
              src={colorMode === 'light' ? darkTextLogo : lightTextLogo}
            />
          </Center>
        )}
        {NavButton && isMobile && !global.edit && (
          <Center w='11.5rem' p='2'>
            <Image
              h='3.5rem'
              src={colorMode === 'light' ? darkTextLogo : lightTextLogo}
            />
          </Center>
        )}

        <Box flex={1}>
          {global.edit ? (
            <Box w='70%' ml='15%' top='19%' position='relative'>
              <SearchInput isMobile={isMobile} />
            </Box>
          ) : null}
        </Box>

        <Center p='2'>
          <IconButton
            aria-label='toggle dark mode'
            onClick={() => toggleColorMode()}
            icon={<SwitchIcon />}
          />
        </Center>

        <SettingsModalWrapper />

        <Center p='2'>
          <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
              Hi {currentUser?.nameFirst ?? currentUser?.email}
            </MenuButton>

            <MenuList>
              <MenuGroup
                title={`${currentUser?.nameFirst} ${currentUser?.nameLast}`}
              />
              <MenuGroup
                title={
                  Array.isArray(currentUser?.email)
                    ? currentUser?.email?.[0]
                    : currentUser?.email
                }
              />

              <EditAccountModalWrapper ButtonElem={MenuItem} />
              <MenuItem onClick={() => logout()}>Logout</MenuItem>

              {global.view && (
                <MenuGroup title='Admin Features'>
                  <MenuDivider />
                  <MenuItem
                    onClick={() => toggleSuperUser()}
                    color={superUserButtonColor}
                  >
                    {superUser === 'on' ? 'Disable' : 'Enable'} Super User
                  </MenuItem>
                  <IdConverterModal ButtonElem={MenuItem} />
                  <SbdDecoderModal ButtonElem={MenuItem} />
                </MenuGroup>
              )}
            </MenuList>
          </Menu>
        </Center>
      </Flex>
    </Box>
  )
}
