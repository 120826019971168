import type { FormikValues } from 'formik'
import merge from 'lodash.merge'

import { cable } from 'api'

import {
  useProjectSelectOptions,
  filterProjectsByOrgId,
  useOrgSelectOptions,
  useCableIdSelectOptions,
  useSiteSelectOptions,
  filterSitesByProjectId,
} from 'hooks/useSelectOptions'

import { queryClient } from 'lib/react-query'

import type { FormInfo, CreateFormProps } from 'types/FormInfo'
import type { ValidationError } from 'types/ValidationError'

export const addCableUseForm = ({
  initialValues,
}: CreateFormProps = {}): FormInfo<FormikValues> => ({
  title : 'Add Cable',
  fields: [
    {
      name            : 'id',
      type            : 'select',
      label           : 'Cable',
      placeholder     : 'Select a cable',
      gridItemProps   : { colSpan: 3 },
      useSelectOptions: useCableIdSelectOptions,
      isMulti         : true,
      isRequired      : true,
    },
    {
      name            : 'org.id',
      type            : 'select',
      label           : 'Org',
      placeholder     : 'Select an organization',
      gridItemProps   : { colSpan: 3 },
      useSelectOptions: useOrgSelectOptions,
      isRequired      : true,
    },
    {
      name            : 'project.id',
      type            : 'select',
      label           : 'Project',
      placeholder     : 'Select a project',
      gridItemProps   : { colSpan: 3 },
      useSelectOptions: useProjectSelectOptions,
      filter          : filterProjectsByOrgId,
    },
    {
      name            : 'site.id',
      type            : 'select',
      label           : 'Site',
      placeholder     : 'Select a site',
      gridItemProps   : { colSpan: 3 },
      useSelectOptions: useSiteSelectOptions,
      filter          : filterSitesByProjectId,
    },
  ],
  initialValues: merge(
    {
      id     : 0,
      org    : { id: 0 },
      project: { id: 0 },
      site   : { id: 0 },
    },
    initialValues,
  ),

  submitText: 'Add Cable Use',

  submitFn: (values: any) => {
    const id = values.id
    delete values.id

    if (!values?.project.id) delete values.project

    if (!values?.site?.id) delete values.site

    if (values?.org?.id !== undefined) values.org.id = parseInt(values.org.id)

    if (values?.project?.id !== undefined)
      values.project.id = parseInt(values.project.id)

    if (values?.site?.id !== undefined)
      values.site.id = parseInt(values.site.id)

    return cable.createUse(id, values)
  },

  validateForm: (
    values: FormikValues,
  ): { error?: ValidationError; value: FormikValues } => {
    return { value: values }
  },

  mutationOptions: {
    // onMutate: (variables) => console.log('onMutate', variables),
    onError: (error, variables, context): void => {
      // console.log('onError', error, variables, context);
      // return error.message;
    },
    onSuccess: (data: any, variables: any) => {
      if (data?.error) throw data.error
      if (data?.created === false)
        throw new Error('encountered error updating entry')

      // console.log('onSuccess', data, variables, context);
      queryClient.invalidateQueries('cables')
      queryClient.invalidateQueries('cable')
    },
  },
})
