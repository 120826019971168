import { Text } from '@chakra-ui/react'
import { Cell } from 'react-table'

import { Organization } from '@beaded/models'

import { actions } from 'components/ActionsBlock'
import { InternalLink } from 'components/Links'
import { NotesModal } from 'components/NotesModal'
import { DataTable } from 'components/Table'

import { deleteOrg } from 'lib/mutations/delete/deleteOrg'
import { updateOrgForm } from 'lib/mutations/update/updateOrg'

import type { KeysOf } from 'types/common'

type OrgCell = Cell & {
  row: {
    original: Organization
  }
}

interface OrgsTableProps {
  data: any
  globalFilter: any
  projection: OrgsTableProjection
  download: any
}

export const OrgsTable = ({
  data,
  globalFilter,
  projection = 'default',
  download,
}: OrgsTableProps) => (
  <>
    {data.length ? (
      <DataTable
        columns={projections[projection]}
        data={data}
        initialGlobalFilter={globalFilter}
        hiddenColumns={hiddenColumns}
        hideGlobalFilter={!(data.length > 10)}
        download={download}
      />
    ) : (
      <Text>no data found</Text>
    )}
  </>
)

const hiddenColumns = ['id']

const columns = {
  id  : { Header: 'id', accessor: 'id', isNumeric: true },
  name: {
    Header  : 'Name',
    accessor: 'organizationName',
    Cell    : ({ value, row }: OrgCell) => (
      <InternalLink name={value} href={`/orgs/${row.values.id}`} />
    ),
  },
  publicName: {
    Header  : 'Name',
    accessor: 'organizationName',
    Cell    : ({ value, row }: OrgCell) => (
      <InternalLink name={value} href={`/public/orgs/${row.values.id}`} />
    ),
  },
  notes: {
    Header   : 'Notes',
    accessor : 'notes',
    isNumeric: true,
    Cell     : ({ value, row }: OrgCell) => {
      if (!value) return null
      return <NotesModal note={value} name={row.values?.organizationName} />
    },
  },
  siteCount: {
    Header  : 'Sites',
    accessor: 'extras.activeSiteCount',
  },
  projCount: {
    Header  : 'Projects',
    accessor: 'extras.visibleProjCount',
  },
  publicSiteCount: {
    Header  : 'Sites',
    accessor: 'extras.siteCount',
  },
  publicProjCount: {
    Header  : 'Projects',
    accessor: 'extras.projCount',
  },

  actions: actions({
    formInfo : updateOrgForm,
    deleteFn : deleteOrg,
    changelog: true,
  }),
}

const projections = {
  default: [
    columns.id,
    columns.name,
    columns.projCount,
    columns.siteCount,
    columns.notes,
    columns.actions,
  ],

  publicOrgs: [
    columns.id,
    columns.publicName,
    columns.publicProjCount,
    columns.publicSiteCount,
  ],
}

export type OrgsTableProjection = KeysOf<typeof projections>
