import { UserId } from '@beaded/models'

import { user } from 'api'

import { queryClient } from 'lib/react-query'

export const deleteUser = async (id: UserId) => {
  await user.remove(id)
  queryClient.invalidateQueries('users')
}
