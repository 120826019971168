import { LockIcon, UnlockIcon } from '@chakra-ui/icons'
import { FormLabel, useBoolean, IconButton, HStack } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { useFormikContext } from 'formik'
import { memoize } from 'lodash'
import { useState, useEffect, memo } from 'react'

import { InfoTooltip } from 'components/InfoTooltip'

import type { Option } from 'types/FormInfo'

import { InputWrapperProps } from '../lib/FieldWrapper'

export const MultiSelect = memo(
  ({
    name,
    label,
    field,
    field: { value },
    placeholder,
    options,
    requiresGlobalAdmin = false,
    global = { view: false, edit: false, admin: false },
    values = {},
    filter,
    useSelectOptions = () => [],
    filterCurrentSelection,
    tooltip,
    isMulti = true,
  }: InputWrapperProps) => {
    const { setFieldValue } = useFormikContext()

    const [locked, setLocked] = useBoolean(true)
    const { options: asyncOpts } = useSelectOptions(
      filter,
      values,
      convertOptionsForMultiSelect,
    )
    const [opts, setOpts] = useState<any[]>([])
    const [currentSelection, setCurrentSelection] = useState<any[]>()

    useEffect(() => {
      const fetchOptions = async () => {
        if (Array.isArray(options)) {
          setOpts(options)
        }
        else if (typeof options === 'function') {
          setOpts(await options())
        }
      }

      typeof options !== 'undefined' && fetchOptions()
    }, [options])

    useEffect(() => {
      if (
        asyncOpts?.length &&
        !currentSelection &&
        typeof filterCurrentSelection === 'function'
      )
        setCurrentSelection(filterCurrentSelection(asyncOpts, value))
    }, [asyncOpts, value, currentSelection, filterCurrentSelection])

    const onSelect = (res: any | any[]) => {
      setCurrentSelection(res)

      if (Array.isArray(res)) {
        setFieldValue(
          name,
          res.map((v) => v.value),
        )
      }
      else {
        setFieldValue(name, res?.value)
      }
    }

    return (
      <>
        <FormLabel htmlFor={name}>{label ?? name}</FormLabel>
        <HStack>
          <Select
            isMulti={isMulti}
            placeholder={placeholder ?? name}
            options={(Array.isArray(asyncOpts)
              ? asyncOpts
              : Array.isArray(opts)
                ? opts
                : []
            ).map(convertOptionsForMultiSelect)}
            value={currentSelection}
            onChange={onSelect}
            closeMenuOnSelect={false}
            chakraStyles={{
              dropdownIndicator: (provided: any) => ({
                ...provided,
                bg    : 'transparent',
                px    : 2,
                cursor: 'inherit',
              }),
              indicatorSeparator: (provided: any) => ({
                ...provided,
                display: 'none',
              }),
            }}
            styles={{
              container: (provided: any) => ({
                ...provided,
                width: '100%',
              }),
            }}
          />
          {tooltip && <InfoTooltip text={tooltip} />}

          {requiresGlobalAdmin && global.edit && (
            <IconButton
              icon={locked ? <LockIcon /> : <UnlockIcon />}
              onClick={setLocked.toggle}
              aria-label={locked ? 'locked' : 'unlocked'}
            />
          )}
        </HStack>
      </>
    )
  },
)

const convertOptionsForMultiSelect = memoize(({ value, name }: Option) => ({
  value,
  name,
  label: name,
}))
