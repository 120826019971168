import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { userId, type UserId } from './User.js'

/** Identifier type for public.user_login_ */
export type UserLoginId = string & { __brand: 'UserLoginId' }

/** Represents the table public.user_login_ */
export default interface UserLoginTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<UserLoginId, UserLoginId | undefined, UserLoginId>

  /** Database type: pg_catalog.uuid */
  userId: ColumnType<UserId, UserId, UserId>

  /** Database type: pg_catalog.timestamptz */
  date: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.text */
  ip: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  userAgent: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  credentials: ColumnType<string | null, string | null, string | null>
}

export type UserLogin = Selectable<UserLoginTable>

export type UserLoginInitializer = Insertable<UserLoginTable>

export type UserLoginMutator = Updateable<UserLoginTable>

export const userLoginId = z.string() as unknown as z.Schema<UserLoginId>

export const userLogin = z.object({
  id         : userLoginId,
  userId     : userId,
  date       : z.coerce.date().nullable().optional(),
  ip         : z.string().nullable().optional(),
  userAgent  : z.string().nullable().optional(),
  credentials: z.string().nullable().optional(),
}) as unknown as z.Schema<UserLogin>

export const userLoginInitializer = z.object({
  id         : userLoginId.optional(),
  userId     : userId,
  date       : z.coerce.date().optional().nullable().optional(),
  ip         : z.string().optional().nullable().optional(),
  userAgent  : z.string().optional().nullable().optional(),
  credentials: z.string().optional().nullable().optional(),
}) as unknown as z.Schema<UserLoginInitializer>

export const userLoginMutator = z.object({
  id         : userLoginId.optional(),
  userId     : userId.optional(),
  date       : z.coerce.date().optional().nullable().optional(),
  ip         : z.string().optional().nullable().optional(),
  userAgent  : z.string().optional().nullable().optional(),
  credentials: z.string().optional().nullable().optional(),
}) as unknown as z.Schema<UserLoginMutator>
