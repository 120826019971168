import { z } from 'zod'

/** Represents the enum public.project_role_type */
enum ProjectRoleType {
  PROJECT_ADMIN = 'PROJECT_ADMIN',
  PROJECT_EDIT = 'PROJECT_EDIT',
  PROJECT_VIEW = 'PROJECT_VIEW',
}

export default ProjectRoleType

/** Zod schema for project_role_type */
export const projectRoleType = z.enum([
  'PROJECT_ADMIN',
  'PROJECT_EDIT',
  'PROJECT_VIEW',
])
