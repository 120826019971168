import { Button, AspectRatio } from '@chakra-ui/react'

import { ErrorWrapper } from 'components/ErrorWrapper'

import { ModalContainer } from 'containers/ModalContainer'

export const YoutubeModal = () => {
  return (
    <ModalContainer
      TriggerButton={({ onClick }) => (
        <Button onClick={onClick} size='sm'>
          Youtube Tutorial
        </Button>
      )}
      modalProps={{ size: '2xl' }}
      header='Youtube Tutorial'
    >
      <ErrorWrapper>
        <AspectRatio ratio={1.76}>
          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/hXo_lEZYCSk'
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          ></iframe>
        </AspectRatio>
      </ErrorWrapper>
    </ModalContainer>
  )
}
