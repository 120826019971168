import { Box, Text } from '@chakra-ui/react'
import { FormikValues } from 'formik'

import { useOrgSelectOptions } from 'hooks/useSelectOptions'

import { validateDate } from 'lib/validateDate'

import type { FormInfo } from 'types/FormInfo'

const dotStyles = {
  display     : 'inline-block',
  height      : '20px',
  width       : '20px',
  borderRadius: '50%',
  // position: 'relative',
  top         : '5px',
}

export const projectFields: FormInfo<FormikValues>['fields'] = [
  {
    name       : 'projectName',
    type       : 'text',
    label      : 'Project Name',
    placeholder: 'Project Name',
    isRequired : true,
  },
  {
    name       : 'notes',
    type       : 'textarea',
    label      : 'Notes',
    placeholder: 'Notes',
  },
  {
    name   : 'visible',
    type   : 'checkbox',
    label  : 'Visible',
    tooltip: 'If checked, the project will be visible to users.',
  },
  {
    name : 'public',
    type : 'checkbox',
    label: 'Public',
    tooltip:
      'If checked, the project and associated sites will be visible to the public.',
  },
  // {
  //   name       : 'ts.estimatedStartDate',
  //   type       : 'mask',
  //   label      : 'Estimated Start Date',
  //   placeholder: 'YYYY-MM-DD',
  //   mask       : '####-##-##',
  //   validate   : validateDate,
  // },
  {
    name       : 'expiresAt',
    type       : 'mask',
    label      : 'Expiration Date',
    placeholder: 'YYYY-MM-DD',
    mask       : '####-##-##',
    validate   : validateDate,
  },
  {
    name            : 'organizationId',
    label           : 'Organization',
    type            : 'select',
    placeholder     : 'Select an Organization',
    useSelectOptions: useOrgSelectOptions,
  },
  {
    name : 'showRetiredSites',
    label: 'View Retired Sites',
    type : 'checkbox',
  },
  {
    type        : 'group',
    title       : 'Units',
    titleColSpan: 2,
    gridProps   : {
      templateColumns: '1fr 1fr',
      gap            : 3,
    },
    fields: [
      {
        name   : 'tempUnits',
        type   : 'radio',
        label  : 'Temperature',
        options: [
          { name: 'F', value: 'F' },
          { name: 'C', value: 'C' },
        ],
      },

      {
        name   : 'lengthUnits',
        type   : 'radio',
        label  : 'Length',
        options: [
          { name: 'M', value: 'M' },
          { name: 'CM', value: 'CM' },
          { name: 'FT', value: 'FT' },
          { name: 'IN', value: 'IN' },
        ],
      },
    ],
  },

  {
    type       : 'text',
    name       : 'googlePhotoAlbum',
    label      : 'Google Photo Album Link',
    placeholder: 'Google Photo Album Link',
    validate   : (value: string) => {
      if (!value) return
      if (!/^https:\/\/photos.app.goo.gl\/[a-zA-Z0-9]+$/.test(value)) {
        return 'Invalid Google Photo Album Link'
      }
    },
  },

  {
    type : 'checkbox',
    name : 'enableForecasting',
    label: 'Enable Forecasting',
  },

  {
    type       : 'image',
    name       : 'blueprintImageUrl',
    description: 'Limited to 5mb',
    accept     : 'image/*',
    label      : 'Blueprint Image',
  },

  {
    type       : 'number',
    name       : 'temperatureThreshold',
    label      : 'Temperature Threshold (°C)',
    description: (
      <Text>
        The temperature threshold is the number of units above the temperature.
        Values below this threshold will be cold (
        <Box bgColor='#07559B' position='relative' {...dotStyles} />
        ), values above this threshold will be hot (
        <Box bgColor='#ea841e' position='relative' {...dotStyles} />
        ). (Default: 0)
      </Text>
    ),
  },
]
