export const alphabeticalByField = (field: string) => (a: any, b: any) =>
  a[field].toLowerCase().localeCompare(b[field].toLowerCase())

export const alphabeticalByFieldCaseInsensitive =
  (field: string) => (a: any, b: any) =>
    a[field]
      .toLowerCase()
      .localeCompare(b[field].toLowerCase(), 'en', { sensitivity: 'base' })

export const alphabeticalByName = alphabeticalByField('name')
