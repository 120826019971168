import { z } from 'zod'

/** Represents the enum public.cable_connector_type_ */
enum CableConnectorType {
  XLR = 'XLR',
  RAYMO = 'RAYMO',
  BARE = 'BARE',
}

export default CableConnectorType

/** Zod schema for cable_connector_type_ */
export const cableConnectorType = z.enum(['XLR', 'RAYMO', 'BARE'])
