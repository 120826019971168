import {
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  Image,
  useColorMode,
} from '@chakra-ui/react'

import { UserWithPermissions } from '@beaded/models'

import darkTextLogo from 'assets/beadedcloud-dark-font.svg'
import lightTextLogo from 'assets/beadedcloud-white-font.svg'

import { LinkTree } from './LinkTree'

interface IOpenDrawerProps {
  children: React.ReactNode
  permissions: UserWithPermissions['permissions'] | null
  isOpen: boolean
  onClose: () => void
}

export const OpenDrawer = ({
  children,
  permissions,
  ...rest
}: IOpenDrawerProps) => {
  const { colorMode } = useColorMode()

  return (
    <Drawer variant='alwaysOpen' blockScrollOnMount={false} {...rest}>
      <DrawerContent>
        <DrawerHeader padding='0' borderBottomWidth='1px'>
          <Center w='11.5rem' p='2'>
            <Image
              h='3rem'
              src={colorMode === 'light' ? darkTextLogo : lightTextLogo}
            />
          </Center>
        </DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
        <DrawerFooter>
          <LinkTree />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
