import { z } from 'zod'

/** Represents the enum public.cable_lead_component_type_ */
enum CableLeadComponentType {
  NO_MLINK = 'NO_MLINK',
  MLINK = 'MLINK',
  BARE = 'BARE',
  XLR = 'XLR',
  RAYMO = 'RAYMO',
  NONE = 'NONE',
}

export default CableLeadComponentType

/** Zod schema for cable_lead_component_type_ */
export const cableLeadComponentType = z.enum([
  'NO_MLINK',
  'MLINK',
  'BARE',
  'XLR',
  'RAYMO',
  'NONE',
])
