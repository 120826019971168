import type { AxiosRequestConfig } from 'axios'

import {
  GlobalRoleType,
  OrganizationId,
  OrganizationRoleType,
  ProjectId,
  ProjectRoleType,
  UserId,
} from '@beaded/models'

import type { Obj, ID } from 'types/common'

import * as apiUtils from '.'
import * as perm from '.'

const url = '/permission'

export const get = (id: UserId, options: AxiosRequestConfig = {}) =>
  perm.GET(`${url}/${id}`, options)

export const getAll = (options: AxiosRequestConfig = {}) =>
  perm.GET(`${url}/`, options)

export const create = (id: ID, data: Obj) => perm.POST(`${url}/${id}`, data)

export const update = (id: ID, data: Obj) => perm.PUT(`${url}/${id}`, data)

export const remove = (id: ID, data: Obj) => perm.DELETE(`${url}/${id}`, data)

export const getProjectUsers = (id: ID, options: AxiosRequestConfig = {}) =>
  perm.GET(`${url}/project/${id}`, options)

export const getOrgUsers = (id: ID, options: AxiosRequestConfig = {}) =>
  perm.GET(`${url}/organization/${id}`, options)

export const addUserToOrg = (userId: UserId, orgId: OrganizationId) =>
  apiUtils.POST(`${url}/organization/${orgId}/${userId}`, {})

export const removeUserFromOrg = (userId: UserId, orgId: OrganizationId) =>
  apiUtils.DELETE(`${url}/organization/${orgId}/${userId}`, {})

export const addRole = (
  group: 'organization' | 'project',
  userId: UserId,
  role: OrganizationRoleType | ProjectRoleType,
  id: OrganizationId | ProjectId,
) => apiUtils.POST(`${url}/${group}/${id}/${userId}/${role}`, {})

export const addGlobalRole = (userId: UserId, role: GlobalRoleType) =>
  apiUtils.POST(`${url}/global/${userId}/${role}`, {})

export const removeRole = (
  group: 'organization' | 'project',
  userId: UserId,
  role: OrganizationRoleType | ProjectRoleType,
  id: OrganizationId | ProjectId,
) => apiUtils.DELETE(`${url}/${group}/${id}/${userId}/${role}`, {})

export const removeGlobalRole = (userId: UserId, role: GlobalRoleType) =>
  apiUtils.DELETE(`${url}/global/${userId}/${role}`, {})

export const admin = {
  getOrgUsers: (id: ID, options: AxiosRequestConfig = {}) =>
    getOrgUsers(id, options),
  getProjectUsers: (id: ID, options: AxiosRequestConfig = {}) =>
    getProjectUsers(id, options),
}
