import { useRef, useState } from 'react'

export const useDraggable = () => {
  const [isDragging, setIsDragging] = useState(false)
  const [point, setPoint] = useState({ x: 0, y: 0 })
  const [position, setPosition] = useState({ top: 0, left: 0 })
  const [hasMoved, setHasMoved] = useState(false)
  const elemRef = useRef<HTMLElement>(null)

  const onMouseDown = (e: any) => {
    e.preventDefault()
    const currentPos = e.target.getBoundingClientRect()
    setPosition({ top: currentPos.top, left: currentPos.left })
    setIsDragging(true)
    setHasMoved(true)
    setPoint({ x: e.clientX, y: e.clientY })
  }

  const onMouseMove = (e: any) => {
    e.preventDefault()
    if (isDragging) {
      const pos1 = point.x - e.clientX
      const pos2 = point.y - e.clientY
      const pos3 = e.clientX
      const pos4 = e.clientY

      if (elemRef?.current !== null) {
        const newPosition = {
          top : elemRef.current.offsetTop - pos2,
          left: elemRef.current.offsetLeft - pos1,
        }
        setPoint({ x: pos3, y: pos4 })
        setPosition(newPosition)
      }
    }
  }

  const onMouseUp = (e: any) => {
    e.preventDefault()
    setIsDragging(false)
  }

  return {
    onMouseDown,
    onMouseMove,
    onMouseUp,
    position: {
      top : `${position.top < 0 ? 0 : position.top}px`,
      left: `${position.left < 0 ? 0 : position.left}px`,
    },
    elemRef,
    hasMoved,
  }
}
