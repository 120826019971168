import { z } from 'zod'

/** Represents the enum public.cable_heatshrink_type_ */
enum CableHeatshrinkType {
  NONE = 'NONE',
  BLACK = 'BLACK',
  WHITE = 'WHITE',
}

export default CableHeatshrinkType

/** Zod schema for cable_heatshrink_type_ */
export const cableHeatshrinkType = z.enum(['NONE', 'BLACK', 'WHITE'])
