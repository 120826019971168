import { z } from 'zod'

/** Represents the enum public.organization_role_type */
enum OrganizationRoleType {
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
  ORGANIZATION_EDIT = 'ORGANIZATION_EDIT',
  ORGANIZATION_VIEW = 'ORGANIZATION_VIEW',
}

export default OrganizationRoleType

/** Zod schema for organization_role_type */
export const organizationRoleType = z.enum([
  'ORGANIZATION_ADMIN',
  'ORGANIZATION_EDIT',
  'ORGANIZATION_VIEW',
])
