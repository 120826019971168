import Joi from 'joi'

import * as common from './common.js'

export const cableTypePrices = Joi.object({
  type : Joi.string().valid('cable').required(),
  name : Joi.string().valid('standard', 'armored', 'white', 'red').required(),
  price: Joi.object({
    ft: Joi.number().required(),
    m : Joi.number().required(),
    in: Joi.number().required(),
    cm: Joi.number().required(),
    mm: Joi.number().required(),
  }).required(),
})

export const conduitPrices = Joi.object({
  type : Joi.string().valid('conduit').required(),
  name : Joi.string().valid('flex', 'pex', 'pexSand', 'hdpe').required(),
  price: Joi.object({
    ft: Joi.number().required(),
    m : Joi.number().required(),
    in: Joi.number().required(),
    cm: Joi.number().required(),
    mm: Joi.number().required(),
  }).required(),
})

export const cableConnectorPrices = Joi.object({
  type : Joi.string().valid('connector').required(),
  name : Joi.string().valid('xlr', 'raymo', 'bare').required(),
  price: Joi.number().required(),
})

export const cableHeatshrinkPrices = Joi.object({
  type : Joi.string().valid('heatshrink').required(),
  name : Joi.string().valid('black', 'white', 'none').required(),
  price: Joi.number().required(),
})

export const cablePackagingPrices = Joi.object({
  type: Joi.string().valid('packaging').required(),
  name: Joi.string()
    .valid(
      'Cardboard Box',
      'Pallet',
      'Action Packer',
      'Wooden Spool on Pallet',
      'Plastic Spool on Pallet',
    )
    .required(),
  price: Joi.number().required(),
})

export const fittingsPrices = Joi.object({
  type : Joi.string().valid('fittings').required(),
  name : Joi.string().valid('pex', 'flex').required(),
  price: Joi.number().required(),
})

export const sensorPrice = Joi.object({
  type : Joi.string().valid('sensor').required(),
  name : Joi.string().valid('sensor').required(),
  price: Joi.number().required(),
})

export const cablePrices = Joi.object({
  baseCost: Joi.object({
    low : Joi.number().required(),
    high: Joi.number().required(),
  }).required(),
  breakpoints: Joi.object({
    mm: Joi.number().required(),
    cm: Joi.number().required(),
    m : Joi.number().required(),
    in: Joi.number().required(),
    ft: Joi.number().required(),
  }).required(),
  lengthPrices: Joi.array().items(cableTypePrices, conduitPrices).required(),
  unitPrices  : Joi.array()
    .items(
      cableConnectorPrices,
      cableHeatshrinkPrices,
      cablePackagingPrices,
      fittingsPrices,
      sensorPrice,
    )
    .required(),
  stainlessPrice: Joi.object({
    a: Joi.number().required(),
    b: Joi.number().required(),
    c: Joi.number().required(),
    d: Joi.number().required(),
  }).required(),
  ts: {
    ...common.TS,
    effective: Joi.date().required(),
  },
  by_user: common.BY_USER,
})
