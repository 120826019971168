import { LockIcon, UnlockIcon } from '@chakra-ui/icons'
import {
  Select as ChakraSelect,
  FormLabel,
  useBoolean,
  IconButton,
  HStack,
  Input,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import memoize from 'memoizee'
import { useState, useEffect } from 'react'

import { InfoTooltip } from 'components/InfoTooltip'

import type { Option } from 'types/FormInfo'

import { InputWrapperProps } from '../lib/FieldWrapper'

export const Select = ({
  name,
  label,
  field,
  placeholder,
  options,
  requiresGlobalAdmin = false,
  global = { view: false, edit: false, admin: false },
  filter,
  filterFlags = () => null,
  isReadOnly = false,
  useSelectOptions = () => [],
  onChange,
  keyPrefix = '',
  tooltip,
  style = {},
  useValueIfNoOptions = false,
}: InputWrapperProps) => {
  const [locked, setLocked] = useBoolean(true)
  const { setFieldValue, values } = useFormikContext()
  const asyncOpts = useSelectOptions(filter, values, filterFlags(values, name))
  const [opts, setOpts] = useState<any[]>([])

  if (typeof onChange === 'function') {
    //
  }

  useEffect(() => {
    const fetchOptions = async () => {
      if (Array.isArray(options)) {
        setOpts(options)
      }
      else if (typeof options === 'function') {
        setOpts(await options())
      }
    }

    typeof options !== 'undefined' && fetchOptions()
  }, [options])

  const reduceOptions = memoize(
    (): Array<{
      name: string
      value: any
    }> | null =>
      Array.isArray(asyncOpts?.options)
        ? asyncOpts?.options?.map(({ value, name }: Option) => ({
          name,
          value,
        }))
        : Array.isArray(opts)
          ? opts?.map(({ value, name }) => ({ name, value }))
          : null,
  )

  if (
    isReadOnly ||
    (useValueIfNoOptions === true &&
      reduceOptions()?.filter(
        ({ value }) =>
          value === field.value ||
          (!isNaN(field.value) && Number(field.value) === value),
      ).length === 0 &&
      Boolean(field.value))
  ) {
    return (
      <>
        {label !== '' && <FormLabel htmlFor={name}>{label ?? name}</FormLabel>}
        <Input
          readOnly={true}
          {...field}
          value={
            reduceOptions()?.find(({ value }) => value === field.value)?.name ??
            useValueIfNoOptions
              ? field.value
              : ''
          }
          {...style}
        />
      </>
    )
  }

  const getPlaceholder = memoize(() => {
    const opts = reduceOptions()
    if (Array.isArray(opts)) {
      if (opts.length >= 1) return placeholder
    }
  })

  return (
    <>
      {label !== '' && <FormLabel htmlFor={name}>{label ?? name}</FormLabel>}
      <HStack>
        <ChakraSelect
          placeholder={getPlaceholder()}
          {...field}
          {...(typeof onChange === 'function'
            ? {
              onChange: (e) => {
                onChange(e, setFieldValue, values)
                field.onChange(e)
              },
            }
            : {})}
          isReadOnly={isReadOnly}
          readOnly={isReadOnly}
          isDisabled={locked && requiresGlobalAdmin}
        >
          {reduceOptions()?.map(({ value, name }: any, index: number) => (
            <option
              key={`${keyPrefix}_${
                typeof value === 'object' ? JSON.stringify(value) : value
              }_${index}`}
              value={typeof value === 'object' ? JSON.stringify(value) : value}
            >
              {name ?? JSON.stringify(value)}
            </option>
          )) ?? null}
        </ChakraSelect>
        {tooltip && <InfoTooltip text={tooltip} />}

        {requiresGlobalAdmin && global.edit && (
          <IconButton
            icon={locked ? <LockIcon /> : <UnlockIcon />}
            onClick={setLocked.toggle}
            aria-label={locked ? 'locked' : 'unlocked'}
          />
        )}
      </HStack>
    </>
  )
}
