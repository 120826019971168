import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

/** Identifier type for public.cable_price_ */
export type CablePriceId = number & { __brand: 'CablePriceId' }

/** Represents the table public.cable_price_ */
export default interface CablePriceTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<CablePriceId, CablePriceId | undefined, CablePriceId>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.numeric */
  baseCostLow: ColumnType<number, number, number>

  /** Database type: pg_catalog.numeric */
  baseCostHigh: ColumnType<number, number, number>

  /** Database type: pg_catalog.numeric */
  breakpointsFt: ColumnType<number, number, number>

  /** Database type: pg_catalog.numeric */
  breakpointsIn: ColumnType<number, number, number>

  /** Database type: pg_catalog.numeric */
  breakpointsM: ColumnType<number, number, number>

  /** Database type: pg_catalog.numeric */
  breakpointsCm: ColumnType<number, number, number>

  /** Database type: pg_catalog.numeric */
  breakpointsMm: ColumnType<number, number, number>

  /** Database type: pg_catalog.jsonb[] */
  lengthPrice: ColumnType<unknown[], unknown[], unknown[]>

  /** Database type: pg_catalog.jsonb[] */
  unitPrice: ColumnType<unknown[], unknown[], unknown[]>

  /** Database type: pg_catalog.numeric */
  stainlessPriceA: ColumnType<number, number, number>

  /** Database type: pg_catalog.numeric */
  stainlessPriceB: ColumnType<number, number, number>

  /** Database type: pg_catalog.numeric */
  stainlessPriceC: ColumnType<number, number, number>

  /** Database type: pg_catalog.numeric */
  stainlessPriceD: ColumnType<number, number, number>

  /** Database type: pg_catalog.timestamptz */
  dateEffective: ColumnType<Date, Date | string, Date | string>
}

export type CablePrice = Selectable<CablePriceTable>

export type CablePriceInitializer = Insertable<CablePriceTable>

export type CablePriceMutator = Updateable<CablePriceTable>

export const cablePriceId = z.number() as unknown as z.Schema<CablePriceId>

export const cablePrice = z.object({
  id             : cablePriceId,
  createdAt      : z.coerce.date().nullable().optional(),
  createdBy      : z.string().nullable().optional(),
  updatedAt      : z.coerce.date().nullable().optional(),
  updatedBy      : z.string().nullable().optional(),
  deletedAt      : z.coerce.date().nullable().optional(),
  deletedBy      : z.string().nullable().optional(),
  baseCostLow    : z.number(),
  baseCostHigh   : z.number(),
  breakpointsFt  : z.number(),
  breakpointsIn  : z.number(),
  breakpointsM   : z.number(),
  breakpointsCm  : z.number(),
  breakpointsMm  : z.number(),
  lengthPrice    : z.unknown().array(),
  unitPrice      : z.unknown().array(),
  stainlessPriceA: z.number(),
  stainlessPriceB: z.number(),
  stainlessPriceC: z.number(),
  stainlessPriceD: z.number(),
  dateEffective  : z.coerce.date(),
}) as unknown as z.Schema<CablePrice>

export const cablePriceInitializer = z.object({
  id             : cablePriceId.optional(),
  createdAt      : z.coerce.date().optional().nullable().optional(),
  createdBy      : z.string().optional().nullable().optional(),
  updatedAt      : z.coerce.date().optional().nullable().optional(),
  updatedBy      : z.string().optional().nullable().optional(),
  deletedAt      : z.coerce.date().optional().nullable().optional(),
  deletedBy      : z.string().optional().nullable().optional(),
  baseCostLow    : z.number(),
  baseCostHigh   : z.number(),
  breakpointsFt  : z.number(),
  breakpointsIn  : z.number(),
  breakpointsM   : z.number(),
  breakpointsCm  : z.number(),
  breakpointsMm  : z.number(),
  lengthPrice    : z.unknown().array(),
  unitPrice      : z.unknown().array(),
  stainlessPriceA: z.number(),
  stainlessPriceB: z.number(),
  stainlessPriceC: z.number(),
  stainlessPriceD: z.number(),
  dateEffective  : z.coerce.date(),
}) as unknown as z.Schema<CablePriceInitializer>

export const cablePriceMutator = z.object({
  id             : cablePriceId.optional(),
  createdAt      : z.coerce.date().optional().nullable().optional(),
  createdBy      : z.string().optional().nullable().optional(),
  updatedAt      : z.coerce.date().optional().nullable().optional(),
  updatedBy      : z.string().optional().nullable().optional(),
  deletedAt      : z.coerce.date().optional().nullable().optional(),
  deletedBy      : z.string().optional().nullable().optional(),
  baseCostLow    : z.number().optional(),
  baseCostHigh   : z.number().optional(),
  breakpointsFt  : z.number().optional(),
  breakpointsIn  : z.number().optional(),
  breakpointsM   : z.number().optional(),
  breakpointsCm  : z.number().optional(),
  breakpointsMm  : z.number().optional(),
  lengthPrice    : z.unknown().array().optional(),
  unitPrice      : z.unknown().array().optional(),
  stainlessPriceA: z.number().optional(),
  stainlessPriceB: z.number().optional(),
  stainlessPriceC: z.number().optional(),
  stainlessPriceD: z.number().optional(),
  dateEffective  : z.coerce.date().optional(),
}) as unknown as z.Schema<CablePriceMutator>
