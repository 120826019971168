import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { organizationId, type OrganizationId } from './Organization.js'
import {
  organizationRoleType,
  type default as OrganizationRoleType,
} from './OrganizationRoleType.js'
import { userId, type UserId } from './User.js'

/** Represents the table public.organization_user_ */
export default interface OrganizationUserTable {
  /** Database type: pg_catalog.int4 */
  organizationId: ColumnType<OrganizationId, OrganizationId, OrganizationId>

  /** Database type: pg_catalog.uuid */
  userId: ColumnType<UserId, UserId, UserId>

  /** Database type: public.organization_role_type */
  role: ColumnType<
    OrganizationRoleType | null,
    OrganizationRoleType | null,
    OrganizationRoleType | null
  >
}

export type OrganizationUser = Selectable<OrganizationUserTable>

export type OrganizationUserInitializer = Insertable<OrganizationUserTable>

export type OrganizationUserMutator = Updateable<OrganizationUserTable>

export const organizationUser = z.object({
  organizationId: organizationId,
  userId        : userId,
  role          : organizationRoleType.nullable().optional(),
}) as unknown as z.Schema<OrganizationUser>

export const organizationUserInitializer = z.object({
  organizationId: organizationId,
  userId        : userId,
  role          : organizationRoleType.optional().nullable().optional(),
}) as unknown as z.Schema<OrganizationUserInitializer>

export const organizationUserMutator = z.object({
  organizationId: organizationId.optional(),
  userId        : userId.optional(),
  role          : organizationRoleType.optional().nullable().optional(),
}) as unknown as z.Schema<OrganizationUserMutator>
