import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

/** Represents the table public.site_label_ */
export default interface SiteLabelTable {
  /** Database type: pg_catalog.numeric */
  number: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.text */
  note: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  visible: ColumnType<boolean | null, boolean | null, boolean | null>
}

export type SiteLabel = Selectable<SiteLabelTable>

export type SiteLabelInitializer = Insertable<SiteLabelTable>

export type SiteLabelMutator = Updateable<SiteLabelTable>

export const siteLabel = z.object({
  number : z.number().nullable().optional(),
  note   : z.string().nullable().optional(),
  visible: z.boolean().nullable().optional(),
}) as unknown as z.Schema<SiteLabel>

export const siteLabelInitializer = z.object({
  number : z.number().optional().nullable().optional(),
  note   : z.string().optional().nullable().optional(),
  visible: z.boolean().optional().nullable().optional(),
}) as unknown as z.Schema<SiteLabelInitializer>

export const siteLabelMutator = z.object({
  number : z.number().optional().nullable().optional(),
  note   : z.string().optional().nullable().optional(),
  visible: z.boolean().optional().nullable().optional(),
}) as unknown as z.Schema<SiteLabelMutator>
