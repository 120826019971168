import {
  ParseLogger,
  ParseCables,
  ParseSnowDepth,
  ParseTransmissionTimestamp,
} from '../FormatHelpers/formatHelper'

export const format3to7 = (decoded: any, version: number): string => {
  let output = `Version: ${version}\n\n`

  output += ParseTransmissionTimestamp(decoded)
  output += ParseLogger(decoded)
  output += ParseSnowDepth(decoded)
  output += ParseCables(decoded)

  return output
}
