import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'

import type { Obj, IMEI } from 'types/common'

import * as apiUtils from '.'

const url = '/modem'

export const get = (imei: IMEI, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/${imei}`, merge({}, apiUtils.extrasFlags, options))

export const getAll = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({}, apiUtils.extrasFlags, options))

export const create = (data: Obj) => apiUtils.POST(`${url}/`, data)

export const createUse = (imei: IMEI, data: Obj) =>
  apiUtils.PUT(`${url}/${imei}/use`, data)

export const update = (imei: IMEI, data: Obj) =>
  apiUtils.PUT(`${url}/${imei}`, data)

export const patch = (imei: IMEI, data: Obj) =>
  apiUtils.PATCH(`${url}/${imei}`, data)

export const remove = (imei: IMEI) => apiUtils.DELETE(`${url}/${imei}`)

export const getMenuProjection = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({ params: { projection: 'menu' } }, options))

export const getModemUse = (useId: string, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/modem-use/${useId}`, options)

export const validateImei = (imei: string) =>
  apiUtils.GET(`${url}/validate`, { params: { imei } })

export const admin = {
  get: (imei: IMEI, options: AxiosRequestConfig = {}) =>
    get(imei, merge({}, apiUtils.globalFlags, options)),
  getAll: (options: AxiosRequestConfig = {}) =>
    getAll(merge({}, apiUtils.globalFlags, options)),
  getMenuProjection: (options: AxiosRequestConfig = {}) =>
    getMenuProjection(merge({}, apiUtils.globalFlags, options)),
}
