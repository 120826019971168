import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'

import { ProvideIrcContext } from 'components/IrcModule'
import { Loading } from 'components/Loading'

import BaseLayout from 'containers/Layout'

import { useTracking } from 'hooks/useTracking'

import { authRoutes, unAuthRoutes } from 'routes'

export const Router = () => {
  return <Routes />
}

const Routes = () => {
  useTracking()

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        {unAuthRoutes}
        <BaseLayout>
          <Switch>
            <ProvideIrcContext>{authRoutes}</ProvideIrcContext>
            <Route
              path='/'
              component={React.lazy(() => import('pages/NotFound'))}
            />
          </Switch>
        </BaseLayout>
      </Switch>
    </Suspense>
  )
}
