import { useCallback, useEffect, useState } from 'react'

import { useSocket } from 'hooks/useSocket'

import { TerminalHistory, TerminalHistoryItem } from './types'

declare global {
  interface Window {
    socket: any
  }
}

export const useTerminal = () => {
  // const [isConnected, setIsConnected] = useState(socket.connected);
  const [terminalRef, setDomNode] = useState<HTMLDivElement>()
  const setTerminalRef = useCallback(
    (node: HTMLDivElement) => setDomNode(node),
    [],
  )

  const [history, setHistory] = useState<TerminalHistory>([])

  // const { currentUser } = useUserContext();

  /**
   * Scroll to the bottom of the terminal when window is resized
   */
  useEffect(() => {
    const windowResizeEvent = () => {
      terminalRef?.scrollTo({
        top     : terminalRef?.scrollHeight ?? 99999,
        behavior: 'smooth',
      })
    }
    window.addEventListener('resize', windowResizeEvent)

    return () => {
      window.removeEventListener('resize', windowResizeEvent)
    }
  }, [terminalRef])

  /**
   * Scroll to the bottom of the terminal on every new history item
   */
  useEffect(() => {
    terminalRef?.scrollTo({
      top     : terminalRef?.scrollHeight ?? 99999,
      behavior: 'smooth',
    })
  }, [history, terminalRef])

  const pushToHistory = useCallback((item: TerminalHistoryItem) => {
    setHistory((old) => [...old, item])
  }, [])

  const { isConnected, socket } = useSocket(pushToHistory)

  const connect = useCallback(
    (user: string) => {
      socket.send({ command: 'CONNECT', user })
    },
    [socket],
  )

  const part = useCallback(
    (channel: string) => {
      socket.send({ command: 'PART', channel })
    },
    [socket],
  )

  const join = useCallback(
    (channel: string) => {
      socket.send({ command: 'JOIN', channel })
    },
    [socket],
  )

  const privmsg = useCallback(
    (channel: string, message: string) => {
      pushToHistory(`> ${channel} :${message}`)
      socket.send({ command: 'PRIVMSG', channel, message })
    },
    [pushToHistory, socket],
  )

  /**
   * Reset the terminal window
   */
  const resetTerminal = useCallback(() => {
    setHistory([])
  }, [])

  window.socket = socket

  return {
    history,
    pushToHistory,

    connect,
    part,
    join,
    privmsg,

    terminalRef,
    setTerminalRef,

    isConnected,

    resetTerminal,
  }
}
