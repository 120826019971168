import { AnyZodObject, z } from 'zod'

import {
  UserInitializer as UI,
  userInitializer as uI,
} from '../schemas/public/User.js'

export type ExtendedUserInitializer = UI & {
  password: string
} & {
  passwordHash?: string
  passwordSalt?: string
}

const passwordField = z.object({
  password: z.string().superRefine((val, ctx) => {
    const containsNumber = (ch: string) => /[0-9]/.test(ch)
    const containsUppercase = (ch: string) => /[A-Z]/.test(ch)
    const containsLowercase = (ch: string) => /[a-z]/.test(ch)
    const containsSpecialChar = (ch: string) =>
      /[`!@#$%^&*()_\-+=[\]{};':"\\|,.<>/?~ ]/.test(ch)

    const error =
      'password must be at least 10 characters and contain at least one of each: lowercase, uppercase, special character, number'

    if (
      val !== '' &&
      val !== undefined &&
      !(
        containsNumber(val) &&
        containsUppercase(val) &&
        containsLowercase(val) &&
        containsSpecialChar(val) &&
        val.length >= 10
      )
    ) {
      ctx.addIssue({
        code   : 'custom',
        message: error,
      })
    }

    return null
  }),
})

export const extendedUserInitializer = passwordField
  .merge(uI as unknown as AnyZodObject)
  .merge(
    z.object({
      passwordHash: z.string().optional(),
      passwordSalt: z.string().optional(),
    }) as unknown as AnyZodObject,
  ) as unknown as z.Schema<ExtendedUserInitializer>
