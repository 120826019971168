import { search } from 'api'

import { useQuery } from 'hooks/useQuery'

import { Obj } from 'types/common'

export const useSearch = (term?: string) => {
  const { data, ...rest } = useQuery(
    ['search', { search: term }],
    () => search.get(term as string),
    { enabled: !!term },
  )

  return {
    results: data as Obj,
    ...rest,
  }
}
