import type { SuperUser } from '@beaded/models'

import { useRouter } from 'hooks/useRouter'

interface QueryString {
  q?: string
  superUser?: SuperUser
}

export const useSearchParams = () => {
  const { query }: { query: QueryString } = useRouter()

  const { q: globalFilter, superUser, ...rest } = query

  return { globalFilter, superUser, ...rest }
}
