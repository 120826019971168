import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { cableId, type CableId } from './Cable.js'
import {
  cableColorType,
  type default as CableColorType,
} from './CableColorType.js'
import {
  cableConduitType,
  type default as CableConduitType,
} from './CableConduitType.js'
import {
  cableMoldColorType,
  type default as CableMoldColorType,
} from './CableMoldColorType.js'
import {
  cableMoldType,
  type default as CableMoldType,
} from './CableMoldType.js'
import {
  cableSectionType,
  type default as CableSectionType,
} from './CableSectionType.js'

/** Identifier type for public.cable_body_section_ */
export type CableBodySectionId = number & { __brand: 'CableBodySectionId' }

/** Represents the table public.cable_body_section_ */
export default interface CableBodySectionTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<
    CableBodySectionId,
    CableBodySectionId | undefined,
    CableBodySectionId
  >

  /** Database type: pg_catalog.int4 */
  cableId: ColumnType<CableId, CableId, CableId>

  /** Database type: public.cable_section_type_ */
  type: ColumnType<CableSectionType, CableSectionType, CableSectionType>

  /** Database type: pg_catalog.numeric */
  length: ColumnType<number, number, number>

  /** Database type: pg_catalog.numeric */
  position: ColumnType<number, number, number>

  /** Database type: public.cable_conduit_type_ */
  conduit: ColumnType<CableConduitType, CableConduitType, CableConduitType>

  /** Database type: pg_catalog.text */
  component: ColumnType<string, string, string>

  /** Database type: public.cable_color_type_ */
  cableColor: ColumnType<CableColorType, CableColorType, CableColorType>

  /** Database type: public.cable_mold_color_type_ */
  moldColor: ColumnType<
    CableMoldColorType,
    CableMoldColorType,
    CableMoldColorType
  >

  /** Database type: public.cable_mold_type_ */
  moldType: ColumnType<CableMoldType, CableMoldType, CableMoldType>
}

export type CableBodySection = Selectable<CableBodySectionTable>

export type CableBodySectionInitializer = Insertable<CableBodySectionTable>

export type CableBodySectionMutator = Updateable<CableBodySectionTable>

export const cableBodySectionId =
  z.number() as unknown as z.Schema<CableBodySectionId>

export const cableBodySection = z.object({
  id        : cableBodySectionId,
  cableId   : cableId,
  type      : cableSectionType,
  length    : z.number(),
  position  : z.number(),
  conduit   : cableConduitType,
  component : z.string(),
  cableColor: cableColorType,
  moldColor : cableMoldColorType,
  moldType  : cableMoldType,
}) as unknown as z.Schema<CableBodySection>

export const cableBodySectionInitializer = z.object({
  id        : cableBodySectionId.optional(),
  cableId   : cableId,
  type      : cableSectionType,
  length    : z.number(),
  position  : z.number(),
  conduit   : cableConduitType,
  component : z.string(),
  cableColor: cableColorType,
  moldColor : cableMoldColorType,
  moldType  : cableMoldType,
}) as unknown as z.Schema<CableBodySectionInitializer>

export const cableBodySectionMutator = z.object({
  id        : cableBodySectionId.optional(),
  cableId   : cableId.optional(),
  type      : cableSectionType.optional(),
  length    : z.number().optional(),
  position  : z.number().optional(),
  conduit   : cableConduitType.optional(),
  component : z.string().optional(),
  cableColor: cableColorType.optional(),
  moldColor : cableMoldColorType.optional(),
  moldType  : cableMoldType.optional(),
}) as unknown as z.Schema<CableBodySectionMutator>
