import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

import { BaseInputProps } from 'forms/helpers/types'

export type DefaultInputProps = BaseInputProps & {
  type?: string
  readonly?: boolean
  right?: string | JSX.Element | ((props: any) => JSX.Element)
  left?: string | JSX.Element | ((props: any) => JSX.Element)
}

export const DefaultInput = (props: DefaultInputProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <FormControl
      isRequired={Boolean(props?.hookformProps?.required)}
      isInvalid={Boolean(errors[props.name])}
    >
      <FormLabel htmlFor={props.name}>{props.label ?? props.name}</FormLabel>
      {props.description && <Text>{props.description}</Text>}
      <InputGroup>
        {props.left && <InputLeftAddon children={props.left} />}
        <Input
          autoComplete='off'
          hidden={props.hidden ?? false}
          readOnly={props.readonly ?? false}
          type={props.type ?? 'text'}
          placeholder={props.placeholder ?? props.name}
          {...register(props.name, props.hookformProps)}
        />
        {props.right && <InputRightAddon children={props.right} />}
      </InputGroup>
      <FormErrorMessage>{errors[props.name]?.message}</FormErrorMessage>
    </FormControl>
  )
}
