import { HStack, Spacer, Text, SkeletonText } from '@chakra-ui/react'

import { ErrorCard } from 'components/ErrorCard'
import { FormModal } from 'components/FormModal'
import {
  OrgsTable,
  OrgsTableProjection,
} from 'components/tables/OrgsTable.component'

import { Card } from 'containers/Card'

import { useTableDownload } from 'hooks/useTableDownload'

import { getTableTitle } from 'lib/getTableTitle'
import { createOrgForm } from 'lib/mutations/create/createOrg'

import type { PageCard } from 'types/PageCard'

import { useOrgsList } from './useOrgsList'

export const OrgsCard = <T extends OrgsTableProjection>({
  type,
  input,
  globalFilter,
  global,
  initialValues,
  projection,
  filename = 'orgs.csv',
}: PageCard<T>) => {
  const { isLoading, errors, orgs } = useOrgsList(
    type === 'query' ? input : null,
    {
      enabled: type === 'query',
    },
  )
  const { download, DownloadButton } = useTableDownload(filename)

  const name = getTableTitle('Org', type === 'query' ? orgs : input)

  return (
    <>
      <Card>
        <HStack pb='1rem'>
          <Text layerStyle='heading'>{name}</Text>
          <Spacer />
          {global.view && <DownloadButton />}
          {global.edit && (
            <FormModal
              formInfo={createOrgForm({ initialValues })}
              buttonText='Add Organization'
              buttonProps={{ w: '10rem' }}
            />
          )}
        </HStack>

        {isLoading ? (
          <SkeletonText noOfLines={4} spacing={4} />
        ) : errors ? (
          <ErrorCard errors={errors} />
        ) : (
          <OrgsTable
            data={type === 'query' ? orgs : input}
            globalFilter={globalFilter}
            download={download}
            projection={projection}
          />
        )}
      </Card>
    </>
  )
}
