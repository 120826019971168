import { FormikValues } from 'formik'

import type { FormInfo, AnyField } from 'types/FormInfo'

export const serialGroup: AnyField = {
  title       : 'Serial',
  type        : 'group',
  titleColSpan: 2,
  gridProps   : {
    templateColumns: '1fr 1fr',
    gap            : 3,
  },
  fields: [
    {
      name       : 'serial.model',
      type       : 'select',
      label      : 'Model',
      placeholder: 'Select model...',
      options    : [
        { value: 'D605', name: 'D605' },
        { value: 'SL', name: 'SL' },
        { value: 'D505', name: 'D505' },
        { value: 'DLB', name: 'DLB' },
      ],
      isRequired: true,
    },
    {
      name      : 'serial.id',
      type      : 'number',
      label     : 'Serial',
      isRequired: true,
    },
  ],
}

export const modemAppGroup: AnyField = {
  title       : '',
  titleColSpan: 2,
  type        : 'group',
  gridProps   : {
    templateColumns: '1fr 1fr',
    gap            : 3,
  },
  fields: [
    {
      name    : 'modem.imei',
      type    : 'text',
      label   : 'IMEI',
      validate: (value: string) => {
        if (value === undefined || value === '') return
        // imei should be 15 digits
        if (!/^\d{15}$/.test(value)) return 'IMEI should be 15 digits'
      },
    },
    {
      name : 'app',
      type : 'text',
      label: 'Firmware',
    },
  ],
}

export const modem: AnyField = {
  name    : 'modem.imei',
  type    : 'text',
  label   : 'IMEI',
  validate: (value: string) => {
    if (value === undefined || value === '') return
    // imei should be 15 digits
    if (!/^\d{15}$/.test(value)) return 'IMEI should be 15 digits'
  },
}

export const ports: AnyField = {
  name : 'ports',
  type : 'text',
  label: 'Ports',
}

export const configurationGroup: AnyField = {
  title       : '',
  titleColSpan: 2,
  type        : 'group',
  gridProps   : {
    templateColumns: '1fr 1fr',
    gap            : 3,
  },
  fields: [
    {
      name       : 'chipset',
      type       : 'select',
      label      : 'Chipset',
      placeholder: 'Select chipset...',
      options    : [
        { value: 'nordic', name: 'nordic' },
        { value: 'cypress', name: 'cypress' },
      ],
    },
    {
      name : 'configuration',
      type : 'text',
      label: 'Configuration',
    },
  ],
}

export const notes: AnyField = {
  name : 'notes',
  label: 'Notes',
  type : 'textarea',
}

export const loggerFields: FormInfo<FormikValues>['fields'] = [
  serialGroup,
  modemAppGroup,
  ports,
  configurationGroup,
  notes,
]
