import { ParseLogger, ParseCables } from '../FormatHelpers/formatHelper'

export const format2 = (decoded: any, version: number): string => {
  let output = `Version: ${version}\n\n`

  output += `If you see this message, the format function for this file is incomplete. Please report this\n
  to a developer or create a ticket in Asana and label it high priority.`
  // There are currently no v2 test files to test against. As of 2/24/24, no transmissions of files <= v6 have
  // been transmitted from Jan 1st 2024.
  return output
}
