import { z } from 'zod'

/** Represents the enum public.length_unit_type_ */
enum LengthUnitType {
  M = 'M',
  CM = 'CM',
  MM = 'MM',
  FT = 'FT',
  IN = 'IN',
}

export default LengthUnitType

/** Zod schema for length_unit_type_ */
export const lengthUnitType = z.enum(['M', 'CM', 'MM', 'FT', 'IN'])
