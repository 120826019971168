import {
  AddIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  MinusIcon,
} from '@chakra-ui/icons'
import {
  Center,
  IconButton,
  Spacer,
  Text,
  Box,
  Grid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Input,
} from '@chakra-ui/react'
import { FieldArray as FormikFieldArray, useFormikContext } from 'formik'
import type { ArrayHelpers, FormikHelpers, FormikValues } from 'formik'
import { Fragment, useState } from 'react'

import { useUserContext } from 'hooks/useUserContext'

import { getNestedField } from 'lib/getNestedField'

import type { Field as TField, FieldArray as TFieldArray } from 'types/FormInfo'

import { FieldWrapper } from '../lib/FieldWrapper'

export const CollapsibleFieldArray = (
  props: TFieldArray & { lastItem: boolean },
) => {
  const {
    values,
    setFieldValue,
  }: {
    values: any
    setFieldValue: FormikHelpers<any>['setFieldValue']
  } = useFormikContext()
  const { superUser, global } = useUserContext()

  const negateSensorLabels = () => {
    setFieldValue(
      props.name,
      getNestedField(values, props.name).map(({ number, ...rest }: any) => ({
        number: 0 - number,
        ...rest,
      })),
    )
  }

  const visible =
    typeof props.shouldBeVisible === 'function'
      ? props.shouldBeVisible(values as FormikValues)
      : typeof props.shouldBeVisible === 'boolean'
        ? props.shouldBeVisible
        : true

  return (
    <GridItem
      key={props.title}
      {...props.gridItemProps}
      {...(visible === false ? { className: 'hidden' } : {})}
    >
      <Accordion allowToggle defaultIndex={props.initiallyCollapsed ? [] : [0]}>
        <AccordionItem borderBottom='none' borderTop='none'>
          <AccordionButton>
            <Box flex='1' textAlign='left' layerStyle='heading'>
              <Text fontSize='lg'>{props.title}</Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <FormikFieldArray
              name={props.name}
              render={(arrayHelpers: any) => {
                const nestedValues = getNestedField(values, props.name)

                return (
                  <Grid>
                    {props.description && (
                      <GridItem colSpan={props.titleColSpan ?? 1} pb='0.5rem'>
                        {typeof props.description === 'string' ? (
                          <Text whiteSpace='pre-wrap'>{props.description}</Text>
                        ) : (
                          props.description
                        )}
                      </GridItem>
                    )}
                    {props.title === 'Sensor Labels' && (
                      <GridItem colSpan={props.titleColSpan ?? 1} pb='0.5rem'>
                        <Button onClick={negateSensorLabels}>
                          Negate Sensor Labels
                        </Button>
                      </GridItem>
                    )}
                    <GridItem colSpan={props.titleColSpan ?? 1}>
                      <Grid {...props.gridProps}>
                        {props.headers ? (
                          props.headers.map(
                            (header: JSX.Element | string, i: number) => (
                              <GridItem
                                key={`${props.name}_header_${i}`}
                                justifySelf='center'
                                {...props.gridItemProps}
                              >
                                {typeof header === 'string' ? (
                                  <Text>{header}</Text>
                                ) : (
                                  header
                                )}
                              </GridItem>
                            ),
                          )
                        ) : (
                          <Fragment key={`empty-header-elem`}> </Fragment>
                        )}
                        {nestedValues?.map(
                          (item: any, index: number, array: any[]) =>
                            props.fields.reduce(
                              (
                                output: any,
                                {
                                  gridItemProps,
                                  name,
                                  ...subProps
                                }:
                                  | TField
                                  | (TFieldArray & { keyPrefix: string }),
                                i: number,
                                array,
                              ) => {
                                if (props.showLineNumbers === true && i === 0)
                                  output.push(
                                    <LineNumber
                                      key={`${index}_row_label`}
                                      index={index}
                                      lineNumberLabels={props.lineNumberLabels}
                                    />,
                                  )

                                output.push(
                                  <GridItem
                                    key={`${props.name}_${index}_${name}`}
                                    {...gridItemProps}
                                  >
                                    <FieldWrapper
                                      global={global}
                                      superUser={superUser}
                                      name={`${props.name}.${index}${
                                        name ? '.' + name : ''
                                      }`}
                                      isReadOnly={
                                        subProps.type !== 'array' &&
                                        subProps?.shouldBeReadOnly?.(
                                          values[props.name][index],
                                          index,
                                          array.length,
                                        )
                                      }
                                      {...subProps}
                                    />
                                  </GridItem>,
                                )

                                if (
                                  i === array.length - 1 &&
                                  props.removeItem !== false
                                )
                                  output.push(
                                    <GridItem key={`${index}_add_move_item`}>
                                      {props.swappable && (
                                        <SwapItemButton
                                          arrayHelpers={arrayHelpers}
                                          index={index as number}
                                          neighborIndex={(index - 1) as number}
                                          variant='back'
                                        />
                                      )}
                                      <RemoveItemButton
                                        index={index}
                                        arrayHelpers={arrayHelpers}
                                      />
                                      {props.swappable && (
                                        <SwapItemButton
                                          arrayHelpers={arrayHelpers}
                                          index={index as number}
                                          neighborIndex={(index + 1) as number}
                                          variant='forward'
                                        />
                                      )}
                                    </GridItem>,
                                  )

                                return output
                              },
                              [],
                            ),
                        )}
                      </Grid>
                    </GridItem>

                    <AddItemButton
                      newItem={props?.newItem}
                      titleColSpan={props?.titleColSpan}
                      arrayHelpers={arrayHelpers}
                      newItemText={props?.newItemText}
                      duplicateItemText={props?.duplicateItemText}
                      values={getNestedField(values, props.name)}
                    />
                  </Grid>
                )
              }}
            />
            {props.lastItem && <Spacer />}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </GridItem>
  )
}

export const FieldArray = (props: TFieldArray & { lastItem: boolean }) => {
  const {
    values,
    setFieldValue,
  }: {
    values: any
    setFieldValue: FormikHelpers<any>['setFieldValue']
  } = useFormikContext()
  const { superUser, global } = useUserContext()

  const negateSensorLabels = () => {
    setFieldValue(
      props.name,
      getNestedField(values, props.name).map(({ number, ...rest }: any) => ({
        number: 0 - number,
        ...rest,
      })),
    )
  }

  const visible =
    typeof props.shouldBeVisible === 'function'
      ? props.shouldBeVisible(values as FormikValues)
      : typeof props.shouldBeVisible === 'boolean'
        ? props.shouldBeVisible
        : true

  return (
    <GridItem
      {...props.gridItemProps}
      {...(visible === false ? { className: 'hidden', display: 'none' } : {})}
    >
      <FormikFieldArray
        name={props.name}
        render={(arrayHelpers: any) => {
          const nestedValues = getNestedField(values, props.name)

          return (
            <Grid>
              <GridItem colSpan={props.titleColSpan ?? 1}>
                <Text fontSize='lg'>{props.title}</Text>
              </GridItem>
              {props.title === 'Sensor Labels' && global.edit && (
                <GridItem colSpan={props.titleColSpan ?? 1} pb='0.5rem'>
                  <Button onClick={negateSensorLabels}>
                    Negate Sensor Labels
                  </Button>
                </GridItem>
              )}
              {props.description && (
                <GridItem colSpan={props.titleColSpan ?? 1} pb='0.5rem'>
                  {typeof props.description === 'string' ? (
                    <Text whiteSpace='pre-wrap'>{props.description}</Text>
                  ) : (
                    props.description
                  )}
                </GridItem>
              )}
              <GridItem colSpan={props.titleColSpan ?? 1}>
                <Grid {...props.gridProps}>
                  {props.headers ? (
                    props.headers.map(
                      (header: JSX.Element | string, i: number) => (
                        <GridItem
                          key={`${props.name}_header_${i}`}
                          justifySelf='center'
                          {...props.gridItemProps}
                        >
                          {typeof header === 'string' ? (
                            <Text>{header}</Text>
                          ) : (
                            header
                          )}
                        </GridItem>
                      ),
                    )
                  ) : (
                    <Fragment key='empty-header'></Fragment>
                  )}
                  {nestedValues?.map((item: any, index: number, array: any[]) =>
                    props.fields.reduce(
                      (
                        output: any,
                        {
                          gridItemProps,
                          ...subProps
                        }: TField | (TFieldArray & { keyPrefix: string }),
                        i: number,
                        array,
                      ) => {
                        if (props.showLineNumbers === true && i === 0)
                          output.push(
                            <LineNumber
                              key={`${index}_row_label`}
                              index={index}
                              lineNumberLabels={props.lineNumberLabels}
                            />,
                          )

                        if (subProps.type === 'array') {
                          const visible =
                            typeof subProps.shouldBeVisible === 'function'
                              ? subProps.shouldBeVisible(values as FormikValues)
                              : typeof subProps.shouldBeVisible === 'boolean'
                                ? subProps.shouldBeVisible
                                : true

                          if (subProps.collapsible)
                            output.push(
                              <GridItem
                                key={`${props.name}_${index}_${subProps.name}`}
                                {...gridItemProps}
                                {...(visible === false
                                  ? { className: 'hidden', display: 'none' }
                                  : {})}
                              >
                                <CollapsibleFieldArray
                                  {...subProps}
                                  lastItem={i === array.length - 1}
                                  name={`${props.name}.${index}${
                                    subProps.name ? '.' + subProps.name : ''
                                  }`}
                                />
                              </GridItem>,
                            )
                          else
                            output.push(
                              <GridItem
                                key={`${props.name}_${index}_${subProps.name}`}
                                {...gridItemProps}
                                {...(visible === false
                                  ? { className: 'hidden' }
                                  : {})}
                              >
                                <FieldArray
                                  {...subProps}
                                  lastItem={i === array.length - 1}
                                  name={`${props.name}.${index}${
                                    subProps.name ? '.' + subProps.name : ''
                                  }`}
                                />
                              </GridItem>,
                            )
                        }
                        else
                          output.push(
                            <GridItem
                              key={`${props.name}_${index}_${subProps.name}`}
                              {...gridItemProps}
                            >
                              <FieldWrapper
                                global={global}
                                superUser={superUser}
                                isReadOnly={// subProps.type !== 'array' &&
                                  subProps?.shouldBeReadOnly?.(
                                    getNestedField(
                                      values,
                                    `${props.name}.${index}`,
                                    ),
                                    index,
                                    array.length,
                                  )}
                                {...subProps}
                                name={`${props.name}.${index}${
                                  subProps.name ? '.' + subProps.name : ''
                                }`}
                              />
                            </GridItem>,
                          )

                        if (
                          i === array.length - 1 &&
                          props.removeItem !== false
                        )
                          output.push(
                            <GridItem key={`${index}_add_move_item`}>
                              {props.swappable && (
                                <SwapItemButton
                                  arrayHelpers={arrayHelpers}
                                  index={index as number}
                                  neighborIndex={(index - 1) as number}
                                  variant='back'
                                />
                              )}
                              <RemoveItemButton
                                index={index}
                                arrayHelpers={arrayHelpers}
                              />
                              {props.swappable && (
                                <SwapItemButton
                                  arrayHelpers={arrayHelpers}
                                  index={index as number}
                                  neighborIndex={(index + 1) as number}
                                  variant='forward'
                                />
                              )}
                            </GridItem>,
                          )

                        return output
                      },
                      [],
                    ),
                  )}
                </Grid>
              </GridItem>

              <AddItemButton
                key='add-new-item'
                newItem={props?.newItem}
                titleColSpan={props?.titleColSpan}
                arrayHelpers={arrayHelpers}
                newItemText={props?.newItemText}
                duplicateItemText={props?.duplicateItemText}
                values={getNestedField(values, props.name)}
              />
            </Grid>
          )
        }}
      />
      {props.lastItem && <Spacer />}
    </GridItem>
  )
}

const AddItemButton = ({
  newItem,
  titleColSpan,
  arrayHelpers,
  newItemText,
  duplicateItemText,
  values,
}: {
  newItem: TFieldArray['newItem']
  titleColSpan?: TFieldArray['titleColSpan']
  arrayHelpers: ArrayHelpers
  newItemText?: string
  duplicateItemText?: string
  values?: any
}) => {
  const [input, setInput] = useState<any>(1)

  if (newItem === undefined) return null

  return (
    <GridItem colSpan={titleColSpan ?? 1} pt={3}>
      {newItemText ? (
        <Center>
          <Button
            onClick={() =>
              arrayHelpers.push(
                typeof newItem === 'function'
                  ? newItem()
                  : newItem !== undefined
                    ? newItem
                    : null,
              )
            }
          >
            {newItemText}
          </Button>
          {duplicateItemText && Array.isArray(values) && values.length >= 1 && (
            <>
              <Input
                ml={2}
                w={10}
                placeholder='#'
                value={input}
                onChange={(e) => setInput(e.target.value)}
                paddingInlineStart={1}
                paddingInlineEnd={1}
              />
              <Button
                ml={2}
                onClick={() => {
                  for (let i = 0; i < parseInt(input); i++) {
                    arrayHelpers.push(values.at(-1))
                  }
                }}
              >
                {duplicateItemText}
              </Button>
            </>
          )}
        </Center>
      ) : (
        <Center>
          <IconButton
            icon={<AddIcon />}
            aria-label='add item'
            onClick={() =>
              arrayHelpers.push(
                typeof newItem === 'function'
                  ? newItem()
                  : newItem !== undefined
                    ? newItem
                    : null,
              )
            }
          />
        </Center>
      )}
    </GridItem>
  )
}

const RemoveItemButton = ({
  arrayHelpers,
  index,
}: {
  index: number
  arrayHelpers: ArrayHelpers
}) => (
  <Center>
    <IconButton
      size='sm'
      icon={<MinusIcon />}
      aria-label='remove item'
      onClick={() => arrayHelpers.remove(index)}
      tabIndex={-1}
    />
  </Center>
)

const SwapItemButton = ({
  arrayHelpers,
  index,
  neighborIndex,
  variant,
}: {
  arrayHelpers: ArrayHelpers
  index: number
  neighborIndex: number
  variant: 'forward' | 'back'
}) => {
  const { global } = useUserContext()

  if (!global.edit) return null

  return (
    <GridItem>
      <Center>
        <IconButton
          m={1}
          size='sm'
          icon={variant === 'forward' ? <ArrowDownIcon /> : <ArrowUpIcon />}
          aria-label='swap item'
          onClick={() => arrayHelpers.swap(index, neighborIndex)}
          tabIndex={-1}
        />
      </Center>
    </GridItem>
  )
}

const LineNumber = ({
  index,
  lineNumberLabels,
}: {
  index: number
  lineNumberLabels: TFieldArray['lineNumberLabels']
}) => {
  return (
    <GridItem justifySelf='center' alignSelf='center'>
      <Text>
        {lineNumberLabels?.position === 'left' && lineNumberLabels?.text + ' '}
        {index + 1}
        {lineNumberLabels?.position === 'right' && lineNumberLabels?.text + ' '}
      </Text>
    </GridItem>
  )
}
