import Joi from 'joi'

import * as common from './common.js'

const label = Joi.object({
  note   : Joi.string().empty(''),
  number : Joi.number().empty(''),
  visible: Joi.boolean().default(true),
})

export const loggerUseEntry = Joi.object({
  id          : Joi.number().integer(),
  decode      : Joi.array().items(Joi.number()),
  modemUse    : Joi.string().guid(),
  loggerSerial: Joi.string().empty(''),
  serial      : {
    model: Joi.string(),
    id   : Joi.number().integer(),
  },
  imei  : Joi.string().length(15).regex(/^\d+$/).empty(''),
  begin : Joi.date().iso().greater('2000-01-01T00:00:00Z'),
  end   : Joi.date().iso().greater('2000-01-01T00:00:00Z'),
  labels: Joi.array().items(label),
})

export const cableUseEntry = Joi.object({
  serial: Joi.number().integer(),
  decode: Joi.array().items(Joi.number()),
  begin : Joi.date().iso().greater('2000-01-01T00:00:00Z'),
  end   : Joi.date().iso().greater('2000-01-01T00:00:00Z'),
  labels: Joi.array().items(label),
})

export const site = Joi.object({
  id                    : Joi.number().integer(),
  name                  : Joi.string(),
  visible               : Joi.boolean(),
  includes_archived_data: Joi.boolean(),
  alert                 : {
    data_age: Joi.number(),
    send    : Joi.number(),
  },
  cable: {
    serial : Joi.number().integer().empty(''),
    airtemp: Joi.number().integer().empty(''),
  },
  data: {
    format: Joi.string().valid(
      'v1',
      'v2',
      'v3',
      'v4',
      'v5',
      'v6',
      'v7',
      'v11',
      'csi',
    ),
    labels: Joi.array().items(label),
    units : common.UNITS,
    type  : Joi.string().empty(''),
  },
  display: {
    order       : Joi.number().integer(),
    gzoom       : Joi.number().integer(),
    plot_snow   : Joi.boolean(),
    plot_airtemp: Joi.boolean(),
  },
  geo: {
    lat      : Joi.number(),
    lon      : Joi.number(),
    elevation: Joi.number(),
  },
  imei  : Joi.string().length(15).regex(/^\d+$/).empty(''),
  logger: {
    use_id: Joi.number().integer(),
  },
  msg_size: Joi.number(),
  notes   : Joi.string().empty(''),
  details : Joi.string().empty(''),
  numdata : Joi.number(),
  project : {
    projectid: Joi.string().empty(''),
    id       : Joi.number().integer().required(),
  },
  sensor: {
    at     : Joi.number().empty(''),
    decode : Joi.array().items(Joi.number()),
    height : Joi.number().empty(''),
    offsets: Joi.string().empty(''),
  },
  status: Joi.string().empty(''),
  ts    : {
    eff        : Joi.date().iso().greater('2000-01-01T00:00:00Z'),
    iridium_exp: Joi.date().iso().greater('2000-01-01T00:00:00Z'),
    ...common.TS,
  },
  web: {
    id  : Joi.string().empty(''),
    uuid: Joi.string().guid().empty(''),
  },
  by_user: common.BY_USER,
  v      : Joi.number().integer(),
  use    : {
    logger: Joi.array().items(loggerUseEntry),
    cable : Joi.array().items(cableUseEntry),
  },

  labelSettings: Joi.object({
    showCableSerials: Joi.boolean(),
    sortBy          : Joi.string().valid('depth', 'serial'),
  }),

  readingsVersion: Joi.number().integer(),

  useRecordDate: Joi.boolean().default(false),

  showSdmLines: Joi.boolean().default(false),

  lineGapSize: Joi.number().integer().default(25).empty(''),
})
