import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { cableId, type CableId } from './Cable.js'
import {
  cableColorType,
  type default as CableColorType,
} from './CableColorType.js'
import {
  cableConduitType,
  type default as CableConduitType,
} from './CableConduitType.js'
import {
  cableLeadComponentType,
  type default as CableLeadComponentType,
} from './CableLeadComponentType.js'
import {
  cableSectionType,
  type default as CableSectionType,
} from './CableSectionType.js'

/** Identifier type for public.cable_lead_section_ */
export type CableLeadSectionId = number & { __brand: 'CableLeadSectionId' }

/** Represents the table public.cable_lead_section_ */
export default interface CableLeadSectionTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<
    CableLeadSectionId,
    CableLeadSectionId | undefined,
    CableLeadSectionId
  >

  /** Database type: pg_catalog.int4 */
  cableId: ColumnType<CableId, CableId, CableId>

  /** Database type: public.cable_section_type_ */
  type: ColumnType<CableSectionType, CableSectionType, CableSectionType>

  /** Database type: pg_catalog.numeric */
  length: ColumnType<number, number, number>

  /** Database type: pg_catalog.numeric */
  position: ColumnType<number, number, number>

  /** Database type: public.cable_conduit_type_ */
  conduit: ColumnType<CableConduitType, CableConduitType, CableConduitType>

  /** Database type: public.cable_lead_component_type_ */
  component: ColumnType<
    CableLeadComponentType,
    CableLeadComponentType,
    CableLeadComponentType
  >

  /** Database type: public.cable_color_type_ */
  cableColor: ColumnType<CableColorType, CableColorType, CableColorType>
}

export type CableLeadSection = Selectable<CableLeadSectionTable>

export type CableLeadSectionInitializer = Insertable<CableLeadSectionTable>

export type CableLeadSectionMutator = Updateable<CableLeadSectionTable>

export const cableLeadSectionId =
  z.number() as unknown as z.Schema<CableLeadSectionId>

export const cableLeadSection = z.object({
  id        : cableLeadSectionId,
  cableId   : cableId,
  type      : cableSectionType,
  length    : z.number(),
  position  : z.number(),
  conduit   : cableConduitType,
  component : cableLeadComponentType,
  cableColor: cableColorType,
}) as unknown as z.Schema<CableLeadSection>

export const cableLeadSectionInitializer = z.object({
  id        : cableLeadSectionId.optional(),
  cableId   : cableId,
  type      : cableSectionType,
  length    : z.number(),
  position  : z.number(),
  conduit   : cableConduitType,
  component : cableLeadComponentType,
  cableColor: cableColorType,
}) as unknown as z.Schema<CableLeadSectionInitializer>

export const cableLeadSectionMutator = z.object({
  id        : cableLeadSectionId.optional(),
  cableId   : cableId.optional(),
  type      : cableSectionType.optional(),
  length    : z.number().optional(),
  position  : z.number().optional(),
  conduit   : cableConduitType.optional(),
  component : cableLeadComponentType.optional(),
  cableColor: cableColorType.optional(),
}) as unknown as z.Schema<CableLeadSectionMutator>
