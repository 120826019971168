import Beaded from './beaded.js'
import {
  assemble_record_date,
  decadeOf,
  get_logger_voltage,
  get_air_temp,
  filterReadings,
  applyOffsets,
} from './utils.js'

export const decode3to7 = async function (opts) {
  const doc = new Beaded()
  doc.set('data.format', opts.data.format)
  doc.set('data.packet', opts.data.packet)

  if (!opts?.data?.packet) return doc
  const raw = Buffer.from(opts.data.packet)

  let byte = raw.readUInt8(0) // byte 1
  const air_temp_included = (byte & 0b00010000) >> 4 // eslint-disable-line no-unused-vars
  const snow_depth_included = (byte & 0b00100000) >> 5
  const sensor_bytes_included = (byte & 0b11000000) >> 6

  if (raw.length < 6) return doc // test / garbage data

  switch (doc.data.format) {
    case 3:
    case 6:
    case 7:
      if (byte & 0b01000000) {
        // console.error(`reserved flag bit 7 non-zero: ${(byte & 0b01000000) >> 6}`)
      }
      if (byte & 0b10000000) {
        // console.error(`reserved flag bit 7 non-zero: ${(byte & 0b10000000) >> 7}`)
      }
      break
    case 4:
    case 5:
      doc.is_calibrated = (byte & 0b01000000) >> 6
      // assert.equal(       (byte & 0b10000000), 0)
      break
    default:
      throw new Error(
        `version ${doc.data.format} not supported by 'decode3to7'`,
      )
  }

  byte = raw.readUInt8(1) // byte 2
  doc.set('ts.year', +(byte & 0b00001111) + decadeOf(opts))
  doc.set('ts.month', (byte & 0b11110000) >> 4)

  byte = raw.readUInt8(2) // byte 3
  doc.set('ts.day', byte & 0b00011111)
  let hour = (byte & 0b11100000) >> 5

  byte = raw.readUInt8(3) // byte 4
  doc.set('ts.minute', (byte & 0b11111100) >> 2)
  doc.set('ts.hour', (hour += (byte & 0b00000011) << 3))
  assemble_record_date(doc)

  // We prefer email dates when showing data to the user,
  // so we don't need to abort if the record date is missing.
  // if (!doc.ts.record) return

  doc.set('logger.temp', raw.readInt8(4)) // byte 5

  // byte = raw.readUInt8(5)                   // byte 6
  doc.set(
    'logger.battery',
    get_logger_voltage(doc.data.format, raw.readUInt8(5)),
  )

  if (raw.length > 6) {
    byte = raw.readUInt8(6) & 0b00001111 // byte 7
    if (byte && byte !== 1) doc.set('modem.retries', byte)

    if (doc.data.format >= 6 && byte & 0b11110000) {
      doc.set('soos.tac1', (byte & 0b10000000) >> 7)
      doc.set('soos.tac2', (byte & 0b01000000) >> 6)
      doc.set('soos.tac3', (byte & 0b00100000) >> 5)
      doc.set('soos.tac4', (byte & 0b00010000) >> 4)
      console.error(`v${doc.data.format} soos!`)
    }
  }

  let position = 7

  if (doc.data.format > 3 && snow_depth_included && raw.length > 8) {
    doc.set('snow_depth', raw.readUInt16LE(position))
    doc.set('snow_depth_raw', raw.readUInt16LE(position))
    doc.set('site.snow_depth', raw.readUInt16LE(position))
    if (opts?.sensor?.height) {
      doc.set(
        'snow_depth',
        +(opts.sensor.height - doc.site.snow_depth).toFixed(4),
      )
      doc.set(
        'site.snow_depth',
        +(opts.sensor.height - doc.site.snow_depth).toFixed(4),
      )
    }
    // console.log(`v${doc.data.format} snow_depth: ${doc.site.snow_depth} in doc ${doc.record.id}`)
    position += 2
  }

  get_air_temp(doc, raw, position)

  let tacNum = 1
  switch (doc.data.format) {
    case 3:
    case 4:
    case 5:
      while (position < raw.length - 1) {
        doc.push('readings', raw.readInt16LE(position) / 100)
        doc.push('raw', raw.readInt16LE(position) / 100)
        position += 2
      }
      break
    case 6:
    case 7:
      while (position < raw.length - 1) {
        let onewire_id
        if (sensor_bytes_included > 0) {
          const onewire_parts = []
          for (let i = 0; i < sensor_bytes_included; i++) {
            onewire_parts.push(raw.readUInt8(position))
            position++
          }
          onewire_id = onewire_parts.reduce((acc, val) => {
            const hex = val.toString(16).padStart(2, '0')
            return acc + hex
          }, '')
        }

        byte = raw.readUInt8(position)
        position++

        const sensors = byte & 0b01111111

        const cableReadings = []

        // 'sensors' can have wildly high values, hence the extra position
        // check to avoid readings off the end of the byte stream
        for (let i = 0; i < sensors && position < raw.length - 1; i++) {
          const val = raw.readInt16LE(position) / 100

          doc.push('readings', val)
          doc.push('raw', val)
          cableReadings.push(val)

          if (opts?.sensor?.at === tacNum) {
            doc.set('site.air_temp', val)
          }

          position += 2
          tacNum++
        }

        if (onewire_id) {
          doc.push('cable', { onewire_id, readings: cableReadings })
        }
        else {
          doc.push('cable', { readings: cableReadings })
        }
      }
      break
    default:
      throw new Error(`invalid version ${doc.data.format}`)
  }

  filterReadings(doc, opts)
  applyOffsets(doc, opts)

  return doc
}
