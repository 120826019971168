import { Spinner, Center } from '@chakra-ui/react'
import {
  chakra,
  forwardRef,
  keyframes,
  omitThemingProps,
} from '@chakra-ui/system'

import baseLogo from 'assets/border-base-logo.svg'

import { Card } from 'containers/Card'

export const Loading = ({ size = 'xl', variant = 'beadedstream', ...rest }) => (
  <Center
    style={{ width: 'max-content' }}
    layerStyle='centeredLoading'
    left={{ base: '50vw', xl: 'calc(50vw + 160px)' }}
    {...rest}
  >
    {variant === 'beadedstream' ? (
      <BeadedstreamSpinner />
    ) : (
      <Spinner size={size} />
    )}
  </Center>
)

export const LoadingCard = ({
  size = 'xl',
  variant = 'beadedstream',
  ...rest
}) => (
  <Card style={{ width: 'max-content' }}>
    <Loading variant={variant} size={size} {...rest} />
  </Card>
)

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '16.6%': {
    transform: 'rotate(60deg)',
  },
  '33.3%': {
    transform: 'rotate(120deg)',
  },
  '50%': {
    transform: 'rotate(180deg)',
  },
  '66.6%': {
    transform: 'rotate(240deg)',
  },
  '83.3%': {
    transform: 'rotate(300deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
})

export const BeadedstreamSpinner = forwardRef((props: any, ref) => {
  const {
    label = 'Loading...',
    speed = '6s',
    emptyColor = 'transparent',
    ...rest
  } = omitThemingProps(props)

  const spinnerStyles = {
    display          : 'inline-block',
    borderColor      : 'currentColor',
    borderBottomColor: emptyColor,
    borderLeftColor  : emptyColor,
    animation        : `${spin} ${speed} infinite cubic-bezier(0,0.2,1,-0.5)`,
    width            : '5rem',
    height           : '5rem',
    margin           : '0.5rem',
    marginLeft       : '-50%',
  }

  return (
    <chakra.div ref={ref} __css={spinnerStyles} {...rest}>
      <chakra.img src={baseLogo} alt={label} width='8rem' />
    </chakra.div>
  )
})
