import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import {
  lengthUnitType,
  type default as LengthUnitType,
} from './LengthUnitType.js'
import { tempUnitType, type default as TempUnitType } from './TempUnitType.js'

/** Identifier type for public.project_ */
export type ProjectId = number & { __brand: 'ProjectId' }

/** Represents the table public.project_ */
export default interface ProjectTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<ProjectId, ProjectId | undefined, ProjectId>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  projectName: ColumnType<string, string, string>

  /** Database type: pg_catalog.text */
  slug: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  notes: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  asanaUrl: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  showRetiredSites: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.text */
  timezone: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  graphLogo: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  sidebarLogo: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  googlePhotoAlbum: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  public: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: public.length_unit_type_ */
  lengthUnits: ColumnType<
    LengthUnitType | null,
    LengthUnitType | null,
    LengthUnitType | null
  >

  /** Database type: public.temp_unit_type_ */
  tempUnits: ColumnType<
    TempUnitType | null,
    TempUnitType | null,
    TempUnitType | null
  >

  /** Database type: pg_catalog.bool */
  visible: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.bool */
  enableForecasting: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.text */
  blueprintImageUrl: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.numeric */
  temperatureThreshold: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.timestamptz */
  expiresAt: ColumnType<Date | null, Date | string | null, Date | string | null>
}

export type Project = Selectable<ProjectTable>

export type ProjectInitializer = Insertable<ProjectTable>

export type ProjectMutator = Updateable<ProjectTable>

export const projectId = z.number() as unknown as z.Schema<ProjectId>

export const project = z.object({
  id                  : projectId,
  createdAt           : z.coerce.date().nullable().optional(),
  createdBy           : z.string().nullable().optional(),
  updatedAt           : z.coerce.date().nullable().optional(),
  updatedBy           : z.string().nullable().optional(),
  deletedAt           : z.coerce.date().nullable().optional(),
  deletedBy           : z.string().nullable().optional(),
  projectName         : z.string(),
  slug                : z.string().nullable().optional(),
  notes               : z.string().nullable().optional(),
  asanaUrl            : z.string().nullable().optional(),
  showRetiredSites    : z.boolean().nullable().optional(),
  timezone            : z.string().nullable().optional(),
  graphLogo           : z.string().nullable().optional(),
  sidebarLogo         : z.string().nullable().optional(),
  googlePhotoAlbum    : z.string().nullable().optional(),
  public              : z.boolean().nullable().optional(),
  lengthUnits         : lengthUnitType.nullable().optional(),
  tempUnits           : tempUnitType.nullable().optional(),
  visible             : z.boolean().nullable().optional(),
  enableForecasting   : z.boolean().nullable().optional(),
  blueprintImageUrl   : z.string().nullable().optional(),
  temperatureThreshold: z.number().nullable().optional(),
  expiresAt           : z.coerce.date().nullable().optional(),
}) as unknown as z.Schema<Project>

export const projectInitializer = z.object({
  id                  : projectId.optional(),
  createdAt           : z.coerce.date().optional().nullable().optional(),
  createdBy           : z.string().optional().nullable().optional(),
  updatedAt           : z.coerce.date().optional().nullable().optional(),
  updatedBy           : z.string().optional().nullable().optional(),
  deletedAt           : z.coerce.date().optional().nullable().optional(),
  deletedBy           : z.string().optional().nullable().optional(),
  projectName         : z.string(),
  slug                : z.string().optional().nullable().optional(),
  notes               : z.string().optional().nullable().optional(),
  asanaUrl            : z.string().optional().nullable().optional(),
  showRetiredSites    : z.boolean().optional().nullable().optional(),
  timezone            : z.string().optional().nullable().optional(),
  graphLogo           : z.string().optional().nullable().optional(),
  sidebarLogo         : z.string().optional().nullable().optional(),
  googlePhotoAlbum    : z.string().optional().nullable().optional(),
  public              : z.boolean().optional().nullable().optional(),
  lengthUnits         : lengthUnitType.optional().nullable().optional(),
  tempUnits           : tempUnitType.optional().nullable().optional(),
  visible             : z.boolean().optional().nullable().optional(),
  enableForecasting   : z.boolean().optional().nullable().optional(),
  blueprintImageUrl   : z.string().optional().nullable().optional(),
  temperatureThreshold: z.number().optional().nullable().optional(),
  expiresAt           : z.coerce.date().optional().nullable().optional(),
}) as unknown as z.Schema<ProjectInitializer>

export const projectMutator = z.object({
  id                  : projectId.optional(),
  createdAt           : z.coerce.date().optional().nullable().optional(),
  createdBy           : z.string().optional().nullable().optional(),
  updatedAt           : z.coerce.date().optional().nullable().optional(),
  updatedBy           : z.string().optional().nullable().optional(),
  deletedAt           : z.coerce.date().optional().nullable().optional(),
  deletedBy           : z.string().optional().nullable().optional(),
  projectName         : z.string().optional(),
  slug                : z.string().optional().nullable().optional(),
  notes               : z.string().optional().nullable().optional(),
  asanaUrl            : z.string().optional().nullable().optional(),
  showRetiredSites    : z.boolean().optional().nullable().optional(),
  timezone            : z.string().optional().nullable().optional(),
  graphLogo           : z.string().optional().nullable().optional(),
  sidebarLogo         : z.string().optional().nullable().optional(),
  googlePhotoAlbum    : z.string().optional().nullable().optional(),
  public              : z.boolean().optional().nullable().optional(),
  lengthUnits         : lengthUnitType.optional().nullable().optional(),
  tempUnits           : tempUnitType.optional().nullable().optional(),
  visible             : z.boolean().optional().nullable().optional(),
  enableForecasting   : z.boolean().optional().nullable().optional(),
  blueprintImageUrl   : z.string().optional().nullable().optional(),
  temperatureThreshold: z.number().optional().nullable().optional(),
  expiresAt           : z.coerce.date().optional().nullable().optional(),
}) as unknown as z.Schema<ProjectMutator>
