import { useOS } from 'hooks/useOS'

interface ExtraOpts {
  preventDefault?: boolean
  preventIfBody?: boolean
  skipHandler?: (e: KeyboardEvent) => boolean
}

export const useKeyboardShortcut = (
  key: string | string[],
  handler: Function,
  opts: ExtraOpts = {},
) => {
  const os = useOS()

  const alt = Array.isArray(key) && key.includes('alt')
  const ctrl = Array.isArray(key) && key.includes('ctrl')
  const shift = Array.isArray(key) && key.includes('shift')

  const actualKey =
    (Array.isArray(key) &&
      key.filter((k) => k !== 'alt' && k !== 'ctrl' && k !== 'shift')[0]) ||
    key

  const checkKey = (e: KeyboardEvent) => {
    if (opts?.skipHandler?.(e) === true) return

    if (
      alt === e.altKey &&
      ctrl === ((os === 'macos' && e.metaKey) || e.ctrlKey) &&
      shift === e.shiftKey &&
      e.key === actualKey
    ) {
      if (opts?.preventDefault) e.preventDefault()
      return true
    }
  }

  const eventHandler = (e: KeyboardEvent) => {
    if (checkKey(e)) handler()
  }

  return eventHandler
}
