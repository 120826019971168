import {
  Box,
  Button,
  Code,
  useColorModeValue,
  useClipboard,
} from '@chakra-ui/react'

interface ICodeBlockProps {
  code: string
}

export const CodeBlock = ({ code }: ICodeBlockProps) => {
  const { hasCopied, onCopy } = useClipboard(code)
  const copyButtonStyle = useColorModeValue(
    { bgColor: 'gray.300', color: 'gray.800' },
    { bgColor: 'gray.700', color: 'white' },
  )

  return (
    <Box position='relative' px='0' py='0'>
      <Box rounded='8px' my='0' overflow='hidden'>
        <Code
          style={{ whiteSpace: 'pre-wrap', width: '100%' }}
          position='relative'
          padding='5'
        >
          {code}
        </Code>
      </Box>
      <Button
        size='sm'
        position='absolute'
        textTransform='uppercase'
        fontSize='xs'
        height='24px'
        top='4'
        right='1.25em'
        zIndex='1'
        {...copyButtonStyle}
        onClick={onCopy}
      >
        {hasCopied ? 'copied' : 'copy'}
      </Button>
    </Box>
  )
}
