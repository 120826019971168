import { FormikValues } from 'formik'

type Label = {
  number?: number
  note?: string
}

const shouldRenderNote = (label: Label): boolean => {
  return label.note !== undefined && label.note !== ''
}

const shouldRenderNumber = (label: Label): boolean => {
  return label.number !== undefined && `${label.number}` !== ''
}

export const setDemoSensorLabel = (
  e: any,
  setFieldValue: Function,
  values: FormikValues,
) => {
  const value = e?.target?.value ?? e
  const name = e?.target?.name ?? 'units'

  const lengthUnits = name === 'units' ? value : values.data.units.length

  // const [, obj, objIdx, , labelIdx] = name.split('.');
  // const prefix = `use.${obj}.${objIdx}.labels.${labelIdx}`;
  // const label: Label = values?.use?.[obj]?.[objIdx]?.labels?.[labelIdx] ?? {};
  if (name === 'units') {
    for (const obj of ['logger', 'cable']) {
      for (const objIdx in values?.use?.[obj]) {
        for (const [idx, label] of Object.entries<Label>(
          values?.use?.[obj]?.[objIdx]?.labels ?? [],
        )) {
          const prefix = `use.${obj}.${objIdx}.labels.${idx}`

          if (name.startsWith(prefix)) {
            if (name.endsWith('number') && shouldRenderNote(label)) {
              setFieldValue(
                `${prefix}.render`,
                `${
                  name.endsWith('number') ? value : label.number
                } ${lengthUnits} (${
                  name.endsWith('note') ? value : label.note
                })`,
              )
            }
            else if (name.endsWith('note') && shouldRenderNumber(label)) {
              setFieldValue(
                `${prefix}.render`,
                `${
                  name.endsWith('number') ? value : label.number
                } ${lengthUnits} (${
                  name.endsWith('note') ? value : label.note
                })`,
              )
            }
            else if (name.endsWith('number')) {
              setFieldValue(`${prefix}.render`, `${value} ${lengthUnits}`)
            }
            else if (name.endsWith('note')) {
              setFieldValue(`${prefix}.render`, `${value}`)
            }
          }
          else {
            if (shouldRenderNumber(label) && shouldRenderNote(label)) {
              setFieldValue(
                `${prefix}.render`,
                `${label.number} ${lengthUnits} (${label.note})`,
              )
            }
            else if (shouldRenderNumber(label)) {
              setFieldValue(
                `${prefix}.render`,
                `${label.number} ${lengthUnits}`,
              )
            }
            else if (shouldRenderNote(label)) {
              setFieldValue(`${prefix}.render`, `${label.note}`)
            }
          }

          // if (shouldRenderNumber(label) && shouldRenderNote(label)) {
          //   setFieldValue(
          //     `${prefix}.render`,
          //     `${label.number} ${lengthUnits} (${label.note})`
          //   );
          // } else if (shouldRenderNumber(label)) {
          //   setFieldValue(`${prefix}.render`, `${label.number} ${lengthUnits}`);
          // } else if (shouldRenderNote(label)) {
          //   setFieldValue(`${prefix}.render`, `${label.note}`);
          // }
        }
      }
    }

    // for (const [idx, label] of Object.entries<Label>(
    //   values?.use?.[obj]?.[objIdx]?.labels ?? []
    // )) {
    //   const prefix = `data.labels.${idx}`;

    //   if (name.startsWith(prefix)) {
    //     if (name.endsWith('number') && shouldRenderNote(label)) {
    //       setFieldValue(
    //         `${prefix}.render`,
    //         `${
    //           name.endsWith('number') ? value : label.number
    //         } ${lengthUnits} (${name.endsWith('note') ? value : label.note})`
    //       );
    //     } else if (name.endsWith('note') && shouldRenderNumber(label)) {
    //       setFieldValue(
    //         `${prefix}.render`,
    //         `${
    //           name.endsWith('number') ? value : label.number
    //         } ${lengthUnits} (${name.endsWith('note') ? value : label.note})`
    //       );
    //     } else if (name.endsWith('number')) {
    //       setFieldValue(`${prefix}.render`, `${value} ${lengthUnits}`);
    //     } else if (name.endsWith('note')) {
    //       setFieldValue(`${prefix}.render`, `${value}`);
    //     }
    //   } else {
    //     if (shouldRenderNumber(label) && shouldRenderNote(label)) {
    //       setFieldValue(
    //         `${prefix}.render`,
    //         `${label.number} ${lengthUnits} (${label.note})`
    //       );
    //     } else if (shouldRenderNumber(label)) {
    //       setFieldValue(`${prefix}.render`, `${label.number} ${lengthUnits}`);
    //     } else if (shouldRenderNote(label)) {
    //       setFieldValue(`${prefix}.render`, `${label.note}`);
    //     }
    //   }
    // }
  }
  else {
    const [, obj, objIdx, , labelIdx] = name.split('.')
    const prefix = `use.${obj}.${objIdx}.labels.${labelIdx}`
    const label: Label = values?.use?.[obj]?.[objIdx]?.labels?.[labelIdx] ?? {}

    if (name.endsWith('number') && shouldRenderNote(label)) {
      setFieldValue(
        `${prefix}.render`,
        shouldRenderNumber({ number: value })
          ? `${value} ${lengthUnits} (${label.note})`
          : label.note,
      )
    }
    else if (name.endsWith('note') && shouldRenderNumber(label)) {
      setFieldValue(
        `${prefix}.render`,
        `${label.number} ${lengthUnits}` +
          (shouldRenderNote({ note: value }) ? ` (${value})` : ''),
      )
    }
    else if (name.endsWith('number')) {
      setFieldValue(`${prefix}.render`, `${value} ${lengthUnits}`)
    }
    else if (name.endsWith('note') && shouldRenderNote({ note: value })) {
      setFieldValue(`${prefix}.render`, `${value}`)
    }
    else {
      setFieldValue(`${prefix}.render`, '')
    }
  }
}
