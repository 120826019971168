import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import { Global } from '@emotion/react'

import backgroundDark from 'assets/background-dark.png'
import backgroundLight from 'assets/background-light.png'
import eot from 'assets/fonts/beaded.eot'
import svg from 'assets/fonts/beaded.svg'
import ttf from 'assets/fonts/beaded.ttf'
import woff from 'assets/fonts/beaded.woff'

export const Fonts = () => (
  <Global
    styles={`
		@font-face {
			font-family: "beaded";
			src:url(${eot});
			src:url("assets/fonts/beaded.eot?#iefix") format("embedded-opentype"),
				url(${woff}) format("woff"),
				url(${ttf}) format("truetype"),
				url(${svg}#beaded) format("svg");
			font-weight: normal;
			font-style: normal;
		}
	`}
  />
)

const colors = {
  brand: {
    100: '#1772dd',
    200: '#07559B',
  },
}

const styles = {
  global: (props: any) => ({
    '@page': {
      size: 'auto',
      // marginTop: '1.5cm',
      // marginBottom: '1.5cm',
      // marginLeft: '1.5cm',
      // marginRight: '1.5cm',
    },
    body: {
      bg     : mode('gray.50', 'gray.800')(props),
      bgImage: mode(
        `url('${backgroundLight}')`,
        `url('${backgroundDark}')`,
      )(props),
      bgSize             : 'cover',
      bgAttachment       : 'fixed',
      bgRepeat           : 'no-repeat',
      bgPosition         : 'center',
      overscrollBehaviorY: 'none',
      '@media print'     : {
        bg: 'white',
      },
    },
    '::-webkit-scrollbar'      : { height: '10px' },
    '::-webkit-scrollbar-track': {
      background: mode('gray.50', 'gray.800')(props),
      boxShadow : mode(
        'inset 0px 4rem 0 0 white, inset 0 calc(4rem + 4px) rgb(0 0 0 / 6%)',
        'inset 0px 4rem 0 0 #2D3748, inset 0 calc(4rem + 4px) rgb(0 0 0 / 6%)',
      )(props),
      border: '0px solid transparent',
    },
    '::-webkit-scrollbar-thumb': {
      background  : 'brand.200',
      borderRadius: '10px',
    },
    '.markdown *': {
      all: 'revert',
    },
    '#sentry-feedback': {
      '--background'             : mode('#F7FAFC', '#2D3748')(props),
      '--background-hover'       : mode('#E2E8F0', '#1A202C')(props),
      '--foreground'             : mode('#1A202C', '#F7FAFC')(props),
      '--border'                 : mode('0.5px solid #4A5568', '0.5px solid #E2E8F0')(props),
      '--submit-background'      : mode('#1A202C', '#F7FAFC')(props),
      '--submit-background-hover': mode('#1A202C', '#F7FAFC')(props),
      '--submit-border'          : mode('#1A202C', '#F7FAFC')(props),
      '--submit-outline-focus'   : mode('#1A202C', '#F7FAFC')(props),
      '--submit-foreground'      : mode('#F7FAFC', '#1A202C')(props),
      '--submit-foreground-hover': mode('#F7FAFC', '#1A202C')(props),
      '--cancel-background'      : mode('#F7FAFC', '#2D3748')(props),
      '--cancel-background-hover': mode('#F7FAFC', '#2D3748')(props),
      '--input-background'       : mode('#F7FAFC', '#2D3748')(props),
      '--input-foreground'       : mode('#1A202C', '#F7FAFC')(props),
      '--input-outline-focus'    : mode('#1A202C', '#F7FAFC')(props),
    },
    '.bulk-export-checkbox + span': {
      borderWidth: '3px',
      borderColor: 'gray.500',
    },
    '.table-col-gray': {
      '.chakra-ui-dark &': {
        bg: 'gray.600',
      },
      bg: 'gray.200',
    },
  }),
}

const layerStyles = {
  // menu bars
  menuBar: {
    bg                 : 'white',
    '.chakra-ui-dark &': { bg: 'gray.700' },
    boxShadow          : 'md',
    '@media print'     : {
      display: 'none',
    },
  },
  adminMenuBar: {
    bg                 : 'white',
    '.chakra-ui-dark &': { bg: 'gray.700' },
    boxShadow:
      '0 4px 6px -1px rgb(255 0 0 / 70%), 0 2px 4px -1px rgb(255 0 0 / 42%)',
    '@media print': {
      display: 'none',
    },
  },

  // cards
  card: {
    bg                 : 'white',
    '.chakra-ui-dark &': { bg: 'gray.700' },
    p                  : 3,
    boxShadow          : 'md',
    borderRadius       : 'md',
    maxWidth           : 'calc(100vw - 6rem)',
    width              : { base: 'calc(100% - 3rem)' },
    '@media print'     : {
      width    : '100vw',
      maxWidth : 'unset',
      boxShadow: 'none',
    },
    opacity: 0.99,
  },

  cardContainer: {
    mt            : '2rem',
    mb            : '3.2rem',
    '@media print': {
      width   : '100vw',
      maxWidth: 'unset',
      m       : 0,
    },
  },

  // cards
  mobileCard: {
    bg                 : 'white',
    '.chakra-ui-dark &': { bg: 'gray.700' },
    p                  : 3,
    boxShadow          : 'md',
    borderRadius       : 'md',
    maxWidth           : '70rem',
    width              : { base: 'calc(100% - 1rem)' },
    '@media print'     : {
      width    : '100vw',
      maxWidth : 'unset',
      boxShadow: 'none',
    },
    opacity: 0.99,
  },

  mobileCardContainer: {
    mt            : '0.5rem',
    mb            : '0.5rem',
    '@media print': {
      width   : '100vw',
      maxWidth: 'unset',
      m       : 0,
    },
  },

  heading: {
    fontSize: 'xl',
    // ml: 6,
    // mr: 6,
  },

  centeredLoading: {
    left    : '50vw',
    top     : '20vh',
    position: 'absolute',
  },

  beadedFont: {
    fontFamily: 'beaded',
  },
  customScrollbar: {
    '::-webkit-scrollbar'      : { height: '10px' },
    '::-webkit-scrollbar-track': {
      bg                 : 'gray.50',
      boxShadow          : 'inset 0px 2.5rem 0 0 #F7FAFC',
      '.chakra-ui-dark &': {
        bg       : 'gray.800',
        boxShadow: 'inset 0px 2.5rem 0 0 #1A202C',
      },
      border: '0px solid transparent',
    },
  },
  customSelect: {
    color              : '#1A202C',
    '.chakra-ui-dark &': {
      color: 'rgba(255, 255, 255, 0.92)',
    },
  },
  printStyleForSelect: {
    '@media print': {
      border             : 'none',
      appearance         : 'none',
      WebkitAppearance   : 'none',
      MozAppearance      : 'none',
      padding            : 0,
      margin             : 0,
      color              : '#1A202C',
      '.chakra-ui-dark &': {
        color: 'rgba(255, 255, 255, 0.92)',
      },
    },
  },
  printStyleForInput: {
    '@media print': {
      border             : 'none',
      color              : '#1A202C',
      '.chakra-ui-dark &': {
        color: 'rgba(255, 255, 255, 0.92)',
      },
      resize: 'none',
    },
  },
  printStyleForInputPadding: {
    '@media print': {
      border             : 'none',
      bg                 : 'white',
      color              : '#1A202C',
      '.chakra-ui-dark &': {
        color: 'rgba(255, 255, 255, 0.92)',
      },
    },
  },
  printStyleForNumberInput: {
    '@media print': {
      border             : 'none',
      color              : '#1A202C',
      '.chakra-ui-dark &': {
        color: 'rgba(255, 255, 255, 0.92)',
      },
      textAlign: 'right',
    },
  },
  printStyleForRadioInput: {
    '@media print': {
      'div[class^=chakra-radio__]': {
        display: 'none',
      },
      '.chakra-radio__label[data-checked]': {
        display: 'inline-flex',
      },
    },
  },
  hideOnPrint: {
    '@media print': {
      display: 'none',
    },
  },
  hideOnNotPrint: {
    '@media not print': {
      display: 'none',
    },
  },
}

const components = {
  Drawer: {
    variants: {
      alwaysOpen: {
        dialog: {
          pointerEvents: 'auto',
        },
        dialogContainer: {
          pointerEvents: 'none',
        },
      },
    },
  },
  Modal: {
    variants: {
      alwaysOpen: {
        dialog: {
          pointerEvents: 'auto',
        },
        dialogContainer: {
          pointerEvents: 'none',
        },
      },
      minimized: {
        dialog: {
          pointerEvents: 'auto',
        },
        dialogContainer: {
          pointerEvents: 'none',
          display      : 'none',
        },
      },
    },
  },
  Code: {
    variants: {
      inModal: {
        whiteSpace: 'pre-wrap',
      },
    },
  },
  Button: {
    variants: {
      link: {
        _hover: {
          transform: 'scale(1.75)',
        },
        minWidth           : 'unset',
        color              : '#1A202C',
        '.chakra-ui-dark &': {
          color: 'rgba(255, 255, 255, 0.92)',
        },
        '.gr-icon path': {
          stroke             : '#1A202C',
          '.chakra-ui-dark &': {
            stroke: 'rgba(255, 255, 255, 0.92)',
          },
        },
        '.beta-button': {
          path: {
            stroke             : 'rgb(192, 86, 33)',
            color              : 'rgb(192, 86, 33)',
            '.chakra-ui-dark &': {
              stroke: 'rgb(251, 211, 141)',
              color : 'rgb(251, 211, 141)',
            },
          },
        },
      },
    },
  },
}

const config = {
  initialColorMode: 'light',
}

const fonts = {
  beadedFont: 'beaded',
}

export const theme = extendTheme({
  styles,
  colors,
  fonts,
  layerStyles,
  components,
  config,
})
