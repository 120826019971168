import Beaded from './beaded.js'
import { assemble_record_date, filterReadings, applyOffsets } from './utils.js'

export const decode2 = async function (opts) {
  const doc = new Beaded()
  doc.set('data.format', opts.data.format)
  doc.set('data.packet', opts.data.packet)

  const raw = Buffer.from(opts.data.packet)

  // doc.set('data.format',  raw.readUInt16LE(0))  // bytes 1-2
  doc.set('ts.year', '20' + raw.readUInt8(2)) // byte  3
  doc.set('ts.month', raw.readUInt8(3)) // byte  4
  doc.set('ts.day', raw.readUInt8(4)) // byte  5
  doc.set('ts.hour', raw.readUInt8(5)) // byte  6
  doc.set('ts.minute', raw.readUInt8(6)) // byte  7
  // doc.set('ts.second',    raw.readUInt8(7))  // byte  8
  assemble_record_date(doc)

  if (raw.length < 10) return doc
  doc.set('logger.temp', raw.readInt16LE(8) / 100) // bytes 9-10
  if (raw.length < 12) return doc
  doc.set('logger.battery', raw.readUInt16LE(10) / 1000) // bytes 11-12
  if (raw.length < 14) return doc
  doc.set('modem.battery', raw.readInt16LE(12) / 1000) // bytes 13-14

  // console.log(`start: ${raw.readUInt8(14)} end: ${raw.readUInt8(15)}`)

  if (opts?.sensor?.at) {
    const position = (opts.sensor.at - 0) * 2 + 16
    doc.set('site.air_temp', raw.readInt16LE(position) / 100)
    // console.log(`v2 air_temp: ${doc.site.air_temp}`)
  }

  let position = 16

  while (position < raw.length - 1) {
    doc.push('readings', raw.readInt16LE(position) / 100)
    position += 2
  }

  filterReadings(doc, opts)
  applyOffsets(doc, opts)

  return doc
}
