import { z } from 'zod'

/** Represents the enum public.cable_conduit_type_ */
enum CableConduitType {
  NONE = 'NONE',
  FLEX = 'FLEX',
  PEX = 'PEX',
  PEXSAND = 'PEXSAND',
  HDPE = 'HDPE',
  STAINLESS = 'STAINLESS',
}

export default CableConduitType

/** Zod schema for cable_conduit_type_ */
export const cableConduitType = z.enum([
  'NONE',
  'FLEX',
  'PEX',
  'PEXSAND',
  'HDPE',
  'STAINLESS',
])
