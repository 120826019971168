import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  InputGroup,
  HStack,
  FormErrorMessage,
  Checkbox,
  Spacer,
  VStack,
} from '@chakra-ui/react'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useMutation } from 'react-query'

import { logger, modem } from 'api'

import { ModalContainer } from 'containers/ModalContainer'

import { DefaultInput } from 'forms/inputs/DefaultInput'
import { SelectInput } from 'forms/inputs/SelectInput'
import { SelectOrganization } from 'forms/inputs/SelectOrganization'
import { SelectProjectWithOrgFilter } from 'forms/inputs/SelectProject'
import { TextareaInput } from 'forms/inputs/TextAreaInput'

import { formatLoggerSerial } from 'lib/formatLoggerSerial'
import { loggerSchema, loggerUseSchema } from 'lib/jsValidate'
import { logValidationErrors } from 'lib/logValidationErrors'
import { queryClient } from 'lib/react-query'

import { Obj } from 'types/common'

type LoggerModel = 'D605' | 'SL' | 'D505' | 'DLB'
const LoggerModels = ['D605', 'SL', 'D505', 'DLB']

interface CreateLoggerFormInput {
  app: string
  configuration: string
  modem: {
    status: string
    imei?: string
  }
  mounting_field: string
  ports: string
  serial: {
    id: number
    model: LoggerModel
  }
  plan: string
  org: { id: number }
  project: { id: number }
  reuseImei: boolean
}

export const CreateLogger = () => {
  const methods = useForm<CreateLoggerFormInput>({
    // resolver: joiResolver(schema),
  })

  const mutation = useMutation(
    async (data: CreateLoggerFormInput) => {
      const { value } = loggerSchema.validate(data, {
        stripUnknown: true,
        abortEarly  : false,
      })

      const loggerResult = await logger.create(value)

      const loggerUse: Obj = {
        begin  : new Date().toISOString(),
        org    : { id: data.org.id },
        project: { id: data.project.id },
      }

      const { error, value: useDoc } = loggerUseSchema.validate(loggerUse, {
        abortEarly  : false,
        stripUnknown: true,
      })

      if (error) logValidationErrors(error)

      return logger.createUse(loggerResult?.id, useDoc)
      // return useResult
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('loggers')
      },
    },
  )

  const handler = (data: CreateLoggerFormInput) => mutation.mutateAsync(data)

  const model = methods.watch('serial.model')

  return (
    <ModalContainer
      TriggerButton={({ onClick }: any) => (
        <Button onClick={onClick}>Create Logger</Button>
      )}
      modalProps={{ size: '2xl' }}
      header='Create Logger'
      footer={() => (
        <VStack justifyContent='right' align='right' width='100%'>
          <HStack>
            <Spacer />
            {mutation.isSuccess && <CheckCircleIcon color='green.400' />}
            {mutation.isError && <WarningIcon color='red.400' />}
            <Button
              onClick={methods.handleSubmit(handler)}
              isLoading={mutation.isLoading}
            >
              Submit
            </Button>
          </HStack>
        </VStack>
      )}
    >
      <FormProvider {...methods}>
        <form>
          <Grid templateColumns='repeat(6, 1fr)' gap={3}>
            <GridItem colSpan={3}>
              <SerialInputGroup />
            </GridItem>

            <GridItem colSpan={3}>{model !== 'SL' && <ImeiInput />}</GridItem>

            <GridItem colSpan={2}>
              <SelectInput
                name='chipset'
                label='Chipset'
                options={['nordic', 'cypress']}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <DefaultInput name='app' label='Firmware' />
            </GridItem>

            <GridItem colSpan={2}>
              <DefaultInput name='configuration' label='Configuration' />
            </GridItem>

            <GridItem colSpan={6}>
              <DefaultInput name='ports' label='Ports' />
            </GridItem>

            <GridItem colSpan={6}>
              <SelectOrganization
                name='org.id'
                label='Organization'
                hookformProps={{
                  required: true,
                }}
              />
            </GridItem>

            <GridItem colSpan={6}>
              <SelectProjectWithOrgFilter
                name='project.id'
                label='Project'
                hookformProps={{
                  required: true,
                }}
                watchField='org.id'
              />
            </GridItem>

            <GridItem colSpan={6}>
              <TextareaInput name='notes' label='Notes' />
            </GridItem>
          </Grid>
        </form>
      </FormProvider>
    </ModalContainer>
  )
}

interface SerialInputGroupProps {}

type SerialValidity =
  | {
      valid: boolean
      message?: string
    }
  | null
  | undefined

// Input Pair
const SerialInputGroup = (props: SerialInputGroupProps) => {
  const { register, watch } = useFormContext()
  const model = watch('serial.model')
  const id = watch('serial.id')

  const [validity, setValidity] = useState<SerialValidity>(null)

  useEffect(() => {
    const validateSerial = debounce(async () => {
      const validationResult = await logger.validateSerial(
        formatLoggerSerial({ model, id }),
      )
      setValidity(validationResult)
    }, 500)

    if (model && id && id > 10) validateSerial()
  }, [model, id])

  return (
    <>
      <FormControl isRequired={true} isInvalid={validity?.valid === false}>
        <HStack>
          <FormLabel htmlFor='serial.model'>Model</FormLabel>

          <FormLabel
            htmlFor='serial.id'
            style={{ marginBottom: '8px', marginLeft: '80px' }}
          >
            Serial
          </FormLabel>
        </HStack>
        <InputGroup>
          <Select
            borderRight='none'
            borderEndEndRadius={0}
            borderStartEndRadius={0}
            {...register('serial.model', {
              required: true,
            })}
            autoComplete='off'
          >
            {LoggerModels.map((model) => (
              <option value={model}>{model}</option>
            ))}
          </Select>
          <Input
            borderLeft='none'
            borderEndStartRadius={0}
            borderStartStartRadius={0}
            autoComplete='off'
            {...register('serial.id', {
              required     : true,
              valueAsNumber: true,
              validate     : async (id: number) => {
                if (validity?.valid === false) return validity?.message
                return true
              },
            })}
            type='number'
          />
        </InputGroup>
        <FormErrorMessage>
          {validity?.valid === false
            ? validity?.message ?? 'There was an error'
            : ''}
        </FormErrorMessage>
      </FormControl>
    </>
  )
}

interface ImeiInputProps {}

const ImeiInput = (props: ImeiInputProps) => {
  const { register, watch } = useFormContext()
  const [validity, setValidity] = useState<SerialValidity>(null)

  const imei = watch('modem.imei')
  const reuseImei = watch('reuseImei')
  const model = watch('serial.model')

  useEffect(() => {
    const validateImei = debounce(async () => {
      const validationResult = await modem.validateImei(imei)
      setValidity(validationResult)
    }, 500)

    if (imei && imei.length === 15) validateImei()
  }, [imei])

  return (
    <FormControl
      isInvalid={validity?.valid === false && !reuseImei}
      isRequired={model !== 'SL'}
    >
      <FormLabel htmlFor='modem.imei'>IMEI</FormLabel>
      <InputGroup>
        <Input
          autoComplete='off'
          {...register('modem.imei', {
            maxLength: 15,
            minLength: 15,
            validate : async (imei: string) => {
              if (imei.length !== 15) return 'IMEI must be 15 digits'
              if (validity?.valid === false && !reuseImei)
                return validity?.message
              return true
            },
          })}
          required={model !== 'SL'}
        />
        {validity?.valid === false &&
        validity?.message?.startsWith('IMEI is currently in use') ? (
            <Checkbox pl={2} {...register('reuseImei')}>
            Reuse
            </Checkbox>
          ) : null}
      </InputGroup>
      <FormErrorMessage>
        {validity?.valid === false
          ? validity?.message ?? 'There was an error'
          : ''}
      </FormErrorMessage>
    </FormControl>
  )
}
