import type { IMEI, Serial } from 'types/common'

import * as apiUtils from '.'

const url = '/cloudloop'

const parseInput = (input: IMEI | Serial) => {
  if (!input) return {}
  if (input.length === 15) return { imei: input }
  return { serial: input }
}

export const activateSubscriber = (input: IMEI | Serial) =>
  apiUtils.GET(`${url}/activate-subscriber`, { params: parseInput(input) })

export const createDestination = (input: IMEI | Serial) =>
  apiUtils.GET(`${url}/create-destination`, { params: parseInput(input) })

export const createHardware = (input: IMEI | Serial) =>
  apiUtils.GET(`${url}/create-hardware`, { params: parseInput(input) })

export const createSubscriber = (input: IMEI | Serial, name: string) =>
  apiUtils.GET(`${url}/create-subscriber`, {
    params: { ...parseInput(input), name },
  })

export const deactivateSubscriber = (input: IMEI | Serial) =>
  apiUtils.GET(`${url}/deactivate-subscriber`, { params: parseInput(input) })

export const getHardware = (input: IMEI | Serial) =>
  apiUtils.GET(`${url}/get-hardware`, { params: parseInput(input) })

export const getSubscriber = (input: IMEI | Serial) =>
  apiUtils.GET(`${url}/get-subscriber`, { params: parseInput(input) })

export const getSubscribers = (input: IMEI | Serial) =>
  apiUtils.GET(`${url}/get-subscribers`, { params: parseInput(input) })

export const getUsageSummary = (input: IMEI | Serial) =>
  apiUtils.GET(`${url}/get-usage-summary`, { params: parseInput(input) })

export const reassociateSubscriber = (input: IMEI | Serial, newImei: string) =>
  apiUtils.GET(`${url}/reassociate-subscriber`, {
    params: { ...parseInput(input), 'new-imei': newImei },
  })

export const resumeSubscriber = (input: IMEI | Serial) =>
  apiUtils.GET(`${url}/resume-subscriber`, { params: parseInput(input) })

export const sendMessage = (input: IMEI | Serial, message: string) =>
  apiUtils.GET(`${url}/send-message`, {
    params: { ...parseInput(input), message },
  })

export const suspendSubscriber = (input: IMEI | Serial) =>
  apiUtils.GET(`${url}/suspend-subscriber`, { params: parseInput(input) })

export const updateSubscriber = (input: IMEI | Serial, name: string) =>
  apiUtils.GET(`${url}/update-subscriber`, {
    params: { ...parseInput(input), name },
  })

export const setupWizard = (input: IMEI | Serial, name: string) =>
  apiUtils.GET(`${url}/setup-wizard`, {
    params: { ...parseInput(input), name },
  })
