import { captureException } from '@sentry/react'
import { QueryClient, setLogger } from 'react-query'

// import { createLocalStoragePersistor } from 'react-query/createLocalStoragePersistor-experimental';
// import { persistQueryClient } from 'react-query/persistQueryClient-experimental';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime           : 1000 * 60 * 60 * 24, //one day
      cacheTime           : 1000 * 60 * 60 * 24, //one day
      refetchOnWindowFocus: false,
    },
  },
})

setLogger({
  error: (error) => {
    captureException(error)
  },
  // eslint-disable-next-line no-console
  log : console.log,
  warn: console.warn,
})

// const localStoragePersistor = createLocalStoragePersistor();

// persistQueryClient({
// 	queryClient,
// 	persistor: localStoragePersistor,
// });
