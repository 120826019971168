import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { projectId, type ProjectId } from './Project.js'
import {
  projectRoleType,
  type default as ProjectRoleType,
} from './ProjectRoleType.js'
import { userId, type UserId } from './User.js'

/** Represents the table public.project_user_ */
export default interface ProjectUserTable {
  /** Database type: pg_catalog.int4 */
  projectId: ColumnType<ProjectId, ProjectId, ProjectId>

  /** Database type: pg_catalog.uuid */
  userId: ColumnType<UserId, UserId, UserId>

  /** Database type: public.project_role_type */
  role: ColumnType<
    ProjectRoleType | null,
    ProjectRoleType | null,
    ProjectRoleType | null
  >
}

export type ProjectUser = Selectable<ProjectUserTable>

export type ProjectUserInitializer = Insertable<ProjectUserTable>

export type ProjectUserMutator = Updateable<ProjectUserTable>

export const projectUser = z.object({
  projectId: projectId,
  userId   : userId,
  role     : projectRoleType.nullable().optional(),
}) as unknown as z.Schema<ProjectUser>

export const projectUserInitializer = z.object({
  projectId: projectId,
  userId   : userId,
  role     : projectRoleType.optional().nullable().optional(),
}) as unknown as z.Schema<ProjectUserInitializer>

export const projectUserMutator = z.object({
  projectId: projectId.optional(),
  userId   : userId.optional(),
  role     : projectRoleType.optional().nullable().optional(),
}) as unknown as z.Schema<ProjectUserMutator>
