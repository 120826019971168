import { CheckCircleIcon, InfoIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Button,
  HStack,
  IconButton,
  Spacer,
  Text,
  useBoolean,
  Box,
  VStack,
  Grid,
  GridItem,
  Tooltip,
  chakra,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  UseDisclosureProps,
  useModalContext,
} from '@chakra-ui/react'
import { Formik, Form, FormikValues } from 'formik'
import merge from 'lodash.merge'
import { useState } from 'react'
import { useMutation } from 'react-query'

import type { GlobalPerm, SuperUser } from '@beaded/models'

import { ErrorWrapper } from 'components/ErrorWrapper'

import { ModalContainer } from 'containers/ModalContainer'

import { useUserContext } from 'hooks/useUserContext'

import type { FormInfo } from 'types/FormInfo'

import { CollapsibleFieldArray, FieldArray } from './groups/Array'
import { FieldWrapper } from './lib/FieldWrapper'
import { validate } from './lib/validate'

export interface IFormModalProps {
  formInfo: FormInfo<any>
  buttonText?: string
  tooltipText?: string | JSX.Element
  disabled?: boolean
  buttonProps?: any
  iconButton?: JSX.Element
  iconButtonProps?: any
  global?: GlobalPerm
  infoIcon?: boolean
  disclosureProps?: UseDisclosureProps
}

export const FormModal = ({
  formInfo,
  buttonText,
  tooltipText,
  disabled,
  buttonProps,
  iconButton,
  iconButtonProps,
  disclosureProps,
  infoIcon = true,
}: IFormModalProps) => {
  const [successMessage, setSuccessMessage] = useState<string>('')
  const mutation = useMutation(formInfo.submitFn, {
    ...formInfo.mutationOptions,
    onSuccess: (data: any, variables: any, context: any) => {
      formInfo.mutationOptions?.onSuccess?.(data, variables, context)
      if (formInfo?.successMessage) {
        setSuccessMessage(formInfo.successMessage(data, variables, context))
      }
    },
  })
  const [showInitialValues, setShowInitialValues] = useBoolean()
  const { superUser, global } = useUserContext()

  const TriggerButton = ({ onClick }: any) =>
    iconButton ? (
      <Tooltip label={tooltipText ?? buttonText}>
        <IconButton
          icon={iconButton}
          onClick={onClick}
          disabled={disabled}
          {...iconButtonProps}
        />
      </Tooltip>
    ) : (
      <Tooltip label={tooltipText}>
        <Button onClick={onClick} disabled={disabled} {...buttonProps}>
          {buttonText}
        </Button>
      </Tooltip>
    )

  return (
    <ErrorWrapper>
      <ModalContainer
        TriggerButton={TriggerButton}
        header={formInfo.title ?? 'Modal Title'}
        disclosureProps={disclosureProps}
        footer={({ onClose }) => {
          return (
            <>
              <VStack justifyContent='right' align='right' width='100%'>
                <HStack>
                  {/* TODO this button needs to vanish... eventually */}
                  {(global.view || process.env.NODE_ENV === 'development') && (
                    <IconButton
                      icon={<InfoIcon />}
                      onClick={setShowInitialValues.toggle}
                      aria-label='show data details'
                    />
                  )}
                  <Spacer />
                  {mutation.isSuccess && <CheckCircleIcon color='green.400' />}
                  {mutation.isError && <WarningIcon color='red.400' />}
                  {!formInfo.disableSubmit && (
                    <Button
                      type='submit'
                      disabled={mutation.isLoading}
                      form='chakra-formik-form'
                    >
                      {formInfo.submitText ?? 'Submit'}
                    </Button>
                  )}
                  <Button onClick={onClose}>Close</Button>
                </HStack>
                {mutation.isError && (
                  <pre>
                    <chakra.code color='red.400'>
                      {JSON.stringify(mutation.error, null, 2)}
                    </chakra.code>
                  </pre>
                )}
                <HStack>
                  {successMessage && <Text>{successMessage}</Text>}
                </HStack>
              </VStack>
            </>
          )
        }}
        modalProps={merge({ size: '2xl' }, formInfo?.modalProps)}
        modalBodyProps={formInfo?.modalBodyProps}
      >
        <FormikWrapper
          formInfo={formInfo}
          mutation={mutation}
          global={global}
          superUser={superUser}
          showInitialValues={showInitialValues}
        />
      </ModalContainer>
    </ErrorWrapper>
  )
}

interface IFormikWrapperProps {
  formInfo: FormInfo<FormikValues>
  mutation: any
  global: GlobalPerm
  superUser: SuperUser
  showInitialValues: boolean
  showButton?: boolean
}

export const FormikWrapper = ({
  formInfo,
  mutation,
  global,
  superUser,
  showInitialValues,
  showButton = false,
}: IFormikWrapperProps) => {
  const { onClose } = useModalContext()
  return (
    <Formik
      onSubmit={async (values, actions) => {
        try {
          let data = values
          if (formInfo.validateForm) {
            const { values: validatedData, errors } = validate({
              validateForm: formInfo.validateForm,
              values,
            })

            if (errors) {
              actions.setErrors(errors)
              actions.setSubmitting(false)
              return
            }
            data = validatedData
          }

          const res = await mutation.mutateAsync(data as unknown as void)
          actions.setSubmitting(false)
          if (
            formInfo?.closeCondition !== undefined &&
            formInfo.closeCondition(res)
          ) {
            onClose()
          }
        }
        catch (error) {
          console.error(error)
        }
      }}
      initialValues={formInfo.initialValues}
      enableReinitialize={true}
    >
      {({ handleSubmit, values, errors }) => (
        <Form onSubmit={handleSubmit} id='chakra-formik-form'>
          <Grid gap={3} {...formInfo.gridProps}>
            {formInfo.fields.map((props, i, arr) => {
              if (props.type === 'group') {
                if (props.collapsible) {
                  return (
                    <GridItem
                      key={props.title ?? props.groupKey}
                      {...props.gridItemProps}
                    >
                      <Accordion
                        allowToggle
                        defaultIndex={props.initiallyCollapsed ? [] : [0]}
                      >
                        <AccordionItem borderBottom='none' borderTop='none'>
                          <AccordionButton>
                            <Box flex='1' textAlign='left' layerStyle='heading'>
                              <Text fontSize='lg'>{props.title}</Text>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel>
                            <Grid {...props.gridProps}>
                              {props.description && (
                                <GridItem
                                  key={props.description}
                                  colSpan={props.titleColSpan ?? 1}
                                  pb='0.5rem'
                                >
                                  <Text>{props.description}</Text>
                                </GridItem>
                              )}
                              {props.fields.map(
                                ({ gridItemProps, ...props }) => {
                                  if (props.type === 'array') {
                                    if (props.collapsible)
                                      return (
                                        <GridItem
                                          key={
                                            props.keyPrefix
                                              ? props.keyPrefix +
                                                props.name +
                                                props.title
                                              : props.name + props.title
                                          }
                                          {...gridItemProps}
                                        >
                                          <CollapsibleFieldArray
                                            {...props}
                                            lastItem={i === arr.length - 1}
                                          />
                                        </GridItem>
                                      )

                                    return (
                                      <GridItem
                                        key={
                                          props.keyPrefix
                                            ? props.keyPrefix + props.name
                                            : props.name
                                        }
                                        {...gridItemProps}
                                      >
                                        <FieldArray
                                          {...props}
                                          lastItem={i === arr.length - 1}
                                        />
                                      </GridItem>
                                    )
                                  }

                                  return (
                                    <GridItem
                                      key={
                                        props.keyPrefix
                                          ? props.keyPrefix + props.name
                                          : props.name
                                      }
                                      {...gridItemProps}
                                    >
                                      <FieldWrapper
                                        global={global}
                                        superUser={superUser}
                                        {...props}
                                      />
                                    </GridItem>
                                  )
                                },
                              )}
                            </Grid>
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </GridItem>
                  )
                }

                return (
                  <GridItem
                    key={props.title ?? props.groupKey}
                    {...props.gridItemProps}
                  >
                    <Grid {...props.gridProps}>
                      {props?.title ? (
                        <GridItem
                          key={props.title}
                          colSpan={props.titleColSpan ?? 1}
                        >
                          <Text fontSize='lg'>{props.title}</Text>
                        </GridItem>
                      ) : (
                        <></>
                      )}
                      {props.fields.map(({ gridItemProps, ...props }) => {
                        if (props.type === 'array') {
                          if (props.collapsible)
                            return (
                              <GridItem
                                key={
                                  props.keyPrefix
                                    ? props.keyPrefix + props.name + props.title
                                    : props.name + props.title
                                }
                                {...gridItemProps}
                              >
                                <CollapsibleFieldArray
                                  {...props}
                                  lastItem={i === arr.length - 1}
                                />
                              </GridItem>
                            )

                          return (
                            <GridItem
                              key={
                                props.keyPrefix
                                  ? props.keyPrefix + props.name
                                  : props.name
                              }
                              {...gridItemProps}
                            >
                              <FieldArray
                                {...props}
                                lastItem={i === arr.length - 1}
                              />
                            </GridItem>
                          )
                        }

                        return (
                          <GridItem key={props.name} {...gridItemProps}>
                            <FieldWrapper
                              global={global}
                              superUser={superUser}
                              {...props}
                            />
                          </GridItem>
                        )
                      })}
                    </Grid>
                    {i === arr.length - 1 && <Spacer />}
                  </GridItem>
                )
              }

              if (props.type === 'array') {
                if (props.collapsible)
                  return (
                    <CollapsibleFieldArray
                      {...props}
                      key={props.title}
                      lastItem={i === arr.length - 1}
                    />
                  )

                return <FieldArray {...props} lastItem={i === arr.length - 1} />
              }

              return (
                <GridItem
                  key={
                    props.keyPrefix ? props.keyPrefix + props.name : props.name
                  }
                  {...props.gridItemProps}
                >
                  <FieldWrapper
                    global={global}
                    superUser={superUser}
                    {...props}
                  />
                </GridItem>
              )
            })}
            {errors && Object.keys(errors).length > 0 && (
              <pre>
                {global?.view ? (
                  <chakra.code color='red'>
                    {JSON.stringify(errors, null, 2)}
                  </chakra.code>
                ) : (
                  <chakra.code color='red'>
                    {Object.entries(errors).map(([key, value]) => (
                      <div key={key}>{JSON.stringify(value)}</div>
                    ))}
                  </chakra.code>
                )}
              </pre>
            )}
          </Grid>
          {showInitialValues && (
            <pre>
              <code>{JSON.stringify(values, null, 2)}</code>
            </pre>
          )}
          <HStack pt={4}>
            <Spacer />
            {showButton && (
              <Button
                type='submit'
                disabled={mutation.isLoading}
                form='chakra-formik-form'
              >
                {formInfo.submitText ?? 'Submit'}
              </Button>
            )}
          </HStack>
        </Form>
      )}
    </Formik>
  )
}
