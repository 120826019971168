import { LockIcon, UnlockIcon } from '@chakra-ui/icons'
import {
  RadioGroup as ChakraRadioGroup,
  Radio as ChakraRadio,
  FormLabel,
  useBoolean,
  IconButton,
  HStack,
  Stack,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import { InfoTooltip } from 'components/InfoTooltip'

import { InputWrapperProps } from '../lib/FieldWrapper'

export const RadioGroup = ({
  name,
  label,
  field,
  options = [],
  requiresGlobalAdmin = false,
  direction = 'row',
  spacing = 4,
  global = { view: false, edit: false, admin: false },
  tooltip,
  onChange,
}: InputWrapperProps) => {
  const [locked, setLocked] = useBoolean(true)
  const { setFieldValue, values } = useFormikContext()

  return (
    <>
      <FormLabel htmlFor={name}>{label ?? name}</FormLabel>
      <HStack>
        <ChakraRadioGroup
          {...field}
          {...(typeof onChange === 'function'
            ? {
              onChange: (e) => {
                onChange(e, setFieldValue, values)
                setFieldValue(name, e)
              },
            }
            : {
              onChange: (e) => {
                setFieldValue(name, e)
              },
            })}
        >
          <Stack direction={direction} spacing={spacing}>
            {Array.isArray(options)
              ? options.map(({ name, value }) => (
                <ChakraRadio
                  key={name}
                  value={value}
                  isDisabled={locked && requiresGlobalAdmin}
                >
                  {name ?? value}
                </ChakraRadio>
              ))
              : null}
          </Stack>
        </ChakraRadioGroup>
        {tooltip && <InfoTooltip text={tooltip} />}

        {requiresGlobalAdmin && global.edit && (
          <IconButton
            icon={locked ? <LockIcon /> : <UnlockIcon />}
            onClick={setLocked.toggle}
            aria-label={locked ? 'locked' : 'unlocked'}
          />
        )}
      </HStack>
    </>
  )
}
