import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useRouter } from 'hooks/useRouter'
import { useUserContext } from 'hooks/useUserContext'

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useUserContext()
  const { location } = useRouter()

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          !auth.currentUser ||
          auth?.currentUser?.error?.includes('not logged in')
        )
          return (
            <Redirect to={{ pathname: '/login', state: { from: location } }} />
          )

        return <Component {...props} />
      }}
    />
  )
}
