import { z } from 'zod'

/** Represents the enum public.data_unit_type_ */
enum DataUnitType {
  B = 'B',
  KB = 'KB',
}

export default DataUnitType

/** Zod schema for data_unit_type_ */
export const dataUnitType = z.enum(['B', 'KB'])
