import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { userId, type UserId } from './User.js'

/** Identifier type for public.user_password_reset_ */
export type UserPasswordResetId = string & { __brand: 'UserPasswordResetId' }

/** Represents the table public.user_password_reset_ */
export default interface UserPasswordResetTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<
    UserPasswordResetId,
    UserPasswordResetId | undefined,
    UserPasswordResetId
  >

  /** Database type: pg_catalog.uuid */
  userId: ColumnType<UserId, UserId, UserId>

  /** Database type: pg_catalog.timestamptz */
  startedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.timestamptz */
  endedAt: ColumnType<Date | null, Date | string | null, Date | string | null>
}

export type UserPasswordReset = Selectable<UserPasswordResetTable>

export type UserPasswordResetInitializer = Insertable<UserPasswordResetTable>

export type UserPasswordResetMutator = Updateable<UserPasswordResetTable>

export const userPasswordResetId =
  z.string() as unknown as z.Schema<UserPasswordResetId>

export const userPasswordReset = z.object({
  id       : userPasswordResetId,
  userId   : userId,
  startedAt: z.coerce.date().nullable().optional(),
  endedAt  : z.coerce.date().nullable().optional(),
}) as unknown as z.Schema<UserPasswordReset>

export const userPasswordResetInitializer = z.object({
  id       : userPasswordResetId.optional(),
  userId   : userId,
  startedAt: z.coerce.date().optional().nullable().optional(),
  endedAt  : z.coerce.date().optional().nullable().optional(),
}) as unknown as z.Schema<UserPasswordResetInitializer>

export const userPasswordResetMutator = z.object({
  id       : userPasswordResetId.optional(),
  userId   : userId.optional(),
  startedAt: z.coerce.date().optional().nullable().optional(),
  endedAt  : z.coerce.date().optional().nullable().optional(),
}) as unknown as z.Schema<UserPasswordResetMutator>
