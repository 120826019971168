interface ISerial {
  model: string
  id: number
}

export const formatLoggerSerial = (serial?: ISerial) => {
  if (!serial) return ''
  if (serial?.model === undefined || serial?.id === undefined) return ''

  switch (serial?.model) {
    case 'SL':
      return `${serial?.model}${serial?.id?.toString().padStart(5, '0')}`

    case 'DLB':
      return `${serial?.model}${serial?.id?.toString().padStart(5, '0')}`

    case 'D405':
    case 'D505':
    case 'D605':
      return `${serial?.model}${serial?.id?.toString().padStart(4, '0')}`

    default:
      return `${serial?.model}${serial?.id?.toString().padStart(4, '0')}`
  }
}
