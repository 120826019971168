export type ApiVersion = 'v1' | 'v1.1'

const hostname = () =>
  window?.location?.hostname === 'localhost'
    ? 'beadedcloud.com'
    : window?.location?.hostname === 'beadedcloud.com'
      ? 'beadedcloud.com'
      : window?.location?.hostname?.split('.').slice(1).join('.') ??
        'beadedcloud.com'

export const API_HOSTNAME = (): string => {
  if (window?.location?.hostname === 'local-dev.beadedcloud.com')
    return 'http://local-api.beadedcloud.com:3030'

  if (window?.location?.hostname === 'web-dev.beadedcloud.com')
    return 'https://api.dev.beadedcloud.com'

  return `https://api.${hostname()}`
}

export const API_BASE_URI = (version: ApiVersion = 'v1'): string => {
  // return `https://api-staging.beadedcloud.com/api/${version}`;
  if (window?.location?.hostname === 'local-dev.beadedcloud.com')
    return `http://local-api.beadedcloud.com:3030/api/${version}`

  if (window?.location?.hostname === 'web-dev.beadedcloud.com')
    return `https://api.dev.beadedcloud.com/api/${version}`

  return `https://api.${hostname()}/api/${version}`
}

export const CHARTS_API_BASE_URI = (): string => {
  if (window?.location?.hostname === 'local-dev.beadedcloud.com')
    return `http://local-charts.beadedcloud.com:3040`

  if (window?.location?.hostname === 'web-dev.beadedcloud.com')
    return `https://charts.dev.beadedcloud.com`

  return `https://charts.${hostname()}`
}

export const API_SOCKET_URL = (): string => {
  if ((window?.location?.hostname ?? []).includes('local-dev'))
    return `ws://local-api.beadedcloud.com:3030`

  return `wss://api.${hostname()}`
}
