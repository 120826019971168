import { ZodError } from 'zod'

import {
  OrganizationId,
  projectWithOrgMutator,
  ProjectWithOrgMutator,
} from '@beaded/models'

import { project } from 'api'

import { logValidationErrors } from 'lib/logValidationErrors'
import { queryClient } from 'lib/react-query'

import type { FormInfo, UpdateFormProps } from 'types/FormInfo'
import type { ValidationError } from 'types/ValidationError'

import { projectFields } from '../fields/project'

export const updateProjectForm = ({
  initialValues,
}: UpdateFormProps<ProjectWithOrgMutator>): FormInfo<ProjectWithOrgMutator> => ({
  fields: projectFields,

  title: 'Edit Project',
  initialValues,

  submitText: 'Save Project',

  submitFn: (values: any) => project.update(values.id, values),

  validateForm: (
    values: ProjectWithOrgMutator,
  ): {
    error?: ValidationError | ZodError
    value: ProjectWithOrgMutator
  } => {
    values.organizationId = Number(values.organizationId) as OrganizationId

    const validationResult = projectWithOrgMutator.safeParse(values)

    if (validationResult.success === false) {
      logValidationErrors(validationResult.error)
      return { error: validationResult.error, value: values }
    }

    return {
      value: validationResult.data,
    }
  },

  mutationOptions: {
    // onMutate: (variables) => console.log('onMutate', variables),
    onError: (error, variables, context): void => {
      // console.log('onError', error, variables, context);
      // return error;
    },
    onSuccess: (data: any, variables: any) => {
      if (data?.error) throw data.error
      if (data?.updated === false)
        throw new Error('encountered error creating entry')

      // console.log('onSuccess', data, variables, context);
      queryClient.invalidateQueries(['project', { id: `${variables.id}` }])
      queryClient.invalidateQueries('projects')
      queryClient.invalidateQueries('project')
    },
  },

  closeCondition: (res) => res?.updated === true,
})
