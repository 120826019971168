import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { projectId, type ProjectId } from './Project.js'

/** Identifier type for public.blueprint_point_ */
export type BlueprintPointId = number & { __brand: 'BlueprintPointId' }

/** Represents the table public.blueprint_point_ */
export default interface BlueprintPointTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<
    BlueprintPointId,
    BlueprintPointId | undefined,
    BlueprintPointId
  >

  /** Database type: pg_catalog.numeric */
  x: ColumnType<number, number, number>

  /** Database type: pg_catalog.numeric */
  y: ColumnType<number, number, number>

  /** Database type: pg_catalog.int4 */
  decode: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.int4 */
  sensor: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.int4 */
  serial: ColumnType<number | null, number | null, number | null>

  /**
   * Database type: pg_catalog.int4
   * This does not need to be a foreign key because its only used for decoding at the presentation layer.
   */
  siteId: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.int4 */
  projectId: ColumnType<ProjectId, ProjectId, ProjectId>
}

export type BlueprintPoint = Selectable<BlueprintPointTable>

export type BlueprintPointInitializer = Insertable<BlueprintPointTable>

export type BlueprintPointMutator = Updateable<BlueprintPointTable>

export const blueprintPointId =
  z.number() as unknown as z.Schema<BlueprintPointId>

export const blueprintPoint = z.object({
  id       : blueprintPointId,
  x        : z.number(),
  y        : z.number(),
  decode   : z.number().nullable().optional(),
  sensor   : z.number().nullable().optional(),
  serial   : z.number().nullable().optional(),
  siteId   : z.number().nullable().optional(),
  projectId: projectId,
}) as unknown as z.Schema<BlueprintPoint>

export const blueprintPointInitializer = z.object({
  id       : blueprintPointId.optional(),
  x        : z.number(),
  y        : z.number(),
  decode   : z.number().optional().nullable().optional(),
  sensor   : z.number().optional().nullable().optional(),
  serial   : z.number().optional().nullable().optional(),
  siteId   : z.number().optional().nullable().optional(),
  projectId: projectId,
}) as unknown as z.Schema<BlueprintPointInitializer>

export const blueprintPointMutator = z.object({
  id       : blueprintPointId.optional(),
  x        : z.number().optional(),
  y        : z.number().optional(),
  decode   : z.number().optional().nullable().optional(),
  sensor   : z.number().optional().nullable().optional(),
  serial   : z.number().optional().nullable().optional(),
  siteId   : z.number().optional().nullable().optional(),
  projectId: projectId.optional(),
}) as unknown as z.Schema<BlueprintPointMutator>
