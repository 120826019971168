import memoize from 'memoizee'

import { getPrecision } from 'lib/getPrecision'

import type { Obj } from 'types/common'

/*
 * Temporarily commented as this causes bug on the cable builder
Array.prototype.max = function() {
  return Math.max.apply(null, this);
};

Array.prototype.min = function() {
  return Math.min.apply(null, this);
};
*/

export const round = (number: number, digits = 0) => {
  return Math.round(number * Math.pow(10, digits)) / Math.pow(10, digits)
}

/**
 * @param {number} temp
 * @param {('C' | 'F')} from
 * @param {('C' | 'F')} to
 * @returns {number}
 */
export const convertTemp = (temp: number, from: string, to: string) => {
  if (from === 'C' && to === 'F') {
    return round(temp * 1.8 + 32, 3)
  }
  else if (from === 'F' && to === 'C') {
    return round(((temp - 32) * 5) / 9, 3)
  }

  // console.warn(`invalid temperature conversion attempted: ${from}->${to}`);
  return temp
}

/**
 * @param {number} depth
 * @param {('ft' | 'm' | 'cm' | 'in')} from
 * @param {('ft' | 'm' | 'cm' | 'in')} to
 * @returns {number}
 */

// interface ConvertLength
export const convertDepth = memoize(
  (depth: number, from: string, to: string) => {
    if (from === to) return depth

    const key = `${from.toLowerCase()}->${to.toLowerCase()}`

    if (Object.keys(conversions).includes(key)) {
      return round(conversions[key](depth), 3)
    }

    console.info(`invalid depth conversion attempted: ${key}`)
    return depth
  },
)

type AllowedUnits = 'ft' | 'm' | 'cm' | 'in' | 'mm'

export const convertLength = (
  length: number,
  from: AllowedUnits,
  to: AllowedUnits,
) => {
  if (from === undefined || to === undefined) {
    console.warn('undefined from or to', { length, from, to })
  }
  const key = `${from.toLowerCase()}->${to.toLowerCase()}`
  if (Object.keys(conversions).includes(key)) {
    return parseFloat(conversions[key](length).toFixed(getPrecision(to)))
    // return round(conversions[key](length), 3);
  }

  if (typeof length !== 'number') length = parseFloat(length)
  return parseFloat(length.toFixed(getPrecision(to))) // toFixed returns a string
}

const conversions: Obj = {
  'ft->mm': (d: number) => d * 304.8,
  'ft->cm': (d: number) => d * 30.48,
  'ft->m' : (d: number) => d * 0.3048,
  'ft->in': (d: number) => d * 12,

  'mm->cm': (d: number) => d * 0.1,
  'mm->m' : (d: number) => d * 0.001,
  'mm->in': (d: number) => d * 0.0393701,
  'mm->ft': (d: number) => d * 0.00328084,

  'cm->mm': (d: number) => d * 10,
  'cm->m' : (d: number) => d * 0.01,
  'cm->in': (d: number) => d * 0.393701,
  'cm->ft': (d: number) => d * 0.0328084,

  'm->mm': (d: number) => d * 1000,
  'm->cm': (d: number) => d * 100,
  'm->in': (d: number) => d * 39.3701,
  'm->ft': (d: number) => d * 3.28084,

  'in->mm': (d: number) => d * 25.4,
  'in->cm': (d: number) => d * 2.54,
  'in->m' : (d: number) => d * 0.0254,
  'in->ft': (d: number) => d * (1 / 12),
}
