import { ZodError } from 'zod'

import { OrganizationInitializer } from '@beaded/models'
import { organizationInitializer } from '@beaded/models'

import { org } from 'api'

import { logValidationErrors } from 'lib/logValidationErrors'
import { queryClient } from 'lib/react-query'

import type { FormInfo, CreateFormProps } from 'types/FormInfo'
import type { ValidationError } from 'types/ValidationError'

import { orgFields } from '../fields/org'

export const createOrgForm = ({
  initialValues,
}: CreateFormProps<OrganizationInitializer> = {}): FormInfo<OrganizationInitializer> => ({
  title : 'Create Organization',
  fields: orgFields,

  initialValues: Object.assign(
    {
      organizationName: '',
      copperId        : 0,
      copperUrl       : '',
      isFreeAccount   : false,
    },
    initialValues,
  ),

  submitText: 'Create Org',
  submitFn  : (values) => org.create(values as OrganizationInitializer),

  validateForm: (
    values: OrganizationInitializer,
  ): {
    error?: ValidationError | ZodError
    value: OrganizationInitializer
  } => {
    const validationResult = organizationInitializer.safeParse(values)

    if (validationResult.success === false) {
      logValidationErrors(validationResult.error)
      return { error: validationResult.error, value: values }
    }

    return {
      value: validationResult.data,
    }
  },

  mutationOptions: {
    // onMutate: (variables) => console.log('onMutate', variables),
    onError: (error, variables, context): void => {
      // console.log('onError', error, variables, context);
      // return error;
    },
    onSuccess: (data: any, variables: any) => {
      if (data?.error) throw data.error
      if (data?.updated === false)
        throw new Error('encountered error creating entry')

      // console.log('onSuccess', data, variables, context);
      queryClient.invalidateQueries('orgs')
    },
  },
})
