import { CheckCircleIcon } from '@chakra-ui/icons'
import { HStack, Spacer, Text, Checkbox, VStack, Box } from '@chakra-ui/react'
import fastJsonPatch from 'fast-json-patch'
import { ChangeEvent, useState } from 'react'

import { Obj, UserId } from '@beaded/models'
import type { User } from '@beaded/models'

import { user } from 'api'

import { betaOptions } from 'hooks/useUserContext'

import { queryClient } from 'lib/react-query'

const useFeatureFlag = (
  userId: UserId,
  name: string,
  initialValue: boolean = false,
) => {
  const [flag, setFlag] = useState<boolean>(initialValue)

  const updateFlag = async (newValue: boolean) => {
    setFlag(newValue)

    const baseEntry = {
      prefs: {
        beta: {},
      },
    }
    const prefsEntry = {
      prefs: {
        beta: {
          [name]: newValue,
        },
      },
    }

    const changes = fastJsonPatch.compare(baseEntry, prefsEntry)
    const res = await user.patch(userId, changes)

    return res
  }

  return {
    flag,
    updateFlag,
  }
}

interface FeatureFlagProps {
  name: string
  userId: UserId
  label: string
  initialValue?: boolean
  style?: any
}

const FeatureFlag = ({
  name,
  userId,
  label,
  initialValue = false,
  style,
}: FeatureFlagProps) => {
  const { flag, updateFlag } = useFeatureFlag(userId, name, initialValue)

  const [isSyncing, setIsSyncing] = useState<boolean>(false)
  const [wasUpdated, setWasUpdated] = useState<boolean>(false)

  const update = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsSyncing(true)
    await updateFlag(e.target.checked).catch((e) => {
      console.error(e)
      setWasUpdated(false)
    })
    setIsSyncing(false)
    setWasUpdated(true)
    queryClient.invalidateQueries(['user', { id: userId }])
  }

  return (
    <HStack style={style}>
      <Checkbox
        isChecked={flag}
        onChange={(e) => update(e)}
        sx={{ paddingRight: '1.5rem' }}
      >
        <Text>{label ?? name}</Text>
      </Checkbox>

      <Spacer />

      {isSyncing === true && <Text>Syncing...</Text>}
      {isSyncing === false && wasUpdated === true && (
        <CheckCircleIcon color='green.500' />
      )}
    </HStack>
  )
}

interface BetaFlagProps {
  user: User & { prefs: Obj }
}

export const UserBetaFlags = ({ user }: BetaFlagProps) => {
  return (
    <Box>
      <Text layerStyle='heading' pb={4} pt={4}>
        Beta Features
      </Text>

      <VStack align='left' spacing='0px'>
        <FeatureFlag
          name='all'
          label='All'
          userId={user?.id}
          initialValue={user?.prefs?.beta?.all}
        />
        {betaOptions.map(({ name, label }) => (
          <FeatureFlag
            key={name}
            name={name}
            label={label}
            userId={user?.id}
            initialValue={user?.prefs?.beta?.[name]}
            style={{ marginLeft: '25px' }}
          />
        ))}
      </VStack>
    </Box>
  )
}
