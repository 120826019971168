import { ZodError } from 'zod'

import { OrganizationMutator, organizationMutator } from '@beaded/models'

import { org } from 'api'

import { logValidationErrors } from 'lib/logValidationErrors'
import { queryClient } from 'lib/react-query'

import type { FormInfo, UpdateFormProps } from 'types/FormInfo'
import type { ValidationError } from 'types/ValidationError'

import { orgFields } from '../fields/org'

export const updateOrgForm = ({
  initialValues,
}: UpdateFormProps): FormInfo<OrganizationMutator> => ({
  title     : 'Update Organization',
  fields    : orgFields,
  initialValues,
  submitText: 'Save',
  submitFn  : (values: any) => org.update(values?.id, values),

  validateForm: (
    values: OrganizationMutator,
  ): {
    error?: ValidationError | ZodError
    value: OrganizationMutator
  } => {
    const validationResult = organizationMutator.safeParse(values)

    if (validationResult.success === false) {
      logValidationErrors(validationResult.error)
      return { error: validationResult.error, value: values }
    }

    return {
      value: validationResult.data,
    }
  },

  mutationOptions: {
    // onMutate: (variables) => console.log('onMutate', variables),
    onError: (error, variables, context) => {
      // console.log('onError', error, variables, context);
      // return error;
    },
    onSuccess: (data: any, variables: any, context) => {
      if (data?.error) throw data.error
      if (data?.updated === false)
        throw new Error('encountered error updating entry')

      // console.log('onSuccess', data, variables, context);
      queryClient.invalidateQueries(['org', { id: `${variables.id}` }])
      queryClient.invalidateQueries('orgs')
    },
  },

  closeCondition: (res) => res?.updated === true,
})
