import * as Sentry from '@sentry/react'

export const ErrorBoundary = Sentry.withErrorBoundary(
  ({ children }: { children: React.ReactNode }) => <>{children}</>,
  {
    fallback: ({ error, componentStack, resetError }: any) => (
      <div>
        <p>Something went wrong!</p>
        <p>{error.toString()}</p>
        <p>{componentStack}</p>
        <button onClick={resetError}>Try again</button>
      </div>
    ),
  },
)

// const FallbackComponent = () => <div>An error has occurred.</div>

// export const ErrorBoundary = (props: any) => (
//   <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
//     {props.children}
//   </Sentry.ErrorBoundary>
// )
