import { useJsApiLoader } from '@react-google-maps/api'
import { useContext, createContext } from 'react'

const init = { jsIsLoaded: false }
const googleMapsApiContext = createContext(init)

interface IProvideGoogleMapsApi {
  children: JSX.Element | JSX.Element[]
}

export function ProvideGoogleMapsApi({ children }: IProvideGoogleMapsApi) {
  const initialState = useProvideGoogleMapsApi()

  return (
    <googleMapsApiContext.Provider value={initialState}>
      {children}
    </googleMapsApiContext.Provider>
  )
}

export const useGoogleMapsApi = () => {
  return useContext(googleMapsApiContext)
}

function useProvideGoogleMapsApi() {
  const { isLoaded: jsIsLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey:
      import.meta.env.VITE_GMAPS_API_KEY ?? 'no-google-maps-api-found',
  })

  return { jsIsLoaded }
}
