import { z } from 'zod'

/** Represents the enum public.cable_mold_color_type_ */
enum CableMoldColorType {
  BLUE = 'BLUE',
  WHITE = 'WHITE',
  BLACK = 'BLACK',
  NONE = 'NONE',
}

export default CableMoldColorType

/** Zod schema for cable_mold_color_type_ */
export const cableMoldColorType = z.enum(['BLUE', 'WHITE', 'BLACK', 'NONE'])
