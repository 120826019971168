import { FormikValues } from 'formik'

import type { FormInfo } from 'types/FormInfo'

export const cableFields: FormInfo<FormikValues>['fields'] = [
  {
    name      : 'name',
    type      : 'text',
    label     : 'Name',
    isRequired: true,
  },
  {
    name : 'notes',
    label: 'Notes',
    type : 'textarea',
  },
  {
    name       : 'status',
    label      : 'Status',
    type       : 'select',
    isRequired : true,
    placeholder: 'Select status...',
    options    : [
      { name: 'Draft', value: 'Draft' },
      { name: 'Pre-build', value: 'Pre-build' },
      { name: 'Built', value: 'Built' },
      { name: 'Active', value: 'Active' },
      { name: 'Disassembled', value: 'Disassembled' },
      { name: 'Dead', value: 'Dead' },
      { name: 'Unknown', value: 'Unknown' },
    ],
  },
  {
    title       : 'Cable Details',
    titleColSpan: 2,
    type        : 'group',
    fields      : [
      {
        name         : 'serial',
        type         : 'number',
        label        : 'Serial',
        isReadOnly   : true,
        gridItemProps: {
          colSpan: 1,
        },
      },
      {
        name         : 'connector',
        type         : 'text',
        label        : 'Connector',
        isReadOnly   : true,
        gridItemProps: {
          colSpan: 1,
        },
      },
    ],
    gridProps: {
      templateColumns: '1fr 1fr',
      gap            : 3,
    },
  },

  {
    name      : 'display.units.length',
    label     : 'Length Units',
    type      : 'radio',
    isReadOnly: true,
    options   : [
      { value: 'in', name: 'in' },
      { value: 'ft', name: 'ft' },
      { value: 'm', name: 'm' },
      { value: 'cm', name: 'cm' },
    ],
  },
]
