import { DownloadIcon } from '@chakra-ui/icons'
import { IconButton } from '@chakra-ui/react'

export const useTableDownload = (filename) => {
  const download = {
    filename,
  }

  const DownloadButton = () => (
    <IconButton
      onClick={() => typeof download?.fn === 'function' && download?.fn()}
      aria-label='Download Table'
      icon={<DownloadIcon />}
    />
  )

  return {
    download,
    DownloadButton,
  }
}
