import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'

import * as apiUtils from '.'

const url = '/search'

export const get = (search: string, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(
    `${url}/`,
    merge({ params: { search: encodeURIComponent(search) } }, options),
  )
