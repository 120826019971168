import { OrganizationId } from '@beaded/models'

import { org } from 'api'

import { queryClient } from 'lib/react-query'

export const deleteOrg = async (id: string | number) => {
  await org.remove(id as OrganizationId)
  queryClient.invalidateQueries('orgs')
}
