import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { organizationId, type OrganizationId } from './Organization.js'
import { projectId, type ProjectId } from './Project.js'

/** Represents the table public.organization_project_ */
export default interface OrganizationProjectTable {
  /** Database type: pg_catalog.int4 */
  organizationId: ColumnType<OrganizationId, OrganizationId, OrganizationId>

  /** Database type: pg_catalog.int4 */
  projectId: ColumnType<ProjectId, ProjectId, ProjectId>
}

export type OrganizationProject = Selectable<OrganizationProjectTable>

export type OrganizationProjectInitializer =
  Insertable<OrganizationProjectTable>

export type OrganizationProjectMutator = Updateable<OrganizationProjectTable>

export const organizationProject = z.object({
  organizationId: organizationId,
  projectId     : projectId,
}) as unknown as z.Schema<OrganizationProject>

export const organizationProjectInitializer = z.object({
  organizationId: organizationId,
  projectId     : projectId,
}) as unknown as z.Schema<OrganizationProjectInitializer>

export const organizationProjectMutator = z.object({
  organizationId: organizationId.optional(),
  projectId     : projectId.optional(),
}) as unknown as z.Schema<OrganizationProjectMutator>
