import type { QueryKey, QueryFunction, QueryOptions } from 'react-query'

import { useQuery } from 'hooks/useQuery'

interface useAsyncSelectOptionsProps {
  queryArgs: [QueryKey, QueryFunction, QueryOptions?]
  dataKey: string
  mapFn: Function
  sortFn?: Function
  filterFn?: Function
}

export const useAsyncSelectOptions = ({
  queryArgs,
  dataKey,
  mapFn,
  sortFn,
  filterFn,
}: useAsyncSelectOptionsProps) => {
  const { data, ...rest } = useQuery(...queryArgs)

  let options = []

  if (dataKey === '') {
    options = data?.map(mapFn) ?? []
  }
  else {
    options = data?.[dataKey]?.map(mapFn) ?? []
  }

  if (filterFn) options.filter(filterFn)

  if (sortFn) options.sort(sortFn)

  return {
    options,
    ...rest,
  }
}
