import { useEffect, useState } from 'react'

import { TerminalPushToHistory } from 'components/IrcModule/TerminalEmulator/types'

import { socket } from 'lib/socket'

export const useSocket = (pushToHistory: TerminalPushToHistory) => {
  const [isConnected, setIsConnected] = useState(socket.connected)

  useEffect(() => {
    function onConnect() {
      setIsConnected(true)
    }

    function onDisconnect() {
      setIsConnected(false)
    }

    function onMessage(message: string) {
      console.info(message)
      pushToHistory(message)
    }

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
    socket.on('message', onMessage)

    return () => {
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
      socket.off('message', onMessage)
    }
  }, [pushToHistory])

  return { isConnected, socket }
}
