import { useQueries as useQueriesRQ } from 'react-query'
import type { UseQueryOptions } from 'react-query'

const initializeOutput = {
  isLoading: false,
  isSuccess: true,
  isError  : false,
  errors   : [],
  data     : {},
}

const extractErrors = (error: any, data: any) => {
  const errors = []
  error && errors.push(error.toString())
  error && errors.push(error.response?.data)

  data?.error && errors.push(data.error)

  return errors
}

const extractResults = (output: any, res: any) => {
  // collect errors
  output.errors.concat(extractErrors(res.error, res.data))
  res?.data?.error && output.errors.push(res.data.error)

  output.data = Object.assign(output.data, res.data)

  return {
    isLoading: output.isLoading || res.isLoading,
    isSuccess: output.isSuccess && res.isSuccess,
    isError  : output.isError || res.isError || Boolean(res?.data?.error),
    errors   : output.errors,
    data     : output.data,
  }
}

export const useQueries = (queries: UseQueryOptions[]) => {
  const res: any = useQueriesRQ(queries)

  const { isError, errors, ...rest } = res.reduce(
    extractResults,
    initializeOutput,
  )

  return {
    errors: isError ? errors : null,
    ...rest,
  }
}
