import get from 'lodash.get'
import set from 'lodash.set'

const isObject = (obj: any) => obj != null && obj.constructor.name === 'Object'

export const getKeys = (
  obj: any,
  keepObjKeys: boolean = true,
  skipArrays: boolean = false,
  keys: string[] = [],
  scope: string[] = [],
) => {
  if (Array.isArray(obj)) {
    if (!skipArrays) scope.push(`[${obj.length}]`)
    obj.forEach((o) => getKeys(o, keepObjKeys, skipArrays, keys, scope), keys)
  }
  else if (isObject(obj)) {
    Object.keys(obj).forEach((k) => {
      if ((!Array.isArray(obj[k]) && !isObject(obj[k])) || keepObjKeys) {
        const path = scope.concat(k).join('.').replace(/\.\[/g, '[')
        if (!keys.includes(path)) keys.push(path)
      }
      getKeys(obj[k], keepObjKeys, skipArrays, keys, scope.concat(k))
    }, keys)
  }
  return keys
}

export const filterObject = (obj: any, permittedColumns: string[]) => {
  const result = {}

  for (const key of permittedColumns) {
    set(result, key, get(obj, key, null))
  }

  return result
}
