import memoize from 'memoizee'

import { useProjectSelectOptions } from 'hooks/useSelectOptions'

export const useProjectName = (id?: number) => {
  const projectOptions = useProjectSelectOptions()

  const find = memoize((id: number) => {
    return projectOptions.options.find(
      (opt: any) => opt?.value !== undefined && opt.value === id,
    )
  })

  if (Array.isArray(projectOptions?.options) && id !== undefined) {
    const opt = find(id)
    if (opt?.name) return opt.name
    if (opt?.value) return `${opt.value}`
  }

  return `${id ?? ''}`
}
