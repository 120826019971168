import { Box, chakra, Divider, useBreakpointValue } from '@chakra-ui/react'

import { UserWithPermissions } from '@beaded/models'

import { ExternalLink, InternalLink } from 'components/Links'

import { useUserContext } from 'hooks/useUserContext'

import { IDivider, IRoute } from 'routes'

import { DefaultDrawer } from './DefaultDrawer'
import { OpenDrawer } from './OpenDrawer'

interface RenderDrawerEntryProps {
  item: (IRoute & { href: string }) | IDivider
  index: string | number
  global: ReturnType<typeof useUserContext>['global']
  superUser: ReturnType<typeof useUserContext>['superUser']
  permissions: UserWithPermissions['permissions'] | null
  routing: ReturnType<typeof useUserContext>['routing']
}

const renderDrawerEntry = ({
  item,
  index,
  global,
  routing,
  permissions,
}: RenderDrawerEntryProps) => {
  if (item.requiredPermissions && !item.requiredPermissions(permissions))
    return null
  if (item.requiresGlobalUser && !global.view) return null
  if (
    item?.divider === false &&
    item.limitUserVisibility &&
    !routing.canViewNavNode[item.href]
  )
    return null

  if (item.divider === true)
    return (
      <Box key={`divider-${index}`}>
        <Divider mt='0.5rem' mb='0.5rem' />
      </Box>
    )

  if (item?.divider === false && item.external)
    return (
      <Box key={item.href} p='1'>
        <ExternalLink href={item.href} name={item.name} />
      </Box>
    )

  if (item.href)
    return (
      <Box key={item.href} p='1'>
        <InternalLink href={item.href} name={item.name} />
      </Box>
    )

  return (
    <Box key={item.name} p='1'>
      <chakra.span>{item.name}</chakra.span>
    </Box>
  )
}

interface NavDrawerProps {
  isOpen: boolean
  onClose: () => void
  drawerContents: any[]
  auth: ReturnType<typeof useUserContext>
}

export const NavDrawer = ({
  isOpen,
  onClose,
  drawerContents,
  auth: { global, superUser, permissions, routing },
}: NavDrawerProps) => {
  const DrawerVariant = useBreakpointValue({
    base: {
      Component: DefaultDrawer,
      props    : { placement: 'top', isOpen, onClose },
    },
    // sm: { Component: DefaultDrawer, props: { placement: 'top', isOpen, onClose } },
    md: {
      Component: DefaultDrawer,
      props    : { placement: 'left', isOpen, onClose },
    },
    // lg: { Component: DefaultDrawer, props: { placement: 'left', isOpen, onClose } },
    xl: {
      Component: OpenDrawer,
      props    : {
        placement: 'left',
        isOpen   : true,
        trapFocus: false,
        onClose,
      },
    },
  })

  return (
    <>
      {DrawerVariant ? (
        <DrawerVariant.Component
          permissions={permissions}
          {...DrawerVariant.props}
        >
          {drawerContents
            .map((item: any, index: number) =>
              renderDrawerEntry({
                item,
                global,
                permissions,
                superUser,
                index,
                routing,
              }),
            )
            .filter((item: any) => item !== null)}
        </DrawerVariant.Component>
      ) : null}
    </>
  )
}
