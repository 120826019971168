import { LockIcon, UnlockIcon } from '@chakra-ui/icons'
import {
  FormLabel,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputStepper,
  useBoolean,
  IconButton,
  HStack,
  Text,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'

import { InfoTooltip } from 'components/InfoTooltip'

import { InputWrapperProps } from '../lib/FieldWrapper'

export const NumberInput = ({
  left,
  right,
  name,
  label,
  field,
  steppers,
  min,
  max,
  placeholder,
  requiresGlobalAdmin = false,
  global = { view: false, edit: false, admin: false },
  tooltip,
  onChange,
  description,
  style = {},
}: InputWrapperProps) => {
  const { setFieldValue, values } = useFormikContext()
  const [locked, setLocked] = useBoolean(true)

  return left || right ? (
    <>
      {label && <FormLabel htmlFor={name}>{label ?? name}</FormLabel>}
      {description ? (
        typeof description === 'string' ? (
          <Text>{description}</Text>
        ) : (
          description
        )
      ) : null}
      <HStack>
        <InputGroup>
          {left && <InputLeftAddon children={left} />}
          <ChakraNumberInput
            min={min}
            max={max}
            disabled={locked && requiresGlobalAdmin}
            {...field}
          >
            <NumberInputField
              type='number'
              placeholder={placeholder}
              {...field}
              {...style}
            />
            {steppers && (
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            )}
          </ChakraNumberInput>
          {right && <InputRightAddon children={right} />}
        </InputGroup>
        {tooltip && <InfoTooltip text={tooltip} />}

        {requiresGlobalAdmin && global.edit && (
          <IconButton
            icon={locked ? <LockIcon /> : <UnlockIcon />}
            onClick={setLocked.toggle}
            aria-label={locked ? 'locked' : 'unlocked'}
          />
        )}
      </HStack>
    </>
  ) : (
    <>
      {label && <FormLabel htmlFor={name}>{label ?? name}</FormLabel>}
      {description ? (
        typeof description === 'string' ? (
          <Text>{description}</Text>
        ) : (
          description
        )
      ) : null}
      <HStack>
        <ChakraNumberInput
          min={min}
          max={max}
          isDisabled={locked && requiresGlobalAdmin}
          {...field}
        >
          <NumberInputField
            type='number'
            placeholder={placeholder}
            {...field}
            {...(typeof onChange === 'function'
              ? {
                onChange: (e) => {
                  onChange(e, setFieldValue, values)
                  field.onChange(e)
                },
              }
              : {})}
            {...style}
          />
          {steppers && (
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          )}
        </ChakraNumberInput>
        {tooltip && <InfoTooltip text={tooltip} />}

        {requiresGlobalAdmin && global.edit && (
          <IconButton
            icon={locked ? <LockIcon /> : <UnlockIcon />}
            onClick={setLocked.toggle}
            aria-label={locked ? 'locked' : 'unlocked'}
          />
        )}
      </HStack>
    </>
  )
}
