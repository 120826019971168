import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { loggerId, type LoggerId } from './Logger.js'
import { modemId, type ModemId } from './Modem.js'

/** Identifier type for public.modem_history_ */
export type ModemHistoryId = number & { __brand: 'ModemHistoryId' }

/** Represents the table public.modem_history_ */
export default interface ModemHistoryTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<ModemHistoryId, ModemHistoryId | undefined, ModemHistoryId>

  /** Database type: pg_catalog.timestamptz */
  startedAt: ColumnType<Date, Date | string, Date | string>

  /** Database type: pg_catalog.timestamptz */
  endedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.text */
  notes: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.int4 */
  modemId: ColumnType<ModemId, ModemId, ModemId>

  /** Database type: pg_catalog.int4 */
  loggerId: ColumnType<LoggerId, LoggerId, LoggerId>
}

export type ModemHistory = Selectable<ModemHistoryTable>

export type ModemHistoryInitializer = Insertable<ModemHistoryTable>

export type ModemHistoryMutator = Updateable<ModemHistoryTable>

export const modemHistoryId = z.number() as unknown as z.Schema<ModemHistoryId>

export const modemHistory = z.object({
  id       : modemHistoryId,
  startedAt: z.coerce.date(),
  endedAt  : z.coerce.date().nullable().optional(),
  notes    : z.string().nullable().optional(),
  modemId  : modemId,
  loggerId : loggerId,
}) as unknown as z.Schema<ModemHistory>

export const modemHistoryInitializer = z.object({
  id       : modemHistoryId.optional(),
  startedAt: z.coerce.date(),
  endedAt  : z.coerce.date().optional().nullable().optional(),
  notes    : z.string().optional().nullable().optional(),
  modemId  : modemId,
  loggerId : loggerId,
}) as unknown as z.Schema<ModemHistoryInitializer>

export const modemHistoryMutator = z.object({
  id       : modemHistoryId.optional(),
  startedAt: z.coerce.date().optional(),
  endedAt  : z.coerce.date().optional().nullable().optional(),
  notes    : z.string().optional().nullable().optional(),
  modemId  : modemId.optional(),
  loggerId : loggerId.optional(),
}) as unknown as z.Schema<ModemHistoryMutator>
