import Joi from 'joi'

export const TS = {
  created     : Joi.date().iso().greater('2009-01-01T00:00:00Z').max('now'),
  updated     : Joi.date().iso().greater('2009-01-01T00:00:00Z').max('now'),
  deleted     : Joi.date().iso().greater('2009-01-01T00:00:00Z'),
  expired     : Joi.date().iso().greater('2009-01-01T00:00:00Z'),
  locked      : Joi.date().iso().greater('2009-01-01T00:00:00Z'),
  archived    : Joi.date().iso().greater('2009-01-01T00:00:00Z'),
  unsubscribed: Joi.date().iso().greater('2009-01-01T00:00:00Z'),
}

export const ID_PERM = {
  id   : Joi.number().integer(),
  admin: Joi.boolean(),
  edit : Joi.boolean(),
  view : Joi.boolean(),
  ts   : TS,
}

export const TEMP = Joi.string().valid('F', 'C').uppercase()
export const LENGTH = Joi.string().valid('in', 'ft', 'm', 'cm').lowercase()

export const UNITS = Joi.object({
  temp  : TEMP,
  length: LENGTH,
})

export const BY_USER = Joi.object({
  username: Joi.string(),
  id      : Joi.alternatives(Joi.number().integer(), Joi.string().uuid()),
})
