import { Text, useBreakpointValue } from '@chakra-ui/react'
import { format as formatDate } from 'date-fns'
import memoize from 'memoizee'
import { Cell } from 'react-table'

import { actions } from 'components/ActionsBlock'
import { InternalLink } from 'components/Links'
import { NotesModal } from 'components/NotesModal'
import { DataTable } from 'components/Table'

import { useOrgName } from 'hooks/useOrgName'
import { useProjectName } from 'hooks/useProjectName'

import { convertLength } from 'lib/converter'
import { getLastCalibration } from 'lib/lastCalibration'
import { getLastCableUse } from 'lib/lastUse'
import { deleteCable } from 'lib/mutations/delete/deleteCable'
import { updateCableForm } from 'lib/mutations/update/updateCable'

import type { Cable } from 'types/Cable'
import type { KeysOf } from 'types/common'

type CableCell = Cell & {
  row: {
    original: Cable
  }
}

interface CablesTableProps {
  data: any
  globalFilter: any
  projection: CableTableProjection
  download: any
  sortBy?: Array<any>
}

export const CablesTable = ({
  data = [],
  globalFilter,
  projection = 'default',
  download,
  sortBy = [],
}: CablesTableProps) => {
  const zoom = useBreakpointValue({
    base: 0.9,
    md  : 1,
  })

  return (
    <>
      {data.length ? (
        <DataTable
          data={data}
          columns={projections[projection]}
          initialGlobalFilter={globalFilter}
          hiddenColumns={hiddenColumns}
          sortBy={sortBy}
          download={download}
          sx={{
            zoom,
            mozTransform: `scale(${zoom})`,
          }}
        />
      ) : (
        <Text>no data found</Text>
      )}
    </>
  )
}

const hiddenColumns = ['id']

const columns = {
  id: { Header: 'id', accessor: 'id' },

  serial: {
    Header  : 'Serial',
    accessor: 'serial',
    Cell    : ({ value, row }: CableCell) => {
      if (value)
        return <InternalLink name={value} href={`/cables/sn${value}`} />

      return <InternalLink name={value} href={`/cables/${row.original.id}`} />
    },
  },

  serialNoLink: {
    Header  : 'Serial',
    accessor: 'serial',
    Cell    : ({ value, row }: CableCell) => {
      if (value) return value
      return 'Draft'
    },
  },

  adminSerial: {
    Header  : 'Serial',
    accessor: 'serial',
    Cell    : ({ value, row }: CableCell) => {
      if (!value)
        return (
          <InternalLink
            name={value ?? 'Draft'}
            href={`/cables/${row.original.id}`}
          />
        )

      if (typeof row?.original?._filter === 'function') {
        if (row.original._filter(row?.original))
          if (row.values.serial)
            return (
              <InternalLink
                name={value}
                href={`/cables/sn${row.values.serial}`}
              />
            )
          else
            return (
              <InternalLink name={value} href={`/cables/${row.original.id}`} />
            )
        else {
          return (
            <Text color='red' textDecoration='line-through'>
              <InternalLink name={value} href={`/cables/sn${value}`} />
            </Text>
          )
        }
      }

      return <InternalLink name={value} href={`/cables/${row.original.id}`} />
    },
  },

  name: {
    Header  : 'Name',
    accessor: 'name',
    Cell    : ({ value, row }: CableCell) => {
      if (row.original.serial)
        return (
          <InternalLink
            name={value}
            href={`/cables/sn${row.original.serial}`}
          />
        )

      return <InternalLink name={value} href={`/cables/${row.original.id}`} />
    },
  },

  nameNoLink: {
    Header  : 'Name',
    accessor: 'name',
  },

  adminName: {
    Header  : 'Name',
    accessor: 'name',
    Cell    : ({ value, row }: CableCell) => {
      const lastUse = getLastCableUse(row.original?.use)
      const projectName = useProjectName(lastUse?.project?.id)
      const orgName = useOrgName(lastUse?.org?.id)

      if (typeof row?.original?._filter === 'function')
        if (row.original._filter(row?.original))
          return (
            <NameLink
              name={value}
              serial={row.values.serial}
              id={row.original.id}
            />
          )
        else {
          const { project, org } = lastUse
          if (project && org) {
            return (
              <Text>
                This cable is currently in{' '}
                <InternalLink
                  name={projectName}
                  href={`/projects/${project?.id}`}
                />{' '}
                for <InternalLink name={orgName} href={`/orgs/${org?.id}`} />.
              </Text>
            )
          }
          else if (org) {
            return (
              <Text>
                This cable is currently in{' '}
                <InternalLink name={orgName} href={`/orgs/${org?.id}`} />.
              </Text>
            )
          }
          else {
            return (
              <Text>This cable is not currently in this project or org.</Text>
            )
          }
        }

      return <InternalLink name={value} href={`/cables/${row.original.id}`} />
    },
  },

  org: {
    Header  : 'Org',
    accessor: memoize((originalRow: any) => getLastCableUse(originalRow?.use)),
    Cell    : ({ value, row }: CableCell) => {
      const orgName = useOrgName(value?.org?.id)
      return <InternalLink name={orgName} href={`/orgs/${value?.org?.id}`} />
    },
  },

  adminOrg: {
    Header  : 'Org',
    accessor: memoize((originalRow: any) => getLastCableUse(originalRow?.use)),
    Cell    : ({ value, row }: CableCell) => {
      const orgName = useOrgName(value?.org?.id)
      return <InternalLink name={orgName} href={`/orgs/${value?.org?.id}`} />
    },
  },

  project: {
    Header  : 'Project',
    accessor: (originalRow: any, rowIndex: any) =>
      getLastCableUse(originalRow?.use),
    Cell: ({ value, row }: CableCell) => {
      const projectName = useProjectName(value?.project?.id)
      return (
        <InternalLink
          name={projectName}
          href={`/projects/${value?.project?.id}`}
        />
      )
    },
  },

  adminProject: {
    Header  : 'Project',
    accessor: (originalRow: any, rowIndex: any) =>
      getLastCableUse(originalRow?.use),
    Cell: ({ value, row }: CableCell) => {
      const projectName = useProjectName(value?.project?.id)

      return (
        <InternalLink
          name={projectName}
          href={`/projects/${value?.project?.id}`}
        />
      )
    },
  },

  lastModifiedBy: {
    Header  : 'Last Modified By',
    accessor: 'by_user.username',
  },

  sensors: {
    Header   : 'Sensors',
    accessor : 'sensorCount',
    isNumeric: true,
  },

  cableLengths: {
    Header    : 'Cable Lengths',
    isCentered: true,
    columns   : [
      {
        Header  : 'Lead',
        accessor: 'leadLength',
        Cell    : ({ value, row }: CableCell) => {
          return (
            <Text>
              {convertLength(value, 'mm', row.original.display.units.length)}{' '}
              {row.original.display.units.length}
            </Text>
          )
        },
        isNumeric: true,
      },
      {
        Header  : 'Instrumented',
        accessor: 'bodyLength',
        Cell    : ({ value, row }: CableCell) => {
          return (
            <Text>
              {convertLength(value, 'mm', row.original.display.units.length)}{' '}
              {row.original.display.units.length}
            </Text>
          )
        },
        isNumeric: true,
      },
      {
        Header  : 'Total',
        accessor: (originalRow: any, rowIndex: any) =>
          originalRow?.leadLength + originalRow?.bodyLength,
        Cell: ({ value, row }: CableCell) => {
          return (
            <Text>
              {convertLength(value, 'mm', row.original.display.units.length)}{' '}
              {row.original.display.units.length}
            </Text>
          )
        },
        isNumeric: true,
      },
    ],
  },

  calibrated: {
    Header  : 'Calibrated',
    accessor: (originalRow: any, rowIndex: any) =>
      new Date(getLastCalibration(originalRow?.events)?.date ?? 0).getTime(),
    Cell: ({ value, row }: CableCell) => {
      if (value === undefined || value === 0) return null

      const date = new Date(value)

      const twoAndAHalfYearsAgo = new Date()
      twoAndAHalfYearsAgo.setFullYear(new Date().getFullYear() - 2)
      twoAndAHalfYearsAgo.setMonth(new Date().getMonth() - 6)

      const twoYearsAgo = new Date()
      twoYearsAgo.setFullYear(new Date().getFullYear() - 2)

      return (
        <Text
          as='span'
          color={
            date < twoAndAHalfYearsAgo
              ? 'red'
              : date < twoYearsAgo
                ? 'orange'
                : 'green'
          }
        >
          {formatDate(date, 'yyyy-MM-dd')}
        </Text>
      )
    },
  },

  unitPrice: {
    Header  : 'Unit Price',
    accessor: 'unitPrice',
    Cell    : ({ value, row }: CableCell) => {
      if (value === undefined) return null

      const formatter = new Intl.NumberFormat('en-US', {
        style   : 'currency',
        currency: 'USD',
      })
      const format = (num: any) => formatter.format(num).substr(1)

      return <Text>${format(value)}</Text>
    },
    isNumeric: true,
  },

  totalPrice: {
    Header  : 'Total Price',
    accessor: 'totalPrice',
    Cell    : ({ value, row }: CableCell) => {
      if (value === undefined) return null

      const formatter = new Intl.NumberFormat('en-US', {
        style   : 'currency',
        currency: 'USD',
      })
      const format = (num: any) => formatter.format(num).substr(1)

      return <Text>${format(value)}</Text>
    },
    isNumeric: true,
  },

  notes: {
    Header   : 'Notes',
    accessor : 'notes',
    isNumeric: true,
    Cell     : ({ value, row }: CableCell) => {
      if (!value) return null

      return <NotesModal note={value} name={row.values?.name} />
    },
  },

  locked: {
    Header  : 'Locked',
    accessor: 'ts.locked',
    Cell    : ({ value, row }: CableCell) => {
      if (value)
        return (
          <Text
            {...(row.values.status === 'retired'
              ? { color: 'gray.500', fontStyle: 'italic' }
              : {})}
          >
            {value !== undefined && value !== 0 && value !== null
              ? formatDate(new Date(value), 'yyyy-MM-dd')
              : null}
          </Text>
        )

      return null
    },
  },

  updated: {
    Header  : 'Updated',
    accessor: 'ts.updated',
    Cell    : ({ value, row }: CableCell) => {
      if (value)
        return (
          <Text
            {...(row.values.status === 'retired'
              ? { color: 'gray.500', fontStyle: 'italic' }
              : {})}
          >
            {value !== undefined && value !== 0 && value !== null
              ? formatDate(new Date(value), 'yyyy-MM-dd')
              : null}
          </Text>
        )

      return null
    },
  },

  created: {
    Header  : 'Created',
    accessor: 'ts.created',
    Cell    : ({ value, row }: CableCell) => {
      if (value)
        return (
          <Text
            {...(row.values.status === 'retired'
              ? { color: 'gray.500', fontStyle: 'italic' }
              : {})}
          >
            {value !== undefined && value !== 0 && value !== null
              ? formatDate(new Date(value), 'yyyy-MM-dd')
              : null}
          </Text>
        )

      return null
    },
  },

  units: {
    Header  : 'units',
    accessor: 'display.units.length',
  },

  actions: actions({ formInfo: updateCableForm, deleteFn: deleteCable }),
}

const projections = {
  default: [
    columns.id,
    columns.serial,
    columns.name,
    columns.org,
    columns.project,
    columns.cableLengths,
    columns.sensors,
    columns.calibrated,
    columns.actions,
  ],

  projectPage: [
    columns.id,
    columns.serialNoLink,
    columns.nameNoLink,
    columns.cableLengths,
    columns.sensors,
    columns.calibrated,
  ],

  adminProjectPage: [
    columns.id,
    columns.adminSerial,
    columns.adminName,
    columns.adminOrg,
    columns.adminProject,
    columns.cableLengths,
    columns.sensors,
    columns.unitPrice,
    columns.totalPrice,
    columns.calibrated,
    columns.notes,
    columns.actions,
  ],

  orgPage: [
    columns.id,
    columns.serialNoLink,
    columns.nameNoLink,
    columns.project,
    columns.cableLengths,
    columns.sensors,
    columns.calibrated,
  ],

  adminOrgPage: [
    columns.id,
    columns.adminSerial,
    columns.adminName,
    columns.adminOrg,
    columns.adminProject,
    columns.cableLengths,
    columns.sensors,
    columns.unitPrice,
    columns.totalPrice,
    columns.calibrated,
    columns.notes,
    columns.actions,
  ],

  globalPage: [
    columns.id,
    columns.serialNoLink,
    columns.nameNoLink,
    columns.cableLengths,
    columns.sensors,
    columns.calibrated,
    columns.org,
    columns.project,
  ],

  draftPage: [
    columns.id,
    columns.name,
    columns.org,
    columns.project,
    columns.lastModifiedBy,
    columns.cableLengths,
    columns.sensors,
    columns.units,
    columns.calibrated,
  ],

  adminGlobalPage: [
    columns.id,
    columns.adminSerial,
    columns.adminName,
    columns.adminOrg,
    columns.adminProject,
    columns.cableLengths,
    columns.locked,
    columns.updated,
    columns.created,
    columns.calibrated,
    columns.sensors,
    columns.notes,
    columns.actions,
  ],

  adminDraftPage: [
    columns.id,
    columns.adminName,
    columns.adminOrg,
    columns.adminProject,
    columns.cableLengths,
    columns.sensors,
    columns.notes,
    columns.lastModifiedBy,
    columns.actions,
  ],
}

export type CableTableProjection = KeysOf<typeof projections>

interface NameLinkProps {
  name: string
  serial?: number | string
  id?: number | string
}
const NameLink = ({ name, serial, id }: NameLinkProps) => {
  if (serial) return <InternalLink name={name} href={`/cables/sn${serial}`} />
  else return <InternalLink name={name} href={`/cables/${id}`} />
}
