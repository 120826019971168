import {
  Button,
  Divider,
  Grid,
  GridItem,
  IconButton,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { useState } from 'react'

import { reading } from 'api'

import { ErrorWrapper } from 'components/ErrorWrapper'

import { ModalContainer } from 'containers/ModalContainer'

import { combineDecodeList } from 'lib/parseDecodeOrder'

import { Site } from 'types'
import { Obj } from 'types/common'

interface ReprocessSiteModalProps {
  site: Site
  iconButton?: JSX.Element
  iconButtonProps?: any
  buttonText?: string
}

export const ReprocessSiteModal = ({
  site,
  iconButton,
  iconButtonProps,
  buttonText = 'Reprocess Data',
}: ReprocessSiteModalProps) => {
  const filters = buildFilters(site)

  const [results, setResults] = useState<any>()

  const reprocess = async () => {
    const res = await reading.reprocess({ site: { id: site.id } })
    setResults(res.results)
  }

  return (
    <ModalContainer
      TriggerButton={({ onClick }) =>
        iconButton ? (
          <Tooltip label={buttonText}>
            <IconButton
              icon={iconButton}
              onClick={onClick}
              {...iconButtonProps}
            />
          </Tooltip>
        ) : (
          <Tooltip label={buttonText}>
            <Button onClick={onClick}>{buttonText}</Button>
          </Tooltip>
        )
      }
      header='Reprocess Data'
    >
      <ErrorWrapper>
        <>
          <Grid templateColumns='auto 1fr' gap={3}>
            <GridItem colSpan={2}>
              <Text>Name: {site.name}</Text>
            </GridItem>
            <GridItem colSpan={2}>
              <Text>ID: {site.id}</Text>
            </GridItem>
            <GridItem colSpan={2}>
              <Divider />
            </GridItem>
            {filters.readingsDecodeOptions.map((d, i) => [
              <GridItem key={`${i}-header`} colSpan={2}>
                <Text layerStyle='heading'>Filter {i + 1}</Text>
              </GridItem>,
              <GridItem key={`${i}-imei-label`} fontWeight='semibold'>
                <Text>IMEI</Text>
              </GridItem>,
              <GridItem key={`${i}-imei-value`}>
                <Text>{d.filter['modem.imei']}</Text>
              </GridItem>,
              <GridItem key={`${i}-begin-label`}>
                <Text>Begin</Text>
              </GridItem>,
              <GridItem key={`${i}-begin-value`}>
                <Text>{d.filter['ts.email'].$gte ?? null}</Text>
              </GridItem>,
              <GridItem key={`${i}-end-label`}>
                <Text>End</Text>
              </GridItem>,
              <GridItem key={`${i}-end-value`}>
                <Text>{d.filter['ts.email'].$lt ?? null}</Text>
              </GridItem>,
              <GridItem key={`${i}-serial-label`}>
                <Text>Serial</Text>
              </GridItem>,
              <GridItem key={`${i}-serial-value`}>
                <Text>{d.options.serial}</Text>
              </GridItem>,
              <GridItem key={`${i}-decode-label`}>
                <Text>Decode</Text>
              </GridItem>,
              <GridItem key={`${i}-decode-value`}>
                <Text>{combineDecodeList(d.options.decode)}</Text>
              </GridItem>,
              <GridItem key={`${i}-divider`} colSpan={2}>
                <Divider />
              </GridItem>,
            ])}
            <GridItem colSpan={2}>
              <Text layerStyle='heading'>Delete Filter</Text>
            </GridItem>
            {filters.deleteDataFilter.$or.map((d, i) =>
              Object.keys(d).map((k) => [
                <GridItem key={`${i}-label`}>
                  {d[k].$lt ? (
                    <Text>Before</Text>
                  ) : d[k].$gt ? (
                    <Text>After</Text>
                  ) : (
                    <></>
                  )}
                </GridItem>,
                <GridItem key={`${i}-value`}>
                  {d[k].$lt ? (
                    <Text>{d[k].$lt.toISOString()}</Text>
                  ) : d[k].$gt ? (
                    <Text>{d[k].$gt.toISOString()}</Text>
                  ) : (
                    <></>
                  )}
                </GridItem>,
              ]),
            )}
            <GridItem colSpan={2}>
              <Divider />
            </GridItem>
            <GridItem colSpan={2} display='grid'>
              {results && (
                <Text>
                  {results?.removed} removed, {results?.reprocessedRecords}{' '}
                  updated
                </Text>
              )}
              <Button onClick={reprocess} mt={2} justifySelf='flex-end'>
                Reprocess
              </Button>
            </GridItem>
          </Grid>
        </>
      </ErrorWrapper>
    </ModalContainer>
  )
}

const buildFilters = (s: Site) => {
  const readingsDecodeOptions = []
  const deleteDataFilter = {
    'site.id': s.id,
    $or      : [{ 'ts.email': { $exists: false } }] as Obj[],
  }

  for (const l of s.use.logger) {
    for (const c of s.use.cable) {
      // cable use begins before logger begin and ends after logger begin but before logger end
      if (
        new Date(c.begin ?? 0).getTime() <= new Date(l.begin ?? 0).getTime() &&
        (!c.end ||
          !l.end ||
          (new Date(c.end ?? 0).getTime() <= new Date(l.end ?? 0).getTime() &&
            new Date(c.end ?? 0).getTime() >= new Date(l.begin ?? 0).getTime()))
      ) {
        readingsDecodeOptions.push({
          filter: {
            'data.packet': { $exists: true },
            'modem.imei' : l.imei,
            'ts.email'   : {
              $exists: true,
              $gte   : l.begin,
              ...(c.end ? { $lt: c.end } : { $lt: l.end }),
            },
          },
          options: {
            decode: l.decode ?? c.decode ?? [],
            serial: c.serial,
          },
        })
      }
      // cable use begins after logger begin and ends after logger end
      else if (
        new Date(c.begin ?? 0).getTime() >= new Date(l.begin ?? 0).getTime() &&
        (!c.end ||
          !l.end ||
          new Date(c.end ?? 0).getTime() >= new Date(l.end ?? 0).getTime())
      ) {
        readingsDecodeOptions.push({
          filter: {
            'data.packet': { $exists: true },
            'modem.imei' : l.imei,
            'ts.email'   : {
              $exists: true,
              $gte   : c.begin,
              ...(l.end ? { $lt: l.end } : {}),
            },
          },
          options: {
            decode: l.decode ?? c.decode ?? [],
            serial: c.serial,
          },
        })
      }
      // cable use begins after logger begin and ends before logger end
      else if (
        new Date(c.begin ?? 0).getTime() >= new Date(l.begin ?? 0).getTime() &&
        (!c.end ||
          !l.end ||
          new Date(c.end ?? 0).getTime() <= new Date(l.end ?? 0).getTime())
      ) {
        readingsDecodeOptions.push({
          filter: {
            'data.packet': { $exists: true },
            'modem.imei' : l.imei,
            'ts.email'   : {
              $exists: true,
              $gte   : c.begin,
              ...(c.end ? { $lt: c.end } : { $lt: l.end }),
            },
          },
          options: {
            decode: l.decode ?? c.decode ?? [],
            serial: c.serial,
          },
        })
      }
    }
  }

  let firstDate = undefined
  let lastDate = undefined

  for (const d of readingsDecodeOptions) {
    if (
      d.filter['ts.email'].$gte &&
      (firstDate === undefined ||
        new Date(d.filter['ts.email'].$gte ?? 0).getTime() <
          new Date(firstDate).getTime())
    )
      firstDate = new Date(d.filter['ts.email'].$gte)

    if (
      d.filter['ts.email'].$lt &&
      (lastDate === undefined ||
        new Date(d.filter['ts.email'].$lt ?? 0).getTime() >
          new Date(lastDate).getTime())
    )
      lastDate = new Date(d.filter['ts.email'].$lt)

    if (d.filter['ts.email'].$lt === undefined) lastDate = undefined
  }

  if (firstDate !== undefined)
    deleteDataFilter.$or.push({ 'ts.email': { $lt: firstDate } })
  if (lastDate !== undefined)
    deleteDataFilter.$or.push({ 'ts.email': { $gt: lastDate } })

  return {
    readingsDecodeOptions,
    deleteDataFilter,
  }
}
