import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'

import {
  Organization,
  OrganizationId,
  OrganizationInitializer,
  OrganizationMutator,
} from '@beaded/models'

import type { ID } from 'types/common'

import * as apiUtils from '.'

const url = '/org'

export const getCurrent = () => apiUtils.GET(`${url}/`)

export const get = (id: OrganizationId, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/${id}`, merge({}, apiUtils.extrasFlags, options))

export const getName = (id: OrganizationId) => apiUtils.GET(`${url}/${id}/name`)

export const getAll = (
  options: AxiosRequestConfig = {},
): Promise<Array<Organization>> =>
  apiUtils.GET(`${url}/`, merge({}, apiUtils.extrasFlags, options))

export const create = (data: OrganizationInitializer) =>
  apiUtils.POST(`${url}/`, data)

export const update = (id: OrganizationId, data: OrganizationMutator) =>
  apiUtils.PUT(`${url}/${id}`, data)

export const remove = (id: OrganizationId) => apiUtils.DELETE(`${url}/${id}`)

export const getMenuProjection = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({ params: { projection: 'menu' } }, options))

export const publicPage = {
  get: (id: ID, options: AxiosRequestConfig = {}) =>
    apiUtils.GET(
      `/public${url}/${id}`,
      merge({}, apiUtils.extrasFlags, options),
    ),
  getAll: (options: AxiosRequestConfig = {}) =>
    apiUtils.GET(`/public${url}/`, merge({}, apiUtils.extrasFlags, options)),
}

export const admin = {
  get: (id: OrganizationId, options: AxiosRequestConfig = {}) =>
    get(id, merge({}, apiUtils.globalFlags, options)),
  getAll: (options: AxiosRequestConfig = {}) =>
    getAll(merge({}, apiUtils.globalFlags, options)),
  getMenuProjection: (options: AxiosRequestConfig = {}) =>
    getMenuProjection(merge({}, apiUtils.globalFlags, options)),
}
