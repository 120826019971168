import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import {
  siteModemHistoryId,
  type SiteModemHistoryId,
} from './SiteModemHistory.js'

/** Identifier type for public.site_modem_history_label_ */
export type SiteModemHistoryLabelId = number & {
  __brand: 'SiteModemHistoryLabelId'
}

/** Represents the table public.site_modem_history_label_ */
export default interface SiteModemHistoryLabelTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<
    SiteModemHistoryLabelId,
    SiteModemHistoryLabelId | undefined,
    SiteModemHistoryLabelId
  >

  /** Database type: pg_catalog.numeric */
  number: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.text */
  note: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  visible: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.int4 */
  siteModemHistoryId: ColumnType<
    SiteModemHistoryId,
    SiteModemHistoryId,
    SiteModemHistoryId
  >
}

export type SiteModemHistoryLabel = Selectable<SiteModemHistoryLabelTable>

export type SiteModemHistoryLabelInitializer =
  Insertable<SiteModemHistoryLabelTable>

export type SiteModemHistoryLabelMutator =
  Updateable<SiteModemHistoryLabelTable>

export const siteModemHistoryLabelId =
  z.number() as unknown as z.Schema<SiteModemHistoryLabelId>

export const siteModemHistoryLabel = z.object({
  id                : siteModemHistoryLabelId,
  number            : z.number().nullable().optional(),
  note              : z.string().nullable().optional(),
  visible           : z.boolean().nullable().optional(),
  siteModemHistoryId: siteModemHistoryId,
}) as unknown as z.Schema<SiteModemHistoryLabel>

export const siteModemHistoryLabelInitializer = z.object({
  id                : siteModemHistoryLabelId.optional(),
  number            : z.number().optional().nullable().optional(),
  note              : z.string().optional().nullable().optional(),
  visible           : z.boolean().optional().nullable().optional(),
  siteModemHistoryId: siteModemHistoryId,
}) as unknown as z.Schema<SiteModemHistoryLabelInitializer>

export const siteModemHistoryLabelMutator = z.object({
  id                : siteModemHistoryLabelId.optional(),
  number            : z.number().optional().nullable().optional(),
  note              : z.string().optional().nullable().optional(),
  visible           : z.boolean().optional().nullable().optional(),
  siteModemHistoryId: siteModemHistoryId.optional(),
}) as unknown as z.Schema<SiteModemHistoryLabelMutator>
