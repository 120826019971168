import { Button, Text } from '@chakra-ui/react'
import { format } from 'date-fns-tz'
import { Cell } from 'react-table'

import type { UserWithPermissions } from '@beaded/models'

import { actions } from 'components/ActionsBlock'
import { InternalLink } from 'components/Links'
import { DataTable } from 'components/Table'
import { UserManagementModal } from 'components/UserManagementModal'
import { UserPermsModal } from 'components/UserPermsModal'

import { useUserContext } from 'hooks/useUserContext'

import { deleteUser } from 'lib/mutations/delete/deleteUser'

import type { KeysOf } from 'types/common'

type UserCell = Cell & {
  row: {
    original: UserWithPermissions
  }
}

interface UsersTableProps {
  data: any
  globalFilter: any
  projection: UsersTableProjection
  download: any
}

export const UsersTable = ({
  data = [],
  globalFilter,
  projection = 'default',
  download,
}: UsersTableProps) => (
  <>
    {data.length ? (
      <DataTable
        data={data}
        columns={projections[projection]}
        hiddenColumns={hiddenColumns}
        initialGlobalFilter={globalFilter}
        download={download}
      />
    ) : (
      <Text>no data found</Text>
    )}
  </>
)

const hiddenColumns = [] as string[]

const columns = {
  id: {
    Header  : 'id',
    accessor: (row: UserWithPermissions) => {
      return Array.isArray(row?.id) ? JSON.stringify(row.id) : row?.id
    },
  },

  name: {
    Header  : 'Name',
    accessor: (row: UserWithPermissions) => `${row.nameFirst} ${row.nameLast}`,
  },

  email: {
    Header  : 'Email',
    accessor: 'email',
  },

  org: {
    Header  : 'Org(s)',
    accessor: 'org',
    Cell    : ({ row }: UserCell) =>
      row?.original?.permissions?.organizations?.map((o, idx, arr) => (
        <div key={o.id}>
          <InternalLink
            name={o.organizationName ?? o.id}
            href={`/orgs/${o.id}`}
          />
          {`${idx < arr.length - 1 ? ',' : ''}`}
          <br />
        </div>
      )),
  },

  perms: {
    Header  : 'Permissions',
    accessor: (row: UserWithPermissions) => JSON.stringify(row.permissions),
    Cell    : ({ row }: UserCell) => (
      <UserPermsModal
        userId={
          Array.isArray(row?.original?.id)
            ? row?.original?.id?.[0]
            : row?.original?.id
        }
        user={row?.original}
        iconButtonProps={{ size: 'sm', 'aria-label': 'permissions' }}
        center
      />
    ),
  },

  switchToUser: {
    Header: 'Switch To',
    Cell  : ({ row }: UserCell) => {
      const { switchToUser, global, currentUser } = useUserContext()

      if (global.edit !== true) return null

      if (
        row?.original?.id !== undefined &&
        currentUser?.id === row?.original?.id
      )
        return null

      return (
        <Button size='sm' onClick={() => switchToUser(row?.original?.email)}>
          Switch To User
        </Button>
      )
    },
  },

  manage: {
    Header: 'Manage',
    Cell  : ({ row }: UserCell) => {
      const { global } = useUserContext()

      if (global.edit !== true) return null

      return (
        <UserManagementModal
          title={`Manage User: ${row.original.nameFirst} ${row.original.nameLast}`}
          userId={row?.original?.id}
        />
      )
    },
  },

  lastLogin: {
    Header  : 'Last Login',
    accessor: 'lastLogin',
    Cell    : ({ value, row }: UserCell) => (
      <Text>
        {value ? format(new Date(value), 'yyyy-MM-dd HH:mm zzz') : 'never'}
        <br />
        {row.original.lastUsername}
      </Text>
    ),
  },

  actions: actions({
    hideUpdateButton      : true,
    deleteFn              : deleteUser,
    infoModalHiddenColumns: ['perm'],
  }),
}

const projections = {
  default: [
    // columns.id,
    columns.name,
    columns.email,
    columns.org,
    columns.switchToUser,
    columns.manage,
    columns.actions,
  ],

  recentUsers: [
    // columns.id,
    columns.name,
    columns.email,
    columns.org,
    columns.lastLogin,
    columns.manage,
    columns.actions,
  ],
}

export type UsersTableProjection = KeysOf<typeof projections>
