import memoize from 'memoizee'

export const sortCableUse = memoize((a: any, b: any) => {
  if (a?.date === undefined && b?.date === undefined) return 0
  if (a?.date === undefined && b?.date !== undefined) return -1
  if (a?.date !== undefined && b?.date === undefined) return 1

  const dateA = new Date(a?.date)
  const dateB = new Date(b?.date)

  if (dateA > dateB) return 1
  if (dateA < dateB) return -1
  return 0
})

export const getLastCableUse = (useArray: Array<any> = []) => {
  if (useArray === undefined || useArray === null) {
    // console.info('undefined use array for cable');
    return
  }
  try {
    const sorted = useArray?.sort(sortCableUse)
    return sorted[sorted?.length - 1]
  }
  catch (error) {
    console.error(error)
    console.info(useArray)
  }
}

export const sortLoggerUse = memoize((a: any, b: any) => {
  if (a?.end === undefined && b?.end === undefined) return 0
  if (a?.end === undefined && b?.end !== undefined) return 1
  if (a?.end !== undefined && b?.end === undefined) return -1

  const dateA = new Date(a?.end)
  const dateB = new Date(b?.end)

  if (dateA > dateB) return 1
  if (dateA < dateB) return -1
  return 0
})

export const getLastLoggerUse = (useArray: Array<any> = []) => {
  const sorted = useArray.sort(sortLoggerUse)
  return sorted[sorted?.length - 1]
}

export const sortModemUse = memoize((a: any, b: any) => {
  if (a?.end === undefined && b?.end === undefined) return 0
  if (a?.end === undefined && b?.end !== undefined) return 1
  if (a?.end !== undefined && b?.end === undefined) return -1

  const dateA = new Date(a?.end)
  const dateB = new Date(b?.end)

  if (dateA > dateB) return 1
  if (dateA < dateB) return -1
  return 0
})

export const getLastModemUse = (useArray: Array<any> = []) => {
  const sorted = useArray.sort(sortModemUse)
  return sorted[sorted?.length - 1]
}
