import { Input, chakra } from '@chakra-ui/react'
import React from 'react'
import { useAsyncDebounce, UseGlobalFiltersInstanceProps } from 'react-table'

import { useRouter } from 'hooks/useRouter'

interface GlobalFilterProps {
  preGlobalFilteredRows: UseGlobalFiltersInstanceProps<any>['preGlobalFilteredRows']
  setGlobalFilter: UseGlobalFiltersInstanceProps<any>['setGlobalFilter']
  globalFilter?: string
}

export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: GlobalFilterProps) => {
  const { history } = useRouter()
  const count = preGlobalFilteredRows?.length ?? 0
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value ?? undefined)
    history.push({ search: `?q=${value}` })
  }, 200)

  return (
    <chakra.span>
      Search:{' '}
      <Input
        value={value ?? ''}
        onChange={(e) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder={`${count} records...`}
      />
    </chakra.span>
  )
}
